import React, { useState, useEffect } from 'react'
import swal from 'sweetalert2'
import { MdMoreVert } from "react-icons/md"
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { Formik, Form } from 'formik'
import BootstrapTable from 'react-bootstrap-table-next'
import { AllowedFileTypes, audFormatter, StatusClass, UserGroup, FileBidCategories, TradeStatus } from 'app/utils/constants'
import { IwtbCheckbox, IwtbInput, IwtbTextArea, IwtbMultiSelect, IwtbFileUpload } from 'app/widgets'
import { bidSchema } from 'app/utils/schema'
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import { useAuthState } from 'app/auth'
import { Dropdown, Badge } from 'react-bootstrap'
import { classList } from '@utils'
import uuid from 'react-uuid'
import { useHistory, useParams } from "react-router-dom";
import { getTrades } from 'app/utils/common'

export const SubmitBidWidget = ({ bidService }) => {

  const { projectId } = useParams();
  const history = useHistory()
  const authState = useAuthState()
  const [trades, setTrades] = useState([])
  const [projectName, setProjectName] = useState()
  const [projectStatus, setProjectStatus] = useState()
  const [bids, setBids] = useState([])
  const [loading, setLoading] = useState(false)
  const [showFileUploader, setShowFileUploader] = useState(false)
  const [showForm, setShowForm] = useState(true)

  const bidColumns = [
    {
      dataField: 'trades',
      text: 'Trades',
      headerAlign: 'center',
      formatter: ((cell, row) => row.trade ? row.trade.text : '')
    },
    {
      dataField: 'proposal',
      text: 'Proposal',
      headerAlign: 'center',
    },
    {
      dataField: 'amount',
      text: 'Amount',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
      formatter: ((cell, row) => audFormatter.format(row.amount))
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '60px' }),
      formatter: ((cell, row) => {
        return <Dropdown>
          <Dropdown.Toggle as="span" className="cursor-pointer toggle-hidden">
            <MdMoreVert size={18}></MdMoreVert>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => deleteBid(row)}><i className='i-Close-Window text-danger'> </i>Remove Bid</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      })
    },
  ]

  const initialValues = {
    contractorId: authState.user.id,
    userGroup: UserGroup.CONTRACTOR,
    trade: [],
    amount: '',
    subject: '',
    proposal: '',
    files: [],
  }

  useEffect(() => {

    if (projectId && getTrades) {
      getTrades(bidService, authState.user.id, projectId)
      .then(result => {
        if (!result || result?.error) {
          return NotificationManager.error(result ? result.message : 'Something went wrong.', 'Server Connection', 3000);      
        }
        setProjectName(result.projectName)
        setProjectStatus(result.status)
        if (result.trades?.length > 0) {
          const trades = result.trades.filter(t => t.status === TradeStatus.ACTIVE)
          setTrades(trades);
        }
      })
      .catch(e => { NotificationManager.error('Something went wrong.', 'Server Connection', 3000) });
    }
  }, [projectId]);

  const cancelSubmission = () => {

    if (bids.length > 0) {
      swal.fire({
        title: 'Are you sure?',
        text: 'You would not be able to revert this!',
        icon: 'warning',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, cancel submission',
        cancelButtonText: 'No'
      })
        .then(result => {
          if (result.value) {
            history.goBack()
          }
        })
    }
    else {
      history.goBack()
    }
  }

  const handleSubmitBid = () => {

    if (!bidService?.submitBid) {
      NotificationManager.error('Sorry, something went wrong.', 'Submit Bid', 6000)
      return
    }
    swal.fire({
      title: 'Are you sure?',
      text: 'Once formally submitted you cannot retract your bid unless client re-invites you to do so!',
      icon: 'warning',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, submit bid',
      cancelButtonText: 'No'
    })
      .then(result => {
        if (result.value) {
          setLoading(true)

          bidService.submitBid(authState.user.id, projectId, bids)
            .then(result => {
              setLoading(false)
              NotificationManager.success(
                'Your bid(s) was submitted successfully.',
                'Submit Bid',
                2000
              )
              setTimeout(_ => history.goBack(), 2000)
            })
            .catch(ex => {
              setLoading(false)
              NotificationManager.error(
                'Your bid(s) was not submitted successfully.',
                'Submit Bid',
                3000
              )
            })
        }
      })
  }

  const addBid = (values, { resetForm }) => {

    const bid = {
      id: uuid(),
      contractorId: authState.user.id,
      userGroup: authState.user.userGroup,
      trade: values.trade.length > 0 ? values.trade[0] : '',
      amount: values.amount,
      subject: values.subject,
      proposal: values.proposal,
      files: values.files,
    }
    setBids([...bids, bid])

    NotificationManager.warning(
      'Trade bid was added. Add new trade bid or click Submit Formal Bid to submit your bid(s)',
      'Submit Bid',
      6000
    )

    setShowForm(false)
    resetForm()
  }

  const deleteBid = (bid) => {

    var index = bids.findIndex(b => b.id === bid.id)
    bids.splice(index, 1)
    setBids([...bids])
  }

  return (
    <div>
      {projectName && <div className='row'>
        <div className='col-md-12'>

          <div className="card">

            <div className="card-header bg-transparent">
              <div className='d-flex flex-row justify-content-between'>
                <h5>Project: <strong>{projectName}</strong></h5>
                {projectStatus && <h5>Status: <Badge className={StatusClass[projectStatus.toUpperCase()]}>{projectStatus}</Badge></h5>}
              </div>
            </div>

            <div className="card-body">

              {bids.length > 0 && <>

                <div className='row'>
                  <div className='col-md-12 mb-1'>
                    <ToolkitProvider keyField='id' data={bids} columns={bidColumns} search exportCSV>
                      {props => (<>
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4
                          wrapperClasses="table-responsive"
                          noDataIndication={'No records found'}
                          hover
                          condensed
                        />
                      </>)}
                    </ToolkitProvider>
                  </div>
                </div>

                <div className='custom-separator mb-1'></div>

              </>}

              {showForm && <>
                <Formik
                  initialValues={initialValues}
                  validationSchema={bidSchema}
                  onSubmit={addBid}
                >
                  {({
                    errors,
                    touched,
                    handleSubmit,
                    isValid,
                    setFieldValue
                  }) => {
                    return (

                      <Form
                        className='needs-validation'
                        onSubmit={handleSubmit}
                        noValidate
                      >
                        <div className='form-row'>
                          <div className='form-group col-md-6'>

                            <div className='form-row'>
                              <div className={classList({
                                "col-md-12 mb-3": true,
                                "valid-field": touched.trade && !errors.trade,
                                "invalid-field": touched.trade && errors.trade
                              })}
                              >
                                <IwtbMultiSelect label='Trade (one per bid)' name='trade' selectionLimit={1} displayValue="text" onChange={selects => setFieldValue('trade', selects)} options={trades} />
                                <div className="invalid-feedback">Trade is required.</div>
                              </div>
                            </div>

                          </div>
                        </div>

                        <div>
                          <div className='form-row'>
                            <IwtbInput label='Amount (GST inclusive)' name='amount' type='number' min='0' required />
                          </div>
                        </div>

                        <div>
                          <div className='form-row'>
                            <IwtbInput label='Subject' name='subject' type='text' required />
                          </div>
                        </div>

                        <div>
                          <div className='form-row'>
                            <IwtbTextArea label='Proposal' name='proposal' type='text' placeholder='' style={{ height: 100 }} />
                          </div>
                        </div>

                        {/* Supporting Documents (Contractor)  */}

                        <div>
                          <div className='form-row'>
                            <IwtbCheckbox name='showFileUploader' onClick={e => setShowFileUploader(e.target.checked)}>
                              <span>Upload Files</span>
                            </IwtbCheckbox>
                          </div>
                        </div>

                        {showFileUploader &&
                          <div>
                            <div className='row mt-3'>
                              <div className='col-md-6'>
                                <h5>Upload Files</h5>
                                <div className='mb-3'></div>
                              </div>
                            </div>

                            <div className='col-md-12 mb-5'>
                              <IwtbFileUpload allowedTypes={AllowedFileTypes} setUploadedFiles={files => setFieldValue('files', files)} categories={FileBidCategories} />
                            </div>
                          </div>}

                        {!isValid && <div className='form-row mb-3'><span className='text-18 text-danger'>Please check mandatory fields and try again.</span></div>}

                        <button type='submit' className='btn btn-success m-1 mr-1'>Save Trade Bid</button>
                        <button type='button' className='btn btn-outline-warning m-1 footer-delete-right' onClick={_ => setShowForm(false)}>Cancel Adding Bid</button>
                      </Form>
                    )
                  }}
                </Formik>
              </>}

            </div>

            {!showForm && <div className="card-footer">
              <div className="mc-footer">
                <div className="row">
                  <div className="col-md-12">
                    {bids.length > 0 &&
                      <LaddaButton className='btn btn-primary m-1' loading={loading} disabled={loading} progress={0.5}
                        data-style={EXPAND_RIGHT}
                        onClick={handleSubmitBid}
                      >Submit Formal Bid
                      </LaddaButton>
                    }
                    <button type='button' className='btn btn-outline-success m-1' onClick={_ => setShowForm(true)}>Add Trade Bid</button>
                    <button type='button' className='btn btn-warning m-1 footer-delete-right' onClick={cancelSubmission}>Cancel & Return</button>
                  </div>
                </div>
              </div>
            </div>}

          </div>
        </div>
      </div>}

      <NotificationContainer />
    </div>
  )
}

