import HttpService from 'app/services/httpService'

class projectService {

    http = new HttpService()

    cancelProject = (clientId, projectId) => {
        return this.http.delete(`clients/${clientId}/projects/${projectId}/cancel`)
    }

    deleteProject = (clientId, projectId) => {
        return this.http.delete(`clients/${clientId}/projects/${projectId}/delete`)
    }

    getDeclinedProjects = (clientId) => {
        return this.http.get(`clients/${clientId}/declined`)
    }

    getClientProjects = (clientId) => {
        return this.http.get(`clients/${clientId}/projects`)
    }

    getClientProject = (clientId, projectId) => {
        return this.http.get(`clients/${clientId}/projects/${projectId}`)
    }

    getProjectServices = (clientId, projectId) => {
         return this.http.get(`clients/${clientId}/projects/${projectId}/services`)
    }

    getTrades = (clientId, projectId) => {
        return this.http.get(`clients/${clientId}/projects/${projectId}/trades`)
    }

    getAwardRegister = (clientId, projectId) => {
        return this.http.get(`clients/${clientId}/projects/${projectId}/awards`)
    }

    awardBid = (clientId, projectId, bidId) => {
        return this.http.post(`clients/${clientId}/projects/${projectId}/bids/${bidId}`)
    }

    declineBid = (clientId, projectId, bidId, reason) => {
        return this.http.put(`clients/${clientId}/projects/${projectId}/bids/${bidId}?reason=${reason}`)
    }

    // deleteBid = (clientId, projectId, bidId) => {
    //     return this.http.delete(`clients/${clientId}/projects/${projectId}/bids/${bidId}`)
    // }

    getBidRegister = (clientId, projectId) => {
        return this.http.get(`clients/${clientId}/projects/${projectId}/bids`)
    }

    uploadQuotes = (clientId, projectId, files) => {
        return this.http.post(`clients/${clientId}/projects/${projectId}/quotes`, { files: files })
    }

    updateTrades = (clientId, projectId, trades) => {
        return this.http.post(`clients/${clientId}/projects/${projectId}/trades`, { trades: trades })
    }

    deleteService = (clientId, projectId, serviceId, reason) => {
        return this.http.delete(`clients/${clientId}/projects/${projectId}/services/${serviceId}?reason=${reason}`)
    }

    deleteTrade = (clientId, projectId, tradeId, reason) => {
        return this.http.delete(`clients/${clientId}/projects/${projectId}/trades/${tradeId}?reason=${reason}`)
    }

    updateProject = (clientId, project) => {
        return this.http.post(`clients/${clientId}/projects/${project.id}`, { project: project })
    }

    createProject = (clientId, project, subscriptionRef) => {
        return this.http.post(`clients/${clientId}/projects`, { project: project, subscriptionRef: subscriptionRef })
    }

    updateDraft = (clientId, project) => {
        return this.http.post(`clients/${clientId}/drafts/${project.id}`, { project: project })
    }

    createDraft = (clientId, project) => {
        return this.http.post(`clients/${clientId}/drafts`, { project: project })
    }

    approveAccess = (clientId, access) => {
        return this.http.put(`clients/${clientId}/projects/${access.projectId}/access/${access.id}`)
    }

    declineAccess = (clientId, access, reason) => {
        return this.http.delete(`clients/${clientId}/projects/${access.projectId}/access/${access.id}?reason=${reason}`)
    }

    deleteProjectFile = (clientId, projectId, blobName) => {
        return this.http.put(`clients/${clientId}/projects/${projectId}/files/`, { blobName })
    }
}

export default new projectService()