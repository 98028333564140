import projectService from './projectService'
import bidService from '../bid/bidService'
import swal from "sweetalert2";
import { NotificationManager } from "react-notifications"
import { mapServices } from 'app/utils/common'

export {
    getAwardRegister,
    getBidRegister,
    getProjectServices,
    declineBid,
    awardBid,
}

const getAwardRegister = async (clientId, projectId) => {

    const result = await projectService.getAwardRegister(clientId, projectId);
    if (result && result.awardRegister) {
      return result.awardRegister; 
    }
    return [];
}

const getBidRegister = async (clientId, projectId) => {

    let bidRegister = await projectService.getBidRegister(clientId, projectId);
    if (bidRegister && bidRegister.length > 0) {
        bidRegister = bidRegister.map((trade, i) => {
            const bids = trade.bids.map((bid, i) => {
                const company = bid.contractor.businessName && bid.contractor.businessName.length > 0 ? bid.contractor.businessName : `${bid.contractor.firstName} ${bid.contractor.lastName}`; 
                return {index: ++i, company: company, ...bid };
            });
            return { ...trade, bids, index: i };
        });
        return bidRegister;
    }
    return [];
}

const getProjectServices = async (clientId, projectId) => {

    const result = await projectService.getProjectServices(clientId, projectId);
    if (result?.error) {
      NotificationManager.error('Semething went wrong', 'Error', 3000);
      return [];    
    }
    if (result?.additionalServices) {
        const services = mapServices(result.additionalServices);
        return services
    }
    else {
        return []
    }
}

const declineBid = (clientId, projectId, bid) => {

    return new Promise((resolve, reject) => {
        swal.queue([{
            title: "Enter reason for decline",
            input: "textarea",
            inputAttributes: {
            autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "Decline bid",
        showLoaderOnConfirm: true,
        inputAutoTrim: true,
        inputValidator: result => !result && 'You need to enter something!',
        preConfirm: message => {
            if (message.length > 0) {
            return bidService.declineBid(clientId, projectId, bid.id, message)
            .then(response => {
                if (response.error) {
                    return reject(response.message);
                }
                return resolve();
            })
            .catch(error => {
                return reject();
            });  
            }
        },
        allowOutsideClick: () => !swal.isLoading()
        }])
    })
}

const awardBid = async (clientId, projectId, bidId) => {

    return new Promise((resolve, reject) => {
        swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, award trade',
            cancelButtonText: 'No'
        })
        .then(result => {
            if (result.value) {    
                return bidService.awardBid(clientId, projectId, bidId)
                .then(response => {
                    if (response.error) {
                        return reject(response.message);
                    }
                    return resolve();
                })
                .catch(ex => {
                    return reject();
                });
            }
        });  
    })
}
