import React from 'react';
import { Badge } from 'react-bootstrap';
import { StatusClass, audFormatter, paginationOptions } from 'app/utils/constants';
import { formatDateTime12 } from 'app/utils/time';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';

export const HistorySubscriptionWidget = ({ subscriptions }) => {

  const columns = [
    {
      dataField: 'ref',
      text: 'Ref',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
    },
    {
      dataField: 'title',
      text: 'Title',
      headerAlign: 'center',
      align: 'center',
    },
    {
      dataField: 'amount',
      text: 'Amount',
      headerAlign: 'center',
      align: 'right',
      style: { cursor: 'pointer' },
      formatter: ((cell, row) => <span className='text-info'>{row.payment ? audFormatter.format(row.payment.grossAmount) : ''}</span>)
    },
    { 
      dataField: 'created',
      text: 'Date',
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => formatDateTime12(row.start))
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
  ]

  return (<>
    <div className="ul-widget__head border-0 mb-2">
      <div className="ul-widget__head-label">
        {subscriptions && <h3 className="ul-widget__head-title">
          Payment history
        </h3>}
        {!subscriptions && <h3 className="ul-widget__head-title">
          No previous payments
        </h3>}
      </div>
    </div>
    {subscriptions && <>
      <ToolkitProvider
        keyField='ref'
        data={subscriptions}
        columns={columns}
        search
        exportCSV
      >
        {props => (
          <>
            <BootstrapTable
              {...props.baseProps}
              bootstrap4
              wrapperClasses="table-responsive"
              pagination={paginationFactory({ ...paginationOptions, totalSize: subscriptions.length })}
              noDataIndication={'No records found'}
              striped
              condensed
            />
          </>
        )}
      </ToolkitProvider>

      {/* <div className="ul-widget-body">
        <div className="ul-widget3">
          <div className="ul-widget6__item--table">
            <table className="table ">
              <thead>
                <tr className="ul-widget6__tr--sticky-th">
                  <th scope="col">Ref</th>
                  <th scope="col">Date</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Status</th>
                  <th scope="col">Title</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.slice(currentPage * itemsPerPage, itemsPerPage * (currentPage + 1))
                  .map((subscription, index) => (
                    <tr key={index}>
                      <td>{subscription.ref}</td>
                      <td>{formatDateTime12(subscription.payment ? subscription.payment.created : subscription.start)}</td>
                      <td>{subscription.payment ? audFormatter.format(subscription.payment.grossAmount) : ''}</td>
                      <td>
                        <Badge className={StatusClass[subscription.status.toUpperCase()]}>{subscription.status}</Badge>
                      </td>
                      <td>{subscription.title}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <nav aria-label="Page navigation example">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={Math.ceil(subscriptions.length / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </nav>
        </div>
      </div> */}
    </>}
  </>)
}

