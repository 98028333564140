import React, { useState } from "react";
import { Breadcrumb } from '@gull';
import { ContactUsWidget } from "app/pages";
import guestService from 'app/guest/guestService';

export const ContactUs = () => {

    const [ showEditorDialog, setShowEditorDialog] = useState(true)
    const [ dialogValues, setDialogValues] = useState()
    const [ submitting, setSubmitting] = useState(false)
    const [ submitted, setSubmitted] = useState(false)
    const [ error, setError] = useState(false)
    
    const handleFormSubmit = values => {

        console.log(values)
        setShowEditorDialog(false)
        setSubmitting(true)

        guestService.contactUs(values)
        .then(result => {
            if (result.error) {
                setError(true)
            }
            else {
                setError(false)
                setSubmitted(true)
            }
        })
        .catch(ex => {
            setError(true)
        })
        .finally(() => {
            setSubmitting(false);
            setShowEditorDialog(false)
        });
    }

    const toggleEditorDialog = show => {

        setDialogValues(null)
        setSubmitting(false)
        setSubmitted(false)
        setShowEditorDialog(show)
    }

    return (<>
        <Breadcrumb
            routeSegments={[
            { name: 'Home', path: '' },
            { name: 'Contact Us' }
            ]}
        ></Breadcrumb>
        
        <ContactUsWidget
            show={showEditorDialog}
            initialValues={dialogValues}
            toggleEditorDialog={toggleEditorDialog}
            handleFormSubmit={handleFormSubmit}
        />

        <div className="row d-flex justify-content-center">
            {submitting &&
                <div className="col-lg-12 text-center">
                    <h4 className='text-primary'>Sending...</h4>
                    <p>This can take a moment.</p>
                </div>
            }
            {submitted &&
                <div className="col-lg-12 text-center">
                    <h4 className='text-success'>Your message has been sent!</h4>
                </div>
            }
            {error &&
                <div className="col-lg-12 text-center">
                    <h4 className='text-danger'>Sorry, something went wrong.</h4>
                    <p className='text-danger'>Your message has not been sent. Please try again later or contact us via email: <a href='mailto:support@iwanttobuild.com.au'>suppor@iwanttobuild.com.au</a>.</p>
                </div>
            } 
            {!submitting &&
                <div className="col-lg-12 text-center">
                    <br/>
                    <p className='text-primary'>
                        Your enquiry matters to us.<br/>
                        We will respond generally within 24 to 48 hours.<br/>
                        If your matter is urgent, please contact us on 1300 GO BUILD.
                    </p>
                    <p className='text-primary'>Thank you, IWTB Team</p>
                    <button className='btn btn-outline-primary mb-2 mr-1' onClick={() => toggleEditorDialog(true)}>Open contact form</button>
                </div>
            }
        </div>
    </>)
}