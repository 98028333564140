import React, {useEffect, useState, useCallback } from 'react'
import { Modal, Button } from 'react-bootstrap';
import paymentService from 'app/payment/paymentService';
import { NotificationManager } from 'react-notifications';
import { EwayWidget } from './EwayWidget'
import { PayPalWidget } from './PayPalWidget'
import { PaymentProvider } from './constants'
import { audFormatter } from 'app/utils/constants';
import ReactGA from 'react-ga';

export const PaymentWidget = ({ show, onHide, userId, subscription, onPaymentCompleted }) => {

    const [terms, setTerms] = useState(false)
    const [clientId, setClientId] = useState()
    const [paymentProvider, setPaymentProvider] = useState()
    
    const getPaymentToken = useCallback(() => {

        paymentService.getPaymentToken(userId, subscription?.key)
        .then(async result => {
            if (result?.error) {
                return NotificationManager.warning(result.message, 'Payment Gateway', 3000);      
            }
            if (result.clientId) {
                setClientId(result.clientId)
            }
            if (result.paymentProvider) {
                setPaymentProvider(result.paymentProvider)
            }
        })
        .catch(e => { 
            NotificationManager.warning(e.message, 'Payment Gateway', 3000);      
        })
    }, [subscription])

    useEffect(() => {

        getPaymentToken()
    }, [])

    useEffect(() => {

        if (subscription) {
            try {
                ReactGA.event({ category: 'Subscription', action: subscription.title })
            } catch {}  
        }
    }, [subscription])

    const clear = () => {

        setTerms(false)
        onHide()
    }

    const handlePayment = async (order) => {
        
        if (!order?.id) {
            return
        }      
        if (paymentProvider === PaymentProvider.PAYPAL) {
            const payment = await paymentService.verifyPayment(userId, order.id)

            if (payment?.error) {
                return NotificationManager.error(payment.message, 'Payment', 6000);      
            }
            if (payment?.status) {

                try {
                    ReactGA.event({ category: 'Payment', action: payment.status, value: payment.grossAmount })
                } catch {}  

                onPaymentCompleted(payment);
                clear()
            }
        }
    }

    return (
        <Modal show={show} onHide={clear} centered={true} size='md'>
            <Modal.Header closeButton>
                <Modal.Title>IWTB Subscription | {`${subscription?.title} | ${audFormatter.format((subscription?.price ? subscription.price : 0) * 1.1)}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {terms && <>
                    {paymentProvider === PaymentProvider.EWAY && 
                        <EwayWidget userId={userId} subscription={subscription} onPaymentCompleted={handlePayment} />
                    }
                    {paymentProvider === PaymentProvider.PAYPAL && 
                        <>
                            <PayPalWidget 
                                userId={userId} 
                                clientId={clientId} 
                                subscription={subscription} 
                                terms={terms} 
                                onPaymentCompleted={handlePayment} 
                            />
                        </>
                    }
                </>
                }
                <div className='form-check'>
                    <input className='form-check-input' type='checkbox' id='chkTerms' onChange={(event) => setTerms(event.target.checked)} />
                    <label className='form-check-label ml-2' htmlFor='chkTerms'>
                        <a href='/legal/terms' target='_blank'>I accept terms and conditions</a>
                    </label>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='btn btn-warning btn-lg border-0 mr-2 mb-2' onClick={clear}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    )
}
