import { useEffect, useState } from 'react'
import mapsService from 'app/map/mapsService'

export const Marker = ({ location, map }) => {

    const [marker, setMarker] = useState()
    const [latLng, setLatLng] = useState()
  
    useEffect(() => {
  
      if (!marker) {
        setMarker(new window.google.maps.Marker())
      }
  
      // remove marker from map on unmount
      return () => {
        if (marker) {
          marker.setMap(null)
        }
      }
    }, [marker])
  
    useEffect(() => {
  
      if (location?.latLng) {
        setLatLng(location.latLng)
      }
      else {
        mapsService.getPlaceDetails(location.id)
        .then(location => {
          if (location?.latLng) {
            setLatLng(location.latLng)
          }
        })
      }
    }, [location])
  
    useEffect(() => {
  
      if (marker) {
        marker.setOptions({ map: map, position: latLng, title: location?.address })
      }
    }, [marker, map, latLng])
  
    return null
  }