import * as yup from 'yup';

export const bidSchema = yup.object().shape({

    trades: yup.array().min(1, 'Enter one item'),
    subject: yup.string().required('Subject is required'),
    proposal: yup.string().required('Proposal is required'),
    amount: yup.number().required('Amount is required'),    
});

  