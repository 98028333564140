
export const initialState = {
  errors: [],
  showErrors: false,
};
 
export const ErrorReducer = (state, action) => {

  switch (action.type) {
        
    case 'CLEAR_ERRORS':
        return { ...initialState };
         
    case 'ADD_ERROR':
        return {
            ...state,
            showErrors: true,
            error: state.errors.push(action.error),
        };
        
    default:
      return state
  }
};