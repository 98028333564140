import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Badge, Dropdown, Popover, OverlayTrigger  } from "react-bootstrap";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { RfiAction } from '../constants';
import { paginationOptions, StatusClass } from 'app/utils/constants';
import { formatDate } from 'app/utils/time';
import { constructCompanyName } from 'app/utils/common';
import { ContractorNavigation } from '../contractorRoutes';
import { useHistory } from "react-router-dom";
import { MdMoreVert} from "react-icons/md";
import rfiService from './rfiService';
import { useSpinner } from 'app/IwtbLayout/SharedComponents/spinner/SpinnerContext';
import { RefreshType, refreshOnNotification } from 'app/notification'
import { useSocket } from 'app/socket.io';
import { useAuthState } from 'app/auth';
import { ReloadButton } from 'app/widgets';

export const RfiRegisterWidget = ({ projectId, readOnly, disableNewRfi, onSubmitRfi }) => {

  const history = useHistory();
  const [rfiRegister, setRfiRegister] = useState([]);
  const { SearchBar, ClearSearchButton } = Search;
  const spinner = useSpinner()
  const socket = useSocket()
  const authState = useAuthState();

  let columns = [
    {
      dataField: 'trade.text',
      text: 'Trade',
      headerAlign: 'center',
      sort: true,
    },
    {
      dataField: 'contractorId',
      text: 'Company',
      headerAlign: 'center',
      sort: true,
      formatter: ((cell, row) => constructCompanyName(row.contractor))
    },
    {
      dataField: 'subject',
      text: 'RFI',
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => { 
        if (row.subject && row.subject.length > 0) {
          
          const popover = props => (
            <Popover id="popover-basic" {...props}>
              <Popover.Title as="h3">Request</Popover.Title>
              <Popover.Content>{row.subject}</Popover.Content>
            </Popover>
          );
          
          return <>
            <OverlayTrigger trigger="hover" placement="auto" overlay={popover} delay={{ show: 250, hide: 400 }}>
              <i className='nav-icon i-ID-Card font-weight-bold cursor-pointer text-success mr-2' onClick={e => {actionRfi(RfiAction.VIEW, row)}}></i>
            </OverlayTrigger></>
        }
        return ''
      })
    },
    {
      text: 'Reply',
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => { 
        if (row.response && row.response.message && row.response.message.length > 0) {
          
          const popover = props => (
            <Popover id="popover-basic" {...props}>
              <Popover.Title as="h3">Response</Popover.Title>
              <Popover.Content>{row.response.message}</Popover.Content>
            </Popover>
          );
          
          return <>
            <OverlayTrigger trigger="hover" placement="auto" overlay={popover} delay={{ show: 250, hide: 400 }}>
              <i className='nav-icon i-ID-Card font-weight-bold cursor-pointer text-primary mr-2' onClick={e => {actionRfi(RfiAction.VIEW, row)}}></i>
            </OverlayTrigger></>
        }
        return ''
      })
    },
    {
      dataField: 'Requested',
      text: 'Requested',
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => formatDate(row.created))
    },
    {
      dataField: 'Responded',
      text: 'Responded',
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => row.response ? formatDate(row.response.created) : '')
    },
    {
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
    {
      dataField: 'ref',
      text: 'Ref',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
    },
];

  if (!readOnly) {
    columns = [...columns,
    {
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '60px' }),
      formatter: ((cell, row) => { 
        return <Dropdown onSelect={(action) => actionRfi(action, row)}>
          <Dropdown.Toggle as="span" className="cursor-pointer toggle-hidden">
            <MdMoreVert size={18}></MdMoreVert>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey={RfiAction.VIEW}><i className="i-ID-Card"> </i>View</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      })
    }];
  }

  useEffect(() => {

    const onUserNotification$ = socket.onUserNotification().subscribe(notification => {

      refreshOnNotification(notification.type, RefreshType.CONTRACTOR_PROJECT_RFI, loadRfiRegister)
    })
    return() => {
        onUserNotification$.unsubscribe()
    }
  }, []);

  useEffect(() => {

    loadRfiRegister();
  }, [projectId]);

  const loadRfiRegister = async () => {

    if (projectId) {
      spinner.show()
      try {
        const result = await rfiService.getRfiRegister(authState.user.id, projectId)
        if (result.rfiRegister) {
          setRfiRegister(result.rfiRegister);
        }
      }
      catch(ex) {

      }
      finally {
        spinner.hide()
      }
    }
  }

  const actionRfi = (action, rfi) => {
    
    history.push({
      pathname: ContractorNavigation.VIEW_RFI.replace(':projectId', rfi.projectId).replace(':requestId', rfi.id),
      state: { rfi: rfi }
    });  
  }

  return (
    <>
        <ToolkitProvider
            keyField='id'
            data={rfiRegister}
            columns={columns}
            search
            exportCSV
        >
            {toolkitProps => (
            <>
                <div className='d-flex justify-content-end align-items-center'>
                  <span className='mb-2 mr-1'>Search:</span>
                  <SearchBar {...toolkitProps.searchProps} className='mb-0 ' />
                  <ClearSearchButton { ...toolkitProps.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
                  <ReloadButton onClick={loadRfiRegister} />
                  {!disableNewRfi && <button className='btn btn-outline-primary mb-2' onClick={onSubmitRfi}>Submit RFI</button>}
                </div>
                <BootstrapTable
                  {...toolkitProps.baseProps}
                  bootstrap4
                  wrapperClasses="table-responsive"
                  pagination={paginationFactory({ ...paginationOptions, totalSize: rfiRegister.length })}
                  noDataIndication={'No records found'}
                  condensed
                />
            </>
            )}
        </ToolkitProvider>
    </>
  );
}
