import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import { Button } from 'react-bootstrap';
import uuid from 'react-uuid'

export const IwtbImageCrop = ({ circularCrop, aspect, dataUrlChanged, blobChanged }) => {

  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: aspect ?? 1 / 1 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onFileSelected = files => {

    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(files[0]);
    }
  };

  const onLoad = useCallback(img => {

    imgRef.current = img;
  }, []);

  useEffect(() => {

    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    if (dataUrlChanged) {
      const dataUrl = canvas.toDataURL('image/jpeg', 0.5);
      dataUrlChanged(dataUrl);
    }
    if (blobChanged) {
      canvas.toBlob(blob => {blobChanged(blob)}, 'image/jpeg', 0.5);
    }
  }, [completedCrop]);

  const id = uuid();

  return (
    <div>
        <label htmlFor={id}>
            <Button className='btn mr-3 mb-3' as='span'>
                <div className='flex flex-middle'>
                    <i className='i-Share-on-Cloud'> </i>
                    <span>Select Image</span>
                </div>
            </Button>
        </label>

        <input type='file' onChange={e => onFileSelected(e.target.files)} className='d-none' accept='image/*' id={id} />

        <div className='d-block'>
            <ReactCrop
                src={upImg}
                crop={crop}
                circularCrop={circularCrop ?? false}
                onImageLoaded={onLoad}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => {
                  setCompletedCrop(c)
                }}
            />
        </div>
        <div>
            <canvas ref={previewCanvasRef}
                // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                style={{
                    width: Math.round(completedCrop?.width ?? 0),
                    height: Math.round(completedCrop?.height ?? 0)
                }}
            />
        </div>
    </div>
  );
}
