
import axios from "axios";
import config from 'app/utils/config';

const https = require('https');

export default class HttpService {
    
  constructor() {
        
    this.http = axios.create({
      httpsAgent: new https.Agent({  
        rejectUnauthorized: false,
        requestCert: false,
        agent: false,
      }),
      baseURL: config.API,
      headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
      },
      responseType: 'json'
    })

    this.http.interceptors.request.use(
      request => {
        request.headers.common['Authorization'] = this.authBearer()
        return request
      },
      error => {
        return Promise.reject(error)
      }
    )

    this.http.interceptors.response.use(
      response => {
        return response
      },
      error => {
        if (error.response) {
          const code = error.response?.status || 500
          switch (code) {
            case 401:
            case 403:
              localStorage.clear();
              window.location.href = '/';
              return Promise.reject({ error: error.message, code: code })
            case 400:
            case 404:
              return Promise.reject({ error: error.message, code: code })
            case 500:
              return Promise.reject({ error: error.message, code: code })
          }
          return Promise.reject(error.response)
        }
        if (error.message === 'Network Error') {
          return Promise.reject('There are some connectivity issues. Please try again.')
        }
        return Promise.reject(error.message)
      }
    )    
  }

  authBearer = () => {

    // return authorization header with jwt token
    const user = JSON.parse(localStorage.getItem('currentUser'));

    if (user && user.token) {
        return `Bearer ${user.token}`;
    } else {
        return '';
    }
  }

  delete = async (url, config) => {
    const result = await this.http.delete(url, config);
    return result.data
  }

  post = async (url, data, config) => {
    const result = await this.http.post(url, data, config);
    return result.data
  }

  patch = async (url, data, config) => {
    const result = await this.http.patch(url, data, config);
    return result.data
  }

  put = async (url, data, config) => {
    const result = await this.http.put(url, data, config);
    return result.data
  }

  get = async (url, config) => {
    const result = await this.http.get(url, config);
    return result.data
  }

  getBlobArchive = (url, data) => {
    return this.http.post(url, data, { responseType: 'blob' });
  }

  getBlob = (url) => {
    return this.http.get(url, { responseType: 'blob' });
  }
}
