import HttpService from 'app/services/httpService';

class RfiService {
    
    http = new HttpService();

    getRfi = (contractorId, projectId, rfiId) => {
        return this.http.get(`contractors/${contractorId}/projects/${projectId}/rfi/${rfiId}`);
    };

    submitRfi = (contractorId, projectId, rfi) => {
        return this.http.post(`contractors/${contractorId}/projects/${projectId}/rfi`, { rfi: rfi });
    };
        
    getRfiRegister = (contractorId, projectId) => {
        return this.http.get(`contractors/${contractorId}/projects/${projectId}/rfi`);
    };
}

export default new RfiService();