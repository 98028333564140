import React from "react";
import { classList } from '@utils';
import { useField } from 'formik';
import Multiselect from 'multiselect-react-dropdown';

export const IwtbMultiSelect = ({ label, ...props }) => {

    const [field, meta] = useField(props);

    const onChange = (selectedList, selectedItem) => {

        props.onChange(selectedList);
    }

    return (
        <div className={classList({'valid-field': meta.touched && !meta.error, 'invalid-field': meta.touched && meta.error})}>
            {label && <label htmlFor={props.id || props.name}>{label}</label>}
            <Multiselect {...field} {...props}
                onSelect={onChange} 
                onRemove={onChange} 
                showArrow={true}
                avoidHighlightFirstOption={true}
            />
            <div className='invalid-feedback'>{ meta.error }</div>
        </div>
    );
};
