import HttpService from 'app/services/httpService';
import { indexArray } from 'app/utils/common';

export class ConfigurationService {

    constructor() {
        this.http = new HttpService();
    }
    
    getClientCategories = () => {
        return this.http.get('configurations/clinet-category');
    };
    
    getProjectBudgets = () => {
        return this.http.get('configurations/project-budget');
    };
    
    getProjectSectors = () => {
        return this.http.get('configurations/project-sector');
    };
    
    getSpecialistCategories = () => {
        return this.http.get('configurations/specialist-category');
    };
    
    getTradeCategories = () => {
        return this.http.get('configurations/trade-category');
    };
}

export default new ConfigurationService();