import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// import config from '../utils/config';
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
    // <GoogleReCaptchaProvider
    //     reCaptchaKey={config.RECAPTCHA_KEY}
    //     useRecaptchaNet="false"
    //     useEnterprise="false"
    //     scriptProps={{
    //         async: true, // optional, default to false,
    //         defer: false, // optional, default to false
    //         appendTo: "head", // optional, default to "head", can be "head" or "body",
    //         nonce: undefined // optional, default undefined
    //     }}
    // >
    //     <App />
    // </GoogleReCaptchaProvider>
    <App />
    , document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
