import React, { useEffect } from 'react';
import swal from "sweetalert2";
import { SimpleCard } from '@gull';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Badge, Button, Dropdown } from "react-bootstrap";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Breadcrumb } from '@gull';
import service from '../contractorService';
import moment from 'moment';
import { audFormatter, paginationOptions, ProjectStatus } from 'app/utils/constants';
import { useAuthState } from 'app/auth';
import { ProjectAction, ProjectTab } from '../constants';
import { ContractorNavigation } from '../contractorRoutes';
import { StatusClass } from 'app/utils/constants';
import { formatDateTime12, formatDate } from 'app/utils/time';
import { MdMoreVert} from "react-icons/md";
import { getProjectAwards } from '../functions';
import { ReloadButton } from 'app/widgets';
import { useSpinner } from 'app/IwtbLayout/SharedComponents/spinner/SpinnerContext';
import { RefreshType, refreshOnNotification } from 'app/notification'
import { useSocket } from 'app/socket.io';

export const ContractorProjects = (props) => {

  const { SearchBar, ClearSearchButton } = Search;
  const [loading, setLoading] = React.useState(true);
  const [projects, setProjects] = React.useState([]);
  const spinner = useSpinner()
  const socket = useSocket()
  const authState = useAuthState();

  const projectColumns = [
    {
      dataField: 'projectName',
      text: 'Project',
      headerAlign: 'center',
      sort: true,
      headerStyle: () => ({ width: '25%' }),
      formatter: ((cell, row) => { 
        return <span className="text-primary cursor-pointer" onClick={() => navigateTo(authState.navigation.PROJECT_CARD, row, ProjectTab.DETAILS)}>{row.projectName}</span>;
      })
    },
    {
      dataField: 'ref',
      text: 'Ref',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
    },
    {
      dataField: 'budget',
      text: 'Budget',
      headerAlign: 'center',
      align: 'center',
      sort: true,
    },
    {
      dataField: 'created',
      text: 'Listed',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: ((cell, row) => formatDate(row.created))
    },
    {
      dataField: 'tenderRequiredBy',
      text: 'Quotes Due',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: ((cell, row) => formatDateTime12(row.tenderRequiredBy))
    },
    {
      dataField: 'Time Left',
      text: 'Time Left',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: ((cell, row) => { 
        let retVal = '';
        if (row.created && row.tenderRequiredBy) {
          const hours = moment(row.tenderRequiredBy).diff(moment(), 'hours'); 
          if (hours && hours > 24) {
            retVal = <span className="ul-widget3-status text-success t-font-bolder">{ Math.floor(hours/24) } days</span>
          }
          else {
            retVal = <span className="ul-widget3-status text-warning t-font-bolder">{ hours } hours</span>
          }
        }
        return retVal;
      })
    },
    {
      dataField: 'revision',
      text: 'Rev',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '40px' }),
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '90px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
    {
      dataField: 'Action',
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '60px' }),
      formatter: ((cell, row) => { 
        return <Dropdown>
          <Dropdown.Toggle as="span" className="cursor-pointer toggle-hidden">
            <MdMoreVert size={18}></MdMoreVert>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => navigateTo(ContractorNavigation.PROJECT_CARD, row)}><i className="i-ID-Card"> </i>Project Card</Dropdown.Item>
            {row.status !== ProjectStatus.PENDING && <>
              <Dropdown.Item onClick={() => navigateTo(ContractorNavigation.SUBMIT_BID, row)}><i className="i-Dollar-Sign"> </i>Submit Bid</Dropdown.Item>
              <Dropdown.Item onClick={() => navigateTo(ContractorNavigation.SUBMIT_RFI, row)}><i className="i-Information"> </i>Submit RFI</Dropdown.Item>
            </>}
            <Dropdown.Item
              onClick={() => {
                swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to access this project!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, withdraw!",
                    cancelButtonText: "No"
                  })
                  .then(result => {
                    if (result.value) {
                      deleteAccess(row);
                    } 
                  });
              }}
            >
              <i className="nav-icon i-Close-Window text-danger font-weight-bold mr-2"></i>
              Withdraw
            </Dropdown.Item>

          </Dropdown.Menu>
        </Dropdown>
      })
    },

  ];

  const projectInfoColumns = [
    {
      dataField: 'projectLocation',
      text: 'Location',
      headerAlign: 'center',
      headerStyle: () => ({ width: '25%' }),
      formatter: ((cell, row) => row.projectLocation ? row.projectLocation.address : '')
    },
    {
      dataField: 'sector',
      text: 'Sector',
      headerAlign: 'center',
      headerStyle: () => ({ width: '25%' }),
    },
    {
      dataField: 'RFI',
      text: ProjectTab.RFI,
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => { 
        return <Button onClick={() => navigateTo(ContractorNavigation.PROJECT_CARD, row, ProjectTab.RFI)} variant='outline-warning' className="m-1 text-capitalize">{row.rfiRegister ? row.rfiRegister.length : 0}</Button>;
      })
    },
    {
      dataField: 'BIDS',
      text: ProjectTab.BIDS,
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => { 
        return <Button onClick={() => navigateTo(ContractorNavigation.PROJECT_CARD, row, ProjectTab.BIDS)} variant='outline-success' className="m-1 text-capitalize">{row.bidCount ?? 0}</Button>;
      })
    },
    {
      dataField: 'AWARDS',
      text: ProjectTab.AWARDS,
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => { 
        return <Button onClick={() => navigateTo(ContractorNavigation.PROJECT_CARD, row, ProjectTab.BIDS)} variant='outline-info' className="m-1 text-capitalize">{row.awardCount ?? 0}</Button>;
      })
    },
    {
      dataField: 'notices',
      text: ProjectTab.NOTICES,
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => { 
        return <Button onClick={() => navigateTo(ContractorNavigation.PROJECT_CARD, row, ProjectTab.BIDS)} variant='outline-danger' className="m-1 text-capitalize">{row.requestCount ?? 0}</Button>;
      })
    },
    {
      dataField: 'My Budget',
      text: 'My Budget',
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => {
        const { total } = getProjectAwards(row.bidRegister);
        return <span className="ul-widget3-status text-primary t-font-bolder">{audFormatter.format(total)}</span>
      })
    },
  ];

  const getContractorProjects = React.useCallback(() => {

    if (authState.user?.id) {
      spinner.show()
      service.getContractorProjects(authState.user.id)
      .then(results => {
        if (results.error) {
          NotificationManager.error(
            results.message,
            'Server Connection',
            3000
          );      
        }
        else {
          setLoading(false);
          setProjects(results); 
        }
      })
      .catch(e => {
        NotificationManager.error('Something went wrong.', 'Server Connection', 3000);      
      })
      .finally(() => {
        spinner.hide()
      })
    }
  })  

  useEffect(() => {

    const onUserNotification$ = socket.onUserNotification().subscribe(notification => {

      refreshOnNotification(notification.type, RefreshType.CONTRACTOR_PROJECTS, getContractorProjects)
    })
    return() => {
        onUserNotification$.unsubscribe()
    }
  }, []);

  React.useEffect(() => {
    
    getContractorProjects();
  }, [authState.user]);

  const navigateTo = (pathname, project, tab) => {
    
    switch(pathname) {
      case ContractorNavigation.PROJECT_CARD:
        pathname = pathname.replace(':tab?', tab ? tab.toLowerCase() : '');
    }
    pathname = pathname.replace(':projectId', project.id);

    const { history } = props;

     history.push({
      pathname: pathname,
      state: { project: project }
    });  
  }

  const actionProject = (action, project) => {

    let pathname = '';
    switch(action) {
      case ProjectAction.SUBMIT_BID:
        pathname = ContractorNavigation.SUBMIT_BID;
        break;
      case ProjectAction.SUBMIT_RFI:
        pathname = ContractorNavigation.SUBMIT_RFI;
        break;
      case ProjectAction.VIEW_PROJECT:
        pathname = ContractorNavigation.PROJECT_CARD;
        break;
      }
    return navigateTo(pathname, project);
  }
  
  const deleteAccess = (project) => {

    service.deleteAccess(authState.user.id, project.id)
    .then(result => {
      if (result.error) {
        NotificationManager.error(result.message, 'Delete Access', 3000);      
      }
      else {
        NotificationManager.warning('Project access was withdrawn', 'Delete Access', 3000);      
        getContractorProjects();    
      }
    })
    .catch(e => NotificationManager.error('Something went wrong.', 'Server Connection', 3000));
  }

  const expendRow = {

    parentClassName: 'table-primary',
    showExpandColumn: true,
    renderer: row => (
      <BootstrapTable keyField='index' data={ [row] } columns={ projectInfoColumns } />
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
      if (expanded) {
        return <i className='nav-icon i-Arrow-Down font-weight-900 text-primary cursor-pointer'></i>    
      }
      else {
        return <i className='nav-icon i-Arrow-Right font-weight-900 text-primary cursor-pointer'></i>    
      }
    },
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (''),
  }

  return (
    <div>
      <Breadcrumb
        routeSegments={[
          { name: 'My Projects' }
        ]}
      ></Breadcrumb>

      <div className='row'>
          <div className='col-md-12'>

          <SimpleCard>
          
            <ToolkitProvider
              keyField='id'
              data={projects}
              columns={projectColumns}
              search
              exportCSV
            >
              {props => (
                <>
                  <div className='d-flex justify-content-end align-items-center'>
                   
                    <span className='mb-2 mr-1'>Search:</span>
                    <SearchBar {...props.searchProps} className='mb-0 ' />
                    <ClearSearchButton { ...props.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
                    <ReloadButton onClick={getContractorProjects} />
                  </div>
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4
                    wrapperClasses="table-responsive"
                    pagination={paginationFactory({ ...paginationOptions, totalSize: projects.length })}
                    noDataIndication={'No records found'}
                    // striped
                    condensed
                    loading={ loading }
                    expandRow={ expendRow }
                  />
                </>
              )}
            </ToolkitProvider>
          </SimpleCard>
        </div>
      </div>

      <NotificationContainer />
    </div>
  );
}
