import HttpService from 'app/services/httpService';

export class GuestService {

    http = new HttpService();

    contactUs = (contact) => {
        return this.http.post(`contact-us`, { contact: contact });
    };

    validateUsername = (username) => {
        return this.http.post('validate-username', { username: username });
    };

    registerUser = (user) => {
        return this.http.post('signup', { user: user });
    };

    verifyUser = (key) => {
        return this.http.get(`verify/${key}`);
    };

    getContractorSubscriptions = () => {
        return this.http.get('/subscriptions/contractor');
    };

    getClientSubscriptions = () => {
        return this.http.get('/subscriptions/client');
    };
}

export default new GuestService();