import HttpService from 'app/services/httpService';
import { sortArray } from 'app/utils/common';

export class NotificationService {

  constructor() {
    this.http = new HttpService();
}

  getUserNewNotifications = (userId) => {

    return new Promise(async (resolve) => {
      const communications = await this.http.get(`users/${userId}/notifications/new`);
      const retVal = Array.isArray(communications) && communications.length > 0 ? sortArray(communications, 'time', true) : [];
      resolve(retVal);
    });
  };

  markAsRead = (userId, notificationId) => {
    return this.http.put(`users/${userId}/notifications/${notificationId}`);
  };
}

export default new NotificationService();