import moment from 'moment';
import { isValid, lastDayOfMonth, parseISO, formatDistanceToNow, formatDistanceToNowStrict, format } from 'date-fns'
import enAU from 'date-fns/locale/en-AU'

export {
    formatDateTime12_FNS,
    formatDate_FNS,
    parseIsoDateTime,
    humanReadableDate,
    daysToNow,
    localTime,
    parseDateTime,
    getTimeDifference,
    getCurrentDateTime24,
    getCurrentIsoDateTime,
    formatDateTimeIso,
    formatDateTime24,
    formatDateTime12,
    formatDate,
    formatTime24,
    formatTime12,
    getFirstDayOfMonth,
    getLastDayOfMonth,
}

const getFirstDayOfMonth = (date) => {

    const firstDateOfMonth = format(date, 'yyyy-MM-01')
    return firstDateOfMonth
}

const getLastDayOfMonth = (date) => {

    const lastDateOfMonth = format(lastDayOfMonth(date), 'yyyy-MM-dd')
    return lastDateOfMonth
}

const formatDate_FNS = (date) => {
    
    return isValid(date) ? format(date, 'dd/MM/yyyy') : null
}

const formatDateTime12_FNS = (date) => {
    
    return isValid(date) ? format(date, 'dd/MM/yyyy hh:mm a') : null
}

const parseIsoDateTime = (dateTime) => {

    if (!dateTime) {
        return null
    }

    let isoDate = parseISO(dateTime)

    if (!isValid(isoDate)) {
        return null
    }
    const offsetDate = new Date(isoDate.valueOf() - isoDate.getTimezoneOffset() * 60 * 1000);
    return offsetDate
}

const formatDateTimeIso = (date, format) => {

    const momentDate = moment(date, format)
    return momentDate.isValid() ? momentDate.format('YYYY-MM-DD HH:mm:ss') : null
}

const daysToNow = dateTime => {

    const offsetDate = parseIsoDateTime(dateTime)

    if (isValid(offsetDate)) {
        return formatDistanceToNowStrict(offsetDate, { addSuffix: true, unit: 'day', roundingMethod: 'floor', locale: enAU })
    }
    else {
        return ''
    }
}

const humanReadableDate = dateTime => {

    const offsetDate = parseIsoDateTime(dateTime)

    if (isValid(offsetDate)) {
        return formatDistanceToNow(offsetDate, { addSuffix: true, locale: enAU })
    }
    else {
        return ''
    }
}

function localTime(date) {

    return moment.utc(date).local();
}

const parseDateTime = dateTime => {

    const parsed = moment(dateTime)

    return parsed.isValid() ? parsed.toDate() : null
}

const getTimeDifference = date => {

    let retVal = '';
    const local = localTime(date)
    let difference = moment().diff(local) / 1000;
  
    if (difference < 60) retVal = `${Math.floor(difference)} sec`;
    else if (difference < 3600) retVal =  `${Math.floor(difference / 60)} min`;
    else if (difference < 86400) retVal =  `${Math.floor(difference / 3660)} hours`;
    else if (difference < 86400 * 30) retVal = `${Math.floor(difference / 86400)} days`;
    else if (difference < 86400 * 30 * 12) retVal = `${Math.floor(difference / 86400 / 30)} months`;
    else retVal = `${(difference / 86400 / 30 / 12).toFixed(1)} years`;

    return retVal;
  }
  
  export const diffHours = dateTime => {
    if (dateTime) {
        var date = moment(dateTime, 'YYYY-MM-DD HH:mm');
        return date.isValid() ? moment(dateTime).diff(moment(), 'hours') : -9999;
    }
    else {
        return '';
    }
}

const getCurrentDateTime24 = () => {
    
    return moment().format('YYYY-MM-DD HH:mm')
}

const getCurrentIsoDateTime = () => {
    
    return moment().format('YYYY-MM-DD HH:mm:ss')
}

const formatDateTime24 = dateTime => {

    if (dateTime) {
        var date = moment(dateTime, 'YYYY-MM-DD HH:mm');
        return date.isValid() ? date.format('DD/MM/YYYY HH:mm') : '';
    }
    else {
        return '';
    }
}

const formatDateTime12 = dateTime => {
    
    if (dateTime) {
        var date = moment(dateTime, 'YYYY-MM-DD HH:mm');
        return date.isValid() ? date.format('DD/MM/YYYY hh:mm A') : '';
    }
    else {
        return '';
    }
}

const formatDate = dateTime => {
    
    if (dateTime) {
        var date = moment(dateTime, 'YYYY-MM-DD HH:mm');
        return date.isValid() ? date.format('DD/MM/YYYY') : '';
    }
    else {
        return '';
    }
}

const formatTime24 = dateTime => {

    if (dateTime) {
        var date = moment(dateTime, 'YYYY-MM-DD HH:mm');
        return date.isValid() ? date.format('HH:mm') : '';
    }
    else {
        return '';
    }
}

const formatTime12 = dateTime => {
    if (dateTime) {
        var date = moment(dateTime, 'YYYY-MM-DD HH:mm');
        return date.isValid() ? date.format('hh:mm A') : '';
    }
    else {
        return '';
    }
}