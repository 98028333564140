import * as yup from 'yup';
import { UserGroup } from 'app/utils/constants';

export const membershipSchema = yup.object().shape({

  trades: yup.array().min(1, 'Enter one or more items'),
  mobile: yup.string().required('Mobile is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Surname is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  location: yup.object().required('Address is required'),
  website: yup.string().url('http://www.iwt.build'),
  businessYearsOperating: yup.number().positive('Must be greater than or equal to 0').integer('Must be an integer'),
  industry: yup.string().required('Industry is required'),
  beenBankrupt: yup.string().required('Please answer Yes or No'),

  precludeWork: yup.string().when('userGroup', {
    is: UserGroup.CONTRACTOR,
    then: yup.string().required('Please answer Yes or No')
  }),

  backgroundCheck: yup.string().when('userGroup', {
    is: UserGroup.CONTRACTOR,
    then: yup.string().required('Please answer Yes or No')
  }),

  safetyPack: yup.string().when('userGroup', {
    is: UserGroup.CONTRACTOR,
    then: yup.string().required('Please answer Yes or No')
  }),

  financialGuarantee: yup.string().when('userGroup', {
    is: UserGroup.CLIENT,
    then: yup.string().required('Please answer Yes or No')
  }),
});

