import React, { useEffect, useState } from 'react';
import { SimpleCard } from '@gull';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Breadcrumb } from '@gull';
import { AdminNavigation } from '../adminRoutes';
import { MdMoreVert} from "react-icons/md";
import { paginationOptions, UserStatus, UserGroup, StatusClass, ProfileCardTab, SubscriptionStatus } from 'app/utils/constants';
import { UserAction } from '../constants'
import { formatDate, daysToNow } from 'app/utils/time';
import { navigateTo, handleOpenChat } from 'app/utils/common'
import { Dropdown, Badge } from 'react-bootstrap';
import { useParams, useHistory } from "react-router-dom";
import adminService from '../adminService';
import swal from "sweetalert2";
import { SendMessageWidget } from "app/chat";
import { useSpinner } from 'app/IwtbLayout/SharedComponents/spinner/SpinnerContext';
import { ReloadButton } from 'app/widgets';
import { RefreshType, refreshOnNotification } from 'app/notification'
import { useSocket } from 'app/socket.io';
import { useAuthState } from 'app/auth';

export const Memberships = () => {

  const spinner = useSpinner()
  const authState = useAuthState();
  const socket = useSocket()
  const { SearchBar, ClearSearchButton } = Search;
  const history = useHistory();
  const [registrations, setMemberships] = React.useState([]);
  const { userGroup } = useParams();
  const [ selectedUsers, setSelectedUsers ] = useState([]);

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    selectColumnPosition: 'right',
    style: {backgroundColor: '#FCF0BE'},
    selectionHeaderRenderer: ({ indeterminate, rowIndex, rowKey, ...rest }) => (
      <input type="checkbox"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        { ...rest } onChange={e => {}}
      />
    ),
    selectionRenderer: ({ mode, rowIndex, rowKey, ...rest }) => (
      <input type={mode} rowindex={rowIndex} rowkey={rowKey} {...rest} onChange={e => {}} />
    ),
    onSelect: (row, isSelect) => {
      if (isSelect && row.id === authState.user.id) {
        return NotificationManager.error('You cannot send the message to yourself', 'Message', 3000)
      }
      // handleSelectionChanged(isSelect, row)
      if (isSelect) {
        // add trade
        const found = selectedUsers.some(item => item.id === row.id); 
        if (!found) {
          const trades = [...selectedUsers, row]
          setSelectedUsers(trades);
        }
      }
      else {
        // remove the trade
        const trades = selectedUsers.filter(item => item.id !== row.id); 
        setSelectedUsers(trades);
      }  
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setSelectedUsers(rows)
      }
      else {
        setSelectedUsers([])
      }
    }
  }
  
  const columns = [
    {
      dataField: 'username',
      text: 'Username',
      headerAlign: 'center',
    },
    {
      dataField: 'userGroup',
      text: 'Role',
      headerAlign: 'center',
      sort: true,
    },
    {
      dataField: 'name',
      text: 'Name',
      headerAlign: 'center',
    },
    {
      dataField: 'industry',
      text: 'Industry',
      headerAlign: 'center',
      sort: true
    },
    {
      dataField: 'created',
      text: 'Since',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '90px' }),
      formatter: ((cell, row) => formatDate(row.created))
    },
    {
      dataField: 'verified',
      text: '',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '50px' }),
      formatter: ((cell, row) => row.verified ? <i className="i-Checked-User text-success mr-2"></i> : <i className="i-Remove-User text-danger mr-2"></i>)
    },
    {
      dataField: 'subscription',
      text: 'Subscription',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '100px' }),
      formatter: ((cell, row) => {
        if (Array.isArray(row.subscriptions)) {
          const activeSubscriptions = row.subscriptions.filter(s => s.status === SubscriptionStatus.ACTIVE)
          if (activeSubscriptions?.length > 0) {
            if (row.userGroup === UserGroup.CLIENT) {
              return <span className='text-success'>{activeSubscriptions.length}</span>
            }
            else {
              const days = daysToNow(activeSubscriptions[activeSubscriptions.length - 1]?.end)
              if (days.includes('ago')) {
                return <span className='text-warning'>{days}</span>
              }
              return <span className='text-success'>{days}</span>
            }
          }
          else {
            const days = daysToNow(row.subscriptions[row.subscriptions.length - 1]?.end)
            return <span className='text-warning'>{days}</span>
          }
        }
        else {
          return ''
        }
      })
    },
    {
      dataField: 'status',
      text: '',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      editable: false,
      headerStyle: () => ({ width: '80px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
    {
      dataField: 'ref',
      text: 'Ref',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
    },
    {
      dataField: 'Action',
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '60px' }),
      formatter: ((cell, row) => { 
        return <Dropdown onSelect={(action) => actionUser(action, row)}>
          <Dropdown.Toggle as="span" className="cursor-pointer toggle-hidden">
            <MdMoreVert size={18}></MdMoreVert>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {(row.status === UserStatus.UNVERIFIED || row.status === UserStatus.PENDING) && <div>
              <Dropdown.Item eventKey={UserAction.APPROVE_USER}><i className='i-Like text-success'> </i>Approve user</Dropdown.Item>
            </div>}
            <Dropdown.Item eventKey={UserAction.VIEW_PROFILE}><i className='i-ID-Card text-primary'> </i>User card</Dropdown.Item>
            
            {(row.userGroup === UserGroup.CONTRACTOR || row.userGroup === UserGroup.SPECIALIST) && 
              <Dropdown.Item eventKey={UserAction.USER_SUBSCRIPTIONS}><i className='i-Dollar-Sign text-success'> </i>Subscripiton</Dropdown.Item>
            }
            <Dropdown.Item eventKey={UserAction.USER_PROJECTS}><i className='i-Gears text-info'> </i>User projects</Dropdown.Item>

            {row.status !== UserStatus.SUSPENDED && <div>
              <Dropdown.Item eventKey={UserAction.CONTACT_USER}><i className='i-Business-Man text-success'> </i>Contact user</Dropdown.Item>
              <div className='dropdown-divider'></div>
              <Dropdown.Item eventKey={UserAction.SUSPEND_USER}><i className='i-Close-Window text-danger'> </i>Suspend user</Dropdown.Item>
            </div>}
          </Dropdown.Menu>
        </Dropdown>
      })
    },    
  ]

  useEffect(() => {

    const onUserNotification$ = socket.onUserNotification().subscribe(notification => {

      refreshOnNotification(notification.type, RefreshType.ADMIN_MEMBERSHIPS, loadMemberships)
    })
    return() => {
        onUserNotification$.unsubscribe()
    }
  }, []);

  useEffect(() => {
    
    loadMemberships();
  }, [userGroup]);

  const loadMemberships = async () => {

    let results = []
    try {
      spinner.show()
      if (userGroup === UserStatus.PENDING) {
        results = await adminService.getPendingRegistrations(authState.user.id)
      }
      else {
        results = await adminService.getMemberships(authState.user.id, userGroup)
      }
      if (results.error) {
        NotificationManager.error(results.message, 'Server Connection', 3000);      
      }
      else {
        const items = results.map((item, i) => { return {...item, name: `${item.firstName} ${item.lastName}`, index: i + 1 }});
        setMemberships(items); 
      }
    }
    catch {
      NotificationManager.error('Something went wrong.', 'Server Connection', 3000);      
    }
    finally {
      spinner.hide()
    }
  }

  const handleSuspendUser = (userId) => {

    swal.queue([{
      title: "Enter reason for suspension",
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Suspend User",
      showLoaderOnConfirm: true,
      inputAutoTrim: true,
      inputValidator: result => !result && 'You need to enter something!',
      preConfirm: message => {
        if (message.length > 0) {
          return adminService.suspendUser(authState.user.id, userId, message)
          .then(response => {
            if (response.error) {
              swal.showValidationMessage(`Request failed: ${response.message}`);
            }
            swal.insertQueueStep('User was suspended');
          })
          .catch(error => {
            swal.insertQueueStep({
              icon: "error",
              title: "Semething went wrong."
            });
          });  
        }
      },
      allowOutsideClick: () => !swal.isLoading()
    }])
    .then(result => {
      if (result.value) {
        loadMemberships()
      }
    });
  }

  const actionUser = (action, user) => {

    switch(action) {

      case UserAction.APPROVE_USER:
        return navigateTo({ history: history, navigation: AdminNavigation, pathname: AdminNavigation.APPROVE_USER, user: user });
        
      case UserAction.USER_SUBSCRIPTIONS:
      case UserAction.USER_PROJECTS:
      case UserAction.VIEW_PROFILE:
        let tab
        if (action === UserAction.USER_PROJECTS) {
          tab = ProfileCardTab.PROJECTS
        }
        if (action === UserAction.USER_SUBSCRIPTIONS) {
          tab = ProfileCardTab.SUBSCRIPTION
        }
        if (user.userGroup === UserGroup.CLIENT) {
          return navigateTo({ history: history, navigation: AdminNavigation, pathname: AdminNavigation.CLIENT_CARD, user: user, tab: tab });
        }
        else if (user.userGroup === UserGroup.CONTRACTOR || user.userGroup === UserGroup.SPECIALIST) {
          return navigateTo({ history: history, navigation: AdminNavigation, pathname: AdminNavigation.CONTRACTOR_CARD, user: user, tab: tab });
        }
        break;

      case UserAction.CONTACT_USER:
        return handleOpenChat(authState, history, user.id);

      case UserAction.SUSPEND_USER:
        return handleSuspendUser(user.id);
    }
  }

  return (
    <div>
      <Breadcrumb
        routeSegments={[
          { name: 'Admin', path: AdminNavigation.HOME },
          { name: `${userGroup} Memberships` }
        ]}
      ></Breadcrumb>

      <div className='row'>
          <div className='col-md-12'>

          <SimpleCard>

            <ToolkitProvider
              keyField='id'
              data={registrations}
              columns={columns}
              search
              exportCSV
            >
              {props => (
                <>
                  <div className='d-flex justify-content-end align-items-center'>
                   
                    <span className='mb-2 mr-1'>Search:</span>
                    <SearchBar {...props.searchProps} className='mb-0 ' />
                    <ClearSearchButton { ...props.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
                    <button className='btn btn-outline-success mb-2 mr-1' onClick={_ => props.csvProps.onExport()}>Export CSV</button>
                    <ReloadButton onClick={loadMemberships} />
                    <span className='mb-2'><SendMessageWidget recipients={selectedUsers} /></span>
                  </div>
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4
                    wrapperClasses="table-responsive"
                    pagination={paginationFactory({ ...paginationOptions, totalSize: registrations.length })}
                    noDataIndication={'No records found'}
                    striped
                    hover
                    condensed
                    selectRow={ selectRow }
                  />
                </>
              )}
            </ToolkitProvider>
          </SimpleCard>
        </div>
      </div>

      <NotificationContainer />
    </div>
  );
}
