import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Badge, Dropdown } from "react-bootstrap";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { NotificationContainer } from 'react-notifications';
import profileService from '../../profile/profileService';
import { paginationOptions, StatusClass, AccessAction } from 'app/utils/constants';
import { useAuthState } from 'app/auth';
import { formatDate } from 'app/utils/time';
import { constructCompanyName } from 'app/utils/common';
import { AdminNavigation } from '../adminRoutes';
import { useHistory } from "react-router-dom";
import { MdMoreVert} from "react-icons/md";
import { IwtbModal } from 'app/widgets';

export const AccessRegisterWidget = ({ project, readOnly }) => {

  const authState = useAuthState();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [accessRegister, setAccessRegister] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState('');

  const { SearchBar, ClearSearchButton } = Search;

  let columns = [
    {
      dataField: 'trades',
      text: 'Trades',
      headerAlign: 'center',
      formatter: ((cell, row) => row.trades ? row.trades.map(v => v.text).join(', ') : '')
    },
    {
      dataField: 'company',
      text: 'Company',
      headerAlign: 'center',
      formatter: ((cell, row) => { 
        return <span className="text-primary cursor-pointer" onClick={() => navigateTo(AdminNavigation.CONTRACTOR_CARD, row)}>{constructCompanyName(row)}</span>;
      })
    },
    {
      dataField: 'industry',
      text: 'Industry',
      headerAlign: 'center',
    },  
    {
      dataField: 'created',
      text: 'Submitted',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => ({ width: '100px' }),
      formatter: ((cell, row) => formatDate(row.created))
    },
    {
      dataField: 'access',
      text: 'Access',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => ({ width: '90px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
  ];

  if (!readOnly) {
    columns = [
      ...columns,
      {
        dataField: 'action',
        text: 'Action',
        headerAlign: 'center',
        align: 'center',
        headerStyle: (colum, colIndex) => ({ width: '60px' }),
        formatter: ((cell, row) => { 
          return <Dropdown onSelect={(action) => actionAccess(action, row)}>
            <Dropdown.Toggle as="span" className="cursor-pointer toggle-hidden">
              <MdMoreVert size={18}></MdMoreVert>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {row.message?.length > 0 && <>
                <Dropdown.Item eventKey={AccessAction.VIEW_COMMENTS}><i className='i-Speach-Bubble-3 text-info'> </i>View comments</Dropdown.Item>
              </>}
            </Dropdown.Menu>
          </Dropdown>
        })
      },
      ]
  }

  React.useEffect(() => {

    if (authState.isAuthenticated && project) {
      loadProjectAccessRegister(project);
    }
  }, [authState.user, project]);

  const loadProjectAccessRegister = (project) => {

    if (project.accessRegister && project.accessRegister.length > 0) {
      setLoading(true);

      const promises = project.accessRegister.map((item, i) => new Promise( async (resolve, reject) => {
          const contractor = await profileService.getContractorProfile(authState.user.id, item.contractorId);
          return resolve({...contractor, ...item });
      }));
  
      Promise.all(promises).then((items) => {
        setLoading(false);
        setAccessRegister(items); 
      });
    }
  }

  const navigateTo = (pathname, request) => {
    
    switch(pathname) {
      case AdminNavigation.CONTRACTOR_CARD:
        pathname = pathname.replace(':contractorId', request.contractorId);
    }
    history.push({ pathname: pathname });  
  }


  const actionAccess = (action, access) => {

    switch(action) {

      case AccessAction.VIEW_COMMENTS:
        setComments(access.message)
        setShowComments(true)
        return 
    }
  }

  return (
    <>
        <ToolkitProvider
            keyField='id'
            data={accessRegister}
            columns={columns}
            search
            exportCSV
        >
            {props => (
            <>
                <div className='d-flex justify-content-end align-items-center'>
                
                <span className='mb-2 mr-1'>Search:</span>
                <SearchBar {...props.searchProps} className='mb-0 ' />
                <ClearSearchButton { ...props.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
                <button className='btn btn-outline-success mb-2 mr-1' onClick={_ => props.csvProps.onExport()}>Export CSV</button>
                </div>
                <BootstrapTable
                {...props.baseProps}
                bootstrap4
                wrapperClasses="table-responsive"
                pagination={paginationFactory({ ...paginationOptions, totalSize: accessRegister.length })}
                noDataIndication={'No records found'}
                hover
                condensed
                loading={ loading }
                />
            </>
            )}
        </ToolkitProvider>

        <NotificationContainer />
        <IwtbModal show={showComments} title='Comments' message={comments} handleClose={() => setShowComments(false)} />
    </>
  );
}
