
import React from "react";
import { Badge, Alert } from "react-bootstrap";
import { StatusClass, ContractorSubscriptionKey, SubscriptionStatus } from 'app/utils/constants';
import { formatDateTime12, parseIsoDateTime, formatDate_FNS } from 'app/utils/time';
import { addMonths, isAfter, isValid } from 'date-fns'

export const ContractorSubscriptionWidget = ({ current, subscriptions, onUpgrade }) => {
       
    const today = new Date()
    let currentEnd = today
    let buttonTitle ='Start'
    let status = 'new'

    if (current?.end) {
        // user has existing subscription
        buttonTitle = 'Add'
        currentEnd = parseIsoDateTime(current?.end)
        currentEnd = isValid(currentEnd) ? currentEnd : today
        status = isAfter(currentEnd, today) ? SubscriptionStatus.ACTIVE : SubscriptionStatus.EXPIRED
    }
    currentEnd = status === 'active' ? currentEnd : today

    const content = subscriptions.map((subscription, index) => {

        const end = addMonths(currentEnd, subscription?.length || 0)

        if (current?.key && subscription.key === ContractorSubscriptionKey.TRIAL) {
            // do not offer free trial when user had a subscription
            return <span key={index}></span>
        } 

        return <div key={index} className="col-lg-3 col-xl-3">
            <div className="ul-pricing__table-1 mb-4">
                <div className="ul-pricing__image card-icon-bg-primary">
                    <i className={subscription.icon}></i>
                </div>
                <div className="ul-pricing__title">
                    <h2 className="heading text-primary">{subscription.title}</h2>
                </div>
                <div className="ul-pricing__main-number">
                    <h3 className="heading display-3 text-primary t-font-boldest">${subscription.price}</h3>
                    <div className="ul-pricing__table-listing mb-4">
                    <ul>
                        <li className="t-font-bolder text-mute">plus GST</li>
                        <li className="ul-pricing__text text-mute">Until {formatDate_FNS(end)}</li>
                    </ul>
                </div>
            </div>
            <button type="button" className="btn btn-lg btn-primary m-1" onClick={() => onUpgrade(subscription)}>{buttonTitle}</button>            </div>
        </div>
    })

    return (<>
        <div className="row">
            <div className="col-md-12">

                {status === SubscriptionStatus.ACTIVE && <Alert variant='light'>
                    <div className='d-flex flex-row justify-content-between'>
                        <span><strong>Status: </strong><Badge className={StatusClass[status.toUpperCase()]}>{status}</Badge></span>
                        <span><strong>Expires:</strong> {formatDateTime12(current.end)}</span>
                    </div>
                </Alert>}
                    
                {status === SubscriptionStatus.EXPIRED && <Alert variant='danger'>
                    <div className='d-flex flex-row justify-content-between'>
                        <span><strong>Status: </strong><Badge className={StatusClass[status.toUpperCase()]}>{status}</Badge></span>
                        <span><strong>Expired:</strong> {formatDateTime12(current.end)}</span>
                    </div>
                </Alert>}

                <div className="row d-flex flex-row justify-content-center">{content}</div>
            </div>
        </div>
    </>)
}
