import HttpService from 'app/services/httpService'
import { sortArray } from 'app/utils/common'

class userService {

    http = new HttpService();

    switchProfile = (userId, userGroup, requiredFields) => {
        return this.http.post(`users/${userId}/profiles/switch`, { userGroup, requiredFields });
    }

    getUserProfiles= (userId, username) => {
        return this.http.get(`users/${userId}/profiles/${username}`);
    }

    addSubscription = (userId, subscriptionKey, payment) => {
        return this.http.post(`users/${userId}/subscriptions`, { payment: payment, subscriptionKey: subscriptionKey, userId: userId });
    };

    getMySubscriptions = async (userId) => {

        let subscriptions = await this.http.get(`users/${userId}/subscriptions/history`)

        if (Array.isArray(subscriptions)) {
            subscriptions = sortArray(subscriptions, 'start', true)
        }
        return subscriptions
    }

    getMySubscription = (userId) => {
        return this.http.get(`users/${userId}/subscriptions/current`);
    }

    sendInvite = (userId, contact) => {
        return this.http.post(`users/${userId}/invite`, { contact: contact });
    };

    getUserProfileImage = (userId) => {
        return this.http.get(`users/${userId}/profile/image`);
    };

    updateProfileImage = (userId, image) => {
        return this.http.put(`users/${userId}/profile/image`, { image: image });
    };

    updateProfileBackground = (userId, blobName) => {
        return this.http.put(`users/${userId}/profile/background`, { image: { blobName: blobName }});
    };

    addProfilePhotos = (userId, photos) => {
        return this.http.post(`users/${userId}/profile/photos`, { photos: photos });
    };

    setChatAsRead = (userId, opponentId) => {
        return this.http.put(`users/${userId}/chats/opponents/${opponentId}`);
    };

    getUserChat = (userId, opponentId) => {
        return this.http.get(`users/${userId}/chats/opponents/${opponentId}`);
    };

    getUserContact = (userId, contactId) => {
        return this.http.get(`users/${userId}/contacts/${contactId}`);
    };
  
    getUserContacts = (userId) => {
        return this.http.get(`users/${userId}/contacts`);
    };
  
    getUserChatContacts = (userId) => {
        return this.http.get(`users/${userId}/chats/contacts`);
    };

    addContactWithId = (userId, contactId) => {
        return this.http.post(`users/${userId}/contacts/${contactId}`);
    };

    addContact = (userId, contact) => {
        return this.http.post(`users/${userId}/contacts`, { contact: contact });
    };

    updateContact = (userId, contactId, contact) => {
        return this.http.put(`users/${userId}/contacts/${contactId}`, { contact: contact });
    };

    deleteContact = (userId, contactId) => {
        return this.http.delete(`users/${userId}/contacts/${contactId}`);
    };

    deleteFile = (userId, blobName) => {
        return this.http.put(`users/${userId}/files`, { blobName: blobName });
    };
}

export default new userService();