import { GuestNavigation } from './guestRoutes';

export const guestMenu = [
  {
    name: "Sign Up",
    description: "Register with us.",
    type: "link",
    icon: "i-Engineering",
    path: GuestNavigation.REGISTRATION
  },
  {
    name: "Contact Us",
    description: "Contact our support.",
    type: "link",
    icon: "i-Paper-Plane",
    path: GuestNavigation.CONTACT_US
  },
];
