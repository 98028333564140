import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { Button, Alert } from "react-bootstrap";
import { Breadcrumb } from '@gull';
import { ClientNavigation } from '../clientRoutes';
import { RfiWidget } from '.';

export const RfiView = (props) => {
  
  const history = useHistory();
  const route = useLocation();
  const [rfi, setRfi] = useState();

  useEffect(_ => {

    const state = route.state;
    if (state && state.rfi) {
      setRfi(state.rfi);
    }
  }, [route]);

  return (
    <>
      <Breadcrumb
        routeSegments={[
          { name: "My Projects", path: ClientNavigation.PROJECTS },
          { name: 'RFI' }
        ]}
      ></Breadcrumb>

      {rfi && <RfiWidget rfi={rfi} />} 

      {!rfi && <Alert className="text-center alert-card" variant="danger">
        RFI was not found.
        <Button variant="warning" className="btn ml-3" onClick={_ => {history.goBack();}}>Return</Button>
      </Alert>}

    </>
  );
}
