import { io } from 'socket.io-client';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import config from 'app/utils/config';
import { SocketEvent } from './constants';

export default class SocketService {

    socket = {}

    constructor() {
        this.socket = io(config.SOCKET_ENDPOINT, { path: config.SOCKET_PATH })

        // this.socket.on(SocketEvent.USER_NOTIFICATION, notification => this.onUserNotification(notification))
        // this.socket.on(SocketEvent.SWITCH_ROOM_SUCCESS, data => this.onSwitchRoomSuccess(data));
        // this.socket.on(SocketEvent.ERROR, data => onError(dispatch, data));
    }
    
    onNotificationRefresh () {
        return fromEvent(this.socket, SocketEvent.NOTIFICATION_REFRESH).pipe(map(notification => {
            return notification
        }))
    }

    onUserNotification () {
        return fromEvent(this.socket, SocketEvent.USER_NOTIFICATION).pipe(map(notification => {
            return notification
        }))
    }

    onChat () {
        return fromEvent(this.socket, SocketEvent.CHAT).pipe(map(message => {
            return message
        }))
    }
        
    send = (socketEvent, payload) => {

        return new Promise((resolve, reject) => {
            try {
                this.socket.emit(socketEvent, payload, (response) => {
                    return resolve(response);
                });
            }
            catch(ex) {
                return reject(ex.message);
            }    
        })
    }

    chat = (payload) => {

        return new Promise((resolve, reject) => {
            try {
                this.socket.emit(SocketEvent.CHAT, payload, (response) => {
                    return resolve(response);
                });
            }
            catch(ex) {
                return reject(ex.message);
            }    
        })
    }

    login = (user) => {

        try {
            this.socket.emit(SocketEvent.LOGIN, user);
        }
        catch(ex) {
            throwGenericException('socketUserLogin', ex);
        }
    }

    logout = (user) => {
    
        try {
            this.socket.emit(SocketEvent.LOGOUT, user);
        }
        catch(ex) {
            throwGenericException('socketUserLogout', ex);
        }
    }

    disconnect = () => {

        if(this.socket) {
            this.socket.disconnect();
        }
    }
}

const throwGenericException = (method, exception) => {

    console.log(method, exception.message);
    throw('Socket Error. Something went wrong.');
}

    // onSwitchRoom = () => {
    //     return fromEvent(this.socket, SocketEvent.SWITCH_ROOM_SUCCESS).pipe(tap(chat => {
    //         if (chat?.room) {
    //             this.chatRoom = chat.room
    //             this.messages = chat.messages
    //         }
    //     }))
    // }

    // switchRoom = (userId, opponentId) => {

    //     try {
    //         this.socket.emit(SocketEvent.SWITCH_ROOM, { userId: userId, prevRoom: this.chatRoom, opponentId: opponentId });
    //     }
    //     catch(ex) {
    //         throwGenericException('socketSwitchRoom', ex);
    //     }
    // }