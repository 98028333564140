import React, { useEffect, useState } from "react"
import { Breadcrumb } from '@gull'
import { Tabs, Tab, Badge } from "react-bootstrap"
import { AdminNavigation } from '../adminRoutes';
import { NotificationContainer, NotificationManager } from "react-notifications"
import { useParams, useHistory } from "react-router-dom"
import bidService from './bidService'
import { BidDetailsWidget } from 'app/widgets'
import { useAuthState } from 'app/auth'
import { StatusClass, audFormatter, BidTab, SowStatus, UserGroup, BidStatus } from 'app/utils/constants'
import { formatDateTime12 } from 'app/utils/time'
import { IwtbFileRegistry, SowWidget } from 'app/widgets'
import { calcVariation, sortArray, getSowBadge, customTabHeader } from 'app/utils/common'
import { awardBid } from '../project/functions'
import swal from "sweetalert2"

export const BidCard = () => {

  const { projectId, bidId, tab } = useParams()
  const [defaultActiveKey, setDefaultActiveKey] = useState(BidTab.DETAILS)
  const [variation, setVariation] = useState(0)
  const [bid, setBid] = useState()
  const authState = useAuthState()
  const history = useHistory()
  const [sow, setSow] = useState([])
  const [sowBadge, setSowBadge] = useState()
  const [showAwardButton, setShowAwardButton] = useState(false)
  const [showSowButton, setShowSowButton] = useState(false)
  
  const getBid = React.useCallback((clientId, projectId, bidId) => {

    bidService.getBid(clientId, projectId, bidId)
    .then(bid => {
      if (bid.error) {
        NotificationManager.error(bid.message, 'Server Connection', 3000)      
      }
      else {
        setBid(bid)
        const variation = calcVariation(bid.sow)
        setVariation(variation)
        setShowSowButton(bid.status !== BidStatus.DELETED && bid.status !== BidStatus.DECLINED)

        if (bid.sow?.length > 0) {
          const sow = sortArray(bid.sow, 'created', true)
          setSow(sow)  

          const badge = getSowBadge(sow[0].status)
          setSowBadge(badge)
          setShowAwardButton(!(bid.status === BidStatus.DELETED || bid.status === BidStatus.DECLINED || bid.status === BidStatus.ACCEPTED || bid.status === BidStatus.AWARDED || sow.findIndex(s => s.status === SowStatus.PENDING) > -1))  
        }
      }
    })
    .catch(e => {
      NotificationManager.error('Something went wrong.', 'Server Connection', 3000)      
    })
  })

  useEffect(() => {

    if (projectId && bidId && authState.isAuthenticated) {
      getBid(authState.user.id, projectId, bidId)
    }
  }, [projectId, bidId, authState.user])

  useEffect(() => {

    const bidTab = tab === 'undefined' || tab === undefined  ? BidTab.DETAILS : tab
    setDefaultActiveKey(bidTab)
  }, [tab])

  const handleSowConfirm = sow => {

    swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, confirm SOW',
        cancelButtonText: 'No'
    })
    .then(async result => {
      if (result.value) {
        try {
          await bidService.confirmSow(authState.user.id, projectId, bidId, sow)
          NotificationManager.success('SOW was confirmed successfully.', 'Confirm SOW', 2000)
          setTimeout(() => history.goBack(), 1500)
        }
        catch {
          NotificationManager.error('Something went wrong.', 'Confirm SOW', 3000)
        }
      }
    })  
  }

  const handleAddSow = async () => {

    history.push({
      pathname: AdminNavigation.SUBMIT_SOW.replace(':projectId', projectId).replace(':bidId', bidId),
    });            
  }

  const handleAwardBid = async () => {
    
    try {
      await awardBid(authState.user.id, projectId, bidId)
      NotificationManager.success('Trade was awarded successfully.', 'Trade Award', 2000)  
      handleClose()
    }
    catch {
      NotificationManager.error('Sorry, something went wrong.', 'Trade Award', 3000)
    }
  }

  const handleRejectVariation = async (sow) => {

    const variation = calcVariation([...bid.sow, sow])
    setVariation(variation)

    try {
      await bidService.rejectSow(authState.user.id, projectId, bid.id, sow)
      NotificationManager.success('Task has been rejected.', 'Reject SOW', 2000)  
      handleClose()
    }
    catch(e) {
      NotificationManager.error('Sorry, something went wrong.', 'Reject SOW', 3000)
    }
  }

  const handleClose = () => {
    
    history.goBack()
  }

  return (
    <div>
      <Breadcrumb
        routeSegments={[
          { name: 'Tendering Projects', path: AdminNavigation.PROJECTS_TENDERING },
          { name: 'View Trade Bid' }
        ]}
      ></Breadcrumb>

      { bid && <>
      
        <section className="ul-contact-detail">
          <div className="row">
            <div className="col-md-12">
              <div className="card">

                <div className="card-header bg-transparent">
                    <div className='d-flex flex-row justify-content-between'>
                      {bid.status && <span>Status: <Badge className={StatusClass[bid.status.toUpperCase()]}>{bid.status}</Badge></span>}
                      {bid.amount && <div>
                        <span className='mr-3'>Bid: <strong className='text-light '>{audFormatter.format(bid.amount ?? 0)}</strong></span>
                        <span className='mr-3'>Variation: <strong className='text-warning'>{audFormatter.format(variation)}</strong></span>
                        <span className='mr-3'>Total Bid: <strong className='text-primary'>{audFormatter.format((bid.amount || 0) + variation)}</strong></span>
                      </div>}
                      {bid.created && <span>Submitted: {formatDateTime12(bid.created)}</span>}
                    </div>
                </div>
                
                <div className="card-body">

                  <Tabs defaultActiveKey={defaultActiveKey}>

                    <Tab eventKey={BidTab.DETAILS} title={customTabHeader(BidTab.DETAILS, "i-Receipt-4")}>
                      <BidDetailsWidget bid={bid} />
                    </Tab>
                    
                    {sow && sow.length > 0 &&
                      <Tab eventKey={BidTab.SOW} title={customTabHeader(BidTab.SOW, "i-Check", sowBadge)}>
                        <SowWidget bid={bid} handleSowConfirm={(sow) => handleSowConfirm(sow)} handleRejectVariation={handleRejectVariation} />
                      </Tab>                        
                    }
                    
                    <Tab eventKey={BidTab.Documents} title={customTabHeader(BidTab.Documents, "i-Files")}>
                      <IwtbFileRegistry files={bid.files} />
                    </Tab>

                  </Tabs>
                </div>

                <div className="card-footer"> 
                  <div className="mc-footer">
                    <div className="row">
                      <div className="col-md-12">
                        {showSowButton && <button type="button" className="btn btn-primary m-1" onClick={handleAddSow}>Add SOW</button>}
                        {showAwardButton && <button type="button" className="btn btn-success m-1" onClick={handleAwardBid}>Award Trade</button>}
                        <button type="button" className="btn btn-warning m-1 footer-delete-right" onClick={handleClose}>Close</button>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

      </>}
      
      <NotificationContainer />
  </div>
  )  
}
