import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Form, ErrorMessage } from 'formik';
import { classList } from '@utils';
import * as yup from 'yup';
import { NotificationManager } from "react-notifications";
import passwordService from './passwordService';
import ReactGA from 'react-ga';

export const ForgotPasswordWidget = ({ show, handleClose }) => {

  const initialValues = {
    username: '',
    email: '',
  }

  const formSchema = yup.object().shape({
    username: yup.string().required('Username is required'),
    email: yup.string().required('Email is required').email('Invalid email'),
  })

  useEffect(() => {
    
    ReactGA.pageview(window.location.pathname);
  }, []);

  const resetPassword = (values, resetForm, setSubmitting) => {
    
    if (values.username.length === 0 || values.email.length === 0) {
      return
    }
    try {
      ReactGA.event({ category: 'Password', action: 'Forgot', value: values.username });
    } catch {}

    setSubmitting(true);
    passwordService.forgotPassword(values.username, values.email).then(result => {
      setSubmitting(false);
      if (result?.error) {
        NotificationManager.error(result.message, 'Forgot Password', 5000);
      }
      else {
        resetForm()
        NotificationManager.success('You will receive password reset email shortly. ', 'Forgot Password', 7000);
        handleClose();  
      }
    })
    .catch(ex => {
      setSubmitting(false);
      NotificationManager.error('Something went wrong', 'Forgot Password', 5000);
    });
  };

  return (<>
      <Formik initialValues={initialValues} validationSchema={formSchema}>
      {({ values, errors, touched, isValid, isSubmitting, handleChange, resetForm, handleBlur, setSubmitting }) => {
        return (
          <Form>
            <Modal show={show} onHide={() => handleClose()} centered={true} size='md'>
              <Modal.Header closeButton>
                <Modal.Title>Forgot Password</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='row'>
                  <div
                    className={classList({
                      'col-md-12 mb-3': true,
                      'valid-field': !errors.username && touched.username,
                      'invalid-field': errors.username && touched.username,
                    })}
                  >
                    <label htmlFor='username'>Username</label>
                    <input
                      type='text'
                      className='form-control'
                      id='username'
                      name='username'
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    <ErrorMessage name="username">{msg => <div className='invalid-feedback'>{msg}</div>}</ErrorMessage>
                  </div>
                </div>

                <div className='row'>
                  <div
                    className={classList({
                      'col-md-12 mb-3': true,
                      'valid-field': !errors.email && touched.email,
                      'invalid-field': errors.email && touched.email,
                    })}
                  >
                    <label htmlFor='email'>Email</label>
                    <input
                      type='email'
                      className='form-control'
                      id='email'
                      name='email'
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    <ErrorMessage name="email">{msg => <div className='invalid-feedback'>{msg}</div>}</ErrorMessage>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className='btn btn-warning m-1' onClick={() => { resetForm(); handleClose() }}>Cancel</button>
                <button className='btn btn-success m-1 footer-delete-right' onClick={() => resetPassword(values, resetForm, setSubmitting)} disabled={!isValid || isSubmitting}>Reset password</button>
              </Modal.Footer>
            </Modal>
          </Form>
        );
      }}
      </Formik>
    </>
  );
};
