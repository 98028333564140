import HttpService from 'app/services/httpService';

class AdminService {

    http = new HttpService();

    deleteProjectFile = (adminId, projectId, blobName) => {
        return this.http.put(`admins/${adminId}/projects/${projectId}/files/`, { blobName })
    }

    deleteProject = (adminId, projectId) => {
        return this.http.delete(`admins/${adminId}/projects/${projectId}/delete`)
    }

    getDeclinedProjects = (adminId) => {
        return this.http.get(`admins/${adminId}/declined`)
    }

    updateTrades = (adminId, projectId, trades) => {
        return this.http.post(`admins/${adminId}/projects/${projectId}/trades`, { trades: trades });
    };

    deleteTrade = (adminId, projectId, tradeId, reason) => {
        return this.http.delete(`admins/${adminId}/projects/${projectId}/trades/${tradeId}?reason=${reason}`);
    };

    restoreTrade = (adminId, projectId, tradeId) => {
        return this.http.put(`admins/${adminId}/projects/${projectId}/trades/${tradeId}/restore`);
    };

    approveTrade = (adminId, projectId, tradeId) => {
        return this.http.put(`admins/${adminId}/projects/${projectId}/trades/${tradeId}/approve`);
    };

    getWorkers = (adminId) => {
        return this.http.get(`admins/${adminId}/workers`);
    };

    // REPORTS

    getRegistrationReport = (adminId, startDate, endDate) => {
        return this.http.get(`admins/${adminId}/reports/registration?startDate=${startDate}&endDate=${endDate}`);
    };

    getActiveSubscriptionReport = (adminId) => {
        return this.http.get(`admins/${adminId}/reports/active-subscription`);
    };

    getProjectStatusReport = (adminId) => {
        return this.http.get(`admins/${adminId}/reports/project-status`);
    };

    getActiveUserReport = (adminId) => {

        return this.http.get(`admins/${adminId}/reports/active-user`);
    };

    // MEMBERSHIP

    getMemberships = (adminId, userGroup) => {
        return this.http.get(`admins/${adminId}/memberships/${userGroup ?? ''}`);
    };

    // REGISTRATIONS
    
    getUser = (adminId, userId) => {
        return this.http.get(`admins/${adminId}/users/${userId}`);
    };
    
    getPendingRegistrations = (adminId) => {
        return this.http.get(`admins/${adminId}/registrations/pending`);
    };

    requestRegistrationInfo = (adminId, userId, message) => {
        return this.http.post(`admins/${adminId}/registrations/${userId}/info`, message);
    };

    suspendUser = (adminId, userId, reason) => {
        return this.http.put(`admins/${adminId}/registrations/${userId}/suspend?reason=${reason}`);
    };

    rejectRegistration = (adminId, userId, reason) => {
        return this.http.delete(`admins/${adminId}/registrations/${userId}?reason=${reason}`);
    };

    approveRegistration = (adminId, user) => {
        return this.http.post(`admins/${adminId}/registrations/${user.id}`, user);
    };
    
    // SERVICES 
    
    deleteService = (adminId, projectId, serviceId, reason) => {
        return this.http.delete(`admins/${adminId}/projects/${projectId}/services/${serviceId}?reason=${reason}`);
    };

    updateServices = (adminId, projectId, services) => {
        return this.http.post(`admins/${adminId}/projects/${projectId}/services`, services);
    };

    // PROJECTS

    getProjects = (adminId, filter) => {
        filter = filter ? '/' + filter : ''
        const path = `admins/${adminId}/projects${filter}`
        return this.http.get(path);
    }

    getAwardRegister = (adminId, projectId) => {
        return this.http.get(`admins/${adminId}/projects/${projectId}/awards`);
    };

    getProjectBids = (adminId, projectId) => {
        return this.http.get(`admins/${adminId}/projects/${projectId}/bids`);
    };

    getProjectServices = (adminId, projectId) => {
        return this.http.get(`admins/${adminId}/projects/${projectId}/services`)
    }

    getServices = (adminId) => {
        return this.http.get(`admins/${adminId}/services`);
    };  

    approveProject = (adminId, project) => {
        return this.http.post(`admins/${adminId}/projects/${project.id}`, project);
    };

    rejectProject = (adminId, projectId, reason) => {
        return this.http.delete(`admins/${adminId}/projects/${projectId}?reason=${reason}`);
    };

    requestProjectInfo = (adminId, projectId, message) => {
        return this.http.post(`admins/${adminId}/projects/${projectId}/info`, message);
    };

    getPendingProjects = (adminId) => {
        return this.http.get(`admins/${adminId}/projects/pending`);
    };  

    getProject = (adminId, projectId) => {
        return this.http.get(`admins/${adminId}/projects/${projectId}`);
    };    
}

export default new AdminService();