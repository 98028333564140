import React, { useEffect } from 'react';
import { userSchema } from './userSchema';
import { Formik, Form } from 'formik';
import {IwtbInput, IwtbPlaceAutocomplete, IwtbTextArea } from 'app/widgets';
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda';
import profileService from './profileService';
import { NotificationManager } from "react-notifications";
import { useAuthState } from 'app/auth';
import ReactGA from 'react-ga';

export const UserEditWidget = ({ user }) => {

  const authState = useAuthState();
  
  useEffect(() => {

    if (user) {
      console.log(user)
    }

  }, [user]);

  const handleUpdate = (values, setSubmitting) => {

    try {
      ReactGA.event({
        category: 'Profile',
        action: 'User Profile Update',
        value: authState.user.id
      });
    } catch {}

    setSubmitting(true);

    profileService.updateProfile(authState.user.id, values)
    .then(result => {
      NotificationManager.success('Your profile was updated', 'Profile', 2000);
      setSubmitting(false);      
    })
    .catch(ex => {  
      setSubmitting(false);      
      NotificationManager.error('Something went wrong', 'Profile', 3000);
    });
  };

  return ( <>
    {user && 
        <Formik initialValues={user} enableReinitialize={true} validationSchema={userSchema}>
        {({
          values,
          handleSubmit,
          isSubmitting,
          isValid,
          setFieldValue,
          setSubmitting
        }) => {
          return (

            <Form className='needs-validation' onSubmit={handleSubmit} noValidate>

              <div className='form-row'>
                <IwtbInput label='Title' name='title' type='text' placeholder='Title' required />
                <IwtbInput label='First Name' name='firstName' type='text' placeholder='First name' required />
                <IwtbInput label='Last Name' name='lastName' type='text' placeholder='Last name' required />
              </div>

              <div className='form-row'>
                <IwtbInput label='Date of Birth' name='DOB' type='text' placeholder='DD/MM/YYYY' required />
                <IwtbInput label='Mobile' name='mobile' type='text' placeholder='0400 111 888' required />
                <IwtbInput label='Email' name='email' type='text' placeholder='Email' required />
              </div>

              <div className='form-row'>
                <div className='col-md-8 mb-3'>
                  <IwtbPlaceAutocomplete name='location' label='Address' location={values.location} type='text' placeholder='Start typing address' locationSelected={ location => setFieldValue('location', location) } />
                </div>
              </div>

              <div className='form-row'>
                <IwtbTextArea label='About Me' name='about' type='text' placeholder='Max 200 characters' maxLength={200} required />
              </div>

              <div className='mb-4'></div>

              <LaddaButton 
                className='btn btn-success btn-lg border-0 mr-2 mb-2 position-relative'
                loading={isSubmitting}
                disabled={!isValid}
                progress={0.5}
                onClick={_ => handleUpdate(values, setSubmitting)}
                data-style={EXPAND_RIGHT}
                type='button'
              >Update</LaddaButton>

            </Form>
          );
        }}
      </Formik>
      
  

}
    </>
  );
}

