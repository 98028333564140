import React from 'react';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Badge, Dropdown } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { paginationOptions, audFormatter, StatusClass, BidStatus, BidAction } from 'app/utils/constants';
import { ContractorNavigation } from '../contractorRoutes';
import { useHistory } from "react-router-dom";
import { MdMoreVert} from "react-icons/md";
import { calcVariation } from 'app/utils/common';

export const AwardRegisterWidget = ({ handleBidAction, awardRegister, readOnly, showExport }) => { 

  const history = useHistory();
  const { SearchBar, ClearSearchButton } = Search;

  let columns = [
    {
      dataField: 'trade',
      text: 'Trade',
      headerAlign: 'center',
      sort: true,
    },
    {
      dataField: 'projectName',
      text: 'Project',
      headerAlign: 'center',
      sort: true,
      formatter: ((cell, row) => {
        return <span className="text-primary cursor-pointer" onClick={() => navigateTo(ContractorNavigation.PROJECT_CARD, row.projectId)}>{row.projectName}</span>;
      })
    },
    {
      dataField: 'bid',
      text: 'Bid',
      headerAlign: 'center',
      align: 'right',
      headerStyle: (colum, colIndex) => ({ width: '100px' }),
      formatter: ((cell, row) => <span className='text-light'>{audFormatter.format(row.amount)}</span>)
    },
    {
      dataField: 'variation',
      text: 'Variation',
      headerAlign: 'center',
      align: 'right',
      headerStyle: () => ({ width: '100px' }),
      formatter: ((cell, row) => <span className='text-warning'>{audFormatter.format(calcVariation(row.sow))}</span>)
    },
    {
      dataField: 'value',
      text: 'Value',
      headerAlign: 'center',
      align: 'right',
      headerStyle: (colum, colIndex) => ({ width: '100px' }),
      formatter: ((cell, row) => <span className='text-primary'>{audFormatter.format(row.amount + calcVariation(row.sow))}</span>)
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => ({ width: '90px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
    {
      dataField: 'ref',
      text: 'Ref',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
    },
  ];

  if (!readOnly) {
    columns = [...columns,
      {
        dataField: 'action',
        text: 'Action',
        headerAlign: 'center',
        align: 'center',
        headerStyle: (colum, colIndex) => ({ width: '80px' }),
        formatter: ((cell, row) => { 
          return <Dropdown onSelect={(action) => handleBidAction(row, action)}>
            <Dropdown.Toggle as="span" className="cursor-pointer toggle-hidden">
              <MdMoreVert size={18}></MdMoreVert>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey={BidAction.CONTACT_CLIENT}><i className='i-Business-Man text-success'> </i>Contact Client</Dropdown.Item>
              {row.comments?.length > 0 && <>
                <Dropdown.Item eventKey={BidAction.VIEW_COMMENTS}><i className='i-Speach-Bubble-3 text-info'> </i>View comments</Dropdown.Item>
              </>}
              <Dropdown.Item eventKey={BidAction.VIEW_BID}><i className="i-Dollar-Sign"> </i>View Bid</Dropdown.Item>
              <Dropdown.Item eventKey={BidAction.VIEW_SOW}><i className="i-Check text-primary"> </i>View SOW</Dropdown.Item>
              {row.status === BidStatus.AWARDED && <Dropdown.Item eventKey={BidAction.ACCEPT_AWARD}><i className="i-Like-2 text-success"> </i>Accept award</Dropdown.Item>}
              <div className="dropdown-divider"></div>
              {row.status === BidStatus.AWARDED && <Dropdown.Item eventKey={BidAction.DECLINE_AWARD}><i className="i-Unlike-2 text-danger"> </i>Decline Award</Dropdown.Item>}
              {row.status !== BidStatus.WITHDRAWN && <Dropdown.Item eventKey={BidAction.WITHDRAW}><i className="i-Close-Window text-danger"> </i>Withdraw Bid</Dropdown.Item>}
          </Dropdown.Menu>
          </Dropdown>
        })
      }
    ];
  }

  const navigateTo = (pathname, projectId) => {
    
    switch(pathname) {
      case ContractorNavigation.PROJECT_CARD:
        pathname = pathname.replace(':projectId', projectId).replace(':tab?', '');
    }
    history.push({ pathname: pathname });  
  }

  return (
    <>
      <ToolkitProvider
        keyField='index'
        data={awardRegister}
        columns={columns}
        search
        exportCSV
      >
        {toolkitProps => (
          <>
            <div className='d-flex justify-content-end align-items-center'>
              
              <span className='mb-2 mr-1'>Search:</span>
              <SearchBar {...toolkitProps.searchProps} className='mb-0 ' />
              <ClearSearchButton { ...toolkitProps.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
              {showExport && <button className='btn btn-outline-success mb-2 mr-1' onClick={_ => toolkitProps.csvProps.onExport()}>Export CSV</button>}
            </div>
            <BootstrapTable
              {...toolkitProps.baseProps}
              bootstrap4
              wrapperClasses="table-responsive"
              pagination={paginationFactory({ ...paginationOptions, totalSize: awardRegister.length })}
              noDataIndication={'No records found'}
              condensed
              defaultSorted={[{ dataField: 'trade.text', order: 'asc'}]}
            />
          </>
        )}
      </ToolkitProvider>
    </>
  );
}
