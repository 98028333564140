import HttpService from 'app/services/httpService'

class bidService {

    http = new HttpService()

    submitBid = (clientId, projectId, bids) => {
        return this.http.post(`clients/${clientId}/projects/${projectId}/bids`, { bids: bids })
    }

    getBid = (clientId, projectId, bidId) => {
        return this.http.get(`clients/${clientId}/projects/${projectId}/bids/${bidId}`)
    }

    rejectSow = (clientId, projectId, bidId, sow) => {
        return this.http.post(`clients/${clientId}/projects/${projectId}/bids/${bidId}/sows/${sow.id}`, { sow: sow });
    }

    confirmSow = (clientId, projectId, bidId, sow) => {
        return this.http.put(`clients/${clientId}/projects/${projectId}/bids/${bidId}/sows/${sow.id}`, { sow: sow });
    }

    awardBid = (clientId, projectId, bidId) => {
        return this.http.post(`clients/${clientId}/projects/${projectId}/bids/${bidId}`);
    }

    declineBid = (clientId, projectId, bidId, reason) => {
        return this.http.put(`clients/${clientId}/projects/${projectId}/bids/${bidId}?reason=${reason}`);
    }

    getTrades = (clientId, projectId) => {
        return this.http.get(`clients/${clientId}/projects/${projectId}/trades`);
    };
}

export default new bidService()