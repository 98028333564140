import React, { useState, useEffect } from 'react';
import { SimpleCard } from '@gull';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Breadcrumb } from '@gull';
import { AdminNavigation } from '../adminRoutes';
import { useHistory } from "react-router-dom";
import { useAuthState } from 'app/auth';
import { MdMoreVert} from 'react-icons/md';
import { Dropdown, Badge } from 'react-bootstrap';
import { ProjectTab, ProjectAction } from '../constants';
import { handleOpenChat, navigateTo, constructCompanyName, mapServices, deleteService } from 'app/utils/common';
import { paginationOptions, StatusClass, ServiceStatus, IWTB_AdditionalServiceTitles, audFormatter } from 'app/utils/constants';
import { formatDate } from 'app/utils/time'
import { RefreshType, refreshOnNotification } from 'app/notification'
import { useSocket } from 'app/socket.io';
import adminService from '../adminService';

export const Services = () => {

  const socket = useSocket()
  const { SearchBar, ClearSearchButton } = Search;
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState([]);
  const authState = useAuthState();

  const columns = [
    {
      dataField: 'projectName',
      text: 'Project',
      headerAlign: 'center',
      sort: true,
      formatter: ((cell, row) => { 
        return <span className='text-primary cursor-pointer' onClick={() => actionService(ProjectAction.VIEW_PROJECT, row)}>{row.projectName}</span>;
      })
    },
    {
      dataField: 'service',
      text: 'Service',
      headerAlign: 'center',
      sort: true,
      formatter: ((cell, row) => IWTB_AdditionalServiceTitles[cell])
    },
    {
      dataField: 'client',
      text: 'Client',
      headerAlign: 'center',
      formatter: ((cell, row) => { 
        return <span className="text-primary cursor-pointer" onClick={() => actionService(ProjectAction.CONTACT_CLIENT, row)}>{constructCompanyName(row.contact)}</span>;
      })
    },
    {
      dataField: 'classification',
      text: 'Classification',
      headerAlign: 'center',
    },
    {
      dataField: 'sector',
      text: 'Sector',
      headerAlign: 'center',
    },
    {
      dataField: 'budget',
      text: 'Budget',
      headerAlign: 'center',
      align: 'right',
      formatter: ((cell, row) => <span className='text-primary'>{audFormatter.format(row.budget)}</span>)
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '90px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status?.toUpperCase()]}>{row.status}</Badge>
      })
    },
    {
      dataField: 'revision',
      text: 'Rev',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '50px' })
    },
    {
      dataField: 'updated',
      text: 'Updated',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: ((cell, row) => formatDate(cell))
    },
    {
      dataField: 'ref',
      text: 'Ref',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      headerStyle: (colum, colIndex) => { return { width: '60px' }},
      formatter: ((cell, row) => { 

        return <Dropdown onSelect={action => actionService(action, row)}>
          <Dropdown.Toggle as='span' className='cursor-pointer toggle-hidden'>
            <MdMoreVert size={18}></MdMoreVert>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey={ProjectAction.VIEW_PROJECT}><i className='i-ID-Card text-primary'> </i>Project Card</Dropdown.Item>
            <Dropdown.Item eventKey={ProjectAction.CONTACT_CLIENT}><i className='i-Business-Man text-success'> </i>Contact Client</Dropdown.Item>
            {row.status !== ServiceStatus.DELETED && <>
              <Dropdown.Item eventKey={ProjectAction.SETUP_ALLOCATION}><i className='i-Management text-warning'> </i>Setup Allocation</Dropdown.Item>
              <div className="dropdown-divider"></div>
              <Dropdown.Item eventKey={ProjectAction.DELETE_SERVICE}><i className="i-Close-Window text-danger"> </i>Delete service</Dropdown.Item>
            </>}
        </Dropdown.Menu>
        </Dropdown>
      })
    }];
  

  const getServices = React.useCallback(() => {

    adminService.getServices(authState.user.id)
    .then(results => {
      if (results?.error) {
        NotificationManager.error('Semething went wrong', 'Error', 3000);
      }
      if (results) {
          const services = mapServices(results);
          setServices(services)
      }
    })
    .catch(ex => {
      NotificationManager.error('Sorry, something went wrong.', 'Services', 3000);
    })
  })

  useEffect(() => {
  
    getServices()

    const onUserNotification$ = socket.onUserNotification().subscribe(notification => {

      refreshOnNotification(notification.type, RefreshType.ADMIN_SERVICES, getServices)
    })
    return() => {
        onUserNotification$.unsubscribe()
    }
  }, []);

  const handleDeleteService = async (projectId, serviceId) => {

    try {

      await deleteService(adminService, authState.user.id, projectId, serviceId);

      getServices()

      NotificationManager.success('Service was deleted successfully.', 'Additional Services', 2000);  
    }
    catch {
      NotificationManager.error('Sorry, something went wrong.', 'Additional Services', 3000);
    }
  }

  const actionService = (action, service) => {

    switch(action) {

      case ProjectAction.VIEW_PROJECT:
        return navigateTo({ history: history, navigation: AdminNavigation, pathname: AdminNavigation.PROJECT_CARD, project: { id: service.projectId, clientId: service.clientId }, tab: ProjectTab.DETAILS });
  
      case ProjectAction.CONTACT_CLIENT:
        return handleOpenChat(authState, history, service.clientId);
      
      case ProjectAction.SETUP_ALLOCATION:
        return navigateTo({ history: history, navigation: AdminNavigation, pathname: AdminNavigation.PROJECT_CARD, project: { id: service.projectId, clientId: service.clientId }, tab: ProjectTab.AWARDS });

      case ProjectAction.DELETE_SERVICE:
        return handleDeleteService(service.projectId, service.id)
  
    }
  }

  return (
    <div>
      <Breadcrumb
        routeSegments={[
          { name: 'Admin', path: AdminNavigation.HOME },
          { name: 'Services' },
        ]}
      ></Breadcrumb>

      <div className='row'>
          <div className='col-md-12'>

          <SimpleCard>

            <ToolkitProvider
              keyField='ref'
              data={services}
              columns={columns}
              search
              exportCSV
            >
              {props => (
                <>
                  <div className='d-flex justify-content-end align-items-center'>
                   
                    <span className='mb-2 mr-1'>Search:</span>
                    <SearchBar {...props.searchProps} className='mb-0 ' />
                    <ClearSearchButton { ...props.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
                    <button className='btn btn-outline-success mb-2 mr-1' onClick={_ => props.csvProps.onExport()}>Export CSV</button>
                  </div>
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4
                    wrapperClasses="table-responsive"
                    pagination={paginationFactory({ ...paginationOptions, totalSize: services.length })}
                    noDataIndication={'No services'}
                    condensed
                    loading={loading}
                  />
                </>
              )}
            </ToolkitProvider>

          </SimpleCard>
        </div>
      </div>

      <NotificationContainer />
    </div>
  );
}
