import React, { useEffect } from 'react';
import swal from 'sweetalert2';
import { SimpleCard } from '@gull';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Dropdown, Badge, Button, Modal } from 'react-bootstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Breadcrumb } from '@gull';
import projectService from './projectService';
import moment from 'moment';
import { paginationOptions, StatusClass, ProjectStatus, AllowedFileTypes, FileCategory } from 'app/utils/constants';
import { ProjectAction, ProjectTab } from '../constants';
import { ClientNavigation } from '../clientRoutes';
import { formatDateTime12, formatDate } from 'app/utils/time';
import { MdMoreVert} from 'react-icons/md';
import { IwtbFileUpload } from 'app/widgets';
import { Formik, Form } from 'formik';
import LaddaButton, { EXPAND_RIGHT } from "react-ladda";
import { useSpinner } from 'app/IwtbLayout/SharedComponents/spinner/SpinnerContext';
import { ReloadButton } from 'app/widgets';
import { RefreshType, refreshOnNotification } from 'app/notification'
import { useSocket } from 'app/socket.io';
import { useAuthState } from 'app/auth';

export const Projects = (props) => {

  const { SearchBar, ClearSearchButton } = Search;
  const spinner = useSpinner()
  const authState = useAuthState();
  const socket = useSocket()

  const [showMessage, setShowMessage] = React.useState({
    show: false,
    title: '',
    message: '',
    project: null
  });  
  const [showUpload, setShowUpload] = React.useState({
    show: false,
    title: '',
    message: '',
    project: null,
    files: [],
  });
  const [initialValues, setInitialValues] = React.useState({
    files: [],
  });

  const [projects, setProjects] = React.useState([]);
  
  const getClientProjects = React.useCallback(() => {
      
    if (authState.user?.id) {
      spinner.show()
      projectService.getClientProjects(authState.user.id)
      .then(result => {
        if (result.error) {
          NotificationManager.error(result.message, 'Server Connection', 3000);      
        }
        else {
          const items = result.map((item, i) => { return {...item, index: i + 1 }});
          setProjects(items); 
        }
      })
      .catch(e => {
        NotificationManager.error('Something went wrong.', 'Server Connection', 3000);      
      })
      .finally(() => {
        spinner.hide()
      })
    }
  })

  const projectColumns = [
    {
      dataField: 'projectName',
      text: 'Project',
      headerAlign: 'center',
      sort: true,
      formatter: ((cell, row) => { 
        return <span className='text-primary cursor-pointer' onClick={() => navigateTo(ClientNavigation.PROJECT_CARD, row, ProjectTab.DETAILS)}>{row.projectName}</span>;
      })
    },
    {
      dataField: 'ref',
      text: 'Ref',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
    },
    {
      dataField: 'created',
      text: 'Listed Date',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '140px' }),
      formatter: ((cell, row) => formatDate(row.created))
    },
    {
      dataField: 'Quotes Due',
      text: 'Quotes Due',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '160px' }),
      formatter: ((cell, row) => formatDateTime12(row.tenderRequiredBy))
    },
    {
      dataField: 'Time Remaining',
      text: 'Time Remaining',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '160px' }),
      formatter: ((cell, row) => { 
        let retVal = '';
        if (row.created && row.tenderRequiredBy) {
          const hours = moment(row.tenderRequiredBy).diff(moment(), 'hours'); 
          if (hours && hours > 24) {
            retVal = <span className='ul-widget3-status text-success t-font-bolder'>{ Math.floor(hours/24) } days</span>
          }
          else {
            retVal = <span className='ul-widget3-status text-warning t-font-bolder'>{ hours } hours</span>
          }
        }
        return retVal;
      })
    },
    {
      dataField: 'revision',
      text: 'Revision',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '70px' }),
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '100px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
  ];

  const projectInfoColumns = [
    {
      dataField: 'projectLocation',
      text: 'Location',
      headerAlign: 'center',
      formatter: ((cell, row) => row.projectLocation ? row.projectLocation.address : '')
    },
    {
      dataField: 'sector',
      text: 'Sector',
      headerAlign: 'center',
    },
    {
      dataField: 'access',
      text: ProjectTab.ACCESS,
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => { 
        return <Button onClick={() => navigateTo(ClientNavigation.PROJECT_CARD, row, ProjectTab.ACCESS)} variant='outline-danger' className='m-1 text-capitalize'>{row.accessRegister ? row.accessRegister.length : 0}</Button>;
      })
    },
    {
      dataField: 'rfi',
      text: ProjectTab.RFI,
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => { 
        return <Button onClick={() => navigateTo(ClientNavigation.PROJECT_CARD, row, ProjectTab.RFI)} variant='outline-warning' className='m-1 text-capitalize'>{row.rfiRegister ? row.rfiRegister.length : 0}</Button>;
      })
    },
    {
      dataField: 'bids',
      text: ProjectTab.BIDS,
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => { 
        return <Button onClick={() => navigateTo(ClientNavigation.PROJECT_CARD, row, ProjectTab.BIDS)} variant='outline-success' className='m-1 text-capitalize'>{row.bidRegister ? row.bidRegister.length : 0}</Button>;
      })
    },
    {
      dataField: 'trades',
      text: ProjectTab.TRADES,
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => { 
        return <Button onClick={() => navigateTo(ClientNavigation.PROJECT_CARD, row, ProjectTab.TRADES)} variant='outline-info' className='m-1 text-capitalize'>{row.tradesCount}</Button>;
      })
    },
    {
      dataField: 'awards',
      text: ProjectTab.AWARDS,
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => { 
        return <Button onClick={() => navigateTo(ClientNavigation.PROJECT_CARD, row, ProjectTab.AWARDS)} variant='outline-info' className='m-1 text-capitalize'>{row.awardRegister ? row.awardRegister.length : 0}</Button>;
      })
    },
    // {
    //  dataField: 'values',
    //   text: 'Current Value',
    //   headerAlign: 'center',
    //   align: 'center',
    //   formatter: ((cell, row) => {
    //     const value = calcProjectTotal()
    //     return <span className='ul-widget3-status text-primary t-font-bolder'>{ audFormatter.format(value) }</span>
    //   })
    // },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '60px' };
      },
      formatter: ((cell, row) => { 

        return <Dropdown onSelect={(action) => actionProject(action, row)}>
          <Dropdown.Toggle as='span' className='cursor-pointer toggle-hidden'>
            <MdMoreVert size={18}></MdMoreVert>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey={ProjectAction.VIEW_PROJECT}><i className='i-ID-Card text-primary'> </i>Project card</Dropdown.Item>

          {(row.status === ProjectStatus.REVISE) && <>
            <Dropdown.Item eventKey={ProjectAction.VIEW_REASON}><i className='i-Receipt-3 text-info'> </i>View reason</Dropdown.Item>
            <Dropdown.Item eventKey={ProjectAction.ADD_ADDENDUM}><i className='i-File-Edit text-warning'> </i>Revise</Dropdown.Item>
          </>}

          {!(row.status === ProjectStatus.DECLINED || row.status === ProjectStatus.CANCELED || row.status === ProjectStatus.REVISE) && <div>
            <Dropdown.Item eventKey={ProjectAction.ADD_ADDENDUM}><i className='i-Add-File text-primary'> </i>Add addendum</Dropdown.Item>
            <Dropdown.Item eventKey={ProjectAction.UPLOAD_QUOTE}><i className='i-Upload text-warning'> </i>Upload other quotes</Dropdown.Item>
          </div>}

          <div className='dropdown-divider'></div>
          {!(row.status === ProjectStatus.DECLINED || row.status === ProjectStatus.CANCELED) && <Dropdown.Item eventKey={ProjectAction.CANCEL_PROJECT}><i className='i-Close-Window text-danger'> </i>Cancel project</Dropdown.Item>}
          {(row.status === ProjectStatus.DECLINED || row.status === ProjectStatus.CANCELED) && <Dropdown.Item eventKey={ProjectAction.DELETE_PROJECT}><i className='i-Close-Window text-danger'> </i>Delete project</Dropdown.Item>}
        </Dropdown.Menu>
        </Dropdown>
      })
    },
  ];

  useEffect(() => {

    const onUserNotification$ = socket.onUserNotification().subscribe(notification => {

      refreshOnNotification(notification.type, RefreshType.CLIENT_PROJECTS, getClientProjects)
    })
    return() => {
        onUserNotification$.unsubscribe()
    }
  }, []);

  useEffect(() => {

    if (authState.isAuthenticated) {
      getClientProjects();
    }    
  }, [authState.user]);

  const deleteProject = (project) => {

    swal.fire({
      title: 'Are you sure?',
      text: 'You would not be able to revert this!',
      icon: 'warning',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete project',
      cancelButtonText: 'No'
    })
    .then(result => {
      if (result.value) {
        projectService.cancelProject(authState.user.id, project.id)
        .then(() => {
          getClientProjects();
          swal.fire('Project was Deleted!', 'Your project has been deleted.', 'success');
        });
      }
    });
  }

  const cancelProject = (project) => {

    swal.fire({
      title: 'Are you sure?',
      text: 'You would not be able to revert this!',
      icon: 'warning',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel project',
      cancelButtonText: 'No'
    })
    .then(result => {
      if (result.value) {
        projectService.cancelProject(authState.user.id, project.id)
        .then(() => {
          getClientProjects();
          swal.fire('Project Canceled!', 'Your project has been canceled.', 'success');
        });
      }
    });
  }

  const navigateTo = (pathname, project, tab) => {
    
    switch(pathname) {
      case ClientNavigation.PROJECT_CARD:
        pathname = pathname.replace(':projectId', project.id).replace(':tab?', tab);
        break;
      case ClientNavigation.EDIT_PROJECT:
        pathname = pathname.replace(':projectId', project.id);
        break;
      }

    const { history } = props;

    history.push({
      pathname: pathname,
      state: { project: project }
    });  
  }

  const handleHideMessage = () => {

    setShowMessage({
      show: false,
      title: '',
      message: ''
    });
  }

  const handleHideUpload = () => {

    setShowUpload({
      show: false,
      title: '',
      files: [],
    });
  }

  const actionProject = (action, project) => {

    switch(action) {

      case ProjectAction.VIEW_PROJECT:
        return navigateTo(ClientNavigation.PROJECT_CARD, project);

      case ProjectAction.DELETE_PROJECT:
        return deleteProject(project);
  
      case ProjectAction.CANCEL_PROJECT:
        return cancelProject(project);

      case ProjectAction.ADD_ADDENDUM:
        return navigateTo(ClientNavigation.EDIT_PROJECT, project);

      case ProjectAction.VIEW_REASON:
        setShowMessage({
          show: true,
          title: 'Revise Reason',
          message: project.reviseReason || '',
          project: project,
        });
        break;

      case ProjectAction.UPLOAD_QUOTE:
        setShowUpload({
          show: true,
          title: 'Upload Quotes',
          project: project,
        });
        break;  
    }
  }
  
  const expendRow = {

    parentClassName: 'table-primary',
    showExpandColumn: true,
    renderer: row => (
      <BootstrapTable keyField='index' data={[row]} columns={projectInfoColumns} />
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
      if (expanded) {
        return <i className='nav-icon i-Arrow-Down font-weight-900 text-primary cursor-pointer'></i>    
      }
      else {
        return <i className='nav-icon i-Arrow-Right font-weight-900 text-primary cursor-pointer'></i>    
      }
    },
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (''),
  }

  const saveUploadedQuotes = (project, values, setSubmitting, setFieldValue) => {

    if (!(values.files && values.files.length >0)) {
      return NotificationManager.error('No quotes were uploaded', 'Upload Quotes', 3000);      
    }

    setSubmitting(true);

    projectService.uploadQuotes(authState.user.id, project.id, values.files)
    .then(result => {
      NotificationManager.success('Quotes have been uploaded', 'Upload Quotes', 2000);
      setFieldValue('files', [])
    })
    .catch(ex => {
      NotificationManager.error('Something went wrong', 'Upload Quotes', 3000);
    })
    .finally(() => {
      setSubmitting(false);
      handleHideUpload()
    });
  };
  
  return (
    <div>
      <Breadcrumb routeSegments={[{ name: 'My Projects' }]}></Breadcrumb>

      <Formik initialValues={initialValues} enableReinitialize={true}>
      {({
        values,
        handleSubmit,
        setFieldValue,
        setSubmitting,
        isSubmitting
      }) => { return (
          <Form className='needs-validation' onSubmit={handleSubmit} noValidate>

            <div className='row'>
                <div className='col-md-12'>

                <SimpleCard>
                
                  <ToolkitProvider
                    keyField='id'
                    data={projects}
                    columns={projectColumns}
                    search
                    exportCSV
                  >
                    {props => (
                      <>
                        <div className='d-flex justify-content-end align-items-center'>
                          <span className='mb-2 mr-1'>Search:</span>
                          <SearchBar {...props.searchProps} className='mb-0 ' />
                          <ClearSearchButton { ...props.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
                          <ReloadButton onClick={getClientProjects} />
                          <button className='btn btn-primary mb-2' onClick={() => navigateTo(ClientNavigation.NEW_PROJECT)}>New Project</button>
                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4
                          wrapperClasses="table-responsive"
                          pagination={paginationFactory({ ...paginationOptions, totalSize: projects.length })}
                          noDataIndication={'No records found'}
                          // striped
                          condensed
                          expandRow={ expendRow }
                        />
                      </>
                    )}
                  </ToolkitProvider>
                </SimpleCard>
              </div>
            </div>

            <Modal show={showMessage.show} onHide={handleHideMessage} centered={true}>
              <Modal.Header closeButton>
                <Modal.Title>{showMessage.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{showMessage.message}</Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={handleHideMessage}>
                  Close
                </Button>
                <Button variant='warning' onClick={() => actionProject(ProjectAction.ADD_ADDENDUM, showMessage.project)}>
                  Revise
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showUpload.show} onHide={handleHideUpload} centered={true} size='xl' >
              <Modal.Header closeButton>
                <Modal.Title>{showUpload.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <IwtbFileUpload allowedTypes={AllowedFileTypes} setUploadedFiles={files => setFieldValue('files', files)} hideCategory={true} category={FileCategory.QUOTE} />
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={handleHideUpload}>
                  Close
                </Button>
                <LaddaButton
                  onClick={() => saveUploadedQuotes(showUpload.project, values, setSubmitting, setFieldValue)}
                  type='button'
                  className='btn btn-warning position-relative'
                  loading={isSubmitting}
                  progress={0.5}
                  data-style={EXPAND_RIGHT}
                >Submit</LaddaButton>
              </Modal.Footer>
            </Modal>
          </Form>
      )}}
      </Formik>

      <NotificationContainer />
    </div>
  );
}
