import HttpService from 'app/services/httpService'

class PasswordService {

    http = new HttpService();

    changePassword = (userId, oldPassword, newPassword) => {
        return this.http.put(`users/${userId}/password`, { oldPassword: oldPassword, newPassword: newPassword });
    };

    forgotPassword = (username, email) => {
        return this.http.put(`forgot-password`, { username: username, email: email });
    };

    newPassword = (token, password, username, email) => {
        return this.http.put(`new-password`, { token, username, email, password });
    };
}

export default new PasswordService();