import React, { useEffect, Fragment } from "react";
import { Button } from "react-bootstrap";
import swal from "sweetalert2";
import { NotificationManager } from 'react-notifications';
import { useSocket } from 'app/socket.io';
import { useAuthState } from 'app/auth';
import { uniqArrayBy } from 'app/utils/common'

export const SendMessageWidget = ({ recipients }) => {

  const authState = useAuthState();
  const socket = useSocket();

  useEffect(() => {

    console.log('recipients:', recipients)
  }, [recipients]);


  const handleMessageSend = () => {

    if (authState.user && recipients && recipients.length > 0) {

      const title = recipients.length === 1 ? `Send Message to ${recipients[0].firstName} ${recipients[0].lastName}` : recipients.length > 1 ? `Send Message to a Group` : 'Send Message';

      swal.queue([{
        title: title,
        input: "textarea",
        inputAttributes: {
            autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "Send",
        showLoaderOnConfirm: true,
        inputAutoTrim: true,
        inputValidator: result => !result && 'You need to enter something!',
        preConfirm: async message => {
          if (message.length > 0) {
            try {
              const items = uniqArrayBy(recipients, 'id') // prevent sending duplicated messages to multi biders

              items.map(recipient => {

                // prevent self messaging
                if (recipient.id !==  authState.user.id) {
                  const payload = {
                    from: authState.user.id,
                    to: recipient.id,
                    message: message,
                  }
                  socket.chat(payload);  
                }
              })
              NotificationManager.success('Message was sent', 'Send Message', 2000);      
            }
            catch(e) {
              NotificationManager.error('Semething went wrong', 'Error', 3000);      
            }
          }
        },
        allowOutsideClick: () => !swal.isLoading()
        }])
    }
    else {
      NotificationManager.error('Semething went wrong', 'Error', 3000);      
    }
  };

  return (
    <Fragment>
      <Button variant="primary" className="btn-icontext-capitalize" onClick={handleMessageSend} disabled={!(recipients && recipients.length > 0)}>
        <span className="ul-btn__icon"><i className="i-Speach-Bubble-6 mr-1"></i></span>
        <span className="ul-btn__text">Message</span>
      </Button>      
    </Fragment>
  )
};
