import React, { useEffect, useState, useMemo } from "react";
import { Breadcrumb } from '@gull';
import { Tabs, Tab, Button, Badge, Alert } from "react-bootstrap";
import { ClientNavigation } from '../clientRoutes';
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useParams, useHistory } from "react-router-dom";
import { ProjectDetailsWidget, AccessRegisterWidget } from '../widgets';
import projectService from './projectService';
import { IwtbFileRegistry, IwtbModal, TradesWidget, AddTradeWidget } from 'app/widgets';
import { ProjectTab, AwardAction } from '../constants';
import { BidTab, audFormatter, StatusClass, ProjectStatus, AllocationAction, BidAction, TradeAction } from 'app/utils/constants';
import { RfiRegisterWidget } from '../rfi';
import { BidRegisterCategoryWidget, AwardRegisterWidget } from '../bid';
import * as fn from './functions';
import { calcProjectTotal, addContactWithId } from 'app/utils/common'
import { handleOpenChat, deleteService, deleteTrade, getTrades, deleteProjectFile } from 'app/utils/common';
import { InviteUserWidget } from 'app/user';
import { ServiceRegisterWidget } from './ServiceRegisterWidget'
import bidService from "../bid/bidService";
import { useSpinner } from 'app/IwtbLayout/SharedComponents/spinner/SpinnerContext';
import { NotificationTo, RefreshType, refreshOnNotification } from 'app/notification'
import { useSocket } from 'app/socket.io';
import { useAuthState } from 'app/auth';

export const ProjectCard = () => {

  const { projectId, tab } = useParams();
  const [project, setProject] = useState();
  const [defaultActiveKey, setDefaultActiveKey] = useState(ProjectTab.DETAILS);
  const [showAddTrade, setShowAddTrade] = useState(false);
  const [awardRegister, setAwardRegister] = useState([]);
  const [bidRegister, setBidRegister] = useState([]);
  const [trades, setTrades] = useState([]);
  const history = useHistory();
  const [notFound, setNotFound] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [showInviteFriend, setShowInviteFriend] = useState(false);
  const [services, setServices] = useState([])
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState('');
  const authState = useAuthState();
  const socket = useSocket()
  const spinner = useSpinner()

  const projectTotal = useMemo(() => calcProjectTotal(awardRegister, services), [awardRegister, services]);

  const getClientProject = React.useCallback(() => {

    if (!(projectId && authState.isAuthenticated)) {
      return
    }

    spinner.show()

    projectService.getClientProject(authState.user.id, projectId)
    .then(async project => {

      if (project && project.error) {
        return NotificationManager.error(project.message, 'Server Connection', 3000);      
      }

      if (project && project.id) {
        setProject(project);

        setReadOnly(project.status === ProjectStatus.DECLINED || project.status === ProjectStatus.CANCELED);

        if (project.awardRegister) {
          setAwardRegister(project.awardRegister);
        }
        const result = await getTrades(bidService, authState.user.id, project.id);
        setTrades(result.trades);
    
        getBidRegister(authState.user.id, projectId);
        loadServices(authState.user.id, projectId);
      }
      else {
        setTimeout(() => setNotFound(true), 2000);
      }
    })
    .catch(e => { NotificationManager.error('Something went wrong.', 'Server Connection', 3000) })
    .finally(() => {
      spinner.hide()
    })

  });

  useEffect(() => {

    const onUserNotification$ = socket.onUserNotification().subscribe(notification => {

      refreshOnNotification(notification.type, RefreshType.CLIENT_PROJECT_CARD, getClientProject)
    })
    return() => {
        onUserNotification$.unsubscribe()
    }
  }, []);

  useEffect(() => {

    const projectTab = tab === 'undefined' || tab === undefined ? ProjectTab.DETAILS : tab;
    setDefaultActiveKey(projectTab);
  }, [tab]);

  useEffect(() => {

    getClientProject()
  }, [projectId, authState.user]);
  
  const getBidRegister = (clientId, projectId) => {

    fn.getBidRegister(clientId, projectId).then(result => setBidRegister(result));
  }

  const loadServices = (clientId, projectId) => {

    fn.getProjectServices(clientId, projectId).then(services => {
      setServices(services)
    });
  }

  const handleServiceAction = (action, service) => {

    switch(action) {

      case AllocationAction.VIEW_COMMENTS:
        setComments(service.comments)
        setShowComments(true)
        return 

      case AllocationAction.DELETE_SERVICE:
        return handleDeleteService(service.id)
  
      case AllocationAction.CONTACT_SUPPORT:
        return handleOpenChat(authState, history, NotificationTo.SUPPORT);
      }
  }

  const handleDeleteService = async (serviceId) => {

    try {

      await deleteService(projectService, authState.user.id, project.id, serviceId);

      loadServices(authState.user.id, project.id)

      NotificationManager.success('Service was deleted successfully.', 'Additional Services', 2000);  
    }
    catch {
      NotificationManager.error('Sorry, something went wrong.', 'Additional Services', 3000);
    }
  }

  const handleDeleteTrade = async (tradeId) => {

    try {
      await deleteTrade(projectService, authState.user.id, project.id, tradeId);

      const result = await getTrades(bidService, authState.user.id, project.id);
      setTrades(result.trades);

      NotificationManager.success('Trade change was submitted successfully.', 'Trades', 2000);  
    }
    catch {
      NotificationManager.error('Sorry, something went wrong.', 'Trades', 3000);
    }
  }

  const handleUpdateTrades = async newTrades => {

    try {
      let result = await projectService.updateTrades(authState.user.id, project.id, newTrades);
      if (result?.error) {
        NotificationManager.error('Semething went wrong', 'Trades', 3000);      
      }
      result = await getTrades(bidService, authState.user.id, project.id);
      setTrades(result.trades);
      NotificationManager.success('Trades were updated', 'Trades', 3000);      
    }
    catch(ex) {
      NotificationManager.error('Semething went wrong', 'Trades', 3000);      
    }
    finally {
      setShowAddTrade(false);
    }
  }

  const handleAwardAction = (bid, action) => {

    switch(action) {

      case AwardAction.ADD_CONTACT:
        addContactWithId(authState.user.id, bid.contractor.id);
        break;
      
      case AwardAction.DECLINE:
        bidAwardChanged(bid, action);
        break;
      
      case AwardAction.ADDITIONAL_SOW:
        history.push({
          pathname: ClientNavigation.REQUEST_SOW.replace(':projectId', project.id).replace(':bidId', bid.id),
        });            
        break;
      
      case AwardAction.ORIGINAL_SOW:
        history.push({
          pathname: ClientNavigation.BID_CARD.replace(':projectId', project.id).replace(':bidId', bid.id).replace(':tab?', BidTab.SOW),
        });            
        break;
      }
  }

  const handleBidAction = (bid, action) => {

    switch(action) {

      case BidAction.VIEW_COMMENTS:
        setComments(bid.comments)
        setShowComments(true)
        return 

      case BidAction.VIEW_DELETE_REASON:
        setComments(bid.deleteReason)
        setShowComments(true)
        break;
  
      case BidAction.ADD_CONTACT:
        addContactWithId(authState.user.id, bid.contractor.id);
        break;

      case BidAction.AWARD:
      case BidAction.DECLINE:
          bidAwardChanged(bid, action);
        break;

      case BidAction.REQUEST_SOW:
        history.push({
          pathname: ClientNavigation.REQUEST_SOW.replace(':projectId', project.id).replace(':bidId', bid.id),
        });            
        break; 

      case BidAction.VIEW_SOW:
        history.push({
          pathname: ClientNavigation.BID_CARD.replace(':projectId', project.id).replace(':bidId', bid.id).replace(':tab?', BidTab.SOW),
        });            
        break;

      case BidAction.BID_CARD:
        history.push({
          pathname: ClientNavigation.BID_CARD.replace(':projectId', project.id).replace(':bidId', bid.id).replace(':tab?', BidTab.DETAILS),
        });            
        break;
      }
  }

  const handleTradeAction = (trade, action) => {

    switch(action) {

      case TradeAction.VIEW_DELETE_REASON:
        setComments(trade.deleteReason)
        setShowComments(true)
        break;
      }
  }

  const bidAwardChanged = async (bid, action) => {

    try {
      if (action === BidAction.DECLINE) {
        await fn.declineBid(authState.user.id, project.id, bid);

        const bidRegister = await fn.getBidRegister(authState.user.id, project.id);
        if (bidRegister) {
          setBidRegister(bidRegister);
        }
    
        const awardRegister = await fn.getAwardRegister(authState.user.id, project.id);
        if (awardRegister) {
          setAwardRegister(awardRegister);
        }
        NotificationManager.success('Trade change was submitted successfully.', 'Trade Award', 2000);  
      }
      else if (action === BidAction.AWARD) {
        await fn.awardBid(authState.user.id, project.id, bid.id);
        getBidRegister(authState.user.id, project.id);
        NotificationManager.success('Trade was awarded successfully.', 'Trade Award', 2000);  
      }
    }
    catch {
      NotificationManager.error('Sorry, something went wrong.', 'Trade Award', 3000);
    }
  }

  const navigateToEditProject = () => {
    
    history.push({
      pathname: ClientNavigation.EDIT_PROJECT.replace(':projectId', projectId),
    });            
  }

  const navigateTo = (pathname) => {
    
    pathname = pathname.replace(':projectId', project.id);
    history.push({ pathname: pathname , state: { project: project }});  
  }

  const handleDeleteFile = async file => {

    await deleteProjectFile(authState.user.id, projectId, file, projectService)
    getClientProject()
  }

  return (
    <div>
      { project && <Breadcrumb
        routeSegments={[
          { name: "My Projects", path: ClientNavigation.PROJECTS },
          { name: project.projectName }
        ]}
      ></Breadcrumb>}

      { project && <>

        <section className="ul-contact-detail">
          <div className="row">
            <div className="col-lg-3 col-xl-3">
              <div className="card o-hidden">
              <div className="card-body">
                  <div className="ul-widget1">
                    <div className="ul-widget__item">
                      <div className="ul-widget__info">
                        <h3 className="ul-widget1__title">Current Value</h3>
                        <span className="ul-widget__desc text-mute">Awarded bids</span>
                      </div>
                      <span className="ul-widget__number text-primary">{audFormatter.format(projectTotal)}</span>
                    </div>
                    <div className="ul-widget__item">
                      <div className="ul-widget__info">
                        <h3 className="ul-widget1__title">Access</h3>
                        <span className="ul-widget__desc text-mute">Access register</span>
                      </div>
                      <span className="ul-widget__number text-danger">{project.accessRegister ? project.accessRegister.length : 0}</span>
                    </div>
                    <div className="ul-widget__item">
                      <div className="ul-widget__info">
                        <h3 className="ul-widget1__title">RFI</h3>
                        <span className="ul-widget__desc text-mute">Request for information</span>
                      </div>
                      <span className="ul-widget__number text-warning">{project.rfiRegister ? project.rfiRegister.length : 0}</span>
                    </div>
                    <div className="ul-widget__item">
                      <div className="ul-widget__info">
                        <h3 className="ul-widget1__title">Bids</h3>
                        <span className="ul-widget__desc text-mute">Registered bids</span>
                      </div>
                      <span className="ul-widget__number text-success">{project.bidRegister ? project.bidRegister.length : 0}</span>
                    </div>
                    <div className="ul-widget__item">
                      <div className="ul-widget__info">
                        <h3 className="ul-widget1__title">Awards</h3>
                        <span className="ul-widget__desc text-mute">Awarded trades</span>
                      </div>
                      <span className="ul-widget__number text-info">{awardRegister ? awardRegister.length : 0}</span>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-xl-9">
              <div className="card mb-4">
                <div className="card-header bg-transparent">
                    <div className='d-flex flex-row justify-content-between'>
                      <span>Status: <Badge className={StatusClass[project.status.toUpperCase()]}>{project.status}</Badge></span>
                      {project.revision && <span>Revision: {project.revision}</span>}
                      {project.ref && <span>Ref: {project.ref}</span>}
                    </div>
                </div>

                <div className="card-body">

                  <Tabs defaultActiveKey={defaultActiveKey}>

                    <Tab eventKey={ProjectTab.DETAILS} title={ProjectTab.DETAILS}>
                      <ProjectDetailsWidget project={project} />
                    </Tab>
                    
                    <Tab eventKey={ProjectTab.ACCESS} title={ProjectTab.ACCESS}>
                        <AccessRegisterWidget project={project} readOnly={readOnly} />                  
                    </Tab>
                    
                    <Tab eventKey={ProjectTab.RFI} title={ProjectTab.RFI}>
                      <RfiRegisterWidget projectId={project.id} readOnly={readOnly} />                  
                    </Tab>
                    
                    <Tab eventKey={ProjectTab.BIDS} title={ProjectTab.BIDS}>{ 
                      readOnly ?
                        <BidRegisterCategoryWidget bidRegister={bidRegister} readOnly={true} />
                        :
                        <BidRegisterCategoryWidget bidRegister={bidRegister} handleBidAction={handleBidAction} handleSubmitBid={() => navigateTo(ClientNavigation.SUBMIT_BID)} />
                      }
                    </Tab>
                    
                    <Tab eventKey={ProjectTab.AWARDS} title={ProjectTab.AWARDS}>{ 
                      readOnly ?
                        <AwardRegisterWidget awardRegister={awardRegister} readOnly={true} handleRefresh={getClientProject} />
                        :
                        <AwardRegisterWidget awardRegister={awardRegister} handleAwardAction={handleAwardAction} handleRefresh={getClientProject} />
                      }
                      <ServiceRegisterWidget services={services} handleServiceAction={handleServiceAction} />
                    </Tab>
                    
                    <Tab eventKey={ProjectTab.TRADES} title={ProjectTab.TRADES}>{ 
                      readOnly ?
                        <TradesWidget trades={trades} readOnly={true} />
                        :
                        <TradesWidget trades={trades} title='Trade' 
                          onAddTrade={() => setShowAddTrade(true)} 
                          deleteTrade={tradeId => handleDeleteTrade(tradeId)} 
                          handleTradeAction={handleTradeAction}
                        />
                      }
                    </Tab>
                    
                    <Tab eventKey={ProjectTab.FILES} title={ProjectTab.FILES}>
                      <IwtbFileRegistry files={project.files} showSearch={true} handleDeleteFile={handleDeleteFile}  />
                    </Tab>

                  </Tabs>
                </div>
              
                <div className="card-footer">
                  <div className="mc-footer">
                    <div className="row">
                      <div className="col-md-12">
                        {!(project.status === ProjectStatus.DECLINED || project.status === ProjectStatus.CANCELED) && <>
                          <button type="button" className="btn btn-warning m-1" onClick={() => setShowInviteFriend(true)}>Invite Contractor</button>
                          <button type="button" className="btn btn-success m-1 footer-delete-right" onClick={navigateToEditProject}>Add Addendum</button>
                        </>}
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
 
        <InviteUserWidget title='Invite a Friend Form' show={showInviteFriend} handleCloseInvite={() => setShowInviteFriend(false)} />
        <AddTradeWidget show={showAddTrade} currentTrades={trades} onSubmit={trades => handleUpdateTrades(trades)} onClose={() => setShowAddTrade(false)}></AddTradeWidget>
      </>}

      { !project && notFound && <>
        <Alert className="text-center alert-card" variant="danger">
          Something went wrong. Project was not found.
          <Button variant="warning" className="btn ml-3" onClick={_ => {history.goBack();}}>Return</Button>
        </Alert>
      </>}

      <NotificationContainer />
      <IwtbModal show={showComments} title='Comments' message={comments} handleClose={() => setShowComments(false)} />
  </div>
  );
}
