import React from 'react';
import { formatDateTime12 } from 'app/utils/time';

export const ProjectDetailsWidget = (props) => {

  return (
    <>

    <form className="mt-3">

      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Name:</label>
        <div className="form-control col-lg-4">{props.project.projectName}</div>
        <label className="col-lg-2 col-form-label">Budget:</label>
        <div className="form-control col-lg-4">{props.project.budget}</div>
      </div>
      <div className="form-group row">
        { props.project.inspectionRequired === 'yes' && <>
            <label className="col-lg-2 col-form-label">Inspection On:</label>
            <div className="form-control col-lg-4">{formatDateTime12(props.project.inspectionRequiredOn)}</div>
          </>
        }
        { props.project.tenderRequiredBy && <>
            <label className="col-lg-2 col-form-label">Tender By:</label>
            <div className="form-control col-lg-4">{formatDateTime12(props.project.tenderRequiredBy)}</div>
          </>
        }
      </div>
      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Details:</label>
        <textarea rows={6} readOnly className="form-control col-lg-10" value={props.project.projectDetails} />
      </div>
      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Classification:</label>
        <div className="form-control col-lg-4">{props.project.classification}</div>
        <label className="col-lg-2 col-form-label">Sector:</label>
        <div className="form-control col-lg-4">{props.project.sector}</div>
      </div>
      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Establishment:</label>
        <div className="form-control col-lg-4">{props.project.establishmentRequired}</div>
        <label className="col-lg-2 col-form-label">Organizer:</label>
        <div className="form-control col-lg-4">{props.project.establishmentOrganizer}</div>
      </div>
      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Lodged DA:</label>
        <div className="form-control col-lg-4">{props.project.lodgedDA}</div>
        <label className="col-lg-2 col-form-label">Permit:</label>
        <div className="form-control col-lg-4">{props.project.receivedPermit}</div>
      </div>
      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Location:</label>
        <div className="form-control col-lg-10">{props.project.projectLocation ? props.project.projectLocation.address : ''}</div>
      </div>
      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Trades:</label>
        <div className="form-control col-lg-10">{props.project.tradeSelect ? props.project.tradeSelect.map(v => v.text).join(', ') : ''}</div>
      </div>
      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Specialists:</label>
        <div className="form-control col-lg-10">{props.project.specialistSelect ? props.project.specialistSelect.map(v => v.text).join(', ') : ''}</div>
      </div>
      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Created:</label>
        <div className="form-control col-lg-4">{formatDateTime12(props.project.created)}</div>
        
        { props.project.updated && <>
          <label className="col-lg-2 col-form-label">Updated:</label>
          <div className="form-control col-lg-4">{formatDateTime12(props.project.updated)}</div>
        </> }
      </div>

    </form>
    </>
  );
}
