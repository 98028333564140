import HttpService from 'app/services/httpService';

class sowService {

    http = new HttpService();

    getSow = (clientId, projectId, bidId) => {
        return this.http.get(`clients/${clientId}/projects/${projectId}/bids/${bidId}/sow`);
    };

    submitSow = (clientId, projectId, bidId, sow) => {
        return this.http.post(`clients/${clientId}/projects/${projectId}/bids/${bidId}/sow`, { sow: sow });
    };
}

export default new sowService();