import React from 'react';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Badge, Dropdown } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { paginationOptions, audFormatter, StatusClass, BidStatus, SowStatus, BidAction } from 'app/utils/constants';
import { MdMoreVert} from "react-icons/md";
import { calcVariation, getSowStatus } from 'app/utils/common';

export const BidRegisterWidget = ({ bidRegister, disableNewBid, readOnly, showExport, handleBidAction, handleSubmitBid }) => {

  const { SearchBar, ClearSearchButton } = Search;

  let columns = [
    {
      dataField: 'trade',
      text: 'Trade',
      headerAlign: 'center',
      sort: true,
    },
    {
      text: 'Check SOW',
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => { 
        const sowStatus = getSowStatus(row.sow);
        return readOnly ? 
          <span className="text-primary"><Badge className={StatusClass[sowStatus.toUpperCase()]}>{sowStatus}</Badge></span> 
          : 
          <span className="text-primary cursor-pointer" onClick={() => handleBidAction(row, BidAction.VIEW_SOW)}><Badge className={StatusClass[sowStatus.toUpperCase()]}>{sowStatus === SowStatus.PENDING ? 'Review' : sowStatus}</Badge></span>;
      })
    },
    {
      text: 'Bid',
      headerAlign: 'center',
      align: 'right',
      formatter: ((cell, row) => <span className='text-light'>{audFormatter.format(row.amount)}</span>)
    },
    {
      text: 'Variation',
      headerAlign: 'center',
      align: 'right',
      formatter: ((cell, row) => <span className='text-warning'>{audFormatter.format(calcVariation(row.sow))}</span>)
    },
    {
      text: 'Value',
      headerAlign: 'center',
      align: 'right',
      formatter: ((cell, row) => <span className='text-primary'>{audFormatter.format(row.amount + calcVariation(row.sow))}</span>)
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
    {
      dataField: 'ref',
      text: 'Ref',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
    },
];

  if (!readOnly) {
    columns = [...columns,
    {
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '60px' }),
      formatter: ((cell, row) => { 
        return <Dropdown onSelect={(action) => handleBidAction(row, action)}>
          <Dropdown.Toggle as="span" className="cursor-pointer toggle-hidden">
            <MdMoreVert size={18}></MdMoreVert>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {row.comments?.length > 0 && <>
              <Dropdown.Item eventKey={BidAction.VIEW_COMMENTS}><i className='i-Speach-Bubble-3 text-info'> </i>View comments</Dropdown.Item>
            </>}
            <Dropdown.Item eventKey={BidAction.VIEW_BID}><i className="i-Dollar-Sign"> </i>View bid</Dropdown.Item>
            {row.status === BidStatus.AWARDED && <Dropdown.Item eventKey={BidAction.ACCEPT_AWARD}><i className="i-Like-2 text-success"> </i>Accept Award</Dropdown.Item>}
            <div className="dropdown-divider"></div>
            {row.status === BidStatus.AWARDED && <Dropdown.Item eventKey={BidAction.DECLINE_AWARD}><i className="i-Unlike-2 text-danger"> </i>Decline Award</Dropdown.Item>}
            {row.status !== BidStatus.WITHDRAWN && <Dropdown.Item eventKey={BidAction.WITHDRAW}><i className="i-Close-Window text-danger"> </i>Withdraw Bid</Dropdown.Item>}
        </Dropdown.Menu>
        </Dropdown>
      })
    }];
  }

  return (
    <>
      <ToolkitProvider
        keyField='index'
        data={bidRegister ?? []}
        columns={columns}
        search
        exportCSV
      >
        {toolkitProps => (
          <>
            <div className='d-flex justify-content-end align-items-center'>
              
              <span className='mb-2 mr-1'>Search:</span>
              <SearchBar {...toolkitProps.searchProps} className='mb-0 ' />
              <ClearSearchButton { ...toolkitProps.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
              {showExport && <button className='btn btn-outline-success mb-2 mr-1' onClick={_ => toolkitProps.csvProps.onExport()}>Export CSV</button>}
              {!disableNewBid && <button className='btn btn-success mb-2' onClick={handleSubmitBid}>Submit Bid</button>}
          </div>
            <BootstrapTable
              {...toolkitProps.baseProps}
              bootstrap4
              wrapperClasses="table-responsive"
              pagination={paginationFactory({ ...paginationOptions, totalSize: bidRegister ? bidRegister.length : 0 })}
              noDataIndication={'No records found'}
              condensed
            />
          </>
        )}
      </ToolkitProvider>
    </>
  );
}
