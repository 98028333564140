import React, { useState, useEffect } from 'react'
import { formatDateTime12 } from 'app/utils/time'
import swal from "sweetalert2"
import { Badge } from "react-bootstrap"
import paginationFactory from 'react-bootstrap-table2-paginator'
import { paginationOptions, StatusClass, audFormatter, SowStatus } from 'app/utils/constants'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'

export const SowItemWidget = ({ sow, handleSowConfirm, handleRejectVariation }) => {
  
  const { SearchBar, ClearSearchButton } = Search
  const [tasks, setTasks] = useState([])

  const columns = [
    {
      dataField: 'index',
      text: 'Reject',
      headerAlign: 'center',
      align: 'center',
      hidden: !sow.allowReject,
      headerStyle: () => ({ width: '80px' }),
      formatter: ((cell, row) => row.status === SowStatus.AMENDED ? <input type="checkbox" onClick={(event) => onRejectSelected(event.target.checked, row)} /> : '')
    },
    {
      dataField: 'task',
      text: 'Task',
      headerAlign: 'center',
    },
    {
      dataField: 'ref',
      text: 'Ref',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '100px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
    {
      dataField: 'amount',
      text: 'Amount',
      headerAlign: 'center',
      align: 'right',
      headerStyle: () => ({ width: '140px' }),
      formatter: ((cell, row) => <span>{isNaN(row.amount) ? '' : audFormatter.format(row.amount)}</span>),
    },
  ]  

  const editColumns = [
    {
      dataField: 'task',
      text: 'Task',
      headerAlign: 'center',
    },
    {
      dataField: 'ref',
      text: 'Ref',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '100px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
    {
      dataField: 'confirmed',
      text: 'Confirmed',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '100px' }),
      formatter: ((cell, row) => <input type="checkbox" checked={cell} onChange={(event) => onTaskConfirmed(event.target.checked, row)} />)
    },
    {
      dataField: 'amount',
      text: 'Amount',
      headerAlign: 'center',
      align: 'right',
      headerStyle: () => ({ width: '140px' }),
      formatter: ((cell, row) => { 
        return <input type="number" id={row.ref} className='form-control' style={{textAlign: "right"}} defaultValue={row.amount} onChange={(event) => handleAmountChange(event.target.value, row)} />
      }),
    },
  ]  

  useEffect(() => {

    if (sow) {
      // to be able to edit inline, the row must have corresponding dataField, in this case amount.
      const items = sow.tasks.map(task => {
        task.confirmed = task.status === SowStatus.AMENDED || task.status === SowStatus.CONFIRMED
        task.amount = task.amount ?? 0
        return task
      })  
      setTasks(items)
    }
  }, [sow])

  const handleAmountChange = (amount, task) => {

    setTimeout(() => {
      const items = tasks.map(v => { 
        if (v.ref === task.ref) {
          v.amount = isNaN(amount) ? 0 : parseInt(amount);
          v.status = v.amount > 0 ? SowStatus.AMENDED : SowStatus.CONFIRMED;
          v.confirmed = true;
        }
        return v;
      });
      setTasks(items);     
    }, 1500)
}
  
  const onRejectSelected = (rejected, task) => {

    const items = tasks.map(v => { 
      if (v.ref === task.ref) {
        v.rejected = rejected
      }
      return v
    })
    setTasks(items)
  }

  const rejectVariation = () => {

    const index = tasks.findIndex(t => t.rejected === true)
    if (index === -1) {
      swal.fire({
        title: 'Select Tasks',
        text: 'You have not selected tasks.',
        icon: 'warning',
        type: 'question',
      })
    }
    else {
      swal.fire({
        title: 'Are you sure?',
        text: 'You would not be able to revert this!',
        icon: 'warning',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reject task',
        cancelButtonText: 'No'
      })
      .then(result => {
        if (result.value) {
          const items = tasks.map(task => { 
            if (task.rejected) {
              task.status = SowStatus.REJECTED
              delete task.rejected
            } 
            return task
          })
          handleRejectVariation({...sow, tasks: items})
        }
      })
    }
  }

  const onTaskConfirmed = (confirmed, task) => {

    const items = tasks.map(v => { 
      if (v.ref === task.ref) {
        v.confirmed = confirmed
        if (!confirmed && v.amount !== 0) {
          // task is exluded but amount is not zero, then set to zero
          v.amount = 0
          v.status = SowStatus.EXCLUDED
        }
        else if (v.confirmed && v.amount !== 0) {
          // task is confirmed but amount is not zero, then status is amended
          v.status = SowStatus.AMENDED
        }
        else {
          v.status = confirmed ? SowStatus.CONFIRMED : SowStatus.EXCLUDED
        }
      }
      return v
    })
    setTasks(items)
  }

  const confirmSow = () => {
    
    let unconfirmed = 0
    const items = tasks.map(task => {
      task.amount = isNaN(task.amount) ? 0 : task.amount
      if (task.status === SowStatus.PENDING) {
        unconfirmed = unconfirmed + 1
      }
      return task
    })

    if (unconfirmed > 0) {
      swal.fire({
        title: 'Unconfirmed Tasks',
        text: 'You have unconfirmed tasks. Please confirm or exclude tasks by clicking on task status.',
        icon: 'warning',
        type: 'question',
      })
    }
    else {
      handleSowConfirm({ ...sow, tasks: items })
    }
  }

  return (
    <div>
      {sow && <>
        <div className='row mt-2 mb-4'>
          <div className='col-md-12'>
            <div className="card">
              <div className="card-header bg-transparent">
                <div className='d-flex flex-row justify-content-between'>
                  {sow.status && <span>Status: <Badge className={StatusClass[sow.status.toUpperCase()]}>{sow.status}</Badge></span>}
                  {sow.ref && <span>Ref: {sow.ref}</span>}
                  {sow.created && <span>Submitted: {formatDateTime12(sow.created)}</span>}
                  {sow.updated && <span>Confirmed: {formatDateTime12(sow.updated)}</span>}
                </div>
              </div>

                <div className="card-body">

                  <div className='row mt-1'>
                    <div className='col-md-12'>
                      <label htmlFor='details'><h5>Details</h5></label>
                      <textarea value={sow.details} className='form-control' readOnly />
                    </div>
                  </div>
                  
                  <div className='row mt-4'>
                    <div className='col-md-12'>

                      <ToolkitProvider
                        keyField='ref'
                        data={tasks}
                        columns={sow.allowAmend ? editColumns : columns}
                        search
                        exportCSV
                      >
                        {props => (
                          <>
                            <div className='d-flex flex-row justify-content-between align-items-center'>
                              <div>
                                <label htmlFor='details'><h5>Tasks</h5></label>
                              </div>
                              <div>
                                <span className='mb-2 mr-1'>Search:</span>
                                <SearchBar {...props.searchProps} className='mb-0 ' />
                                <ClearSearchButton { ...props.searchProps } className='btn btn-light mb-1 mr-1 ml-1' />
                                <button className='btn btn-success mb-1 mr-1' onClick={_ => props.csvProps.onExport()}>Export CSV</button>
                              </div>
                            </div>

                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4
                              wrapperClasses="table-responsive"
                              pagination={paginationFactory({ ...paginationOptions, totalSize: sow.tasks.length })}
                              noDataIndication='No records found'
                              condensed
                            />
                          </>
                        )}
                      </ToolkitProvider>
                      
                    </div>
                  </div>

                </div>

                <div className="card-footer">
                  <div className="mc-footer">
                    <div className="row">
                      <div className="col-md-12">
                        {sow.allowReject && <button type="button" className="btn btn-warning m-1 mr-1" onClick={() => rejectVariation()}>Reject Task</button>}
                        {sow.allowAmend && <button type="button" className="btn btn-primary m-1 mr-1" onClick={() => confirmSow()}>Submit</button>}
                      </div>
                    </div>
                  </div>
                </div>
            
            </div>
          </div>
        </div>
      </>}
    </div>
  )
}

