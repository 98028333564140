import React from 'react';
import { Modal } from 'react-bootstrap';
import { useAuthState } from 'app/auth';
import { Formik, Form } from 'formik';
import { classList } from '@utils';
import * as yup from 'yup';
import Multiselect from 'multiselect-react-dropdown';
import userService from './userService';
import { NotificationManager } from "react-notifications";
import { useConfiguration } from 'app/configuration/ConfigurationContext';

export const InviteUserWidget = ({ title, projectName, show, opponents, ...props }) => {

  const authState = useAuthState();
  const config = useConfiguration()

  const initialValues = {
    personName: '',
    personEmail: '',
    trades: [],
    projectName: projectName || '',
    message: '',
  }

  const formSchema = yup.object().shape({
    personName: yup.string().required('Attention to is required'),
    personEmail: yup.string().email('Invalid email').required('Email is required'),

    message: yup.string().required('Message is required'),
  });
  
  const handleSend = (values, setSubmitting) => {
    
    setSubmitting(true);
    userService.sendInvite(authState.user.id, values).then(result => {
      setSubmitting(false);
      NotificationManager.success('Your invite has been sent.', 'Invite', 2000);
      props.handleCloseInvite();
    })
    .catch(ex => {
      setSubmitting(false);
      NotificationManager.error('Something went wrong', 'Invite', 3000);
    });
  };

  return (

    <Formik initialValues={initialValues} enableReinitialize={true} validationSchema={formSchema} onSubmit={handleSend}>
    {({ values, errors, touched, isValid, isSubmitting, setSubmitting, handleSubmit, setFieldValue, handleChange, handleBlur }) => {
      return (
        <Form
          className='needs-validation'
          onSubmit={handleSubmit}
          noValidate
        >

        <Modal show={show} onHide={() => props.handleCloseInvite()} centered={true} size='md'>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              <div
                className={classList({
                  'col-md-12 mb-3': true,
                  'valid-field': !errors.personName && touched.personName,
                  'invalid-field': errors.personName && touched.personName,
                })}
              >
                <label htmlFor='personName'>Attention to</label>
                <input
                  type='text'
                  className='form-control'
                  id='personName'
                  name='personName'
                  value={values.personName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                <div className='invalid-feedback'>{errors.personName}</div>
              </div>
            </div>

            <div className='row'>
              <div
                className={classList({
                  'col-md-12 mb-3': true,
                  'valid-field': !errors.personEmail && touched.personEmail,
                  'invalid-field': errors.personEmail && touched.personEmail,
                })}
              >
                <label htmlFor='personEmail'>Email</label>
                <input
                  type='text'
                  className='form-control'
                  id='personEmail'
                  name='personEmail'
                  value={values.personEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  minlength='5' maxlength='64'
                  required
                />
                <div className='invalid-feedback'>{errors.personEmail}</div>
              </div>
            </div>

            <div className='row'>
              <div
                className={classList({
                  'col-md-12 mb-3': true,
                  'valid-field': !errors.trades && touched.trades,
                  'invalid-field': errors.trades && touched.trades,
                })}
              >
                <label htmlFor='trades'>Select Trade (up to 5)</label>
                <Multiselect name='trades' selectionLimit={5} selectedValues={values.trades} onChange={ selects => setFieldValue('trades', selects) } options={[...config.tradeCategories, ...config.specialistCategories]} showArrow={true} avoidHighlightFirstOption={true} displayValue='text' />
                <div className='invalid-feedback'>{errors.trades}</div>
              </div>
            </div>

            <div className='row'>
              <div
                className={classList({
                  'col-md-12 mb-3': true,
                  'valid-field': !errors.projectName && touched.projectName,
                  'invalid-field': errors.projectName && touched.projectName,
                })}
              >
                <label htmlFor='projectName'>Project</label>
                <input
                  type='text'
                  className='form-control'
                  id='projectName'
                  name='projectName'
                  value={values.projectName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className='invalid-feedback'>{errors.projectName}</div>
              </div>
            </div>

            <div className='row'>
              <div
                className={classList({
                  'col-md-12 mb-3': true,
                  'valid-field': !errors.message && touched.message,
                  'invalid-field': errors.message && touched.message,
                })}
              >
                <label htmlFor='message'>Message</label>
                <textarea style={{height: 100}} 
                  className='form-control'
                  id='message'
                  name='message'
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder='Write a message'
                  required
                />
                <div className='invalid-feedback'>{errors.message}</div>
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <button type='button' className='btn btn-warning m-1' onClick={() => props.handleCloseInvite()}>Cancel Invite</button>
            <button type='button' className='btn btn-success m-1 footer-delete-right' onClick={() => handleSend(values, setSubmitting)} disabled={!isValid || isSubmitting}>Send Invite</button>
          </Modal.Footer>
        </Modal>
      </Form>
      );
    }}
    </Formik>
  );
};
