import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { downloadBlobFile, isImageFile } from 'app/utils/common';
import { NotificationContainer, NotificationManager } from 'react-notifications';

export const IwtbFilePreview = (props) => {

    const [fileUrl, setFileUrl] = useState();

    const loadFile = React.useCallback(async (file) => {

      let url, type, blob;

      try {      
        if (file.blobName && file.blobName.length > 0) {
          // load file from BLOB
          url = await downloadBlobFile(file.blobName);
          if (url.error) {
            return NotificationManager.error(url.message, 'Server Connection', 3000);      
          }
        }
        else {
          // attempting to load in memory file
          type = file.type;
          blob = new Blob([file], { type: type, encoding: 'UTF-8' });
          url = window.URL.createObjectURL(blob);
        }
    
        // if file is image => preview, otherwise download
        if (isImageFile(file.name)) {
          setFileUrl(url);     
        }
        else {
          saveAs(url, file.name);
        }
      }
      catch {
        NotificationManager.error(`File ${file.name} failed to download.`, 'File Preview', 3000);
      }  
    })

    useEffect(_ => {

      if (props.file && props.file.url) {
        setFileUrl(props.file.url);     
      }
      else if (props.file && props.file.name) {
          loadFile(props.file);
      }
    }, [props.file]);
  
    return( <>
        {props.file && <Modal show={fileUrl?.length > 0} onHide={() => setFileUrl(null)} centered={true} size="lg" scrollable={true}>
            <Modal.Header closeButton>
                <Modal.Title>{props.file.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img src={fileUrl} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={() => setFileUrl(null)}>Close</Button>
                <Button variant='primary' onClick={() => saveAs(fileUrl, props.file.name)}>Download</Button>
            </Modal.Footer>
        </Modal>} 

        <NotificationContainer />      
    </>)
}