import React from 'react';

export const CompanyWidget = ({ user }) => {

  return ( <>
    {user && <form className="mt-3">

      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Name:</label>
        <div className="form-control col-lg-4">{user.businessName}</div>
        <label className="col-lg-2 col-form-label">ABN:</label>
        <div className="form-control col-lg-4">{user.ABN}</div>
      </div>

      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Email:</label>
        <div className="form-control col-lg-4">{user.businessEmail}</div>
        <label className="col-lg-2 col-form-label">Website:</label>
        <div className="form-control col-lg-4">{user.website}</div>
      </div>

      <div className="form-group row">
      <label className="col-lg-2 col-form-label">Years in Business:</label>
        <div className="form-control col-lg-4">{user.businessYearsOperating}</div>
        <label className="col-lg-2 col-form-label">Fax:</label>
        <div className="form-control col-lg-4">{user.businessFax}</div>
      </div>

      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Industry:</label>
        <div className="form-control col-lg-4 text-capitalize">{user.industry}</div>
        <label className="col-lg-2 col-form-label">Safety Pack:</label>
        <div className="form-control col-lg-4 text-capitalize">{user.safetyPack}</div>
      </div>
      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Trades:</label>
        <div className="form-control col-lg-10">{user.trades ? user.trades.map(v => v.text).join(', ') : ''}</div>
      </div>
      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Location:</label>
        <div className="form-control col-lg-10">{user.businessLocation ? user.businessLocation.address : ''}</div>
      </div>

      </form>}
    </>
  );
}

