import { ClientNavigation } from './clientRoutes';

export const clientMenu = [
  {
    name: "New Project",
    description: "Start new project",
    type: "link",
    icon: "i-Idea-3",
    path: ClientNavigation.NEW_PROJECT
  },
  {
    name: "My Projects",
    description: "",
    type: "dropDown",
    icon: "nav-icon i-Gear-2",
    sub: [
      {
        icon: "i-Gears",
        name: "Projects",
        path: ClientNavigation.PROJECTS,
        type: "link"
      },
      {
        icon: "i-Close-Window",
        name: "Canceled & Declined",
        path: ClientNavigation.DECLINED_PROJECTS,
        type: "link"
      },
    ]
  },
  {
    name: "Messenger",
    description: "",
    type: "link",
    icon: "i-Speach-Bubbles",
    path: ClientNavigation.COMMUNICATION
  },
  {
    name: "Contacts",
    description: "",
    type: "link",
    icon: "i-Engineering",
    path: ClientNavigation.CONTACTS
  },
  {
    name: "Contact Us",
    description: "Contact our support.",
    type: "link",
    icon: "i-Paper-Plane",
    path: ClientNavigation.CONTACT_US
  },
];
