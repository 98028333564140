import { BidTab } from 'app/utils/constants';
import { NotificationType, RefreshType, NotificationSubject } from 'app/notification';
import { ProjectTab } from 'app/client/constants';

export const refreshOnNotification = (notificationType, refreshType, callback, params) => {

    switch (refreshType) {
    
        case RefreshType.CHAT_RECENT_CONTACTS:
            switch (notificationType) {
                case NotificationType.CHAT:
                    return callback.apply(this, params)
            }

        case RefreshType.NOTIFICATION_LIST:
            switch (notificationType) {
                case NotificationType.REFRESH_NOTIFICATION_LIST:
                case NotificationType.CHAT:
                    return callback.apply(this, params)
            }
    
        case RefreshType.ADMIN_SERVICES:
            switch (notificationType) {
                case NotificationType.SERVICE_DELETED:
                    return callback.apply(this, params)
            }        
    
        case RefreshType.ADMIN_MEMBERSHIPS:
            switch (notificationType) {
                case NotificationType.ADMIN_USER_PROFILE_UPDATED:
                    return callback.apply(this, params)
            }        
    
        case RefreshType.ADMIN_PROJECT_CARD:
            switch (notificationType) {
                case NotificationType.TRADE_ADDED:
                case NotificationType.TRADE_DELETED:
                case NotificationType.BID_SUBMITTED:
                case NotificationType.BID_RECEIVED:
                case NotificationType.BID_WITHDRAWN:
                case NotificationType.BID_AWARDED:
                case NotificationType.BID_DECLINED:
                case NotificationType.ACCESS_WITHDRAWN:
                case NotificationType.ACCESS_REQUESTED:
                case NotificationType.RFI_SUBMITTED:
                case NotificationType.SOW_AMENDED:
                case NotificationType.SOW_CONFIRMED:
                case NotificationType.SOW_REJECTED:
                case NotificationType.SOW_REQUESTED:
                case NotificationType.AWARD_ACCEPTED:
                case NotificationType.AWARD_DECLINED:
                case NotificationType.ACCESS_APPROVED:
                case NotificationType.ACCESS_DECLINED:
                case NotificationType.RFI_RESPONDED:
                case NotificationType.PROJECT_CANCELED:
                case NotificationType.SERVICE_DELETED:
                    return callback.apply(this, params)
            }
    
        case RefreshType.CONTRACTOR_PROJECT_CARD:
            switch (notificationType) {
                case NotificationType.TRADE_ADDED:
                case NotificationType.TRADE_DELETED:
                case NotificationType.CONTRACTOR_TRADE_DELETED:
                case NotificationType.SOW_REJECTED:
                case NotificationType.ACCESS_APPROVED:
                case NotificationType.ACCESS_DECLINED:
                case NotificationType.SOW_REQUESTED:
                case NotificationType.BID_AWARDED:
                case NotificationType.BID_DECLINED:
                case NotificationType.PROJECT_CANCELED:
                    return callback.apply(this, params)
            }
    
        case RefreshType.CONTRACTOR_PROJECTS:
            switch (notificationType) {
                case NotificationType.RFI_SUBMITTED:
                case NotificationType.BID_SUBMITTED:
                case NotificationType.BID_AWARDED:
                case NotificationType.BID_DECLINED:
                case NotificationType.BID_REJECTED:
                case NotificationType.BID_WITHDRAWN:
                    return callback.apply(this, params)
            }
                
        case RefreshType.CONTRACTOR_PROJECT_RFI:
            switch (notificationType) {
                case NotificationType.RFI_RESPONDED:
                    return callback.apply(this, params)
            }
            
        case RefreshType.CLIENT_PROJECT_RFI:
            switch (notificationType) {
                case NotificationType.RFI_SUBMITTED:
                    return callback.apply(this, params)
            }
        
        case RefreshType.CLIENT_PROJECTS:
            switch (notificationType) {
                case NotificationType.RFI_SUBMITTED:
                case NotificationType.BID_RECEIVED:
                case NotificationType.BID_AWARDED:
                case NotificationType.BID_REJECTED:
                case NotificationType.BID_WITHDRAWN:
                case NotificationType.AWARD_ACCEPTED:
                case NotificationType.AWARD_DECLINED:
                case NotificationType.TRADE_ADDED:
                case NotificationType.TRADE_DELETED:
                case NotificationType.ACCESS_REQUESTED:
                case NotificationType.ACCESS_WITHDRAWN:
                    return callback.apply(this, params)
            }
    
        case RefreshType.CLIENT_PROJECT_CARD:
            switch (notificationType) {
                case NotificationType.TRADE_ADDED:
                case NotificationType.TRADE_DELETED:
                case NotificationType.BID_RECEIVED:
                case NotificationType.ACCESS_REQUESTED:
                case NotificationType.SOW_AMENDED:
                case NotificationType.SOW_CONFIRMED:
                case NotificationType.AWARD_ACCEPTED:
                case NotificationType.AWARD_DECLINED:
                case NotificationType.BID_WITHDRAWN:
                case NotificationType.ACCESS_WITHDRAWN:
                case NotificationType.SERVICE_DELETED:
                case NotificationType.SERVICE_ALLOCATED:
                    return callback.apply(this, params)
            }
    }
}

export const setNotificationByType = (notification, authState) => {
        
    const path = getPath(notification, authState);

    let msg = {
        status: 'New',
        id: notification.id,
        time: notification.time,
        description: notification.message,
        path: path ?? '',
    }

    switch (notification.type) {
        
        case NotificationType.CLIENT_TRADE_DELETED:
            return {
                ...msg,
                icon: 'i-Remove',
                title: NotificationSubject.CLIENT_TRADE_DELETED,
                color: 'danger',
            }    

        case NotificationType.CONTRACTOR_TRADE_DELETED:
            return {
                ...msg,
                icon: 'i-Remove',
                title: NotificationSubject.CONTRACTOR_TRADE_DELETED,
                color: 'danger',
            }    

        case NotificationType.ADMIN_USER_PROFILE_UPDATED:
            return {
                ...msg,
                icon: 'i-Add-User',
                title: NotificationSubject.ADMIN_USER_PROFILE_UPDATED,
                color: 'warning',
            }    

        case NotificationType.REGISTRATION_APPROVED:
            return {
                ...msg,
                icon: 'i-Add-User',
                title: NotificationSubject.REGISTRATION_APPROVED,
                color: 'success',
            }    

        case NotificationType.REGISTRATION_INFO_REQUEST:
            return {
                ...msg,
                icon: 'i-Add-User',
                title: NotificationSubject.REGISTRATION_INFO_REQUEST,
                color: 'warning',
            }    

        case NotificationType.PROJECT_CREATED:
            return {
                ...msg,
                icon: 'i-Gear',
                title: NotificationSubject.PROJECT_CREATED,
                color: 'success',
            }    

        case NotificationType.SERVICE_ALLOCATED:
            return {
                ...msg,
                icon: 'i-Business-Mens',
                title: NotificationSubject.SERVICE_ALLOCATED,
                color: 'success',
            }    

        case NotificationType.SERVICE_DELETED:
            return {
                ...msg,
                icon: 'i-Business-Mens',
                title: NotificationSubject.SERVICE_DELETED,
                color: 'danger',
            }    
    
        case NotificationType.NEW_USER:
            return {
                ...msg,
                icon: 'i-Add-User',
                title: NotificationSubject.NEW_USER,
                color: 'primary',
            }    
    
        case NotificationType.USER_CREATED:
            return {
                ...msg,
                icon: 'i-Add-User',
                title: NotificationSubject.USER_CREATED,
                color: 'primary',
            }    

        case NotificationType.PROJECT_UPDATE:
            return {
                ...msg,
                icon: 'i-File-Edit',
                title: NotificationSubject.PROJECT_UPDATE,
                color: 'danger',
            }    

        case NotificationType.PROJECT_ADDENDUM_RECEIVED:
            return {
                ...msg,
                icon: 'i-File-Edit',
                title: NotificationSubject.PROJECT_ADDENDUM_RECEIVED,
                color: 'danger',
            }    
    
        case NotificationType.PROJECT_REVISE_REQUEST:
            return {
                ...msg,
                icon: 'i-File-Edit',
                title: NotificationSubject.PROJECT_REVISE_REQUEST,
                color: 'warning',
            }    

        case NotificationType.AWARD_DECLINED:
            return {
                ...msg,
                icon: 'i-Medal-3',
                title: NotificationSubject.AWARD_DECLINED,
                color: 'danger',
            }    

        case NotificationType.AWARD_ACCEPTED:
            return {
                ...msg,
                icon: 'i-Medal-3',
                title: NotificationSubject.AWARD_ACCEPTED,
                color: 'success',
            }    

        case NotificationType.TRADE_ADDED:
            return {
                ...msg,
                icon: 'i-Information',
                title: NotificationSubject.TRADE_ADDED,
                color: 'success',
            }    

        case NotificationType.TRADE_DELETED:
            return {
                ...msg,
                icon: 'i-Close-Window',
                title: NotificationSubject.TRADE_DELETED,
                color: 'danger',
            }    
     
        case NotificationType.BID_DECLINED:
            return {
                ...msg,
                icon: 'i-Engineering',
                title: NotificationSubject.BID_DECLINED,
                color: 'danger',
            }    

        case NotificationType.BID_AWARDED:
            return {
                ...msg,
                icon: 'i-Engineering',
                title: NotificationSubject.BID_AWARDED,
                color: 'success',
            }    

        case NotificationType.BID_WITHDRAWN:
            return {
                ...msg,
                icon: 'i-Engineering',
                title: NotificationSubject.BID_WITHDRAWN,
                color: 'warning',
            }    
    
        case NotificationType.BID_RECEIVED:
            return {
                ...msg,
                icon: 'i-Engineering',
                title: NotificationSubject.BID_RECEIVED,
                color: 'success',
            }    

        case NotificationType.BID_SUBMITTED:
            return {
                ...msg,
                icon: 'i-Engineering',
                title: NotificationSubject.BID_SUBMITTED,
                color: 'success',
            }    
        case NotificationType.PROJECT_AMENDED_BY_ADMIN:
            return {
                ...msg,
                icon: 'i-Information',
                title: NotificationSubject.PROJECT_AMENDED_BY_ADMIN,
                color: 'warning',
            }    
        case NotificationType.PROJECT_APPROVED:
            return {
                ...msg,
                icon: 'i-Information',
                title: NotificationSubject.PROJECT_APPROVED,
                color: 'success',
            }    
        case NotificationType.PROJECT_DECLINED:
            return {
                ...msg,
                icon: 'i-Close',
                title: NotificationSubject.PROJECT_DECLINED,
                color: 'danger',
            }    

        case NotificationType.RFI_SUBMITTED:
            return {
                ...msg,
                icon: 'i-Information',
                title: NotificationSubject.RFI_SUBMITTED,
                color: 'primary',
            }    

        case NotificationType.RFI_RESPONDED:
            return {
                ...msg,
                icon: 'i-Information',
                title: NotificationSubject.RFI_RESPONDED,
                color: 'primary',
            }    

        case NotificationType.SOW_REJECTED:
            return {
                ...msg,
                icon: 'i-Check',
                title: NotificationSubject.SOW_REJECTED,
                color: 'warning',
            }   

        case NotificationType.SOW_REQUESTED:
            return {
                ...msg,
                icon: 'i-Check',
                title: NotificationSubject.SOW_REQUESTED,
                color: 'info',
            }   
    
        case NotificationType.SOW_AMENDED:
            return {
                ...msg,
                icon: 'i-Check',
                title: NotificationSubject.SOW_AMENDED,
                color: 'danger',
            }    

        case NotificationType.SOW_CONFIRMED:
            return {
                ...msg,
                icon: 'i-Check',
                title: NotificationSubject.SOW_CONFIRMED,
                color: 'success',
            }    

        case NotificationType.ACCESS_REQUESTED:
            return {
                ...msg,
                icon: 'i-Hand',
                title: NotificationSubject.ACCESS_REQUESTED,
                color: 'primary',
            }   

        case NotificationType.ACCESS_APPROVED:
            return {
                ...msg,
                icon: 'i-Hand',
                title: NotificationSubject.ACCESS_APPROVED,
                color: 'success',
            }    

        case NotificationType.ACCESS_DECLINED:
            return {
                ...msg,
                icon: 'i-Hand',
                title: NotificationSubject.ACCESS_DECLINED,
                color: 'danger',
            }    

        case NotificationType.ACCESS_WITHDRAWN:
            return {
                ...msg,
                icon: 'i-Hand',
                title: NotificationSubject.ACCESS_WITHDRAWN,
                color: 'warning',
            }    
    
        case NotificationType.CHAT:
            return {
                ...msg,
                icon: 'i-Speach-Bubbles',
                title: NotificationSubject.CHAT,
                color: 'warning',
            }    

        default:
            return {
                ...msg,
                icon: 'i-Speach-Bubble-6',
                title: NotificationSubject.DEFAULT,
                color: 'warning',
              }                        
    }
}

export const getPath = (notification, authState) => {
    
    let path = '';

    switch (notification.type) {

        case NotificationType.PROJECT_DECLINED:
    
            path = authState.navigation.DECLINED_PROJECTS && notification.payload ? 
                authState.navigation.DECLINED_PROJECTS : ''
            break;
    
        case NotificationType.USER_CREATED:

            path = authState.navigation.MEMBERSHIPS_PENDING ?? '';
            break;

        case NotificationType.REGISTRATION_APPROVED:
        case NotificationType.REGISTRATION_INFO_REQUEST:
        case NotificationType.NEW_USER:

            path = authState.navigation.USER_PROFILE ?? '';
            break;

        case NotificationType.ADMIN_USER_PROFILE_UPDATED:

            path = authState.navigation.APPROVE_USER && notification.payload ? 
                authState.navigation.APPROVE_USER
                .replace(':userId', notification.payload.userId) : '';
            break;

        case NotificationType.ACCESS_REQUESTED:

            path = authState.navigation.PROJECT_CARD && notification.payload ? 
                authState.navigation.PROJECT_CARD
                .replace(':projectId', notification.payload.projectId)
                .replace(':tab?', ProjectTab.ACCESS) : '';
            break;

        case NotificationType.CONTRACTOR_SITE_INSPECTION:
        case NotificationType.CONTRACTOR_TENDER_DUE:
        case NotificationType.PROJECT_AMENDED_BY_ADMIN:
        case NotificationType.PROJECT_CREATED:
        case NotificationType.PROJECT_UPDATE:
        case NotificationType.PROJECT_ADDENDUM_RECEIVED:
        case NotificationType.PROJECT_APPROVED:
        case NotificationType.PROJECT_REVISE_REQUEST:

            path = authState.navigation.PROJECT_CARD && notification.payload ? 
                authState.navigation.PROJECT_CARD
                .replace(':projectId', notification.payload.projectId)
                .replace(':tab?', ProjectTab.DETAILS) : '';
            break;

        case NotificationType.CLIENT_UPLOADED_QUOTE:

            path = authState.navigation.PROJECT_CARD && notification.payload ? 
                authState.navigation.PROJECT_CARD
                .replace(':projectId', notification.payload.projectId)
                .replace(':tab?', ProjectTab.FILES) : '';
            break;
    
        case NotificationType.SERVICE_ALLOCATED:
        case NotificationType.SERVICE_DELETED:

            path = authState.navigation.PROJECT_CARD && notification.payload ? 
                authState.navigation.PROJECT_CARD
                .replace(':projectId', notification.payload.projectId)
                .replace(':tab?', ProjectTab.AWARDS) : '';
            break;

        case NotificationType.AWARD_DECLINED:
        case NotificationType.AWARD_ACCEPTED:
        case NotificationType.BID_AWARDED:
        case NotificationType.BID_WITHDRAWN:
        case NotificationType.BID_DECLINED:

            path = authState.navigation.BID_CARD && notification.payload ? 
                authState.navigation.BID_CARD
                .replace(':projectId', notification.payload.projectId)
                .replace(':bidId', notification.payload.bidId)
                .replace(':tab?', BidTab.DETAILS) : '';
            break;

        case NotificationType.SOW_REJECTED:
        case NotificationType.SOW_AMENDED:
        case NotificationType.SOW_CONFIRMED:
        case NotificationType.SOW_REQUESTED:

            path = authState.navigation.BID_CARD && notification.payload ? 
                authState.navigation.BID_CARD
                .replace(':projectId', notification.payload.projectId)
                .replace(':bidId', notification.payload.bidId)
                .replace(':tab?', BidTab.SOW) : '';
            break;
        
        case NotificationType.TRADE_ADDED:
        case NotificationType.TRADE_DELETED:

            path = authState.navigation.PROJECT_CARD && notification.payload ? 
                authState.navigation.PROJECT_CARD
                .replace(':projectId', notification.payload.projectId)
                .replace(':tab?', ProjectTab.TRADES) : '';
            break;
     
        case NotificationType.BID_SUBMITTED:
        case NotificationType.BID_RECEIVED:

            path = authState.navigation.PROJECT_CARD && notification.payload ? 
                authState.navigation.PROJECT_CARD
                .replace(':projectId', notification.payload.projectId)
                .replace(':tab?', ProjectTab.BIDS) : '';
            break;
        
        case NotificationType.RFI_SUBMITTED:
        case NotificationType.RFI_RESPONDED:

            path = authState.navigation.PROJECT_CARD && notification.payload ? 
                authState.navigation.PROJECT_CARD
                .replace(':projectId', notification.payload?.projectId)
                .replace(':tab?', ProjectTab.RFI) : '';
            break;

        case NotificationType.ACCESS_APPROVED:
        case NotificationType.ACCESS_WITHDRAWN:
        case NotificationType.ACCESS_DECLINED:
            path = authState.navigation.ACCESS_REGISTER;
            break;

        case NotificationType.CHAT:
            path = authState.navigation.CHAT && notification.from ? authState.navigation.CHAT.replace(':opponentId?', notification.from) : '';
            break;
    
        default:
            path = authState.navigation.CHAT;
    }                    
    return path;  
}