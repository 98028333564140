import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Breadcrumb, SimpleCard } from '@gull';
import adminService from '../adminService';
import { AdminNavigation } from '../adminRoutes';
import { ProjectStatus } from 'app/utils/constants';
import { Row, Col } from "react-bootstrap";
import { useAuthState } from 'app/auth';
import { SubscriptionReport, RegistrationReport, PieChart } from 'app/admin/report';
import { getFirstDayOfMonth, getLastDayOfMonth } from 'app/utils/time'
import { format, addMonths, eachMonthOfInterval } from 'date-fns'

export const AdminDashboard = () => {

  const [pendingRegistrationCount, setPendingRegistrationCount] = React.useState(0);
  const [pendingProjectCount, setPendingProjectCount] = React.useState(0);
  const [activeUserDataset, setActiveUserDataset] = React.useState([]);
  const [projectStatusDataset, setProjectStatusDataset] = React.useState([]);
  const [subscriptionDataset, setSubscriptionDataset] = React.useState([]);
  const [registrationDataset, setRegistrationDataset] = React.useState([]);
  const authState = useAuthState();

  const getActiveSubscriptionReport = React.useCallback(() => {

    adminService.getActiveSubscriptionReport(authState.user.id)
    .then(result => {
      if (result?.error) {
        return NotificationManager.error(result.message, 'Server Connection', 3000);      
      }
      if (Array.isArray(result)) {

        const data = [['userGroup', 'None', 'Expired', 'Free Trial', 'Paid']]

        result.map(s => {
          const stats = { 'None': 0, 'Expired': 0, 'Free Trial': 0, 'Paid': 0 }

          s.subscriptions.map(subscription => {
            switch (subscription.title) {
              case 'None':
              case 'Free Trial':
              case 'Expired':
                stats[subscription.title] = stats[subscription.title] + subscription.count
                break;
              default:
                stats['Paid'] = stats['Paid'] + subscription.count
                break;
            }
          })
          data.push([ s.userGroup, stats['None'], stats['Expired'], stats['Free Trial'], stats['Paid'] ])
        })
        setSubscriptionDataset(data)
      }
    })
    .catch(e => {
      NotificationManager.error('Something went wrong.', 'Server Connection', 3000);      
    });
  })

  const getRegistrationReport = React.useCallback(() => {

    const startDate = getFirstDayOfMonth(addMonths(new Date(), -6))
    const endDate = getLastDayOfMonth(new Date())

    adminService.getRegistrationReport(authState.user.id, startDate, endDate)
    .then(result => {
      if (result?.error) {
        return NotificationManager.error(result.message, 'Server Connection', 3000);      
      }

      let months = eachMonthOfInterval({ start: Date.parse(startDate), end: new Date() })

      const stats = {}
      if (Array.isArray(months)) {
        months = months.map(d => {
          stats[`${format(d, 'yyyy-M')}`] = 0 // init stats, this must match registration year/month format below
          return format(d, 'MMM yy') // populates month names for given range
        })
      }

      if (Array.isArray(result)) {

        const data = [[ 'userGroup', ...months ]]

        result.map(s => {

          s.registrations.map(r => {
            stats[`${r.year}-${r.month}`] = (stats[`${r.year}-${r.month}`] || 0) + r.count
          })
          const statsRow = []
          for (const prop in stats) {
            statsRow.push(stats[prop])
          }
          data.push([ s.userGroup, ...statsRow ])
        })
        setRegistrationDataset(data)
      }
    })
    .catch(e => {
      NotificationManager.error('Something went wrong.', 'Server Connection', 3000);      
    });
  })

  React.useEffect(() => {

    getActiveUserReport();
    getProjectStatusReport();
    getPendingRegistrations();
    getActiveSubscriptionReport();
    getRegistrationReport()

  }, []);

  const getProjectStatusReport = () => {
    
    let pendingProjectCount = 0;

    adminService.getProjectStatusReport(authState.user.id)
    .then(result => {
      if (result?.error) {
        return NotificationManager.error(result.message, 'Server Connection', 3000);      
      }
      if (Array.isArray(result)) {

        setProjectStatusDataset(result)

        result.map(s => {
          if (s.name === ProjectStatus.PENDING || s.name === ProjectStatus.REVISED) {
            pendingProjectCount = s.value;
          }
        })
        setPendingProjectCount(pendingProjectCount);
      }
    })
    .catch(e => {
      NotificationManager.error('Something went wrong.', 'Server Connection', 3000);      
    });
  }

  const getPendingRegistrations = () => {

    adminService.getPendingRegistrations(authState.user.id)
    .then(result => {
      if (result.error) {
        return NotificationManager.error(result.message, 'Server Connection', 3000);      
      }
      else {
        setPendingRegistrationCount(result.length); 
      }
    })
    .catch(e => {
      NotificationManager.error('Something went wrong.', 'Server Connection', 3000);      
    });
  }
  
  const getActiveUserReport = () => {

    adminService.getActiveUserReport(authState.user.id)
    .then(result => {
      if (result?.error) {
        return NotificationManager.error(result.message, 'Server Connection', 3000);      
      }
      if (Array.isArray(result)) {
        setActiveUserDataset(result)
      }
    })
    .catch(e => {
      NotificationManager.error('Something went wrong.', 'Server Connection', 3000);      
    });
  }

  const onMemberChartClick = ({ data }) => {

    console.log(data)
  }

  const onProjectChartClick = ({ data }) => {

    console.log(data)
  }

  return (
    <div>
      <Breadcrumb
        routeSegments={[
          { name: 'Admin', path: '/admin' },
          { name: 'Admin Dashboard' }
        ]}
      ></Breadcrumb>

      <section className="ul-widget-stat-s1">
        <div className="row">

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
              <div className="card-body text-center">
                <i className='i-Gears'></i>
                <div className="content">
                  <Link to={AdminNavigation.PROJECTS_PENDING} className='cursor-pointer'>
                    <p className="text-muted mt-2 mb-0">Pending</p>
                    <p className="text-primary text-24 line-height-1 mb-2">{pendingProjectCount}</p>
                  </Link>                
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
              <div className="card-body text-center">
                <i className='i-Add-User'></i>
                <div className="content">
                  <Link to={AdminNavigation.MEMBERSHIPS_PENDING} className='cursor-pointer'>
                    <p className="text-muted mt-2 mb-0">Pending</p>
                    <p className="text-primary text-24 line-height-1 mb-2">{pendingRegistrationCount}</p>
                  </Link>                
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      
      <Row>
        <Col lg={4} md={4} sm={12} xs={12} className="mb-4">
          <SimpleCard title="Projects">
            <PieChart height="275px" data={projectStatusDataset} onClick={onProjectChartClick} />
          </SimpleCard>              
        </Col>

        <Col lg={4} md={4} sm={12} xs={12} className="mb-4">
          <SimpleCard title="Active Users">
            <PieChart height="275px" data={activeUserDataset} onClick={onMemberChartClick} />
          </SimpleCard>              
        </Col>

        <Col lg={4} md={4} sm={12} xs={12} className="mb-4">
          <SimpleCard title="Subscriptions">
            <SubscriptionReport height="275px" data={subscriptionDataset} />
          </SimpleCard>              
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={4} sm={12} xs={12} className="mb-4">
          <SimpleCard title="Registrations">
            <RegistrationReport height="400px" data={registrationDataset} />
          </SimpleCard>              
        </Col>
      </Row>

      <NotificationContainer />
    </div>
  );
}
