import adminService from '../adminService';
import swal from "sweetalert2";
import bidService from '../bid/bidService'

export const getAwardRegister = async (adminId, projectId) => {

    const result = await adminService.getAwardRegister(adminId, projectId);
    if (result && result.awardRegister) {
      return result.awardRegister; 
    }
    return [];
}

export const getProjectBids = async (adminId, projectId) => {

    let bidRegister = await adminService.getProjectBids(adminId, projectId);
    if (bidRegister && bidRegister.length > 0) {
        bidRegister = bidRegister.map((trade, i) => {
            const bids = trade.bids.map((bid, i) => {
                const company = bid.contractor.businessName && bid.contractor.businessName.length > 0 ? bid.contractor.businessName : `${bid.contractor.firstName} ${bid.contractor.lastName}`; 
                return {index: ++i, company: company, ...bid };
            });
            return { ...trade, bids, index: i };
        });
        return bidRegister;
    }
    return [];
}

export const awardBid = async (clientId, projectId, bidId) => {

    return new Promise((resolve, reject) => {
        swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, award trade',
            cancelButtonText: 'No'
        })
        .then(result => {
            if (result.value) {    
                return bidService.awardBid(clientId, projectId, bidId)
                .then(response => {
                    if (response.error) {
                        return reject(response.message);
                    }
                    return resolve();
                })
                .catch(ex => {
                    return reject();
                });
            }
        });  
    })
}