import React from 'react';
import { Breadcrumb, SimpleCard } from '@gull';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Dropdown, Badge } from "react-bootstrap";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import service from '../contractorService';
import moment from 'moment';
import { paginationOptions } from 'app/utils/constants';
import { useAuthState } from 'app/auth';
import { StatusClass } from 'app/utils/constants';
import { formatDateTime12 } from 'app/utils/time';
import { MdMoreVert} from "react-icons/md";
import swal from "sweetalert2";
import { useConfiguration } from 'app/configuration/ConfigurationContext';

export const AccessRegister = () => {

  const { SearchBar, ClearSearchButton } = Search;
  const authState = useAuthState();
  const [loading, setLoading] = React.useState(true);
  const [projects, setProjects] = React.useState([]);
  const config = useConfiguration()
  
  const getAccessRegister = React.useCallback(userId => {
      
      service.getAccessRegister(userId)
      .then(result => {
        if (result.error) {
          NotificationManager.error(result.message, 'Delete Access', 3000);      
        }
        else {
          setLoading(false);
          setProjects(result); 
        }
      })
      .catch(e => NotificationManager.error('Something went wrong.', 'Server Connection', 3000));
    }
  )

  const projectColumns = [
    {
      dataField: 'projectName',
      text: 'Project',
      headerAlign: 'center',
      sort: true,
      headerStyle: () => ({ width: '30%' }),
   },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
    {
      dataField: 'tenderRequiredBy',
      text: 'Due In',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: ((cell, row) => formatDateTime12(row.tenderRequiredBy))
    },
    {
      dataField: 'Time Remaining',
      text: 'Time Remaining',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: ((cell, row) => { 
        let retVal = '';
        if (row.tenderRequiredBy) {
          const hours = moment(row.tenderRequiredBy).diff(moment(), 'hours'); 
          if (hours && hours > 24) {
            retVal = <span className="ul-widget3-status text-success t-font-bolder">{ Math.floor(hours/24) } days</span>
          }
          else {
            retVal = <span className="ul-widget3-status text-warning t-font-bolder">{ hours } hours</span>
          }
        }
        return retVal;
      })
    },
    {
      dataField: 'Access',
      text: 'Access',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.access.toUpperCase()]}>{row.access}</Badge>
      })
    },
    {
      dataField: 'comments',
      text: 'Comments',
      headerAlign: 'center',
    },
    {
      dataField: 'ref',
      text: 'Ref',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
    },
    {
      dataField: 'Action',
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '60px' }),
      formatter: ((cell, row) => { 
        return <Dropdown>
          <Dropdown.Toggle as="span" className="cursor-pointer toggle-hidden">
            <MdMoreVert size={18}></MdMoreVert>
          </Dropdown.Toggle>
          <Dropdown.Menu>          
            <Dropdown.Item
              onClick={() => {
                swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to access this project!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, withdraw!",
                    cancelButtonText: "No"
                  })
                  .then(result => {
                    if (result.value) {
                      deleteAccess(row);
                    } 
                  });
              }}
            >
              <i className="nav-icon i-Close-Window text-danger font-weight-bold mr-2"></i>
              Withdraw
            </Dropdown.Item>
          
          </Dropdown.Menu>
        </Dropdown>
      })
    },
  ];

  React.useEffect(() => {

    if (authState.isAuthenticated) {
      getAccessRegister(authState.user.id);
    }

    const interval = setInterval(() => {
      if (authState.isAuthenticated) {
        getAccessRegister(authState.user.id);
      }
    }, config.SCHEDULE.ACCESS_REGISTER_SCHEDULE);

    return () => clearInterval(interval)

  }, [authState.user]);

  const deleteAccess = (project) => {

    service.deleteAccess(authState.user.id, project.id)
    .then(result => {
      if (result.error) {
        NotificationManager.error(result.message, 'Delete Access', 3000);      
      }
      else {
        NotificationManager.warning('Project access was withdrawn', 'Delete Access', 3000);      
        getAccessRegister(authState.user.id);    
      }
    })
    .catch(e => NotificationManager.error('Something went wrong.', 'Server Connection', 3000));
  }

  // const actionProject = (action, project) => {

  //   switch(action) {
  //     case ProjectAction.NOT_INTERESTED:
  //       return deleteAccess(project);
  //   }
  // }

  return (
    <div>
      <Breadcrumb
        routeSegments={[
          { name: 'Access Register' }
        ]}
      ></Breadcrumb>

      <div className='row'>
          <div className='col-md-12'>

          <SimpleCard>
            <ToolkitProvider
              keyField='id'
              data={projects}
              columns={projectColumns}
              search
              exportCSV
            >
              {props => (
                <>
                  <div className='d-flex justify-content-end align-items-center'>
                   
                    <span className='mb-2 mr-1'>Search:</span>
                    <SearchBar {...props.searchProps} className='mb-0 ' />
                    <ClearSearchButton { ...props.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
                    <button className='btn btn-outline-success mb-2 mr-1' onClick={_ => props.csvProps.onExport()}>Export CSV</button>
                  </div>
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4
                    wrapperClasses="table-responsive"
                    pagination={paginationFactory({ ...paginationOptions, totalSize: projects.length })}
                    // overlay={ overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(255, 0, 0, 0.5)'}) } }) }
                    noDataIndication={'No records found'}
                    // striped
                    hover
                    condensed
                    loading={ loading }
                  />
                </>
              )}
            </ToolkitProvider>
          </SimpleCard>
        </div>
      </div>

      <NotificationContainer />
    </div>
  );
}
