
export const NotificationFrom = {
  SUPPORT: 'system',
}

export const NotificationTo = {
  SUPPORT: 'system',
}

export const RefreshType = {
  ADMIN_PROJECT_CARD: 'admin-project-card',
  ADMIN_SERVICES: 'admin-services',
  ADMIN_MEMBERSHIPS: 'admin-memberships',
  CONTRACTOR_PROJECTS: 'contractor-projects',
  CONTRACTOR_PROJECT_CARD: 'contractor-project-card',
  CONTRACTOR_PROJECT_RFI: 'contractor-project-rfi',
  CLIENT_PROJECT_CARD: 'client-project-card',
  CLIENT_PROJECT_RFI: 'client-project-rfi',
  CLIENT_PROJECTS: 'client-projects',
  NOTIFICATION_LIST: 'notification-list',
  CHAT_RECENT_CONTACTS: 'chat-recent-contacts',
}

export const NotificationType = {
  REGISTRATION_APPROVED: 'registration-approved',
  REGISTRATION_INFO_REQUEST: 'registration-info-request',
  CONTRACTOR_TENDER_DUE: 'contractor-tender-due',
  CONTRACTOR_SITE_INSPECTION: 'contractor-site-inspection',
  USER_CREATED: 'user-created',
  USER_VERIFIED: 'user-verified',
  ACCESS_REQUESTED: 'access-requested',
  ACCESS_APPROVED: 'access-approved',
  ACCESS_DECLINED: 'access-declined',
  ACCESS_WITHDRAWN: 'access-withdrawn',
  PROJECT_AMENDED_BY_ADMIN: 'project-amended-by-admin',
  PROJECT_CREATED: 'project-created',
  PROJECT_CANCELED: 'project-canceled',
  PROJECT_APPROVED: 'project-approved',
  PROJECT_DECLINED: 'project-declined',
  PROJECT_REVISE_REQUEST: 'project-revise-request',
  PROJECT_UPDATE: 'project-update',
  PROJECT_ADDENDUM_RECEIVED: 'project-addendum-received',
  BID_RECEIVED: 'bid-received',
  BID_SUBMITTED: 'bid-submitted',
  BID_REJECTED: 'bid-rejected',
  BID_DECLINED: 'bid-declined',
  BID_AWARDED: 'bid-awarded',
  BID_WITHDRAWN: 'bid-withdrawn',
  AWARD_ACCEPTED: 'award-accepted',
  AWARD_DECLINED: 'award-declined',
  RFI_SUBMITTED: 'rfi-subitted',
  RFI_RESPONDED: 'rfi-responded',
  SOW_REJECTED: 'sow-rejected',
  SOW_REQUESTED: 'sow-requested',
  SOW_CONFIRMED: 'sow-confirmed',
  SOW_AMENDED: 'sow-amended',
  NEW_USER: 'new-user',
  TRADE_ADDED: 'trade-added',
  TRADE_DELETED: 'trade-deleted',
  CHAT: 'chat',
  INVITE_CONTACT: 'invite-contact',
  SERVICE_DELETED: 'service-deleted',
  SERVICE_ALLOCATED: 'service-allocated',
  CONTACT_US: 'contact-us',
  CLIENT_UPLOADED_QUOTE: 'client-uploaded-quote',
  USER_READ_NOTIFICATION: 'user-read-notification',
  REFRESH_NOTIFICATION_LIST: 'refresh-notification-list',
  ADMIN_USER_PROFILE_UPDATED: 'admin-user-updated-profile',
  CONTRACTOR_TRADE_DELETED: 'contractor-trade-deleted',
  CLIENT_TRADE_DELETED: 'client-trade-deleted',
}

export const NotificationSubject = {
  REGISTRATION_APPROVED: 'Welcome to I Want to Build!',
  REGISTRATION_INFO_REQUEST: 'Your registration requires more information',
  CONTRACTOR_TENDER_DUE: 'Project is due for submittal',
  CONTRACTOR_SITE_INSPECTION: 'Site inspection',
  USER_CREATED: 'New user has been registered',
  USER_VERIFIED: 'Your email has been Verified',
  ACCESS_REQUESTED: 'Access has been Requested',
  ACCESS_APPROVED: 'Access has been Approved',
  ACCESS_DECLINED: 'Access has been Declined',
  ACCESS_WITHDRAWN: 'Access has been Withdrawn',
  AWARD_ACCEPTED: 'Award has been Accepted',
  AWARD_DECLINED: 'Award has been Declined',
  BID_RECEIVED: 'You have received a formal bid',
  BID_SUBMITTED: 'You have submitted a formal bid',
  BID_REJECTED: 'Bid has been Rejected',
  BID_DECLINED: 'Bid has been Declined',
  BID_AWARDED: 'Bid has been Awarded',
  BID_WITHDRAWN: 'Bid has been Withdrawn',
  NEW_USER: 'New user registration Submitted',
  PROJECT_CREATED: 'Project has been Created',
  PROJECT_APPROVED: 'Project has been Approved',
  PROJECT_AMENDED_BY_ADMIN: 'Your project has been amended by Admin',
  PROJECT_DECLINED: 'Project has been Declined',
  PROJECT_UPDATE: 'Project has been Addended',
  PROJECT_ADDENDUM_RECEIVED: 'A project addendum has been Received',
  PROJECT_REVISE_REQUEST: 'You were asked to revise your project',
  PROJECT_CANCELED: 'Project has been Canceled',
  SOW_REJECTED: 'SOW variation has been rejected',
  SOW_REQUESTED: 'SOW has been Requested',
  SOW_AMENDED: 'SOW was Amended',
  SOW_CONFIRMED: 'SOW was Confirmed',
  RFI_SUBMITTED: 'RFI has been Submitted',
  RFI_RESPONDED: 'RFI Response Received',
  TRADE_ADDED: 'Trade has been Added',
  TRADE_DELETED: 'Trade has been Deleted',
  INVITE_CONTACT: 'Your invitation has been sent',
  SERVICE_DELETED: 'IWTB service has been Deleted',
  SERVICE_ALLOCATED: 'IWTB service has been Allocated',
  CONTACT_US_USER: 'Your message has been sent',
  CONTACT_US_ADMIN: 'New user contact',
  CLIENT_UPLOADED_QUOTE: 'Client has uploaded a quote',
  ADMIN_USER_PROFILE_UPDATED: 'User updated profile',
  CONTRACTOR_TRADE_DELETED: 'Client has removed a trade',
  CLIENT_TRADE_DELETED: 'Your trade has been deleted',
}
