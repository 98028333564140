export const MyProfileTab = {
    TIMELINE: 'Timeline',
    ABOUT: 'About',
    COMPANY: 'Company',
    SUBSCRIPTION: 'Subscription',
    PROJECTS: 'Projects',
    CONTACTS: 'Contacts',
    PHOTOS: 'Photos',
    DOCUMENTS: 'Documents',
    REVIEWS: 'Reviews',
}