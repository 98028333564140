import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import LaddaButton, { EXPAND_RIGHT } from "react-ladda";
import { useSocket } from 'app/socket.io';
import { useHistory } from "react-router-dom";
import authService from 'app/auth/authService';
import { NotificationManager } from "react-notifications";
import { useAuthDispatch, dispatchSuccessfulLogin } from 'app/auth';
import { ForgotPasswordWidget } from 'app/password';

const SigninSchema = yup.object().shape({
    username: yup
      .string()
      .required('Username is required'),
    password: yup
      .string()
      .required('Password is required')
});
    
export const IwtbSignin = () => {
  
  const socket = useSocket()
  const authDispatch = useAuthDispatch();
  const history = useHistory();
  const [showForgotPassword, setShowForgotPassword] = useState(false)

  const handleSignin = async (values, { setSubmitting }) => {
    
    try {
      let user = await authService.loginWithUsernamePassword(values);

      if (user.error) {
        NotificationManager.error(user.message, 'Sign in', 6000);
        authDispatch({ type: 'LOGIN_ERROR', error: user.message });
      }
      else {
        dispatchSuccessfulLogin(authDispatch, user, socket)
      }
      history.push('/');
    } 
    catch (error) {
      NotificationManager.error('Something went wrong.', 'Sign in', 3000);
      authDispatch({ type: 'LOGIN_ERROR', error: error });
      setSubmitting(false)
    }
  }
    
  return (<>

    <Formik 
        initialValues={{username: '', password: ''}} 
        onSubmit={handleSignin}
        validationSchema={SigninSchema}>
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>

            <div className='btn-group'>
            <Dropdown>
            <Dropdown.Toggle className='toggle-hidden' variant='outline-primary'>Sign in</Dropdown.Toggle>
            <Dropdown.Menu
                className='dropdown-menu bg-transparent shadow-none p-0 m-0'
                style={{ width: '320px' }}
            >
                <div className='card'>
                <div className='card-body'>
                    <div className='d-flex flex-column'>
                    <div className='form-group'>
                        <input
                            name='username'
                            type='text'
                            className='form-control'
                            placeholder='Enter your username'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                        />
                        {errors.username && <div className='text-danger mt-1 ml-2'>{errors.username}</div>}
                    </div>
                    <div className='form-group'>
                        <input
                            name='password'
                            type='password'
                            className='form-control'
                            placeholder='Enter your password'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                        />
                        {errors.password && <div className='text-danger mt-1 ml-2'>{errors.password}</div>}
                    </div>
                    <LaddaButton 
                        className='btn btn-primary m-1'
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        progress={0.5}
                        data-style={EXPAND_RIGHT}
                        type='submit'
                      >Sign in</LaddaButton>
                      <div className="mt-3 text-center">
                        <a href="#" onClick={() => setShowForgotPassword(true)} title='Forgot Password' className="text-muted">
                          <u>Forgot Password?</u>
                        </a>
                      </div>
                  </div>
                </div>
              </div>
            </Dropdown.Menu>
            </Dropdown>
        </div>

          </form>
        )}
      </Formik>

      <ForgotPasswordWidget show={showForgotPassword} handleClose={() => setShowForgotPassword(false)} />
    </>)
};
