import React from 'react';
import { Modal, Button  } from "react-bootstrap";

export const IwtbModal = ({ title, message, show, handleClose, ...props }) => {

    return (
        <Modal show={show} onHide={handleClose} size="lg" scrollable={true} centered {...props}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>    
    );
};
