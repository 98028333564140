import React from 'react';
import { classList } from '@utils';
import { useField } from 'formik';

export const IwtbTextArea = ({ label, ...props }) => {
    const [field, meta] = useField(props);
        return (
        <div className={classList({
            'col-md-12 mb-3': true,
            'valid-field': meta.touched && !meta.error,
            'invalid-field': meta.touched && meta.error
        })}
        >
        <label htmlFor={props.id || props.name}>{label}</label>
        <div className='input-group'>
            <textarea className='form-control' {...field} {...props} />
            <div className='invalid-feedback'>{ meta.error }</div>
        </div>
        </div>
    );
};
