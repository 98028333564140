import React, { useState, Fragment } from 'react';
import { classList } from '@utils';
import { useField } from 'formik';
import mapsService from 'app/map/mapsService';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

export const IwtbPlaceAutocomplete = ({ label, ...props }) => {

    let defaultPlace = '';
      
    if (props.location && props.location.address) {
      defaultPlace = props.location.address;
    }

    const [meta] = useField(props);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
  
    const handleOnChange = (place) => {
      
      console.log(place);
      props.locationSelected(place[0]);
    }

    const handleSearch = (query) => {

      setIsLoading(true);
  
      mapsService.getPlaceAutocomplete(query)
        .then(({ predictions }) => {
          const options = predictions.map((i) => ({
            // TODO: add state
            id: i.place_id,
            address: i.description,
          }));
  
          setOptions(options);
          setIsLoading(false);
        });
    };
  
    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    const filterBy = () => true;
  
    return (

      <div className={classList({
        // 'col-md-4 mb-3': true,
        'valid-field': meta.touched && !meta.error,
        'invalid-field': meta.touched && meta.error
      })}
      >
        <label htmlFor={props.id || props.name}>{label}</label>
        <div className='input-group'>
            
          <AsyncTypeahead
            defaultInputValue={defaultPlace}
            filterBy={filterBy}
            id="placeAutocomplete"
            isLoading={isLoading}
            labelKey="address"
            minLength={3}
            onSearch={handleSearch}
            options={options}
            placeholder={props.placeholder}
            renderMenuItemChildren={(option, props) => (
              <Fragment>
                <span>{option.address}</span>
              </Fragment>
            )}
            onChange={handleOnChange}
          />
            
          <div className='invalid-feedback'>{ meta.error }</div>
        </div>
      </div>
    );
};
