import React, { useState } from 'react';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Badge, Dropdown } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { paginationOptions, audFormatter, StatusClass, BidStatus } from 'app/utils/constants';
import { AwardAction } from '../constants';
import { constructCompanyName } from 'app/utils/common';
import { ClientNavigation } from '../clientRoutes';
import { useHistory } from "react-router-dom";
import { MdMoreVert} from "react-icons/md";
import { calcVariation } from 'app/utils/common';
import { SendMessageWidget } from "app/chat";
import { useAuthState } from 'app/auth';
import { NotificationContainer, NotificationManager } from "react-notifications";
import { ReloadButton } from 'app/widgets';

export const AwardRegisterWidget = ({ awardRegister, readOnly, handleAwardAction, handleRefresh }) => {

  const history = useHistory();
  const { SearchBar, ClearSearchButton } = Search;
  const [ selectedTrades, setSelectedTrades ] = useState([]);
  const authState = useAuthState();

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    selectColumnPosition: 'right',
    style: {backgroundColor: '#FCF0BE'},
    selectionHeaderRenderer: ({ indeterminate, rowIndex, rowKey, ...rest }) => (
      <input type="checkbox"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        { ...rest } onChange={e => {}}
      />
    ),
    selectionRenderer: ({ mode, rowIndex, rowKey, ...rest }) => (
      <input type={mode} rowindex={rowIndex} rowkey={rowKey} {...rest} onChange={e => {}} />
    ),
    onSelect: (row, isSelect) => {
      if (isSelect && row.contractorId === authState.user.id) {
        return NotificationManager.error('You cannot send the message to yourself', 'Message', 3000)
      }
      if (isSelect) {
        // add trade
        const found = selectedTrades.some(item => item.id === row.contractorId); 
        if (!found) {
          const trades = [ ...selectedTrades, row.contractor ]
          setSelectedTrades(trades);
        }
      }
      else {
        // remove the trade
        const trades = selectedTrades.filter(item => item.id !== row.contractorId); 
        setSelectedTrades(trades);
      }  
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        const trades = rows.map(t => t.contractor)
        setSelectedTrades(trades)
      }
      else {
        setSelectedTrades([])
      }
    }
  }
  
  const columns = [
    {
      dataField: 'trade.text',
      text: 'Trade',
      headerAlign: 'center',
      sort: true,
    },
    {
      dataField: 'company',
      text: 'Company',
      headerAlign: 'center',
      sort: true,
      formatter: ((cell, row) => {
        return <span className="text-primary cursor-pointer" onClick={() => navigateTo(ClientNavigation.CONTRACTOR_CARD, row.contractor)}>{constructCompanyName(row.contractor)}</span>;
      })
    },
    {
      dataField: 'bid',
      text: 'Bid',
      headerAlign: 'center',
      align: 'right',
      formatter: ((cell, row) => <span className='text-light'>{audFormatter.format(row.amount)}</span>)
    },
    {
      dataField: 'variation',
      text: 'Variation',
      headerAlign: 'center',
      align: 'right',
      formatter: ((cell, row) => <span className='text-warning'>{audFormatter.format(calcVariation(row.sow))}</span>)
    },
    {
      dataField: 'value',
      text: 'Value',
      headerAlign: 'center',
      align: 'right',
      formatter: ((cell, row) => <span className='text-primary'>{audFormatter.format(row.amount + calcVariation(row.sow))}</span>)
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '90px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
    {
      dataField: 'ref',
      text: 'Ref',
      headerAlign: 'center',
      align: 'center',
    },  
  ]

  const actionColumns = [
    ...columns,
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '60px' }),
      formatter: ((cell, row) => { 
        return <Dropdown onSelect={(action) => handleAwardAction(row, action)}>
          <Dropdown.Toggle as="span" className="cursor-pointer toggle-hidden">
            <MdMoreVert size={18}></MdMoreVert>
          </Dropdown.Toggle>
          <Dropdown.Menu>
          {authState.user.id !== row.contractorId && <Dropdown.Item eventKey={AwardAction.ADD_CONTACT}><i className="i-Add-User"> </i>Add Contact</Dropdown.Item>}
          <Dropdown.Item eventKey={AwardAction.ORIGINAL_SOW}><i className="i-Check"> </i>Original SOW</Dropdown.Item>
          {row.status !== BidStatus.DECLINED && <Dropdown.Item eventKey={AwardAction.ADDITIONAL_SOW}><i className="i-Check"> </i>Additional SOW</Dropdown.Item>}
          <div className="dropdown-divider"></div>
          {row.status !== BidStatus.DECLINED && <Dropdown.Item eventKey={AwardAction.DECLINE}><i className="i-Close-Window text-danger"> </i>Decline Trade</Dropdown.Item>}
          {row.status === BidStatus.DECLINED && <Dropdown.Item eventKey={AwardAction.DELETE_BID}><i className="i-Close-Window text-danger"> </i>Remove Contractor</Dropdown.Item>}
        </Dropdown.Menu>
        </Dropdown>
      })
    }  
  ];

  const navigateTo = (pathname, contractor) => {
    
    switch(pathname) {
      case ClientNavigation.CONTRACTOR_CARD:
        pathname = pathname.replace(':contractorId', contractor.id);
    }
    history.push({ pathname: pathname });  
  }

  return (
    <>
      <ToolkitProvider
        keyField='ref'
        data={awardRegister}
        columns={readOnly ? columns : actionColumns}
        search
        exportCSV
      >
        {toolkitProps => (
          <>
            <div className='d-flex justify-content-end align-items-center'>
              
              <span className='mb-2 mr-1'>Search:</span>
              <SearchBar {...toolkitProps.searchProps} className='mb-0 ' />
              <ClearSearchButton { ...toolkitProps.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
              <ReloadButton onClick={handleRefresh} />
              {!readOnly && <span className='mb-2'>
                <SendMessageWidget recipients={selectedTrades} />
              </span>}
            </div>
            <BootstrapTable
              {...toolkitProps.baseProps}
              bootstrap4
              wrapperClasses="table-responsive"
              pagination={paginationFactory({ ...paginationOptions, totalSize: awardRegister.length })}
              noDataIndication={'No records found'}
              condensed
              defaultSorted={[{ dataField: 'trade.text', order: 'asc'}]}
              selectRow={ selectRow }
            />
          </>
        )}
      </ToolkitProvider>

      <NotificationContainer />
    </>
  );
}
