import React from 'react';
import { NotificationContainer } from 'react-notifications';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { paginationOptions, StatusClass, TradeStatus, UserGroup, TradeAction } from 'app/utils/constants';
import { formatDate } from 'app/utils/time';
import { Dropdown, Badge } from 'react-bootstrap';
import { MdMoreVert} from 'react-icons/md';
import { useAuthState } from 'app/auth';

export const TradesWidget = ({ title, trades, readOnly, showExport, deleteTrade, approveTrade, restoreTrade, onAddTrade, handleTradeAction }) => {

  const { SearchBar, ClearSearchButton } = Search;
  const authState = useAuthState();

  let columns = [
    {
      dataField: 'text',
      text: title ?? 'Trade',
      headerAlign: 'center',
      sort: true,
    },
    {
      dataField: 'category',
      text: 'Category',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '120px' })
    },
    {
      dataField: 'awarded',
      text: 'Awarded',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '100px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.awarded?.toUpperCase()]}>{row.awarded}</Badge>
      })
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '100px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status?.toUpperCase()]}>{row.status}</Badge>
      })
    },
    {
      dataField: 'updated',
      text: 'Modified',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '100px' }),
      formatter: ((cell, row) => formatDate(row.updated))
    },
  ];

  if (!readOnly) {
    columns = [
      ...columns,
      {
        dataField: 'id',
        text: 'Action',
        headerAlign: 'center',
        align: 'center',
        headerStyle: () => ({ width: '80px' }),
        formatter: ((cell, row) => { 
          return <Dropdown onSelect={action => handleTradeAction(row, action)}>
            <Dropdown.Toggle as="span" className="cursor-pointer toggle-hidden">
              <MdMoreVert size={18}></MdMoreVert>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {row.status === TradeStatus.ADDING && authState.user.userGroup === UserGroup.ADMIN && 
                <Dropdown.Item onClick={() => approveTrade(row.id)}><i className="i-Like text-success"> </i>Approve</Dropdown.Item>
              }
              {row.status === TradeStatus.REMOVING && authState.user.userGroup === UserGroup.ADMIN && <Dropdown.Item onClick={() => approveTrade(row.id)}><i className="i-Like text-danger"> </i>Approve</Dropdown.Item>}
              {row.status === TradeStatus.REMOVING && authState.user.userGroup === UserGroup.ADMIN && <Dropdown.Item onClick={() => restoreTrade(row.id)}><i className="i-Reload text-success"> </i>Restore trade</Dropdown.Item>}
              {row.status !== TradeStatus.REMOVING && row.status !== TradeStatus.DELETED && <Dropdown.Item onClick={() => deleteTrade(row.id)}><i className="i-Close-Window text-danger"> </i>Remove trade</Dropdown.Item>}
            
              {(row.status === TradeStatus.REMOVING || row.status === TradeStatus.DELETED) &&
                <Dropdown.Item eventKey={TradeAction.VIEW_DELETE_REASON}><i className='i-Receipt-3 text-info'> </i>View reason</Dropdown.Item>
              }
            </Dropdown.Menu>
          </Dropdown>
        })
      }
    ]
  }

  return (
    <>
      <ToolkitProvider
        keyField='id'
        data={trades}
        columns={columns}
        search
        exportCSV
      >
        {toolkitProps => (
          <>
            <div className='d-flex justify-content-end align-items-center'>
              
              <span className='mb-2 mr-1'>Search:</span>
              <SearchBar {...toolkitProps.searchProps} className='mb-0 ' />
              <ClearSearchButton { ...toolkitProps.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
              {showExport && <button className='btn btn-outline-success mb-2 mr-1' onClick={_ => toolkitProps.csvProps.onExport()}>Export CSV</button>}
              {!readOnly && <button className='btn btn-primary mb-2 mr-1' onClick={() => onAddTrade()}>Add {title}</button>}
            </div>
            <BootstrapTable
              {...toolkitProps.baseProps}
              bootstrap4
              wrapperClasses="table-responsive"
              pagination={paginationFactory({ ...paginationOptions, totalSize: trades.length })}
              noDataIndication={'No records found'}
              condensed
              defaultSorted={[{ dataField: 'text', order: 'asc'}]}
            />
          </>
        )}
      </ToolkitProvider>

      <NotificationContainer />
    </>
  );
}
