import * as yup from 'yup';
import { parse, isDate } from "date-fns";

const projectSchema = yup.object().shape({

  projectName: yup.string().required('Project name is required'),
  projectDetails: yup.string().required('Project details are required'),
  classification: yup.string().required('Classification is required'),
  sector: yup.string().when('classification', {
    is: 'Commercial',
    then: yup.string().required('Sector is required')
  }),

  budget: yup.string().required('Budget is required'),
  inspectionRequired: yup.string().required('Please answer Yes or No'),  
  inspectionRequiredOn: yup.date()
    .nullable()
    .transform(parseDateString)
    .typeError('Invalid date format. Use 24 hours DD/MM/YYYY HH:mm format')
    .min(new Date(), 'Must be future date'),
  tenderRequiredBy: yup.date()
    .nullable()
    .transform(parseDateString)
    .typeError('Invalid date format. Use 24 hours DD/MM/YYYY HH:mm format')
    .min(new Date(), 'Must be future date'),
  establishmentRequired: yup.string().required('Please answer Yes or No'),
  establishmentOrganizer: yup.string().when('establishmentRequired', {
    is: 'yes',
    then: yup.string().required('Site Organizer is required')
  }),
  receivedPermit: yup.string().required('Please answer Yes or No'),
  lodgedDA: yup.string().required('Please answer Yes or No'),
  projectLocation: yup.object().required('Project address is required'),

  trades: yup.array().min(1, 'Enter one or more items'),
});

function parseDateString(value, originalValue) {

  const parsedDate = isDate(originalValue)
  ? originalValue
  : parse(originalValue, "dd/MM/yyyy HH:mm", new Date());

  return parsedDate;
}

export default projectSchema;