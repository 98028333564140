import HttpService from 'app/services/httpService';
import userService from 'app/user/userService';

class chatService {

    http = new HttpService();

    setChatAsRead =  (userId, opponentId) => {
      return userService.setChatAsRead(userId, opponentId);
    };

    getUserChat =  (userId, opponentId) => {
      return userService.getUserChat(userId, opponentId);
    };

    getUserContact =  (userId, contactId) => {
      return userService.getUserContact(userId, contactId);
    };
  
    getUserContacts = (userId) => {
      return userService.getUserContacts(userId);
    };

    getUserChatContacts = userId => {
    
      return userService.getUserChatContacts(userId);
    };
    
    deleteMessage = message => {
    
      return this.http.post("/api/chat/delete", message);
    };
    
    updateMessage = message => {
      
      return this.http.post("/api/chat/update", message);
    };
}

export default new chatService();