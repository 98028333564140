import React, { useReducer, useContext } from "react";
import { NotificationReducer, initialState } from './notificationReducer';

const NotificationStateContext = React.createContext();
const NotificationDispatchContext = React.createContext();

export function useNotificationState() {

    const context = useContext(NotificationStateContext);
    if (context === undefined) {
      throw new Error("NotificationStateContext must be used within a NotificationProvider");
    }
    return context;
};
   
export function useNotificationDispatch() {

    const context = useContext(NotificationDispatchContext);
    if (context === undefined) {
      throw new Error("NotificationDispatchContext must be used within a NotificationProvider");
    }
    return context;
};

export const NotificationProvider = ({ children }) => {
    
  const [notificationState, dispatch] = useReducer(NotificationReducer, initialState);

  return (
    <NotificationStateContext.Provider value={notificationState}>
      <NotificationDispatchContext.Provider value={dispatch}>
        {children}
      </NotificationDispatchContext.Provider>
    </NotificationStateContext.Provider>
  );
};