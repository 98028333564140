import * as yup from 'yup';
import * as moment from 'moment';
import { parse, isDate } from "date-fns";

const maxLength = 50;
const maxLengthError = `Maximum allowed characters is ${maxLength}`;

export const adminSchema = yup.object().shape({

  businessName: yup.string().test('len', maxLengthError, val => val ? val.length <= maxLength : true),
  ABN: yup.string().test('len', 'Must be 11 digits', val => val ? val.length === 11 : true),
  businessPhone: yup.string().test('len', 'Must be 10 digits', val => val ? val.length === 10 : true),
  businessFax: yup.string().test('len', 'Must be 10 digits', val => val ? val.length === 10 : true),
  businessEmail: yup.string().email('Invalid email').test('len', maxLengthError, val => val ? val.length <= maxLength : true),
  website: yup.string().url('https://www.iwtb.com.au').test('len', maxLengthError, val => val ? val.length <= maxLength : true),
});

export const userSchema = yup.object().shape({

  trades: yup.array().min(1, 'Enter one or more items'),
  mobile: yup.string().required('Mobile is required').test('len', 'Must be 10 digits', val => val ? val.length === 10 : false),
  firstName: yup.string().required('First name is required').test('len', maxLengthError, val => val ? val.length <= maxLength : false),
  lastName: yup.string().required('Surname is required').test('len', maxLengthError, val => val ? val.length <= maxLength : false),
  DOB: yup.date()
    .required('Date of birth is required')
    .transform(parseDateString)
    .typeError('Invalid date format. Use DD/MM/YYYY format')
    .max(moment().add(-18, 'years').toDate(), 'You must be at least 18'),
  role: yup.string().required('Role is required').test('len', maxLengthError, val => val ? val.length <= maxLength : false),
  email: yup.string().email('Invalid email').required('Email is required').test('len', maxLengthError, val => val ? val.length <= maxLength : false),
  // industry: yup.string().required('Industry is required'),
  // beenBankrupt: yup.string().required('Please answer Yes or No'),

  // not required fields

  title: yup.string().test('len', 'Maximum allowed characters is 10', val => val ? val.length <= 10 : true),
  about: yup.string().test('len', maxLengthError, val => val ? val.length <= maxLength : true),
  businessName: yup.string().test('len', maxLengthError, val => val ? val.length <= maxLength : true),
  ABN: yup.string().test('len', 'Must be 11 digits', val => val ? val.length === 11 : true),
  businessPhone: yup.string().test('len', 'Must be 10 digits', val => val ? val.length === 10 : true),
  businessFax: yup.string().test('len', 'Must be 10 digits', val => val ? val.length === 10 : true),
  businessEmail: yup.string().email('Invalid email').test('len', maxLengthError, val => val ? val.length <= maxLength : true),
  website: yup.string().url('https://www.iwtb.com.au').test('len', maxLengthError, val => val ? val.length <= maxLength : true),
  businessYearsOperating: yup.number().positive('Must be greater than or equal to 0').integer('Must be an integer'),
  businessReferences: yup.string().test('len', maxLengthError, val => val ? val.length <= maxLength : true),
  
});

function parseDateString(value, originalValue) {

  const parsedDate = isDate(originalValue)
  ? originalValue
  : parse(originalValue, "dd/MM/yyyy", new Date());

  return parsedDate;
}