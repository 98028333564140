import React from 'react'
import swal from 'sweetalert2'
import { Breadcrumb } from '@gull'
import { SimpleCard } from '@gull'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { paginationOptions, ProjectStatus, StatusClass } from 'app/utils/constants'
import { MdMoreVert} from 'react-icons/md'
import { Dropdown, Button, Modal, Badge } from 'react-bootstrap'
import { useAuthState } from 'app/auth'
import { formatDate } from 'app/utils/time'
import { ProjectAction } from '../constants'
import { useSpinner } from 'app/IwtbLayout/SharedComponents/spinner/SpinnerContext'
import adminService from '../adminService';

export const DeclinedProjects = () => {

  const { SearchBar, ClearSearchButton } = Search
  const [projects, setProjects] = React.useState([])
  const authState = useAuthState()
  const spinner = useSpinner()
  const [showMessage, setShowMessage] = React.useState({
    show: false,
    title: '',
    message: '',
    project: null
  })  

  const columns = [
    {
      dataField: 'projectName',
      text: 'Project',
      headerAlign: 'center',
      sort: true,
    },
    {
      dataField: 'classification',
      text: 'Classification',
      headerAlign: 'center',
    },
    {
      dataField: 'sector',
      text: 'Sector',
      headerAlign: 'center',
    },
    {
      dataField: 'budget',
      text: 'Budget',
      headerAlign: 'center',
      align: 'center',
    },
    {
      dataField: 'revision',
      text: 'Rev',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '50px' })
    },
    {
      dataField: 'created',
      text: 'Added',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => { return { width: '90px' }},
      formatter: ((cell, row) => formatDate(row.created))
    },
    {
      dataField: 'tenderRequiredBy',
      text: 'Due',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => { return { width: '90px' }},
      formatter: ((cell, row) => formatDate(row.tenderRequiredBy))
    },
    {
      dataField: 'ref',
      text: 'Ref',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '90px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
    {
      dataField: 'Action',
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => { return { width: '60px' }},
      formatter: ((cell, row) => { 

        return <Dropdown onSelect={action => actionProject(action, row)}>
          <Dropdown.Toggle as='span' className='cursor-pointer toggle-hidden'>
            <MdMoreVert size={18}></MdMoreVert>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey={ProjectAction.VIEW_REASON}><i className='i-Receipt-3 text-info'> </i>View reason</Dropdown.Item>
            <Dropdown.Item eventKey={ProjectAction.DELETE_PROJECT}><i className='i-Close-Window text-danger'> </i>Delete project</Dropdown.Item>
        </Dropdown.Menu>
        </Dropdown>
      })
  }]

  const loadProjects = React.useCallback(clientId => {

    spinner.show()
      
    adminService.getDeclinedProjects(authState.user.id)
    .then(result => {
      if (result.error) {
        NotificationManager.error(result.message, 'Server Connection', 3000)      
      }
      else {
        const items = result.map((item, i) => { return {...item, index: i + 1 }})
        setProjects(items) 
      }
    })
    .catch(e => { NotificationManager.error('Something went wrong.', 'Server Connection', 3000) })
    .finally(() => {
      spinner.hide()
    })
  })

  React.useEffect(() => {
  
    loadProjects()
  }, [])

  const deleteProject = (project) => {

    swal.fire({
      title: 'Are you sure?',
      text: 'You would not be able to revert this!',
      icon: 'warning',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete project',
      cancelButtonText: 'No'
    })
    .then(result => {
      if (result.value) {
        adminService.deleteProject(authState.user.id, project.id)
        .then(result => {
          loadProjects()
          swal.fire('Project was Deleted!', 'Your project has been deleted.', 'success')
        })
        .catch(e => { NotificationManager.error('Something went wrong.', 'Server Connection', 3000) })
      }
    })
  }

  const actionProject = (action, project) => {

    switch(action) {

      case ProjectAction.DELETE_PROJECT:
        return deleteProject(project)

      case ProjectAction.VIEW_REASON:

        if (project.status === ProjectStatus.CANCELED) {
          setShowMessage({
            show: true,
            title: 'Reason',
            message: project.cancelReason || 'Unspecified',
            project: project,
          })
        }
        else if (project.status === ProjectStatus.DECLINED) {
          setShowMessage({
            show: true,
            title: 'Reason',
            message: project.rejectReason || 'Unspecified',
            project: project,
          })
        }
        break
    }
  }

  const handleHideMessage = () => {

    setShowMessage({
      show: false,
      title: '',
      message: ''
    })
  }

  return (
    <div>
      <Breadcrumb routeSegments={[{ name: 'Canceled & Declined Projects' }]}></Breadcrumb>

      <div className='row'>
          <div className='col-md-12'>
            <SimpleCard>
              <ToolkitProvider
                keyField='id'
                data={projects}
                columns={columns}
                search
                exportCSV
              >
                {props => (
                  <>
                    <div className='d-flex justify-content-end align-items-center'>
                    
                      <span className='mb-2 mr-1'>Search:</span>
                      <SearchBar {...props.searchProps} className='mb-0 ' />
                      <ClearSearchButton { ...props.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
                      <button className='btn btn-outline-success mb-2 mr-1' onClick={_ => props.csvProps.onExport()}>Export CSV</button>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4
                      wrapperClasses="table-responsive"
                      pagination={paginationFactory({ ...paginationOptions, totalSize: projects.length })}
                      // overlay={ overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(255, 0, 0, 0.5)'}) } }) }
                      noDataIndication={'No projects found'}
                      condensed
                    />
                  </>
                )}
              </ToolkitProvider>

            </SimpleCard>
        </div>
      </div>

      <Modal show={showMessage.show} onHide={handleHideMessage} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title>{showMessage.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{showMessage.message}</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleHideMessage}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <NotificationContainer />
    </div>
  )
}
