import { ContractorNavigation } from './contractorRoutes';

export const contractorMenu = [
  {
    name: "Tendering",
    description: "",
    type: "link",
    icon: "i-Loading-3",
    path: ContractorNavigation.TENDERING_PROJECTS
  },
  {
    name: "Requests",
    description: "",
    type: "link",
    icon: "i-Hand",
    path: ContractorNavigation.ACCESS_REGISTER
  },
  {
    name: "My Projects",
    description: "",
    type: "link",
    icon: "i-Gear-2",
    path: ContractorNavigation.CONTRACTOR_PROJECTS
  },
  {
    name: "Awards",
    description: "",
    type: "link",
    icon: "i-Medal-3",
    path: ContractorNavigation.AWARDED_BIDS
  },
  {
    name: "Communication",
    description: "Communication",
    type: "link",
    icon: "i-Speach-Bubbles",
    path: ContractorNavigation.COMMUNICATION
  },
  {
    name: "Contact Us",
    description: "Contact our support.",
    type: "link",
    icon: "i-Paper-Plane",
    path: ContractorNavigation.CONTACT_US
  },
];
