import React from 'react';
import { userSchema } from './userSchema';
import { Formik, Form } from 'formik';
import profileService from './profileService';
import { NotificationManager } from "react-notifications";
import { useAuthState } from 'app/auth';
import { AllowedFileTypes, ProfileFileCategories } from 'app/utils/constants';
import { IwtbFileUpload } from 'app/widgets';

export const UserEditFilesWidget = ({ user }) => {

  const authState = useAuthState();
  
  const handleUploadedFiles = (files, values, setFieldValue, setSubmitting) => {

    setSubmitting(true);
    setFieldValue('files', files);

    profileService.updateProfile(authState.user.id, { ...values, files: files })
    .then(result => {
      NotificationManager.success('Your profile was updated', 'Profile', 2000);
      setSubmitting(false);      
    })
    .catch(ex => {  
      setSubmitting(false);      
      NotificationManager.error('Something went wrong', 'Profile', 3000);
    });
  };

  return ( <>
    {user && 
        <Formik initialValues={user} enableReinitialize={true} validationSchema={userSchema}>
        {({
          values,
          handleSubmit,
          setFieldValue,
          setSubmitting
        }) => {
          return (
            <Form className='needs-validation' onSubmit={handleSubmit} noValidate>
              <IwtbFileUpload allowedTypes={AllowedFileTypes} categories={ProfileFileCategories} uploadedFiles={user.files ?? []} setUploadedFiles={files => handleUploadedFiles(files, values, setFieldValue, setSubmitting)} />
            </Form>
          );
        }}
      </Formik>
    }
    </>
  );
}

