import React, { useState, useEffect } from 'react';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Badge, Dropdown } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { paginationOptions, audFormatter, StatusClass, BidStatus } from 'app/utils/constants';
import { AwardAction } from '../constants';
import { constructCompanyName } from 'app/utils/common';
import { AdminNavigation } from '../adminRoutes';
import { useHistory } from "react-router-dom";
import { MdMoreVert} from "react-icons/md";
import { calcVariation } from 'app/utils/common';
import { SendMessageWidget } from "app/chat";
import { useAuthState } from 'app/auth';

export const AwardRegisterWidget = ({ awardRegister, handleAwardAction, readOnly, showExport }) => {

  const authState = useAuthState();
  const history = useHistory();
  const [ selectedTrades, setSelectedTrades ] = useState([]);
  const { SearchBar, ClearSearchButton } = Search;

  const [ columns, setColumns ] = useState([
    {
      dataField: 'trade.text',
      text: 'Trade',
      headerAlign: 'center',
      sort: true,
    },
    {
      dataField: 'company',
      text: 'Company',
      headerAlign: 'center',
      sort: true,
      formatter: ((cell, row) => {
        return <span className="text-primary cursor-pointer" onClick={() => navigateTo(AdminNavigation.CONTRACTOR_CARD, row.contractor)}>{constructCompanyName(row.contractor)}</span>;
      })
    },
    {
      dataField: 'bid',
      text: 'Bid',
      headerAlign: 'center',
      align: 'right',
      formatter: ((cell, row) => <span className='text-light'>{audFormatter.format(row.amount)}</span>)
    },
    {
      dataField: 'variation',
      text: 'Variation',
      headerAlign: 'center',
      align: 'right',
      formatter: ((cell, row) => <span className='text-warning'>{audFormatter.format(calcVariation(row.sow))}</span>)
    },
    {
      dataField: 'value',
      text: 'Value',
      headerAlign: 'center',
      align: 'right',
      formatter: ((cell, row) => <span className='text-primary'>{audFormatter.format(row.amount + calcVariation(row.sow))}</span>)
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '90px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
  ]);

  useEffect(() => { 

    if (!readOnly) {
      const actionColumns = [
        {
          dataField: 'id',
          text: '',
          headerAlign: 'center',
          align: 'center',
          headerStyle: () => ({ width: '40px' }),
          formatter: ((cell, row) => <input type="checkbox" onClick={event => handleSelectionChanged(event.target.checked, row)} />)
        },  
        ...columns,
        {
          dataField: 'action',
          text: 'Action',
          headerAlign: 'center',
          align: 'center',
          headerStyle: () => ({ width: '60px' }),
          formatter: ((cell, row) => { 
            return <Dropdown onSelect={(action) => handleAwardAction(row, action)}>
              <Dropdown.Toggle as="span" className="cursor-pointer toggle-hidden">
                <MdMoreVert size={18}></MdMoreVert>
              </Dropdown.Toggle>
              <Dropdown.Menu>
              {authState.user.id !== row.contractorId && <Dropdown.Item eventKey={AwardAction.ADD_CONTACT}><i className="i-Add-User"> </i>Add Contact</Dropdown.Item>}
              <Dropdown.Item eventKey={AwardAction.ORIGINAL_SOW}><i className="i-Check"> </i>Original SOW</Dropdown.Item>
              {row.status !== BidStatus.DECLINED && <Dropdown.Item eventKey={AwardAction.ADDITIONAL_SOW}><i className="i-Check"> </i>Additional SOW</Dropdown.Item>}
              <div className="dropdown-divider"></div>
              {row.status !== BidStatus.DECLINED && <Dropdown.Item eventKey={AwardAction.DECLINE}><i className="i-Close-Window text-danger"> </i>Decline Trade</Dropdown.Item>}
              {row.status === BidStatus.DECLINED && <Dropdown.Item eventKey={AwardAction.DELETE_BID}><i className="i-Close-Window text-danger"> </i>Remove Contractor</Dropdown.Item>}
            </Dropdown.Menu>
            </Dropdown>
          })
        }  
      ];
      setColumns(actionColumns);
    }
  }, [readOnly]);

  const navigateTo = (pathname, contractor) => {
    
    switch(pathname) {
      case AdminNavigation.CONTRACTOR_CARD:
        pathname = pathname.replace(':contractorId', contractor.id);
    }
    history.push({ pathname: pathname });  
  }

  const handleSelectionChanged = (selected, trade) => {

    if (!selected) {
      // remove the trade if unselected
      const trades = selectedTrades.find(item => item.id !== trade.id); 
      setSelectedTrades(trades);
    }
    else {
      const trades = selectedTrades.find(item => item.id === trade.id); 
      if (!trades) {
        setSelectedTrades([...selectedTrades, trade]);
      }
    }
  }

  return (
    <>
      <ToolkitProvider
        keyField='ref'
        data={awardRegister}
        columns={columns}
        search
        exportCSV
      >
        {toolkitProps => (
          <>
            <div className='d-flex justify-content-end align-items-center'>
              
              <span className='mb-2 mr-1'>Search:</span>
              <SearchBar {...toolkitProps.searchProps} className='mb-0 ' />
              <ClearSearchButton { ...toolkitProps.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
              {showExport && <button className='btn btn-outline-success mb-2 mr-1' onClick={_ => toolkitProps.csvProps.onExport()}>Export CSV</button>}
              {!readOnly && <span className='mb-2'>
                <SendMessageWidget recipients={selectedTrades} />
              </span>}
            </div>
            <BootstrapTable
              {...toolkitProps.baseProps}
              bootstrap4
              wrapperClasses="table-responsive"
              pagination={paginationFactory({ ...paginationOptions, totalSize: awardRegister.length })}
              noDataIndication={'No records found'}
              condensed
              defaultSorted={[{ dataField: 'trade.text', order: 'asc'}]}
            />
          </>
        )}
      </ToolkitProvider>
    </>
  );
}
