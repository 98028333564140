import React from "react";
import { Breadcrumb } from '@gull';
import { ProjectsWidget } from '.'
import { ProjectStatus } from "app/utils/constants";

export const PendingProjects = () => {

  return (<>
    <Breadcrumb
      routeSegments={[
        { name: "Projects", path: '' },
        { name: 'Pending Projects' }
      ]}
    ></Breadcrumb>
    <ProjectsWidget projectStatus={ProjectStatus.PENDING} />
  </>
  );
}
