import React from "react";
import { Redirect } from 'react-router-dom';
import { NewProject } from './project/NewProject';
import { Projects } from './project/Projects';
import { DeclinedProjects } from './project/DeclinedProjects';
import { EditProject } from './project/EditProject';
import { AccessRegister } from './project/AccessRegister';
import { ProjectCard } from './project/ProjectCard';
import { ContractorCard } from '../profile/ContractorCard';
import { PageNotFound } from 'app/error'
import { AuthRole, AuthGuard } from 'app/auth';
import { RfiView } from "./rfi";
import { RequestSow } from "./sow";
import { BidCard } from "./bid";
import { UserContacts } from "app/user";
import { MyProfile } from "app/profile";
import { Chat } from 'app/chat';
import { ContactUs } from 'app/pages'
import { SubmitBid } from './bid'
import legalRoutes from 'app/pages/legal/legalRoutes';

export const ClientNavigation = {
    PROJECTS:           '/clients/:id/projects',
    DECLINED_PROJECTS:  '/clients/:id/declined',
    NEW_PROJECT:        '/clients/:id/projects/new',
    EDIT_PROJECT:       '/clients/:id/projects/:projectId/edit',
    REQUEST_SOW:        '/clients/:id/projects/:projectId/bids/:bidId/sow',
    BID_CARD:           '/clients/:id/projects/:projectId/bids/:bidId/tabs/:tab?',
    SUBMIT_BID:         '/clients/:id/projects/:projectId/bids',
    PROJECT_CARD:       '/clients/:id/projects/:projectId/:tab?',
    CONTACTS:           '/clients/:id/contacts',
    VIEW_RFI:           '/clients/:id/rfi/view',
    ACCESS_REGISTER:    '/clients/:id/access',
    CONTRACTOR_CARD:    '/clients/:id/contractors/:contractorId',
    COMMUNICATION:      '/clients/:id/chats',
    CHAT:               '/clients/:id/chats/:opponentId?',
    USER_PROFILE:       '/clients/:id/profile/:tab?',
    CONTACT_US:         '/contact-us',
}

const routes = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to={ ClientNavigation.USER_PROFILE } />
    },
    {
        path: ClientNavigation.CONTACT_US,
        component: ContactUs,
    },
    {
        path: ClientNavigation.PROJECTS,
        exact: true,
        component: Projects,
        auth: AuthRole.CLIENT,
    },
    {
        path: ClientNavigation.DECLINED_PROJECTS,
        exact: true,
        component: DeclinedProjects,
        auth: AuthRole.CLIENT,
    },
    {
        path: ClientNavigation.NEW_PROJECT,
        component: NewProject,
        auth: AuthRole.CLIENT,
    },
    {
        path: ClientNavigation.EDIT_PROJECT,
        component: EditProject,
        auth: AuthRole.CLIENT,
    },
    {
        path: ClientNavigation.REQUEST_SOW,
        exact: true,
        component: RequestSow,
        auth: AuthRole.CLIENT,
    },
    {
        path: ClientNavigation.BID_CARD,
        exact: true,
        component: BidCard,
        auth: AuthRole.CLIENT,
    },
    {
        path: ClientNavigation.SUBMIT_BID,
        component: SubmitBid,
        auth: AuthRole.CLIENT,
    },
    {
        path: ClientNavigation.PROJECT_CARD,
        component: ProjectCard,
        auth: AuthRole.CLIENT,
    },
    {
        path: ClientNavigation.VIEW_RFI,
        component: RfiView,
        auth: AuthRole.CLIENT,
    },
    {
        path: ClientNavigation.CONTACTS,
        component: UserContacts,
        auth: AuthRole.CLIENT,
    },
    {
        path: ClientNavigation.ACCESS_REGISTER,
        component: AccessRegister,
        auth: AuthRole.CLIENT,
    },
    {
        path: ClientNavigation.CONTRACTOR_CARD,
        component: ContractorCard,
        auth: AuthRole.CLIENT,
    },
    {
        path: ClientNavigation.CHAT,
        component: Chat,
        auth: AuthRole.CLIENT,
    },
    {
        path: ClientNavigation.COMMUNICATION,
        component: Chat,
        auth: AuthRole.CLIENT,
    },
    {
        path: ClientNavigation.USER_PROFILE,
        component: MyProfile,
        auth: AuthRole.CLIENT,
    },
    {
        path:'/*',
        component: PageNotFound
    },
];
  
export const clientRoutes = [{
    path: '/',
    component: AuthGuard,
    routes: [
    ...legalRoutes,
    ...routes,
    ]
}];
