import React, { useState, useEffect } from 'react';
import { Button, Card, Row, Col, ProgressBar } from 'react-bootstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { uploadFile, deleteBlobFile } from 'app/utils/common';
import { IwtbFilePreview } from '.';
import { FileCategories } from 'app/utils/constants';
import { IwtbMultiSelect } from 'app/widgets';
import LaddaButton, { EXPAND_RIGHT } from "react-ladda";

export const IwtbFileUpload = ({ id, label, ...props }) => {

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dragClass, setDragClass] = useState('');
  const [isEmpty, setIsEmpty] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [allUploaded, setAllUploaded] = useState(false);
  const [previewFile, setPreviewFile] = useState();
  const [category, setCategory] = useState();
  const [options, setOptions] = useState(FileCategories);
  
  let uploadedFiles;

  useEffect(() => {

    if (props.categories) {
      setOptions(props.categories);
    }
    if (props.category) {
      setCategory(props.category);
    }
    if (props.uploadedFiles) {
      uploadedFiles = props.uploadedFiles.map(file => {
        return {
          file: file,
          uploading: false,
          uploaded: true,
          error: false,
          progress: 100
        }
      });
      setSelectedFiles(uploadedFiles);
      setIsEmpty(props.uploadedFiles.length === 0);
    }  
  }, []);

  const handleFileSelect = files => {

    let list = [...selectedFiles];

    for (const file of files) {
      file.category = category;
      list.push({
        file: file,
        uploading: false,
        uploaded: false,
        error: false,
        progress: 0
      });
    }
    setSelectedFiles([...list]);
    setIsEmpty(list.length === 0);
    setAllUploaded(false)
  };

  const handleDragOver = event => {

    event.preventDefault();
    setDragClass('drag-shadow');
  };

  const handleDrop = event => {

    event.preventDefault();
    event.persist();

    handleFileSelect(event.dataTransfer.files);
    setDragClass('');

    return false;
  };

  const handleViewFile = index => {

    setPreviewFile(); // reset preview

    let url;
    let files = [...selectedFiles];
    const fileInfo = files[index];
    setTimeout(() => setPreviewFile(fileInfo.file), 0);
  };

  const handleSingleRemove = index => {

    let files = [...selectedFiles];
    const file = files[index];
    files.splice(index, 1);
    setSelectedFiles([...files])
    setIsEmpty(files.length === 0);
    if (file.uploaded) {
      deleteBlobFile(file.blobName);
      setUploadedFiles(files);
    }
  };

  const handleAllRemove = () => {

    let files = [...selectedFiles];
    files.map(file => {
      if (file.uploaded) {
        deleteBlobFile(file.blobName);
      }  
    });
    setUploadedFiles([]);
    setSelectedFiles([]);
    setIsEmpty(true);
  };

  const handleCategoryChanged = category => {

    setCategory(category);
  }

  const uploadAllFile = (event) => {

    event.stopPropagation()

    let allFiles = [];

    selectedFiles.map(item => {
      allFiles.push({
        ...item,
        uploading: true,
        error: false
      });
      return item;
    });

    setSelectedFiles([...allFiles]);
    setIsEmpty(allFiles.length === 0);
    handleUpload([...allFiles]);
  };

  const setUploadedFiles = (files) => {

    // get uploaded files
    let uploadedFiles = files.filter(i => i.uploaded).map(i => { 
      return { 
        name: i.file.name, 
        blobName: i.file.blobName, 
        category: i.file.category, 
        size: i.file.size,
        type: i.file.type,
      }});
      
    // notify parent with uploaded files
    props.setUploadedFiles(uploadedFiles);
  };

  const handleUpload = files => {

    if( files && files.length > 0) { 

      setIsUploading(true)

      const promises = files.map((item, index) => new Promise((resolve) => {

        if (item.uploading && !item.uploaded) {

          uploadFile(item.file).then(blobName => {
            if (!blobName.error) {
              item.error = false;
              item.uploaded = true;
              item.uploading = false;
              item.progress = 100;
              item.file.blobName = blobName;

              files[index] = item;
              setSelectedFiles([...files]);
              setUploadedFiles([...files]);  
            }
            else {
              item.error = true;
              item.uploading = false;
              item.progress = 0;

              files[index] = item;
              setSelectedFiles([...files]);
              }
            resolve(item)
          })
        }
        else {
          resolve(item)
        }
      }));

      Promise.allSettled(promises).then(results => {

        let uploadError = false
        let errorCount = 0

          results.forEach(result => {
          if (result.value.error || result.status === 'rejected') {
            uploadError = true
            errorCount = errorCount + 1
          }
        })
          if (uploadError) {
            NotificationManager.warning('Something went wrong. Possibly, some files have not been uploaded.', 'File Upload', 3000);
          }
          else {
            NotificationManager.success('Files have been uploaded.', 'File Upload', 3000);
            setAllUploaded(true)
          }
      })
      .catch(() => {
        NotificationManager.warning('Something went wrong. Some files possibly were not been uploaded.', 'File Upload', 3000);
      })
      .finally(() => {
        setIsUploading(false)
      })     
    }
    else {
      NotificationManager.warning('No files were uploaded.', 'File Upload', 3000);
    }
  };

  return (
    <div>

      <div className='d-flex flex-wrap mb-4'>

        {!props.hideCategory && 
          <IwtbMultiSelect name='fileCategory' placeholder='Select Category' singleSelect={true} onChange={categories => handleCategoryChanged(categories[0]) } options={options} isObject={false} />
        }       

        <div className='px-2'></div>

        <label htmlFor={id ?? 'upload-multiple-file'}>
          <Button variant='outline-primary' className='btn-icon mt-1' as='span'>
            <div className='flex flex-middle'>
              <i className='i-Files'> </i>
              <span>Choose Files</span>
            </div>
          </Button>
        </label>
        <input
          className='d-none'
          onChange={e => handleFileSelect(e.target.files)}
          id={id ?? 'upload-multiple-file'}
          type='file'
          accept={props.allowedTypes}
          multiple
        />

      </div>

      <div
        className={`${dragClass} dropzone mb-4 d-flex justify-content-center align-items-center`}
        onDragEnter={ _ => setDragClass({ dragClass: 'drag-shadow' }) }
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {isEmpty ? 
          (<span>Drop your files here</span>) : 
          (<h5 className='m-0'>{selectedFiles.length} file{selectedFiles.length > 1 ? 's' : ''} selected...</h5>)}
      </div>

      <Card className='mb-4'>
        <Row className='align-items-center p-3 d-none d-xl-flex'>
          <Col lg={3} md={3}>
            Name
          </Col>
          <Col lg={3} md={3}>
            Category
          </Col>
          <Col lg={1} md={1}>
            Size
          </Col>
          <Col lg={1} md={1}>
            Progress
          </Col>
          <Col lg={1} md={1}>
            {/* Status */}
          </Col>
          <Col lg={3} md={3}>
            Actions
          </Col>
        </Row>
        <hr className='mt-0 mb-3' />

        {isEmpty && <p className='p-3 py-0'>Queue is empty</p>}

        <div style={{overflowX : 'auto', maxHeight: '400px'}}>
          {selectedFiles.map((item, index) => {
            let { file, uploading, uploaded, error, progress } = item;
            return (
              <Row className='align-items-center px-3' key={file.name}>
                <Col lg={3} md={3} sm={12} xs={12} className='mb-3'>
                  {file.name}
                </Col>
                <Col lg={3} md={3} sm={12} xs={12} className='mb-3'>
                  {file.category}
                </Col>
                <Col lg={1} md={1} sm={12} xs={12} className='mb-3'>
                  {(file.size / 1024 / 1024).toFixed(2)} MB
                </Col>
                <Col lg={1} md={1} sm={12} xs={12} className='mb-3'>
                  <ProgressBar now={progress} variant='success' className='progress-thin'></ProgressBar>
                </Col>
                <Col lg={1} md={1} sm={12} xs={12} className='mb-3'>
                  {error && (
                    <i className='i-Information text-danger text-18'> </i>
                  )}
                </Col>
                <Col lg={3} md={3} sm={12} xs={12} className='mb-3'>
                  <div className='d-flex'>

                    <Button
                      type='button'
                      className='mr-8'
                      variant='outline-primary'
                      onClick={() => handleViewFile(index)}
                    >
                      View
                    </Button>

                    <Button
                      type='button'
                      variant='danger'
                      disabled={isUploading}
                      onClick={() => handleSingleRemove(index)}
                    >
                      Remove
                    </Button>

                  </div>
                </Col>
              </Row>
            );
          })}
        </div>
      </Card>

      <div>
        <div className='flex'>
          <LaddaButton  onClick={uploadAllFile}
            type='button'
            disabled={isEmpty || allUploaded}
            className='btn btn-success position-relative'
            loading={isUploading}
            progress={0.5}
            data-style={EXPAND_RIGHT}
          >Upload All</LaddaButton>

          <Button type='button' disabled={isEmpty || isUploading} className='mx-8' variant='danger' onClick={handleAllRemove}>Remove All</Button>
        </div>
      </div>

      <IwtbFilePreview file={previewFile} />

      <NotificationContainer />
    </div>
  );
};
