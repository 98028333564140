import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useAuthState } from 'app/auth';
import { Formik, Form, ErrorMessage } from 'formik';
import { classList } from '@utils';
import * as yup from 'yup';
import { NotificationManager } from "react-notifications";
import passwordService from './passwordService';
import ReactGA from 'react-ga';

export const ChangePasswordWidget = ({ show, handleClose }) => {

  const authState = useAuthState();

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    rePassword: '',
  }

  const formSchema = yup.object().shape({
    oldPassword: yup.string().required('Old password is required'),
    newPassword: yup.string().required('New password is required'),
    rePassword: yup.string().required('Confirm password is required').when('newPassword', {
      is: val => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf(
        [yup.ref('newPassword')],
        'Both passwords need to be the same'
      )
    }),  
  });

  useEffect(() => {
    
    ReactGA.pageview(window.location.pathname);
  }, []);

  const changePassword = (values, resetForm, setSubmitting) => {
        
    if (values.oldPassword.length === 0 || values.newPassword.length === 0) {
      return
    }
    try {
      ReactGA.event({ category: 'Password', action: 'Change', value: authState.user?.username });
    } catch {}

    setSubmitting(true);
    passwordService.changePassword(authState.user.id, values.oldPassword, values.newPassword).then(result => {
      setSubmitting(false);
      if (result?.error) {
        NotificationManager.error(result.message, 'Change Password', 5000);
      }
      else {
        resetForm()
        NotificationManager.success('Password has been changed', 'Change Password', 2000);
        handleClose();  
      }
    })
    .catch(ex => {
      setSubmitting(false);
      NotificationManager.error('Something went wrong', 'Change Password', 5000);
    });
  };

  return (

    <Formik initialValues={initialValues} validationSchema={formSchema}>
    {({ values, errors, touched, isValid, isSubmitting, handleChange, resetForm, handleBlur, setSubmitting }) => {
      return (
        <Form>
          <Modal show={show} onHide={() => handleClose()} centered={true} size='md'>
            <Modal.Header closeButton>
              <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='row'>
                <div
                  className={classList({
                    'col-md-12 mb-3': true,
                    'valid-field': !errors.oldPassword && touched.oldPassword,
                    'invalid-field': errors.oldPassword && touched.oldPassword,
                  })}
                >
                  <label htmlFor='oldPassword'>Old password</label>
                  <input
                    type='password'
                    className='form-control'
                    id='oldPassword'
                    name='oldPassword'
                    value={values.oldPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  <ErrorMessage name="oldPassword">{msg => <div className='invalid-feedback'>{msg}</div>}</ErrorMessage>
                </div>
              </div>

              <div className='row'>
                <div
                  className={classList({
                    'col-md-12 mb-3': true,
                    'valid-field': !errors.newPassword && touched.newPassword,
                    'invalid-field': errors.newPassword && touched.newPassword,
                  })}
                >
                  <label htmlFor='newPassword'>New password</label>
                  <input
                    type='password'
                    className='form-control'
                    id='newPassword'
                    name='newPassword'
                    value={values.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  <ErrorMessage name="newPassword">{msg => <div className='invalid-feedback'>{msg}</div>}</ErrorMessage>
                </div>
              </div>

              <div className='row'>
                <div
                  className={classList({
                    'col-md-12 mb-3': true,
                    'valid-field': !errors.rePassword && touched.rePassword,
                    'invalid-field': errors.rePassword && touched.rePassword,
                  })}
                >
                  <label htmlFor='rePassword'>Confirm password</label>
                  <input
                    type='password'
                    className='form-control'
                    id='rePassword'
                    name='rePassword'
                    value={values.rePassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  <ErrorMessage name="rePassword">{msg => <div className='invalid-feedback'>{msg}</div>}</ErrorMessage>
                </div>
              </div>

            </Modal.Body>
            <Modal.Footer>
              <button className='btn btn-warning m-1' onClick={() => { resetForm(); handleClose() }}>Cancel</button>
              <button className='btn btn-success m-1 footer-delete-right' onClick={() => changePassword(values, resetForm, setSubmitting)} disabled={!isValid || isSubmitting}>Change Password</button>
            </Modal.Footer>
          </Modal>
        </Form>
      );
    }}
    </Formik>
  );
};
