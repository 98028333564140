import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { constructCompanyName } from 'app/utils/common';
import { formatDateTime12 } from 'app/utils/time';
import { Badge } from "react-bootstrap";
import { StatusClass, AllowedFileTypes } from 'app/utils/constants';
import { IwtbCheckbox, IwtbTextArea, IwtbFileUpload, IwtbFileRegistry } from 'app/widgets';
import { Formik, Form } from 'formik';
import { rfiSchema } from './rfiSchema';
import LaddaButton, { EXPAND_RIGHT } from "react-ladda";
import { NotificationManager } from "react-notifications";
import rfiService from './rfiService';
import { useAuthState } from 'app/auth';

export const RfiWidget = (props) => {
  
  const authState = useAuthState();
  const history = useHistory();
  const route = useLocation();
  const [rfi, setRfi] = useState();
  const [showFileUploader, setShowFileUploader] = useState(false);

  const initialValues = { 
    message: '',
    files: [],
  };

  useEffect(_ => {

    if (props.rfi) {
      setRfi(props.rfi);
    }
  }, [props.rfi]);

  const handleClose = () => {
    history.goBack();
  }

  const handleSubmit = (values, { setSubmitting }) => {
    
    setSubmitting(true);

    const response = {
      message: values.message,
      files: values.files,  
    };

    rfiService.respondRfi(authState.user.id, rfi.projectId, rfi.id, response)
    .then(result => {
      setSubmitting(false);
      NotificationManager.success("RFI response was submitted successfully.", "RFI", 2000);
      setTimeout(_ => history.goBack(), 2000);
    })
    .catch(ex => {
      setSubmitting(false);
      NotificationManager.error("RFI response was not submitted sucessfully", "RFI", 3000);
    });
  };

  return (
    <>
      { rfi && 
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header bg-transparent"><span>Request Status: <Badge className={StatusClass[rfi.status.toUpperCase()]}>{rfi.status}</Badge></span></div>
              <div className="card-body">
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="firstName">Trade</label>
                    <div className="form-control">{rfi.trade.text}</div>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="firstName">Company</label>
                    <div className="form-control">{constructCompanyName(rfi.contractor)}</div>
                  </div>
                </div>
                <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="firstName">Submitted</label>
                    <div className="form-control">{formatDateTime12(rfi.created)}</div>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="firstName">Replied</label>
                    <div className="form-control">{rfi.response ? formatDateTime12(rfi.response.created) : ''}</div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label htmlFor="firstName">Subject</label>
                    <div className="form-control">{rfi.subject}</div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label htmlFor="firstName">Message</label>
                    <textarea rows={6} readOnly className="form-control">{rfi.message}</textarea>          
                  </div>
                </div>

                { rfi.files && rfi.files.length > 0 &&

                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="firstName">Attachments</label>
                      <IwtbFileRegistry files={rfi.files} />
                    </div>
                  </div>
                }

              </div>
  
              <div className="card-footer">
                <div className="mc-footer">
                  <div className="row">
                    <div className="col-md-12">
                      <button type="button" className="btn btn-outline-secondary m-1" onClick={handleClose}>Close</button>
                      <button type="button" className="btn btn-danger m-1 footer-delete-right" disabled>Report</button>
                    </div>
                </div>
              </div>
            </div>
           
           </div>

            <div className="card mt-4">
              <div className="card-header bg-transparent"><h4>Response</h4></div>

              { rfi.response && <>

                <div className="card-body">

                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="firstName">Message</label>
                      <textarea rows={6} readOnly className="form-control">{rfi.response.message}</textarea>          
                    </div>
                  </div>

                  { rfi.response.files && rfi.response.files.length > 0 &&

                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="firstName">Attachments</label>
                        <IwtbFileRegistry files={rfi.response.files} />
                      </div>
                    </div>
                  }           
                </div>

                <div className="card-footer">
                  <div className="mc-footer">
                    <div className="row">
                      <div className="col-md-12">
                        <button type="button" className="btn btn-outline-secondary m-1" onClick={handleClose}>Close</button>
                      </div>
                    </div>
                  </div>
                </div>

              </> }

              { !rfi.response && <>

                <Formik initialValues = {initialValues} validationSchema={rfiSchema} onSubmit={handleSubmit}>
                {({ values, errors, handleSubmit, isSubmitting, isValid, setFieldValue }) => {
                  return (

                    <Form
                      className='needs-validation'
                      onSubmit={handleSubmit}
                      noValidate
                    >

                      <div className="card-body">

                        <div className="form-row">
                          <IwtbTextArea label='Message' name='message' type='text' placeholder='' style={{height: 100}} />
                        </div>
                        <div>
                          <div className='form-row'>
                            <IwtbCheckbox name='showFileUploader' onClick={ e => setShowFileUploader(e.target.checked) }>
                              <span>Upload Files</span>
                            </IwtbCheckbox>
                          </div>
                        </div>

                        { showFileUploader && 
                          <div>
                            <div className='row mt-3'>
                              <div className='col-md-6'>
                                <h5>Upload Files</h5>
                                <div className='mb-3'></div>
                              </div>
                            </div>

                            <div className='col-md-12 mb-5'>
                              <IwtbFileUpload allowedTypes={AllowedFileTypes} setUploadedFiles={files => setFieldValue('files', files)} />
                            </div>
                          </div> 
                        }

                      </div>

                      {!isValid && <div className='form-row mb-3'><span className='text-18 text-danger'>Please check mandatory fields and try again.</span></div>}

                      <div className="card-footer">
                        <div className="mc-footer">
                          <div className="row">
                            <div className="col-md-12">
                              
                              <button type="button" className="btn btn-outline-secondary m-1" onClick={handleClose}>Close</button>
                              <LaddaButton loading={isSubmitting} disabled={!isValid} progress={0.5} data-style={EXPAND_RIGHT}
                                className='btn btn-success border-0 m-1 footer-delete-right'
                              >Submit</LaddaButton>
                            
                            </div>
                          </div>
                        </div>
                      </div>

                    </Form>
                  );
                }}
                </Formik>
                </> }
            </div>
        </div>
      </div> }
    </>
  );
}
