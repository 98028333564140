import React, { useEffect, useState } from "react";
import { Breadcrumb } from '@gull';
import swal from "sweetalert2";
import { Tabs, Tab, Badge } from "react-bootstrap";
import { ContractorNavigation } from '../contractorRoutes';
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useParams, useHistory } from "react-router-dom";
import bidService from './bidService';
import { BidDetailsWidget } from 'app/widgets';
import { useAuthState } from 'app/auth';
import { StatusClass, audFormatter, BidTab, BidStatus } from 'app/utils/constants';
import { formatDateTime12 } from 'app/utils/time';
import { IwtbFileRegistry, SowWidget } from 'app/widgets';
import { calcVariation, getSowBadge, customTabHeader } from 'app/utils/common';
import { ProjectTab } from '../constants';
import { acceptAward } from '../functions';

export const BidCard = () => {

  const { projectId, bidId, tab } = useParams();
  const [defaultActiveKey, setDefaultActiveKey] = useState(BidTab.DETAILS);
  const [variation, setVariation] = useState(0);
  const [bid, setBid] = useState();
  const authState = useAuthState();
  const history = useHistory();
  const [sowBadge, setSowBadge] = useState();

  const getBid = React.useCallback((contractorId, projectId, bidId) => {

    bidService.getBid(contractorId, projectId, bidId)
    .then(result => {
      if (result.error) {
        NotificationManager.error(result.message, 'Server Connection', 3000);      
      }
      else {
        setBid(result);
        const variation = calcVariation(result.sow);
        setVariation(variation);
        
        if (result && result.sow && result.sow.length > 0) {
          const badge = getSowBadge(result.sow[0].status)
          setSowBadge(badge);
        }
      }
    })
    .catch(e => {
      NotificationManager.error(
        'Something went wrong.',
        'Server Connection',
        3000
      );      
    });
  })

  useEffect(() => {

    if (projectId && bidId && authState.isAuthenticated) {
      getBid(authState.user.id, projectId, bidId)
    }
  }, [projectId, bidId, authState.user]);

  useEffect(() => {

    const bidTab = tab === 'undefined' || tab === undefined ? BidTab.DETAILS : tab;
    setDefaultActiveKey(bidTab);
  }, [tab]);

  const handleSowConfirm = sow => {

    swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, confirm SOW',
        cancelButtonText: 'No'
    })
    .then(result => {
      if (result.value) {
        return bidService.confirmSow(authState.user.id, projectId, bidId, sow)
        .then(result => {
          NotificationManager.success('SOW was confirmed successfully.', 'Confirm SOW', 2000);
          setTimeout(() => navigateToProjectDetails(), 1500);
        })
        .catch(ex => {
          NotificationManager.error('Something went wrong.', 'Confirm SOW', 3000);
        });
      }
    });  
  }

  const navigateToRFI = () => {
    
    history.push({
      pathname: ContractorNavigation.SUBMIT_RFI.replace(':projectId', projectId)
    });            
  }

  const navigateToProjectDetails = () => {
    
    history.push({
      pathname: ContractorNavigation.PROJECT_CARD.replace(':projectId', projectId).replace(':tab?', ProjectTab.DETAILS.toLocaleLowerCase())
    });            
  }

  const handleClose = () => {
    
    history.goBack();
  }

  const handleAcceptAward = async () => {
    
    try {
      await acceptAward(authState.user.id, projectId, bidId);
      handleClose()
    }
    catch {
      NotificationManager.error('Sorry, something went wrong.', 'Accept Award', 3000);
    }
  }

  return (
    <div>
      <Breadcrumb
        routeSegments={[
          { name: 'My Projects', path: ContractorNavigation.CONTRACTOR_PROJECTS },
          { name: 'View Trade Bid' }
        ]}
      ></Breadcrumb>

      { bid && <>
      
        <section className="ul-contact-detail">
          <div className="row">
            <div className="col-md-12">
              <div className="card">

                <div className="card-header bg-transparent">
                    <div className='d-flex flex-row justify-content-between'>
                      {bid.status && <span>Status: <Badge className={StatusClass[bid.status.toUpperCase()]}>{bid.status}</Badge></span>}
                      {bid.amount && <div>
                        <span className='mr-3'>Bid: <strong className='text-light '>{audFormatter.format(bid.amount ?? 0)}</strong></span>
                        <span className='mr-3'>Variation: <strong className='text-warning'>{audFormatter.format(variation)}</strong></span>
                        <span className='mr-3'>Total Bid: <strong className='text-primary'>{audFormatter.format((bid.amount || 0) + variation)}</strong></span>
                      </div>}
                      {bid.created && <span>Submitted: {formatDateTime12(bid.created)}</span>}
                    </div>
                </div>
                
                <div className="card-body">

                  <Tabs defaultActiveKey={defaultActiveKey}>

                    <Tab eventKey={BidTab.DETAILS} title={customTabHeader(BidTab.DETAILS, "i-Receipt-4")}>
                      <BidDetailsWidget bid={bid} />
                    </Tab>
                    
                    {bid.sow && bid.sow.length > 0 &&
                      <Tab eventKey={BidTab.SOW} title={customTabHeader(BidTab.SOW, "i-Check", sowBadge)}>
                        <SowWidget bid={bid} handleSowConfirm={(sow) => handleSowConfirm(sow)} />
                      </Tab>                        
                    }
                    
                    <Tab eventKey={BidTab.Documents} title={customTabHeader(BidTab.Documents, "i-Files")}>
                      <IwtbFileRegistry files={bid.files} />
                    </Tab>

                  </Tabs>
                </div>
              

                <div className="card-footer">
                  <div className="mc-footer">
                    <div className="row">
                      <div className="col-md-12">
                        {bid?.status === BidStatus.AWARDED && <button type="button" className="btn btn-primary m-1" onClick={handleAcceptAward}>Accept award</button>}
                        <button type="button" className="btn btn-outline-success m-1" onClick={navigateToRFI}>Submit RFI</button>
                        <button type="button" className="btn btn-warning m-1 footer-delete-right" onClick={handleClose}>Close</button>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

      </>}
      
      <NotificationContainer />
  </div>
  );  
}
