import React, { useState, useEffect } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { paginationOptions, StatusClass } from 'app/utils/constants';

export const TradesWidget = (props) => {

  const [trades, setTrades] = useState([]);
  const { SearchBar, ClearSearchButton } = Search;

  const columns = [
    {
      dataField: 'index',
      text: '#',
      headerAlign: 'center',
      align: 'center',
      headerStyle: (colum, colIndex) => ({ width: '40px' }),
    },
    {
      dataField: 'text',
      text: props.title ?? 'Trade',
      headerAlign: 'center',
      sort: true,
    },
  ];

  useEffect(_ => {
    if (props.trades) {

      const trades = props.trades.map((f, i) => { return {...f, index: ++i }});
      setTrades(trades);
    }
  }, [props.trades]);

  return (
    <>
      <ToolkitProvider
        keyField='id'
        data={trades}
        columns={columns}
        search
        exportCSV
      >
        {toolkitProps => (
          <>
            <div className='d-flex justify-content-end align-items-center'>
              
              <span className='mb-2 mr-1'>Search:</span>
              <SearchBar {...toolkitProps.searchProps} className='mb-0 ' />
              <ClearSearchButton { ...toolkitProps.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
              {props.showExport && <button className='btn btn-outline-success mb-2 mr-1' onClick={_ => toolkitProps.csvProps.onExport()}>Export CSV</button>}
            </div>
            <BootstrapTable
              {...toolkitProps.baseProps}
              bootstrap4
              wrapperClasses="table-responsive"
              pagination={paginationFactory({ ...paginationOptions, totalSize: trades.length })}
              // overlay={ overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(255, 0, 0, 0.5)'}) } }) }
              noDataIndication={'No records found'}
              condensed
              defaultSorted={[{ dataField: 'text', order: 'asc'}]}
            />
          </>
        )}
      </ToolkitProvider>

      <NotificationContainer />
    </>
  );
}
