const config = {
    API: window.env.API,
    RECAPTCHA_KEY: window.env.RECAPTCHA_KEY,
    SCHEDULE: window.env.SCHEDULE,
    SOCKET_ENDPOINT: window.env.SOCKET_ENDPOINT,
    SOCKET_PATH: window.env.SOCKET_PATH,
    GOOGLE_ANALYTICS_ID: window.env.GOOGLE_ANALYTICS_ID,
};

export default config;
