import React, { useEffect, useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Form, ErrorMessage } from 'formik';
import { classList } from '@utils';
import * as yup from 'yup';
import { NotificationManager } from "react-notifications";
import userService from './userService';
import { useAuthState } from 'app/auth';
import { UserGroup, Industry } from 'app/utils/constants';
import { IwtbRadio, IwtbMultiSelect, IwtbSelect, IwtbInput, IwtbTextArea } from 'app/widgets';
import { useConfiguration } from 'app/configuration/ConfigurationContext';

export const SwitchProfileWidget = ({ isSwitching, switchTo, handleSwitchTo, handleClose }) => {

  const authState = useAuthState();
  const [showForm, setShowForm] = useState(false);
  const config = useConfiguration()

  const initialValues = {
    userGroup: UserGroup.CONTRACTOR,
    trades: [],
    industry: '',
    businessName: '',
    safetyPack: '',
    backgroundCheck: '',
    precludeWork: '',
    precludeWorkDetails: '',
  }

  const maxLength = 50;
  const maxLengthError = `Maximum allowed characters is ${maxLength}`;

  const formSchema = yup.object().shape({
    trades: yup.array().min(1, 'Enter one or more items'),
    industry: yup.string().required('Industry is required'),
    businessName: yup.string().test('len', maxLengthError, val => val ? val.length <= maxLength : true),
    precludeWork: yup.string().when('userGroup', {
      is: UserGroup.CONTRACTOR,
      then: yup.string().required('Please answer Yes or No')
    }),
    backgroundCheck: yup.string().when('userGroup', {
      is: UserGroup.CONTRACTOR,
      then: yup.string().required('Please answer Yes or No')
    }),
    safetyPack: yup.string().when('userGroup', {
      is: UserGroup.CONTRACTOR,
      then: yup.string().required('Please answer Yes or No')
    }),
  })

  const getUserProfiles = useCallback(async () => {

    // check if user has more than one profile, then switch to that profile
    const profiles = await userService.getUserProfiles(authState.user.id, authState.user.username)
    let additionalUserGroup = (profiles?.find(p => p.userGroup !== authState.user.userGroup))?.userGroup
    if (additionalUserGroup) {
      handleSwitchTo(additionalUserGroup)
    }
    else {
      if (switchTo === UserGroup.CLIENT) {
        handleSwitchTo(UserGroup.CLIENT)
      }
      else {
        // select contractor or specialist
        // fill mandatory fields
        setShowForm(true)
      }
    }
  }, [authState.user, switchTo])

  useEffect(() => {

    if (isSwitching && switchTo && authState.user) {
      getUserProfiles()
    }
  }, [authState.user, switchTo, isSwitching]);

  const handleSubmit = (values, resetForm) => {

    const userGroup = values.userGroup
    delete values.userGroup
    handleSwitchTo(userGroup, { ...values })
    setShowForm(false)
    resetForm()
  }

  const closeForm = (resetForm) => {

    setShowForm(false); 
    resetForm(); 
    handleClose()
  }

  if (showForm) {
    return (<>
      <Formik initialValues={initialValues} validationSchema={formSchema}>
      {({ values, errors, touched, isValid, isSubmitting, handleChange, setFieldValue, resetForm }) => {
        return (
          <Form>
            <Modal show={true} onHide={() => closeForm(resetForm)} centered={true} size='lg'>
              <Modal.Header closeButton>
                <Modal.Title>Switch Profile</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p><strong>Looks like you don't have a contractor/specialist profile. Please populate following fields and click 'Submit' to create one.</strong></p>
                <div className='form-row'>
                  <div className='form-group col-md-6'>
                    <div className='ul-form__radio-inline'>
                      <IwtbRadio onChange={e => {handleChange(e); setFieldValue('trades', [])}} name='userGroup' value={UserGroup.CONTRACTOR} />
                      <IwtbRadio onChange={e => {handleChange(e); setFieldValue('trades', [])}} name='userGroup' value={UserGroup.SPECIALIST} />
                    </div>                
                  </div>
                </div>

                <div className='form-row'>
                  <div className='col-md-12 mb-3'>
                    {values.userGroup === UserGroup.CONTRACTOR && 
                      <div>
                        <IwtbMultiSelect label='Trade Category (up to 5)' name='trades' selectionLimit={5} displayValue="text" selectedValues={values.trades} onChange={ selects => setFieldValue('trades', selects) } options={config.tradeCategories} />
                      </div>
                    }
                    {values.userGroup === UserGroup.SPECIALIST && 
                      <div>
                        <IwtbMultiSelect label='Speciality (up to 5)' name='trades' selectionLimit={5} displayValue="text" selectedValues={values.trades} onChange={ selects => setFieldValue('trades', selects) } options={config.specialistCategories} />
                      </div>
                    }
                  </div>
                </div>

                <div className='form-row'>
                  <IwtbSelect label='Industry' name='industry' required>
                      <option value=''>Select industry</option>
                      {Industry.map((v, i) => <option value={v} key={i}>{v.toUpperCase()}</option>)}
                  </IwtbSelect>
                </div>

                <div className='form-row'>
                  <IwtbInput label='Business Name' name='businessName' type='text' placeholder='Business name' required />
                </div>


                {values.userGroup === UserGroup.CONTRACTOR && 
                  <div>

                    {/* Answer Yes/No */}

                    <div className='row mt-3'>
                      <div className='col-md-6'>
                        <h4>Answer Yes or No</h4>
                        <div className='mb-3'></div>
                      </div>
                    </div>

                    <div className='form-row'>
                      <div className='form-group col-md-12'>
                      <div className={classList({ 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                        <label className='ul-form__label'>
                          Do you have a company pre-start & safety pack (SWMS, MSDS, Environmental Controls, etc.) in place?
                        </label>
                        <div className='ul-form__radio-inline'>
                          <IwtbRadio name='safetyPack' value='yes' />
                          <IwtbRadio name='safetyPack' value='no' />
                        </div>
                        <div className='invalid-feedback'>{ errors.safetyPack }</div>
                      </div>
                      </div>
                    </div>

                    <div className='form-row'>
                      <div className='form-group col-md-12'>
                      <div className={classList({ 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                        <label className='ul-form__label'>
                          Some projects may require background checks, such as criminal history checks & child safety checks amoung others. If required, you will be required to supply all documentation at your expense. Is there a concern should it be requested?
                        </label>
                        <div className='ul-form__radio-inline'>
                          <IwtbRadio name='backgroundCheck' value='yes' />
                          <IwtbRadio name='backgroundCheck' value='no' />
                        </div>
                        <div className='invalid-feedback'>{ errors.backgroundCheck }</div>
                        </div>
                      </div>
                    </div>

                    <div className='form-row'>
                      <div className='form-group col-md-12'>
                      <div className={classList({ 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                      <label className='ul-form__label'>
                          Is there anything at all that would preclude your company to complete any works should they be awarded to you? If Yes, why?
                        </label>
                        <div className='ul-form__radio-inline'>
                          <IwtbRadio name='precludeWork' value='yes' />
                          <IwtbRadio name='precludeWork' value='no' />
                        </div>
                        <div className='invalid-feedback'>{ errors.precludeWork }</div>
                      </div>
                      </div>
                    
                      {values.precludeWork === 'yes' &&
                        <div className='form-row col-md-12'>
                          <IwtbTextArea label='If yes, write why' name='precludeWorkDetails' type='text' placeholder='' style={{height: 100}} />
                        </div>
                      }
                    </div>
                  </div> 
                }

              </Modal.Body>
              <Modal.Footer>
                <button className='btn btn-warning m-1' onClick={() => closeForm(resetForm)}>Cancel</button>
                <button className='btn btn-success m-1 footer-delete-right' onClick={() => handleSubmit(values, resetForm)} disabled={!isValid || isSubmitting}>Submit</button>
              </Modal.Footer>
            </Modal>
          </Form>
        );
      }}
      </Formik>
    </>
  );
  }
  else {
    return null
  }
};
