
export const AwardAction = {
    SEND_MESSAGE: 'send-message',
    VIEW_SOW: 'view-sow',
    DECLINE: 'decline',
    ACCEPT: 'accept',
}

export const ProjectAction = {
    REQUEST_ACCESS: 'request-access',
    SUBMIT_RFI: 'submit-rfi',
    SUBMIT_BID: 'submit-bid',
    NOT_INTERESTED: 'not-interested',
    REFER: 'refer',
    VIEW_PROJECT: 'view-project',
    CONTACT_CLIENT: 'contact-client',
}

export const ProjectTab = {
    DETAILS: 'Details',
    ACCESS: 'Access Register',
    RFI: 'RFI Register',
    BIDS: 'Bid Register',
    AWARDS: 'Awarded Trades',
    TRADES: 'Trades',
    REQUESTS: 'Requests',
    NOTICES: 'Notices',
    DOCUMENTS: 'Documents',
}

export const AccessAction = {
    APPROVE: 'approve',
    DEDCLINE: 'decline',
}

export const TradeType = {
    TRADE: 'trade',
    SPECIALIST: 'specialist'
}

export const RfiAction = {
    REPLY: 'reply',
    VIEW: 'view',
}