import React from 'react';
import { classList } from '@utils';
import { useField } from 'formik';


export const IwtbCheckbox = ({ children, ...props }) => {

    const [field, meta] = useField({ ...props, type: 'checkbox' });
    return (
        <div className={classList({
            'col-md-4 mb-3': true,
            'valid-field': meta.touched && !meta.error,
            'invalid-field': meta.touched && meta.error
        })}
        >
        <label className='checkbox checkbox-primary'>
        <input type="checkbox" {...field} {...props} />
            {children}
            <span className='checkmark'></span>
        </label>
        <div className='invalid-feedback'>{ meta.error }</div>
        </div>
    );
};
