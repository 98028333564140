import React, {useEffect, useState } from 'react'
import { useScript } from 'app/utils/common'
import paymentService from 'app/payment/paymentService';
import { NotificationManager } from 'react-notifications';

export const EwayWidget = ({ userId, subscription, onPaymentCompleted }) => {

	const status = useScript('https://secure.ewaypayments.com/scripts/eCrypt.min.js')

    const [config, setConfig] = useState()

    useEffect(() => {

        if (status === 'ready' && userId && subscription) {
            paymentService.getPaymentToken(userId, subscription.key)
            .then(result => {
                console.log(result?.SharedPaymentUrl)
                if (result?.SharedPaymentUrl) {
                    setConfig({ sharedPaymentUrl: result.SharedPaymentUrl })
                    // window.eCrypt.showModalPayment(result.SharedPaymentUrl, resultCallback)
                }
                else {
                    NotificationManager.warning('Payment gateway is encountered an error', 'Payment Gateway', 3000)
                }
            })
            .catch(error => {
                console.log(error)
            })
        }
    }, [status, userId, subscription]);

    const resultCallback = (result, transactionID, errors) => {

        if (result == 'Complete') {
          alert('Payment complete! eWAY Transaction ID: ' + transactionID);
        } else if (result == 'Error') {
          alert('There was a problem completing the payment: ' + errors);
        }
    }

    const openEway = () => {
        window.eCrypt.showModalPayment(config, resultCallback)
    }

    return (<>
        <button type='button' onClick={openEway}>Pay with eWAY</button>
    </>)
}
