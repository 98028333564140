import { lazy } from 'react';

const TermsPage = lazy(() => import('./terms'));
const PrivacyPage = lazy(() => import('./privacy'));

const legalRoutes = [
  {
    path: '/legal/privacy',
    component: PrivacyPage
  },
  {
    path: '/legal/terms',
    component: TermsPage
  },
];

export default legalRoutes;
