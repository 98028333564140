import React from 'react';
import { formatDate } from 'app/utils/time';

export const UserWidget = ({ user }) => {

  return ( <>
    {user && <form className="mt-3">

      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Name:</label>
        <div className="form-control col-lg-4">{`${user.firstName} ${user.lastName}`}</div>
        <label className="col-lg-2 col-form-label">Role:</label>
        <div className="form-control col-lg-4">{user.role}</div>
      </div>

      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Mobile:</label>
        <div className="form-control col-lg-4">{user.mobile}</div>
        <label className="col-lg-2 col-form-label">Email:</label>
        <div className="form-control col-lg-4">{user.email}</div>
      </div>
      <div className="form-group row">
        <label className="col-lg-2 col-form-label">About Me:</label>
        <div className="form-control col-lg-10">{user.about}</div>
      </div>
      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Trades:</label>
        <div className="form-control col-lg-10">{user.trades ? user.trades.map(v => v.text).join(', ') : ''}</div>
      </div>
      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Location:</label>
        <div className="form-control col-lg-10">{user.location ? user.location.address : ''}</div>
      </div>
      <div className="form-group row">
        <label className="col-lg-2 col-form-label">About Me:</label>
        <div className="form-control col-lg-10">{user.about}</div>
      </div>
      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Member Since:</label>
        <div className="form-control col-lg-4">{formatDate(user.created)}</div>
        <label className="col-lg-2 col-form-label">Ref:</label>
        <div className="form-control col-lg-4">{user.ref}</div>
      </div>
      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Status:</label>
        <div className="form-control col-lg-4 text-uppercase">{user.status}</div>
      </div>

      </form>}
    </>
  );
}

