import React from 'react'
import { ReactEcharts } from '.'

export const RegistrationReport = ({ height, data = [] }) => {

  const encode = data.length > 0 ? data[0].length - 1 : ''

  const option = {
    legend: {
      // orient: 'vertical',
      // left: 'center',
      // top: '0'
    },
    tooltip: { trigger: 'axis', showContent: false },
    dataset: {
      source: data
      // [
      //   ['userGroup', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb'],
      //   ['Client', 56, 82, 88, 15, 11, 33],
      //   ['Contractor', 51, 30, 44, 60, 90, 21],
      //   ['Specialist', 40, 57, 29, 4, 24, 43],
      // ]
    },
    xAxis: { type: 'category' },
    yAxis: { gridIndex: 0 },
    grid: { top: '50%' },
    series: [
      {
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: { focus: 'series' }
      },
      {
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: { focus: 'series' }
      },
      {
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: { focus: 'series' }
      },
      {
        type: 'pie',
        id: 'pie',
        radius: '45',
        center: ['50%', '30%'],
        emphasis: { focus: 'self' },
        label: { formatter: '{b}: {@Dec} ({d}%)' },
        encode: {
          itemName: 'userGroup',
          value: encode,
          tooltip: encode,
        }
      }
    ]
  }

  const onChartReady = instance => {

    if (instance) {
      instance.on('updateAxisPointer', (event) => {
        const xAxisInfo = event.axesInfo[0]
        if (xAxisInfo) {
          const dimension = xAxisInfo.value + 1
          instance.setOption({
            series: {
              id: 'pie',
              label: { formatter: '{b}: {@[' + dimension + ']} ({d}%)' },
              encode: { value: dimension, tooltip: dimension }
            }
          })
        }
      })
    }
  }

  return <ReactEcharts style={{ height: height }} option={option} onChartReady={onChartReady} />
}
