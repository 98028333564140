import React from 'react';
import { userSchema, adminSchema } from './userSchema';
import { Formik, Form } from 'formik';
import { UserGroup, Industry } from 'app/utils/constants';
import {IwtbTextArea, IwtbRadio, IwtbInput, IwtbMultiSelect, IwtbPlaceAutocomplete, IwtbSelect } from 'app/widgets';
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda';
import profileService from './profileService';
import { NotificationManager } from "react-notifications";
import { useAuthState } from 'app/auth';
import { classList } from '@utils';
import ReactGA from 'react-ga';
import { useConfiguration } from 'app/configuration/ConfigurationContext';

export const CompanyEditWidget = ({ user, ...props }) => {

  const authState = useAuthState();
  const config = useConfiguration()

  const handleUpdate = (values, setSubmitting) => {

    try {
      ReactGA.event({ category: 'Profile', action: 'Company Profile Update', value: authState.user.id });
    } catch {}

    setSubmitting(true);

    profileService.updateProfile(authState.user.id, values)
    .then(result => {
      NotificationManager.success('Your profile was updated', 'Profile', 2000);
      setSubmitting(false);      
    })
    .catch(ex => {  
      setSubmitting(false);      
      NotificationManager.error('Something went wrong', 'Profile', 3000);
    });
  };

  return ( <>
    {user && 
        <Formik initialValues={user} enableReinitialize={true} validationSchema={user.userGroup === UserGroup.ADMIN ? adminSchema : userSchema}>
        {({
          values,
          errors, 
          touched,
          handleSubmit,
          isSubmitting,
          isValid,
          setFieldValue,
          setSubmitting
        }) => {
          return (

            <Form className='needs-validation' onSubmit={handleSubmit} noValidate>

              <div className='form-row'>
                <IwtbInput label='Business Name' name='businessName' type='text' placeholder='Business name' required />
                <IwtbInput label='ABN' name='ABN' type='text' placeholder='ABN' required />
                <IwtbSelect label='Industry' name='industry' required>
                    <option value=''>Select industry</option>
                    {Industry.map((v, i) => <option value={v} key={i}>{v.toUpperCase()}</option>)}
                </IwtbSelect>
              </div>

              <div className='form-row'>
                <IwtbInput label='Phone' name='businessPhone' type='text' placeholder='Phone' required />
                <IwtbInput label='Fax' name='businessFax' type='text' placeholder='Fax' />
                <IwtbInput label='Email' name='businessEmail' type='text' placeholder='Email' required />

                <IwtbInput label='Website' name='website' type='text' placeholder='http://www.iwt.build' required />
                <IwtbInput label='Years Operating' name='businessYearsOperating' type='number' placeholder='Years operating' />
                <IwtbInput label='References / Reviews' name='businessReferences' type='text' placeholder='References and/or reviews' required />
              </div>

              <div className='form-row'>
                <IwtbInput label='Role / Job Title' name='role' type='text' placeholder='Engineer' required />
                <div className='col-md-8 mb-3'>
                  <IwtbPlaceAutocomplete name='businessLocation' label='Business Address' location={values.businessLocation} type='text' placeholder='Start typing address' locationSelected={ location => setFieldValue('businessLocation', location) } />
                </div>
              </div>

              <div className='form-row'>
                <div className='col-md-12 mb-3'>
                  {values.userGroup === UserGroup.CONTRACTOR && 
                    <div>
                      <IwtbMultiSelect label='Trade Category (up to 5)' name='trades' selectionLimit={5} displayValue='text' selectedValues={values.trades} onChange={ selects => setFieldValue('trades', selects) } options={config.tradeCategories} />
                    </div>
                  }
                  {values.userGroup === UserGroup.SPECIALIST && 
                    <div>
                      <IwtbMultiSelect label='Speciality (up to 5)' name='trades' selectionLimit={5} displayValue='text' selectedValues={values.trades} onChange={ selects => setFieldValue('trades', selects) } options={config.specialistCategories} />
                    </div>
                  }
                  {values.userGroup === UserGroup.CLIENT && 
                    <div>
                      <IwtbMultiSelect label='Client Relationship (up to 3)' name='trades' selectionLimit={3} displayValue='text' selectedValues={values.trades} onChange={ selects => setFieldValue('trades', selects) } options={config.clientCategories} />
                    </div>
                  }
                </div>
              </div>

              {values.userGroup !== UserGroup.ADMIN && <>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <h4>Answer Yes or No</h4>
                    <div className='mb-3'></div>
                  </div>
                </div>

                <div className='form-row'>
                  <div className='form-group col-md-12'>
                  <div className={classList({ 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                      <label className='ul-form__label'>
                        Have you been bankrupt, insolvent or have been part of a company that has been bankrupt or insolvent?
                      </label>
                      <div className='ul-form__radio-inline'>
                        <IwtbRadio name='beenBankrupt' value='yes' />
                        <IwtbRadio name='beenBankrupt' value='no' />
                      </div>
                      <div className='invalid-feedback'>{ errors.beenBankrupt }</div>
                    </div>
                  </div>
                </div>
              </>}

              {values.userGroup === UserGroup.CLIENT && <div className='form-row'>
                  <div className='form-group col-md-12'>
                    <div className={classList({ 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                      <label className='ul-form__label'>
                        Some projects may require you to provide a financial guarantee statement by your financial institution to confirm the financial capability of a project.
                        Is there a concern should it be required?
                      </label>
                      <div className='ul-form__radio-inline'>
                        <IwtbRadio name='financialGuarantee' value='yes' />
                        <IwtbRadio name='financialGuarantee' value='no' />
                      </div>
                      <div className='invalid-feedback'>{ errors.financialGuarantee }</div>
                    </div>
                  </div>
              </div>}

              {values.userGroup === UserGroup.CONTRACTOR && <div>

                <div className='form-row'>
                  <div className='form-group col-md-12'>
                  <div className={classList({ 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                    <label className='ul-form__label'>
                      Do you have a company pre-start & safety pack (SWMS, MSDS, Environmental Controls, etc.) in place?
                    </label>
                    <div className='ul-form__radio-inline'>
                      <IwtbRadio name='safetyPack' value='yes' />
                      <IwtbRadio name='safetyPack' value='no' />
                    </div>
                    <div className='invalid-feedback'>{ errors.safetyPack }</div>
                  </div>
                  </div>
                </div>

                <div className='form-row'>
                  <div className='form-group col-md-12'>
                  <div className={classList({ 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                    <label className='ul-form__label'>
                      Some projects may require background checks, such as criminal history checks & child safety checks amoung others. If required, you will be required to supply all documentation at your expense. Is there a concern should it be requested?
                    </label>
                    <div className='ul-form__radio-inline'>
                      <IwtbRadio name='backgroundCheck' value='yes' />
                      <IwtbRadio name='backgroundCheck' value='no' />
                    </div>
                    <div className='invalid-feedback'>{ errors.backgroundCheck }</div>
                    </div>
                  </div>
                </div>

                <div className='form-row'>
                  <div className='form-group col-md-12'>
                  <div className={classList({ 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                  <label className='ul-form__label'>
                      Is there anything at all that would preclude your company to complete any works should they be awarded to you? If Yes, why?
                    </label>
                    <div className='ul-form__radio-inline'>
                      <IwtbRadio name='precludeWork' value='yes' />
                      <IwtbRadio name='precludeWork' value='no' />
                    </div>
                    <div className='invalid-feedback'>{ errors.precludeWork }</div>
                  </div>
                  </div>
                
                  {values.precludeWork === 'yes' &&
                  <div className='form-row col-md-12'>
                    <IwtbTextArea label='If yes, write why' name='precludeWorkDetails' type='text' placeholder='' style={{height: 100}} />
                  </div>}
                </div>
              </div>}

              <div className='mb-4'></div>

              <LaddaButton 
                className='btn btn-success btn-lg border-0 mr-2 mb-2 position-relative'
                loading={isSubmitting}
                disabled={!isValid}
                progress={0.5}
                onClick={_ => handleUpdate(values, setSubmitting)}
                data-style={EXPAND_RIGHT}
                type='button'
              >Update</LaddaButton>

            </Form>
          );
        }}
      </Formik>
      
  

}
    </>
  );
}

