import React, { useState } from "react";
import { Breadcrumb } from '@gull';
import { ClientNavigation } from '../clientRoutes';
import { ProjectWidget } from 'app/widgets';
import { NotificationContainer, NotificationManager } from "react-notifications";
import projectService from './projectService';
import { refreshSubscription, useAuthState, useAuthDispatch } from 'app/auth';
import { useHistory } from "react-router-dom";
import { navigateTo } from 'app/utils/common';

export const NewProject = () => {
  
  const authDispatch = useAuthDispatch()
  const authState = useAuthState();
  const history = useHistory()
  const [isSubmitting, setIsSubmitting] = useState(false);
  const project = {
    projectName: '',
    projectDetails: '',
    classification: '',
    sector: '',
    budget: '',
    inspectionRequired: '',
    inspectionRequiredOn: '',
    establishmentRequired: '',
    establishmentOrganizer: '',
    lodgedDA: '',
    receivedPermit: '',
    projectLocation: '',
    fileCategory: '',
    files: [],
    trades: [], 
    tenderRequiredBy: '',
    contact: {
      title: '',
      firstName: '',
      lastName: '',
      mobile: '',
      email: '',
      location: ''
    },
    clientId: '',
    additionalServices: []
  };

  const handleSubmit = project => {

    setIsSubmitting(true);
    
    projectService.createProject(authState.user.id, project, authState.subscription.ref)
    .then(() => {
      refreshSubscription(authDispatch, authState.user.id)
      NotificationManager.success("Project has been created.", "New Project", 2000);
      setTimeout(_ => history.push(ClientNavigation.PROJECTS), 2000);
    })
    .catch(ex => {
      setIsSubmitting(false);
      NotificationManager.error("Something went wrong.", "New Project", 6000);
    });
  };

  const handleDraft = project => {

    setIsSubmitting(true);
    
    projectService.createDraft(authState.user.id, project)
    .then(draft => {
      NotificationManager.success("Draft has been saved.", "Project Draft", 2000);
      return navigateTo({ history: history, navigation: ClientNavigation, pathname: ClientNavigation.EDIT_PROJECT, project: draft });
    })
    .catch(ex => {
      NotificationManager.error("Something went wrong.", "Project Draft", 6000);
    })
    .finally(() => {
      setIsSubmitting(false);
    })
  }

  return (<>
    <Breadcrumb
      routeSegments={[
        { name: "Projects", path: '' },
        { name: 'New Project' }
      ]}
    ></Breadcrumb>

    <ProjectWidget project={project} handleDraft={handleDraft} handleSubmit={handleSubmit} handleCancel={() => history.goBack()} isSubmitting={isSubmitting} />

    <NotificationContainer />
  </>
  );
}
