import React from "react";
import { Breadcrumb } from "@gull";

export const RegistrationConfirmed = () => {

  return (
    <div>
      <Breadcrumb routeSegments={[{ name: "Registration" }]}></Breadcrumb>

      <div className="d-flex justify-content-center">
        <div className="text-center">
          <h4 className='text-success'>Registration has been submitted successfully!</h4>
          <br/>
          <p className='text-primary'>
            The confirmation email has been sent.<br/>
            Please confirm your email address to complete your registration.
          </p>
        </div>
      </div>
    </div>
  );
}
