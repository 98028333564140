import { adminMenu, adminRoutes, AdminNavigation } from 'app/admin';
import { clientMenu, clientRoutes, ClientNavigation } from 'app/client';
import { guestMenu, guestRoutes, GuestNavigation } from 'app/guest';
import { UserGroup } from 'app/utils/constants';
import { contractorMenu, contractorRoutes, ContractorNavigation } from 'app/contractor';

let user = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')).user : '';

const injectUserIdMenu = (menu, userId) => {

    return menu.map(v => {
        if (v.path) {
            v.path = v.path.replace(':id', userId);
        }
        if (Array.isArray(v.sub)) {
            v.sub = injectUserIdMenu(v.sub, userId)
        }
        return v;
    })
}

const injectUserIdNavigation = (navigation, userId) => {

    for (const key of Object.keys(navigation)) {
        const path = navigation[key].replace(':id', userId);
        navigation[key] = path; 
    }
    return navigation
}

const getUserMenuRoutes = (user) => {

    let menu = [];
    let navigation = []
    let routes = []

    switch(user.userGroup) {
        
        case UserGroup.ADMIN:
            menu = injectUserIdMenu(adminMenu, user.id)
            navigation = injectUserIdNavigation(AdminNavigation, user.id)
            routes = adminRoutes
            break;

        case UserGroup.CLIENT:
            menu = injectUserIdMenu(clientMenu, user.id)
            navigation = injectUserIdNavigation(ClientNavigation, user.id)
            routes = clientRoutes
            break;

        case UserGroup.CONTRACTOR:
        case UserGroup.SPECIALIST:
            menu = injectUserIdMenu(contractorMenu, user.id)
            navigation = injectUserIdNavigation(ContractorNavigation, user.id)
            routes = contractorRoutes
            break;
    }
    return { menu: menu, routes: routes, navigation: navigation };
}

export const initialState = {
  user: user || '',
  isAuthenticated: false,
  loading: false,
  error: null,
  routes: guestRoutes,
  menu: guestMenu,
  navigation: GuestNavigation,
};
 
export const AuthReducer = (state, action) => {

  switch (action.type) {

    case 'ADD_SUBSCRIPTION':
        return { ...state, subscription: action.payload };

    case 'REMOVE_SUBSCRIPTION':
        return { ...state, subscription: null };
        
    case 'REQUEST_USER_REFRESH':
        return { ...state, loading: true };
        
    case 'LOGIN_SUCCESS':
        const { menu, routes, navigation } = getUserMenuRoutes(action.payload);
        return {
            ...state,
            user: action.payload,
            isAuthenticated: true,
            menu: menu,         
            routes: routes,
            navigation: navigation,
            loading: false,
            error: null
        };
    
    case 'LOGOUT':
        return { ...initialState };
         
    case 'LOGIN_ERROR':
        return {
            ...initialState,
            error: action.error
        };
    
    default:
        return state
    }
};