import React from "react";
import { Redirect } from "react-router-dom";
import { GuestRegistration } from './registration/GuestRegistration';
import { RegistrationConfirmed }  from './registration/RegistrationConfirmed';
import { VerifyUserForm }  from './registration/VerifyUserForm';
import { AuthGuard } from 'app/auth';
import legalRoutes from 'app/pages/legal/legalRoutes';
import { ContactUs } from 'app/pages'
import { PageNotFound } from 'app/error'
import { NewPassword } from "app/password";

export const GuestNavigation = {
    REGISTRATION_CONFIRMED: '/registration/confirmed',
    REGISTRATION: '/registration',
    CONTACT_US: '/contact-us',
    VERIFY: '/verify/:key',
    CHANGE_PASSWORD: '/change-password',
    NEW_PASSWORD: '/new-password/:token',
};

const routes = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to={ GuestNavigation.REGISTRATION } />
    },
    {
        path: GuestNavigation.NEW_PASSWORD,
        component: NewPassword,
    },
    {
        path: GuestNavigation.VERIFY,
        component: VerifyUserForm,
    },
    {
        path: GuestNavigation.REGISTRATION_CONFIRMED,
        component: RegistrationConfirmed,
    },
    {
        path: GuestNavigation.REGISTRATION,
        component: GuestRegistration,
    },
    {
        path: GuestNavigation.CONTACT_US,
        component: ContactUs,
    },
    {
        path:'/*',
        component: PageNotFound
    },
];

export const guestRoutes = [{
    path: '/',
    component: AuthGuard,
    routes: [
    ...legalRoutes,
    ...routes,    
    ]
}];