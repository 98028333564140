import React from 'react';
import "../styles/app/app.scss";
import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from "react-redux";
import { Store } from "app/redux/Store";
import { AuthProvider } from "app/auth";
import ReactGA from 'react-ga';
import { ErrorProvider, ErrorFallback } from './error';
import { SpinnerProvider } from './IwtbLayout/SharedComponents/spinner/SpinnerContext';
import { ConfigurationProvider } from './configuration/ConfigurationContext';
import config from 'app/utils/config';

function App() {

  ReactGA.initialize(config.GOOGLE_ANALYTICS_ID);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => window.location.href = '/'}>
      <ErrorProvider>
        <SpinnerProvider>
            <ConfigurationProvider>
              <Provider store={Store}>
                <AuthProvider />
              </Provider>
            </ConfigurationProvider>
        </SpinnerProvider>
      </ErrorProvider>
    </ErrorBoundary>
  );
}

export default App;
