import HttpService from 'app/services/httpService';

class rfiService {
    http = new HttpService();

    respondRfi = (clientId, projectId, rfiId, response) => {
        return this.http.put(`clients/${clientId}/projects/${projectId}/requests/${rfiId}`, { response: response });
    };

    getRfiRegister = (clientId, projectId) => {
        return this.http.get(`clients/${clientId}/projects/${projectId}/requests`);
    };

}

export default new rfiService();