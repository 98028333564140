import React, { useEffect, useState, useCallback } from "react";
import { Breadcrumb } from '@gull';
import { Tabs, Tab, Alert, Button, Badge } from "react-bootstrap";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useParams, useHistory } from "react-router-dom";
import profileService from './profileService';
import { CompanyWidget, ProjectsWidget, UserWidget } from '.';
import { useAuthState } from 'app/auth';
import { IwtbFileRegistry } from 'app/widgets';
import { ProfileCardWidget } from 'app/profile';
import { customTabHeader, handleOpenChat, deleteUserFile } from 'app/utils/common';
import { ProfileCardTab, SubscriptionStatus, StatusClass, UserGroup } from 'app/utils/constants';
import { formatDate } from 'app/utils/time';

export const ClientCard = () => {

  let { clientId, tab } = useParams();
  const [client, setClient] = useState();
  const [projects, setProjects] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [showRemoveFile, setShowRemoveFile] = useState(false)
  const authState = useAuthState();
  const history = useHistory();
  const [defaultActiveKey, setDefaultActiveKey] = useState();
  const [subscription, setSubscription] = useState()

  const getClientProfile = useCallback(() => {

    profileService.getClientProfile(authState.user.id, clientId)
    .then(client => {

      if (client && client.error) {
        return NotificationManager.error(client.message, 'Server Connection', 3000);      
      }

      if (client && client.id) {
        setClient(client);

        if (authState.user.userGroup === UserGroup.ADMIN) {
          profileService.getClientSubscription(authState.user.id, client.id)
          .then(subscription => {
            if (subscription?.error) {
              NotificationManager.error(subscription.message, 'Subscription', 5000)
            }
            else if (subscription) {
              setSubscription(subscription)
            }
          })
          .catch(e => {
            NotificationManager.error('Something went wrong.', 'Server Connection', 3000)
          });
        }
      }
      else {
        setTimeout(() => setNotFound(true), 2000);
      }
    })
    .catch(e => { NotificationManager.error('Something went wrong.', 'Server Connection', 3000) });
  });

  const getClientProjects = useCallback(() => {

    profileService.getClientProjects(authState.user.id, clientId)
    .then(projects => {

      if (projects && projects.error) {
        return NotificationManager.error(projects.message, 'Server Connection', 3000);      
      }

      if (projects && Array.isArray(projects)) {
        setProjects(projects);
      }
    })
    .catch(e => NotificationManager.error('Something went wrong.', 'Server Connection', 3000));
  });

  useEffect(() => {

    const projectTab = tab === 'undefined' || tab === undefined ? ProfileCardTab.PROJECTS : tab;
    setDefaultActiveKey(projectTab);
  }, [tab]);

  useEffect(() => {

    if (clientId && authState.isAuthenticated) {
      getClientProfile();
      getClientProjects();
      setShowRemoveFile(authState.user.userGroup === UserGroup.ADMIN)
    }
  }, [clientId, authState.user]);

  const handleClose = () => {
    
    history.goBack();
  }

  const handleDeleteUserFile = async file => {

    await deleteUserFile(clientId, file)
    getClientProfile()
  }

  return (
    <div>
      <Breadcrumb routeSegments={[
        { name: `Client Profile (${client?.status})` }
      ]}></Breadcrumb>

      { client && <>

        <section className="ul-contact-detail">
          <div className="row">
            <div className="col-lg-3 col-xl-3">
              <ProfileCardWidget user={client} handleOpenChat={() => handleOpenChat(authState, history, client.id)}/>
            </div>
            
            <div className="col-lg-9 col-xl-9">
              <div className="card mb-4">
                
                <div className="card-body">
                  
                <Tabs defaultActiveKey={defaultActiveKey}>

                  <Tab eventKey={ProfileCardTab.ABOUT} title={customTabHeader(ProfileCardTab.ABOUT, "i-Business-Man")}>
                    <UserWidget user={client} />
                  </Tab>

                  <Tab eventKey={ProfileCardTab.COMPANY} title={customTabHeader(ProfileCardTab.COMPANY, "i-Building")}>
                    <CompanyWidget user={client} />
                  </Tab>

                  <Tab eventKey={ProfileCardTab.PROJECTS} title={customTabHeader(ProfileCardTab.PROJECTS, "i-Gears")}>
                    <ProjectsWidget projects={projects} />
                  </Tab>

                  {authState.user.userGroup === UserGroup.ADMIN && <Tab eventKey={ProfileCardTab.SUBSCRIPTION} title={customTabHeader(ProfileCardTab.SUBSCRIPTION, 'i-Dollar-Sign')}>
                    <div className="row">
                        <div className="col-md-12">
                            {subscription && <Alert variant={subscription.status === SubscriptionStatus.ACTIVE ? 'light' : 'warning'}>
                                <div className='d-flex flex-row justify-content-between'>
                                    <span><strong>Status: </strong><Badge className={StatusClass[subscription.status.toUpperCase()]}>{subscription.status}</Badge></span>
                                    <span>
                                      {subscription.status === SubscriptionStatus.EXPIRED && <span><strong>Expired On: </strong> {subscription.end ? formatDate(subscription.end) : 'N/A'}</span>}
                                    </span>
                                </div>
                            </Alert>}
                        </div>
                    </div>
                  </Tab>}

                  <Tab eventKey={ProfileCardTab.DOCUMENTS} title={customTabHeader(ProfileCardTab.DOCUMENTS, "i-Files")}>
                    <IwtbFileRegistry files={client.files ?? []} showSearch={true} handleDeleteFile={showRemoveFile ? handleDeleteUserFile : null} />
                  </Tab>                        
                            
                  <Tab eventKey={ProfileCardTab.PHOTOS} title={customTabHeader(ProfileCardTab.PHOTOS, 'i-Picasa')}>
                    <IwtbFileRegistry files={client.photos ?? []} showSearch={true} handleDeleteFile={showRemoveFile ? handleDeleteUserFile : null} />
                  </Tab>                        

                  {/* <Tab eventKey={ProfileCardTab.REVIEWS} title={customTabHeader(ProfileCardTab.REVIEWS, "i-Like")}>
                    <ReviewsWidget reviews={client.reviews} />
                  </Tab> */}

                </Tabs>

                </div>
                
                <div className="card-footer">
                  <div className="mc-footer">
                    <div className="row">
                      <div className="col-md-12">
                        <button type="button" className="btn btn-warning m-1 footer-delete-right" onClick={handleClose}>Cancel & Return</button>
                      </div>
                    </div>

                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </section>

      </>}
      
      { !client && notFound && <>
        <Alert className="text-center alert-card" variant="danger">
          Something went wrong. Profile was not found.
          <Button variant="warning" className="btn ml-3" onClick={_ => {history.goBack();}}>Return</Button>
        </Alert>
      </>}

      <NotificationContainer />
  </div>
  );  
}
