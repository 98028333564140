 
import authService from './authService';
import userService from 'app/user/userService';

export {
    loadUser,
    refreshSubscription,
    refreshUser,
    logout,
    dispatchSuccessfulLogin,
}

const loadUser = (dispatch, socket) => {

    const currentUser = localStorage.getItem("currentUser");

    if (currentUser) {
        const user = JSON.parse(currentUser);
        if (user.token) {
            refreshUser(dispatch, user.token, socket);        
        }
        else {
            dispatch({ type: 'LOGIN_ERROR' });
        }
    }
}

const refreshSubscription = async (dispatch, userId) => {
    
    try {
        const subscription = await userService.getMySubscription(userId)
        if (!subscription || subscription?.error) {
            dispatch({ type: 'REMOVE_SUBSCRIPTION' });
        }
        else {
            dispatch({ type: 'ADD_SUBSCRIPTION', payload: subscription });
        }
    } 
    catch (error) {
        dispatch({ type: 'REMOVE_SUBSCRIPTION' });
    }
}

const dispatchSuccessfulLogin = async (dispatch, user, socket) => {
    
    dispatch({ type: 'LOGIN_SUCCESS', payload: user });
    localStorage.setItem('currentUser', JSON.stringify(user));
    if (socket) socket.login(user)
    refreshSubscription(dispatch, user.id)
}

const refreshUser = async (dispatch, token, socket) => {
    
    try {
        dispatch({ type: 'REQUEST_USER_REFRESH' });
        let user = await authService.loginWithToken(token);
        dispatchSuccessfulLogin(dispatch, user, socket)
    } 
    catch (error) {
        dispatch({ type: 'LOGIN_ERROR', error: error });
    }
}
 
const logout = async (user, socket) => {

    localStorage.clear();
    window.location.href = '/';
    if (socket) {
        socket.logout(user)
    }
}