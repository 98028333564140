import HttpService from 'app/services/httpService';

class AuthService {

  http = new HttpService();

  loginWithUsernamePassword = loginPayload => {
    return this.http.post('signin/username', loginPayload);
  }

  loginWithToken = token => {
    return this.http.post('signin/token', { token: token });
  }
}

export default new AuthService()
