import React, { useState, useEffect, useCallback } from "react";
import { classList } from '@utils';
import { Formik, Form } from 'formik';
import { IWTB_AdditionalServices, AllowedFileTypes, DateFormat, UserGroup, ClientSubscriptionKey, ProjectStatus, SubscriptionStatus, UserStatus } from 'app/utils/constants';
import { IwtbInput, IwtbFileUpload, IwtbPlaceAutocomplete, IwtbSelect, IwtbTextArea, IwtbRadio, IwtbMultiSelect, IwtbCheckbox } from 'app/widgets';
import projectSchema from './projectSchema';
import { Button, Alert, Popover, OverlayTrigger } from "react-bootstrap";
import SweetAlert from "sweetalert2-react";
import { toggleAdditionalService, hasService, addSubscription, getTradeSpecialistCategories } from 'app/utils/common'
import { formatDateTimeIso } from 'app/utils/time'
import LaddaButton, { EXPAND_RIGHT } from "react-ladda";
import { useAuthState, useAuthDispatch } from 'app/auth';
import { useConfiguration } from "app/configuration/ConfigurationContext";
import { ClientSubscriptionWidget } from 'app/subscription'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { PaymentWidget } from 'app/payment/PaymentWidget'
import guestService from 'app/guest/guestService';
import userService from "app/user/userService";

const popover = props => (
  <Popover id="popover-basic" {...props}>
    <Popover.Title as="h3">Site Establisment</Popover.Title>
    <Popover.Content>Hoardings, amenities, temporary power & water, change rooms, lunch room, etc.</Popover.Content>
  </Popover>
);

export const ProjectWidget = ({ project, isSubmitting, handleSubmit, handleCancel, handleApprove, handleRequestInfo, handleReject, handleDraft }) => {

  const [showUploadPermit, setShowUploadPermit] = useState(false);
  const authState = useAuthState();
  const authDispatch = useAuthDispatch();
  const config = useConfiguration()
  const [showPayment, setShowPayment] = useState(false)
  const [paid, setPaid] = useState(false)
  const [showSubscriptions, setShowSubscriptions] = useState(false)
  const [newSubscription, setNewSubscription] = useState()
  const [subscriptions, setSubscriptions] = useState()

  let isSamePerson = false;

  const getClientSubscriptions = useCallback(async () => {

    const subscriptions = await guestService.getClientSubscriptions()
    if (subscriptions?.error) {
        return NotificationManager.error('Something went wrong.', 'Server Connection', 3000);      
    }
    if (subscriptions?.length > 0) {
        setSubscriptions(subscriptions)
    }  
  })

  const getUserSubscription = useCallback(async () => {

    userService.getMySubscription(authState.user.id)
    .then(subscription => {
      if (subscription?.status === SubscriptionStatus.ACTIVE) {
        setPaid(true)
      }
      else {
        setPaid(false)
      }  
    })
    .catch(e => { NotificationManager.error('Unable to retrieve user subscription.', 'Subscription', 3000) });
  })

  // const getPaymentToken = useCallback(() => {

  //   paymentService.getPaymentToken()
  //   .then(token => {

  //     if (token?.error) {
  //       return NotificationManager.warning(token.message, 'Payment Gateway', 3000);      
  //     }
  //     if (token) {
  //       setPaymentToken(token)
  //     }
  //   })
  //   .catch(e => { 
  //     NotificationManager.warning(e.message, 'Payment Gateway', 3000);      
  //   });
  // })

  useEffect(() => {

    getClientSubscriptions() // list of available subscriptions
    getUserSubscription()
  }, []);


  useEffect(() => {

    if (project && !project.id || project.status === ProjectStatus.DRAFT) {
      // this is new project or draft, thus show subscriptions to buy
      setShowSubscriptions(true)
      // getPaymentToken()
    }
    else {
      setShowSubscriptions(false)
    }
  }, [project]);

  const formatDates = values => {

    let project = {...values}

    if (project.inspectionRequired === 'yes' && project.inspectionRequiredOn?.length > 0) {
      project.inspectionRequiredOn = formatDateTimeIso(project.inspectionRequiredOn, DateFormat)
    }
    else {
      project.inspectionRequiredOn = ''
    }

    if (project.tenderRequiredBy?.length > 0) {
      project.tenderRequiredBy = formatDateTimeIso(project.tenderRequiredBy, DateFormat)
    }
    return project
  }

  const onSaveDraft = values => {

    const project = formatDates(values)
    if (project.projectName?.length == 0) {
      return NotificationManager.error('Project name is required', 'Save Draft', 6000);      
    }
    else {
      handleDraft(project)
    }
  }

  const onSubmit = values => {
    
    const project = formatDates(values)

    if (project.receivedPermit == 'yes' && project.files.length === 0 ) {
      // if DA/Permit received then it is required to upload them
      return setShowUploadPermit(true);
    }

    if (handleApprove) {
      handleApprove(project)
    }
    else if (handleSubmit) {
      handleSubmit(project)
    }
  };

  const samePersonClicked = (e, setFieldValue, values) => {

    if (e.target.checked) {
      isSamePerson = true;
      setFieldValue('contact.title', authState.user.title)
      setFieldValue('contact.firstName', authState.user.firstName)
      setFieldValue('contact.lastName', authState.user.lastName)
      setFieldValue('contact.email', authState.user.email)
      setFieldValue('contact.mobile', authState.user.mobile)
      setFieldValue('contact.location', authState.user.location && authState.user.location.address ? authState.user.location.address : '')
    }
    else {
      isSamePerson = false;
      setFieldValue('contact.title', '')
      setFieldValue('contact.firstName', '')
      setFieldValue('contact.lastName', '')
      setFieldValue('contact.email', '')
      setFieldValue('contact.mobile', '')
      setFieldValue('contact.location', '')
    }
  }

  const handleClientPayment = async (subscription) => {

    if (subscription.key === ClientSubscriptionKey.TRIAL) {
      
      // no payment required, add trial subscription
      await addSubscription(authDispatch, authState.user.id, subscription.key)
      await getUserSubscription()
    }
    else {
      // payment required, show payment widget
      setNewSubscription(subscription)  
      setShowPayment(true)
    }
  }

  const handlePaymentComplete = async (payment) => {

    // payment gateway has processed payment
    setShowPayment(false)
    // validate payment on the server, then add subscription
    await addSubscription(authDispatch, authState.user.id, newSubscription.key, payment)
    await getUserSubscription()
  }

  return (<>

      <Formik
        initialValues={project}
        enableReinitialize={true}
        validationSchema={projectSchema}
        onSubmit={onSubmit}
      >
        {({
            values,
            errors,
            touched,
            handleSubmit,
            isValid,
            setFieldValue,
          }) => {
          return (

            <Form
              className='needs-validation'
              onSubmit={handleSubmit}
              noValidate
            >

              <div className='form-row'>
                <IwtbInput label='Project Name' name='projectName' type='text' placeholder='' required />
                <IwtbTextArea label='Project Details' name='projectDetails' type='text' placeholder='Detailed scope of works' style={{height: 100}} required />
              </div>

              <div className='form-row'>
                <div className={classList({ 'col-md-4 mb-3': true, 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                    <label className='ul-form__label'>Classification</label>
                    <div className='ul-form__radio-inline'>
                      <IwtbRadio name='classification' value='Residential' />
                      <IwtbRadio name='classification' value='Commercial' />
                    </div>
                    <div className='invalid-feedback'>{ errors.classification }</div>
                  </div>
              </div>

              <div className='form-row'>
              {values.classification === 'Commercial' && <IwtbSelect label='Sector' name='sector'>
                  <option value=''>Select Sector</option>
                  {config.projectSectors.map((v, i) => <option value={v} key={i}>{v}</option>)}
                </IwtbSelect>}

                <IwtbSelect label='Budget' name='budget'>
                  <option value=''>Select Budget</option>
                  {config.projectBudgets.map((v, i) => <option value={v} key={i}>{v}</option>)}
                </IwtbSelect>
              </div>

              <div className='form-row'>
                <div className={classList({'col-md-4 mb-3': true, 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                    <label className='ul-form__label'>Inspection Required?</label>
                    <div className='ul-form__radio-inline'>
                      <IwtbRadio name='inspectionRequired' value='yes' />
                      <IwtbRadio name='inspectionRequired' value='no' />
                    </div>
                    <div className='invalid-feedback'>{ errors.inspectionRequired }</div>
                  </div>
              </div>

              {values.inspectionRequired === 'yes' && <div className='form-row'>
                <IwtbInput label='Inspection Required On (24-Hour Time)' name='inspectionRequiredOn' type='text' placeholder='DD/MM/YYYY HH:mm' required />
              </div>}

              <div className='form-row'>
                <div className={classList({'col-md-4 mb-3': true, 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                    <label className='ul-form__label'>Is site establishment required?</label>

                    <OverlayTrigger trigger="hover" placement="right" 
                      overlay={popover}
                      delay={{ show: 250, hide: 400 }}>
                      <i className="text-12 i-Information ml-1"></i>
                    </OverlayTrigger>

                    <div className='ul-form__radio-inline'>
                      <IwtbRadio name='establishmentRequired' value='yes' />
                      <IwtbRadio name='establishmentRequired' value='no' />
                      <IwtbRadio name='establishmentRequired' value='facilities already on-site' />
                    </div>
                    <div className='invalid-feedback'>{ errors.establishmentRequired }</div>
                  </div>
              </div>

              {values.establishmentRequired === 'yes' && <div className='form-row'>
                <IwtbSelect label='Site Establishment Organizer' name='establishmentOrganizer'>
                <option value=''>Select Organizer</option>
                <option value='Myself'>Myself</option>
                <option value='Company'>The individual or company undertaking the construction work</option>
                <option value='IWTB'>I required I WANT TO BUILD's assistance</option>
                </IwtbSelect>
              </div>}

              <div className='form-row'>
                <div className={classList({'col-md-4 mb-3': true, 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                    <label className='ul-form__label'>Have you lodged a Development Application (DA) or Complying Development Certificate (CDC)?</label>
                    <div className='ul-form__radio-inline'>
                      <IwtbRadio name='lodgedDA' value='yes' />
                      <IwtbRadio name='lodgedDA' value='no' />
                    </div>
                    <div className='invalid-feedback'>{ errors.lodgedDA }</div>
                  </div>
              </div>

              <div className='form-row'>
                <div className={classList({'col-md-4 mb-3': true, 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                  <label className='ul-form__label'>Have you received final Approval and Permit to begin construction?</label>
                  <div className='ul-form__radio-inline'>
                    <IwtbRadio name='receivedPermit' value='yes' />
                    <IwtbRadio name='receivedPermit' value='no' />
                  </div>
                  <div className='invalid-feedback'>{ errors.receivedPermit }</div>
                </div>
                {values.receivedPermit === 'no' && 
                  <Alert variant='warning'>
                    You require to obtain and upload these documents prior to commencing Construction!
                  </Alert>
                }
                {values.receivedPermit === 'yes' && 
                  <Alert variant='primary'>
                    Upload these documents required!
                  </Alert>
                }
                
              </div>

              <div className='form-row'>
                <div className="col-md-4 mb-3">
                  <IwtbPlaceAutocomplete name='projectLocation' label='Project Address' location={values.projectLocation} type='text' placeholder='Start typing address' locationSelected={ location => setFieldValue('projectLocation', location) } />
                   <div className="invalid-feedback">Please provide a valid address.</div>
                </div>
              </div>

              <div className='form-row'>
                  <div className='col-md-12 mb-3'>
                    <IwtbMultiSelect label='Trade / Specialist' name='trades' displayValue="text" groupBy='category' selectedValues={values.trades} onChange={ selects => setFieldValue('trades', selects) } options={[...config.tradeCategories, ...config.specialistCategories]} />
                  </div>
              </div>

              <div className='form-row'>
                <IwtbInput label='Tender Required By (24-Hour Time)' name='tenderRequiredBy' type='text' placeholder='DD/MM/YYYY HH:mm' required />
              </div>


              {/* Contact Details  */}

              <div className="border-top mt-2 mb-4"></div>

              <div>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <h4>Contact Details of the Person in Charge</h4>
                    {/* <p>If different from the person posting the project.</p> */}
                  </div>
                </div>
                
                {/* <div className='form-row'>
                  <IwtbInput label='Title' name='contact.title' type='text' placeholder='Title' required />
                  <IwtbInput label='First Name' name='contact.firstName' type='text' placeholder='First name' required />
                  <IwtbInput label='Surname' name='contact.lastName' type='text' placeholder='Surname' required />
                  <IwtbInput label='Mobile' name='contact.mobile' type='text' placeholder='0400 111 888' required />
                  <IwtbInput label='Email' name='contact.email' type='text' placeholder='Email' required />
                </div> */}

                <div className='form-row'>
                  <IwtbCheckbox name='isSamePerson' onClick={ e => samePersonClicked(e, setFieldValue, values) }>
                    <span>I am the person in charge</span>
                  </IwtbCheckbox>
                </div>
                
                { !isSamePerson && <>
                  <div className='form-row'>
                    <IwtbInput label='Title' name='contact.title' type='text' placeholder='Title' required />
                    <IwtbInput label='First Name' name='contact.firstName' type='text' placeholder='First name' required />
                    <IwtbInput label='Surname' name='contact.lastName' type='text' placeholder='Surname' required />
                    <IwtbInput label='Mobile' name='contact.mobile' type='text' placeholder='0400 111 888' required />
                    <IwtbInput label='Email' name='contact.email' type='text' placeholder='Email' required />
                  </div> 
                  
                  <div className='form-row'>
                    <div className="col-md-4 mb-3">
                      <IwtbPlaceAutocomplete label='Address' name='contact.location' location={values.contact.location} type='text' placeholder='Start typing address' locationSelected={ location => setFieldValue('contact.location', location)} />
                      <div className="invalid-feedback">Please provide a valid address.</div>
                    </div>
                  </div>

                </>}

              </div>

              {/* Files  */}

              <div className="border-top mt-4 mb-4"></div>

              <div>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <h4>Upload Files</h4>
                    <p>Select file category, drop or select files, then click upload button.</p>
                  </div>
                </div>

                <div className='col-md-12 mt-3'>
                  <IwtbFileUpload allowedTypes={AllowedFileTypes} uploadedFiles={values.files} setUploadedFiles={files => setFieldValue('files', files)} />
              </div>
              </div>

              {/* IWTB Additional Services  */}

              <div className="border-top mt-4 mb-4"></div>

              <div className='row mb-2'>
                <div className='col-md-6'>
                  <h4>Additional Service Available</h4>
                  <p>IWTB Optional Service</p>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-lg-3 col-md-3">
                  <div className="card h-100">
                    <div className="card-header">IWTB Project Manager</div>
                    <div className="card-body">
                      <p className="card-text">
                        Hire your own dedicated IWTB Project Manager- overseeing, facilitating, and co-ordinating your project necessities ensuring that trades, SOW’s and project provisions run effortlessly from start the finish. Your dedicated Project Manager is your direct point of contact between You and your project.
                        The dedicated Project Manager will liaise with the Site Supervisor daily and report to the Client regularly. Enjoy working alongside a IWTB Project manager.
                      </p>
                    </div>
                  
                    <div className="card-footer">
                      <label className="checkbox checkbox-primary mt-2">
                        <input type="checkbox" 
                          checked={hasService(values.additionalServices, IWTB_AdditionalServices.IWTB_PROJECT_MANAGER)} 
                          onChange={_ => toggleAdditionalService(IWTB_AdditionalServices.IWTB_PROJECT_MANAGER, values, setFieldValue)} 
                        />
                        <span>Apply</span>
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3">
                  <div className="card h-100">
                    <div className="card-header">IWTB Site Supervisor</div>
                    <div className="card-body">
                      <p className="card-text">
                      Hire your own Site Supervisor to coordinate & supervise trades and scope of works on a daily basis ensuring milestones are reached and ensuring all communications between Contractors and Project Manager are delegated. Your Site supervisor also handles the notifications and communications of any site issues, varies or unforeseen items live on site.
                      The Site Supervisor also manages the safety on site and holds everyone accountable for the safety on site.                        </p>
                    </div>
                    <div className="card-footer">
                      <label className="checkbox checkbox-primary mt-2">
                        <input type="checkbox" 
                          checked={hasService(values.additionalServices, IWTB_AdditionalServices.IWTB_SITE_SUPERVISOR)} 
                          onChange={_ => toggleAdditionalService(IWTB_AdditionalServices.IWTB_SITE_SUPERVISOR, values, setFieldValue)} 
                        />
                        <span>Apply</span>
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3">
                  <div className="card h-100">
                    <div className="card-header">IWTB Contract Administrator</div>
                    <div className="card-body">
                      <p className="card-text">
                      Have your own IWTB Contract Administrator Facilitating all trade contracts and project documentation, from the sortation of trade quotations and submissions to variations and invoice control.
                      Your Contract Administrator undertakes the seamless document handling of all your project value needs.
                      Enjoy the simplicity of your own project dedicated Contracts Administrator.                        </p>
                    </div>
                    <div className="card-footer">
                      <label className="checkbox checkbox-primary mt-2">
                        <input type="checkbox" 
                          checked={hasService(values.additionalServices, IWTB_AdditionalServices.IWTB_CONTRACT_ADMINISTRATOR)} 
                          onChange={_ => toggleAdditionalService(IWTB_AdditionalServices.IWTB_CONTRACT_ADMINISTRATOR, values, setFieldValue)} 
                        />
                        <span>Apply</span>
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3">
                  <div className="card h-100">
                    <div className="card-header">IWTB Site Labourer</div>
                    <div className="card-body">
                      <p className="card-text">
                      Have your own site dedicated labourer handling all material deliveries, material loadings, ensuring a clean and safe site and is the Site Supervisor’s assistant. Your site dedicated labourer will ensure workplace cleanliness, report immediately on any safety issues and liaise with the Site Supervisor on all assisting tasks and site related communications                        </p>
                    </div>
                    <div className="card-footer">
                      <label className="checkbox checkbox-primary mt-2">
                        <input type="checkbox" 
                          checked={hasService(values.additionalServices, IWTB_AdditionalServices.IWTB_SITE_LABOURER)} 
                          onChange={_ => toggleAdditionalService(IWTB_AdditionalServices.IWTB_SITE_LABOURER, values, setFieldValue)} 
                        />
                        <span>Apply</span>
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>

              </div>

              {showSubscriptions && authState.user?.userGroup === UserGroup.CLIENT && <>
                  <ClientSubscriptionWidget show={!paid} subscriptions={subscriptions} current={authState.subscription} onSelect={handleClientPayment} />
                  <PaymentWidget 
                    show={showPayment} 
                    userId={authState.user.id}
                    subscription={newSubscription}
                    onPaymentCompleted={handlePaymentComplete} 
                    onHide={() => setShowPayment(false)}
                  />
              </>}

              <div className="border-top mt-4 mb-4"></div>

              {authState.user.status !== UserStatus.ACTIVE && <div className='mb-3'><span className='text-18 text-warning'>Your account is under review! At this stage you are not allowed to submit projects.</span><br /></div>}
              
              {!isValid && <div className='form-row mb-3'><span className='text-18 text-danger'>Please check mandatory fields and try again.</span></div>}

                {handleSubmit && !handleApprove && <LaddaButton 
                  className='btn btn-success btn-lg border-0 mr-2 mb-2 position-relative'
                  loading={isSubmitting}
                  progress={0.5}
                  disabled={
                    isSubmitting || 
                    authState.user.status !== UserStatus.ACTIVE ||
                    (!paid && (project.status === ProjectStatus.DRAFT || project.status === undefined))
                  }
                  data-style={EXPAND_RIGHT}
                  type='submit'
                >Submit</LaddaButton>}

                {handleDraft && <LaddaButton 
                  className='btn btn-info btn-lg border-0 mr-2 mb-2 position-relative'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  progress={0.5}
                  onClick={_ => onSaveDraft(values)}
                  data-style={EXPAND_RIGHT}
                  type='button'
                >Save Draft</LaddaButton>}

                {handleApprove && <LaddaButton 
                  className='btn btn-success btn-lg border-0 mr-2 mb-2 position-relative'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  progress={0.5}
                  data-style={EXPAND_RIGHT}
                  type='submit'
                >Approve</LaddaButton>}

                {handleRequestInfo && <Button className="btn btn-info btn-lg border-0 mr-2 mb-2" onClick={_ => handleRequestInfo(values)}>Request Info</Button>}

                {handleReject && <LaddaButton 
                  className='btn btn-danger btn-lg border-0 mr-2 mb-2 position-relative'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  progress={0.5}
                  onClick={_ => handleReject(values)}
                  data-style={EXPAND_RIGHT}
                  type='button'
                >Reject</LaddaButton>}

              {handleCancel && <Button className="btn btn-warning btn-lg border-0 mr-2 mb-2" onClick={handleCancel}>Cancel</Button>}

              <SweetAlert show={showUploadPermit} title="Document Upload" type="error" text="Documents are required to submit" onConfirm={() => setShowUploadPermit(false)}/>

            </Form>
          );
        }}
      </Formik>

      <NotificationContainer />
  </>
  );
}
