import React from "react";
import { ReactEcharts } from ".";

export const SubscriptionReport = ({ height, data = [] }) => {
    
    const option = {
        legend: {},
        tooltip: {
            trigger: 'axis',
            axisPointer: {
              // Use axis to trigger tooltip
              type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        dataset: {
          source: data,
        //   [
        //     ['userGroup', 'None', 'Free trial', 'Expired', 'Paid'],
        //     ['Client', 43, 85, 11, 93],
        //     ['Contractor', 10, 14, 2, 45],
        //     ['Specialist', 21, 4, 4, 59],
        //   ]
        },
        // Declare an x-axis (category axis).
        // The category map the first column in the dataset by default.
        xAxis: { type: 'value' },
        // Declare a y-axis (value axis).
        yAxis: { type: 'category' },
        // Declare several 'bar' series,
        // every series will auto-map to each column by default.
        series: [
            { type: 'bar', stack: 'total', emphasis: { focus: 'series' }}, 
            { type: 'bar', stack: 'total', emphasis: { focus: 'series' }}, 
            { type: 'bar', stack: 'total', emphasis: { focus: 'series' }}, 
            { type: 'bar', stack: 'total', emphasis: { focus: 'series' }}
        ]
    }

    const onChartReady = instance => {

        if (instance) {
          instance.on('click', (event) => {
            console.log('event: ', event.name)
          })
        }
    }
    
    return <ReactEcharts style={{ height: height }} option={option} onChartReady={onChartReady} />;
}
