import React, { useEffect, useState } from 'react'
import { Wrapper } from '@googlemaps/react-wrapper'
import { DefaultCenter, DefaultZoom } from './constants'
import mapsService from 'app/map/mapsService'
import { Marker, Map } from '.'

export const GoogleMap = ({ projects }) => {

  const [clicks, setClicks] = useState([])
  const [zoom, setZoom] = useState(DefaultZoom)
  const [center, setCenter] = useState(DefaultCenter)
  const [appKey, setAppKey] = useState()

  useEffect(() => {

    mapsService.getAppKey().then(result => {
      setAppKey(result.key)
      setZoom(result.zoom)
    })
  }, [])

  const onClick = (e) => {

    setClicks([...clicks, e.latLng])
  }

  const render = (status) => {

    return <h3>{status}</h3>
  }

  return (
    <div style={{ display: 'flex', height: '50vh', width: '100%' }}>
      {appKey && 
        <Wrapper apiKey={appKey} render={render}>
          <Map
            center={center}
            onClick={onClick}
            zoom={zoom}
            style={{ flexGrow: '1', height: '100%' }}
          >
            {projects.map((project, i) => <Marker key={i} location={project.projectLocation} />)}
          </Map>
        </Wrapper>
      }
    </div>
  )
}
