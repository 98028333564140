
import React from "react";
import { ClientSubscriptionKey } from 'app/utils/constants';
import { Badge, Alert } from "react-bootstrap";
import { StatusClass, SubscriptionStatus } from 'app/utils/constants';

export const ClientSubscriptionWidget = ({ show, subscriptions, current, onSelect }) => {
     
    let content = <></>

    if (subscriptions?.length > 0) {
        content = subscriptions.map((subscription, index) => {

            let buttonTitle ='Buy'
            if (subscription.key === ClientSubscriptionKey.TRIAL) {
                if (current) {
                    // do not offer free trial when user had a subscription
                    return <span key={index}></span>
                }
                else {
                    buttonTitle ='Start'
                }
            } 
            return <div key={index} className="col-lg-4 col-xl-4">
                <div className="ul-pricing__table-1 mb-4">
                    <div className="ul-pricing__image card-icon-bg-primary">
                        <i className={subscription.icon}></i>
                    </div>
                    <div className="ul-pricing__title">
                        <h2 className="heading text-primary">{subscription.title}</h2>
                    </div>
                    <div className="ul-pricing__main-number">
                        <h3 className="heading display-3 text-primary t-font-boldest">${subscription.price}</h3>
                        <div className="ul-pricing__table-listing mb-4">
                        <ul>
                            <li className="t-font-bolder text-mute">plus GST</li>
                            <li className="ul-pricing__text text-mute">{subscription.description}</li>
                        </ul>
                    </div>
                </div>
                <button type="button" className="btn btn-lg btn-primary m-1" onClick={() => onSelect(subscription)}>{buttonTitle}</button>            </div>
            </div>
        })
    }

    return (<>
        <div className="row">
            <div className="col-md-12">
                
                {current?.status === SubscriptionStatus.ACTIVE && <Alert variant='light'>
                    <div className='d-flex flex-row justify-content-between'>
                        <span><strong>Subscription: </strong><Badge className={StatusClass[current.status.toUpperCase()]}>{current.status}</Badge></span>
                        <span><strong>{current.title}</strong></span>
                        <span><strong>Ref:</strong> {current.ref}</span>
                    </div>
                </Alert>}
                
                {(!current || current?.status === SubscriptionStatus.EXPIRED) && <Alert variant='danger'>
                    <div className='d-flex flex-row justify-content-between'>
                        <span><strong>Subscription: </strong><Badge className={StatusClass[SubscriptionStatus.EXPIRED.toUpperCase()]}>No active subscription</Badge></span>
                    </div>
                </Alert>}

                {show && <div className="row d-flex flex-row justify-content-center">{content}</div>}
            </div>
        </div>
    </>)
}
