import React, { useState, useCallback, useEffect } from 'react'
import swal from "sweetalert2"
import { SimpleCard } from '@gull'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { Dropdown, Badge, Form, Button } from "react-bootstrap"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { Breadcrumb } from '@gull'
import service from '../contractorService'
import { useAuthState } from 'app/auth'
import { ProjectAction } from '../constants'
import { StatusClass, paginationOptions } from 'app/utils/constants'
import { ContractorNavigation } from '../contractorRoutes'
import { formatDateTime12, formatDate, diffHours } from 'app/utils/time'
import { getMatchedTrades, formatMatchedTrades } from '../functions'
import { MdMoreVert} from "react-icons/md"
import { handleOpenChat } from 'app/utils/common'
import { useHistory } from "react-router-dom"
import { GoogleMap } from 'app/map'
import { useConfiguration } from 'app/configuration/ConfigurationContext';

export const TenderingProjects = () => {

  const authState = useAuthState()
  const history = useHistory()
  const { SearchBar, ClearSearchButton } = Search
  const [loading, setLoading] = useState(true)
  const [projects, setProjects] = useState([])
  const [tradeMatchOn, setTradeMatchOn] = useState(true)
  const [showMap, setShowMap] = useState(false)
  const config = useConfiguration()

  const getTenderingProjects = useCallback((match = true) => {

    service.getTenderingProjects(authState.user.id, match)
    .then(result => {
      if (result.error) {
        NotificationManager.error(result.message, 'Server Connection', 3000)      
      }
      else {
        const items = result.map((item, i) => { return {...item, index: i + 1 }})
        setLoading(false)
        setProjects(items) 
      }
    })
    .catch(e => {
      NotificationManager.error('Something went wrong.', 'Server Connection', 3000)      
    })
  })

  const projectColumns = [
    {
      dataField: 'state',
      text: 'State',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '70px' }),
    },
    {
      dataField: 'projectName',
      text: 'Project',
      headerAlign: 'center',
      sort: true
    },
    {
      dataField: 'budget',
      text: 'Budget',
      headerAlign: 'center',
      align: 'center',
      sort: true,
    },
    {
      dataField: 'created',
      text: 'Listed Date',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: ((cell, row) => formatDate(row.created))
    },
    {
      dataField: 'tenderRequiredBy',
      text: 'Quotes Due',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: ((cell, row) => formatDateTime12(row.tenderRequiredBy))
    },
    {
      dataField: 'Time Remaining',
      text: 'Time Remaining',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: ((cell, row) => { 
        let retVal = ''
        if (row.created && row.tenderRequiredBy) {
          const hours = diffHours(row.tenderRequiredBy) 

          if (hours && hours > 24) {
            retVal = <span className="ul-widget3-status text-success t-font-bolder">{ Math.floor(hours/24) } days</span>
          }
          else {
            retVal = <span className="ul-widget3-status text-warning t-font-bolder">{ hours } hours</span>
          }
        }
        return retVal
      })
    },
    {
      dataField: 'revision',
      text: 'Rev',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '40px' })
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '90px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
  ]

  const projectInfoColumns = [
    {
      dataField: 'projectDetails',
      text: 'Details',
      headerAlign: 'center',
      headerStyle: () => ({ width: '30%' })
    },
    {
      dataField: 'classification',
      text: 'Classification',
      headerAlign: 'center',
    },
    {
      dataField: 'sector',
      text: 'Sector',
      headerAlign: 'center',
    },
    {
      dataField: 'trades',
      text: 'Matched Trades',
      headerAlign: 'center',
      formatter: ((cell, row) => {
        const trades = formatMatchedTrades(authState.user, row)
        return <>{trades}</>
      })
    },
    {
      dataField: 'Action',
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '60px' }),
      formatter: ((cell, row) => { 
        const trades = getMatchedTrades(authState.user, row)

        return <Dropdown onSelect={(action) => actionProject(action, row)}>
          <Dropdown.Toggle as="span" className="cursor-pointer toggle-hidden">
            <MdMoreVert size={18}></MdMoreVert>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey={ProjectAction.CONTACT_CLIENT}><i className='i-Business-Man text-success'> </i>Contact Client</Dropdown.Item>
            {trades.length > 0 && <Dropdown.Item eventKey={ProjectAction.REQUEST_ACCESS}><i className="i-Firewall text-warning"> </i>Request Access</Dropdown.Item>}
        </Dropdown.Menu>
        </Dropdown>
      })
    },
]

  useEffect(() => {

    getTenderingProjects(tradeMatchOn)

    const interval = setInterval(() => {
      getTenderingProjects(tradeMatchOn)
    }, config.SCHEDULE.TENDERING_REGISTER_SCHEDULE)

    return () => clearInterval(interval)

  }, [tradeMatchOn])
  
  // useEffect(() => {

  //   if (queryMap) {
  //     if (debounceTimer) {
  //       clearTimeout(debounceTimer)
  //     }
  //     debounceTimer = setTimeout(() => {

  //       const list = projects.filter(item =>
  //         item.projectName.toLocaleLowerCase().match(queryMap.toLowerCase())
  //       )
  //       setProjects(list)
  //     }, 250);
  //   }
  // }, [queryMap])

  const expendRow = {

    parentClassName: 'table-primary',
    showExpandColumn: true,
    renderer: row => (
      <BootstrapTable keyField='index' data={[row]} columns={projectInfoColumns} />
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
      if (expanded) {
        return <i className='nav-icon i-Arrow-Down font-weight-900 text-primary cursor-pointer'></i>    
      }
      else {
        return <i className='nav-icon i-Arrow-Right font-weight-900 text-primary cursor-pointer'></i>    
      }
    },
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (''),
  }

  const submitAccess = (project) => {
    
    swal.queue([{
      title: "Write your request",
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Request access",
      showLoaderOnConfirm: true,
      inputAutoTrim: true,
      inputValidator: result => !result && 'You need to enter something!',
      preConfirm: message => {
        if (message.length > 0) {
          return service.submitAccess(authState.user.id, project.id, message)
          .then(response => {
            if (response.error) {
              swal.showValidationMessage(`Submission failed: ${response.message}`)
            }
            swal.insertQueueStep('Your request was submitted')
          })
          .catch(e => NotificationManager.error('Something went wrong.', 'Server Connection', 3000))
        }
      },
      allowOutsideClick: () => !swal.isLoading()
    }])
    .then(result => {
      if (result.value) {
        history.push(ContractorNavigation.TENDERING_PROJECTS)
      }
    })
  }

  const actionProject = (action, project) => {

    switch(action) {

      case ProjectAction.REQUEST_ACCESS:
        return submitAccess(project)
  
      case ProjectAction.CONTACT_CLIENT:
        return handleOpenChat(authState, history, project.clientId)
    }
  }

  const handleTradeFilterChanged = value => {
    setTradeMatchOn(!value)
  }

  return (
    <div>
      <Breadcrumb
        routeSegments={[
          { name: 'Tendering Projects' }
        ]}
      ></Breadcrumb>

      <div className='row'>
          <div className='col-md-12'>

          <SimpleCard>

            <ToolkitProvider
              keyField='id'
              data={projects}
              columns={projectColumns}
              search
              exportCSV
            >
              {props => (
                <>
                  <div className="row row-xs">
                    <div className="col-md-5 d-flex align-items-center">
                      {showMap && 
                        <Button variant="primary" className='btn-google btn-icon text-capitalize mb-2 mr-4' onClick={() => setShowMap(false)}>
                          <span className="ul-btn__icon"><i className='i-Align-Justify-All'></i></span>
                          <span className="ul-btn__text text-capitalize"> Table</span>
                        </Button>
                      }
                      {!showMap && 
                        <Button variant="primary" className='btn-google btn-icon text-capitalize mb-2 mr-4' onClick={() => setShowMap(true)}>
                          <span className="ul-btn__icon"><i className='i-Map-Marker'></i></span>
                          <span className="ul-btn__text text-capitalize"> Map</span>
                        </Button>
                      }
                      <Form.Check
                        name="tradeMatch"
                        onChange={() => handleTradeFilterChanged(tradeMatchOn)}
                        checked={tradeMatchOn}
                        type="switch"
                        id="tradeMatch"
                        label="My trades"
                      />
                    </div>
                    <div className="col-md-7 d-flex justify-content-end align-items-center">
                      {!showMap && <div>
                        <SearchBar {...props.searchProps} className='mb-0' style={{width: '100%'}} />
                        <ClearSearchButton { ...props.searchProps } className='btn btn-light mb-1 mr-1 ml-1' />
                      </div>}
                    </div>
                  </div>
                  {showMap && 
                    <GoogleMap projects={projects} />
                  }
                  {!showMap && 
                    <BootstrapTable
                      {...props.baseProps}
                      onSearch
                      bootstrap4
                      wrapperClasses="table-responsive"
                      pagination={paginationFactory({ ...paginationOptions, totalSize: projects.length })}
                      noDataIndication={'No records found'}
                      condensed
                      loading={loading}
                      expandRow={expendRow}
                    />
                  }
                </>
              )}
            </ToolkitProvider>
          </SimpleCard>
        </div>
      </div>

      <NotificationContainer />
    </div>
  )
}
