import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { loadScript } from '@paypal/paypal-js'

export const PayPalWidget = ({ userId, clientId, subscription, terms, onPaymentCompleted }) => {
	
	const [paypal, setPaypal] = useState()

	const initButtons = async (clientId) => {
		
		if (!clientId || !paypal) {
			return
		}
		if (!subscription?.price) {
			return NotificationManager.error('Incorrect total', 'Payment', 6000)
		}

		const total = ((subscription.price ? subscription.price : 0) * 1.1).toFixed(2)

		paypal.Buttons({
			createOrder: (data, actions) => {
			  return actions.order.create({
				purchase_units: [{ amount: { 
					value: total,
				}}],
			  })
			},
			onApprove: (data, actions) => {
				return actions.order.capture().then(order => {
					console.log('Order: ', order)
					const transaction = order.purchase_units[0].payments.captures[0];
					console.log('Transaction:', transaction);
	           
					onPaymentCompleted(order)
				})
			},
			onCancel: (data) => {
				NotificationManager.warning('You have not completed the payment.', 'Payment', 3000)
			},
			onError: (err) => {
				console.log(`PayPal: `, err)
				NotificationManager.error('Sorry, something went wrong.', 'Payment', 6000)
				onPaymentCompleted()
			}
		})
		.render('#paypal-container')
		.catch((error) => {
			console.error('failed to render the PayPal Buttons', error);
		});
	}

	useEffect(() => {

		loadScript({ 'client-id': clientId, currency: 'AUD' })
		.then((paypal) => setPaypal(paypal))
		.catch((error) => {
			console.error("failed to load the PayPal JS SDK script", error);
		});
	}, [])

	useEffect(() => {

		initButtons(clientId)
	}, [paypal, clientId, terms])

	return (<>
		<div id='paypal-container' />
	</>)
}