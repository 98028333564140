
export const SocketEvent = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  USER_LEFT_CHAT: 'user-left-chat',  
  CHAT: 'chat',
  SWITCH_ROOM: 'switch-room',
  SWITCH_ROOM_SUCCESS: 'switch-room-success',
  ERROR: 'error',
  USER_NOTIFICATION: 'user-notification',
  NOTIFICATION_REFRESH: 'notification-refresh',
}

export const SocketActions = {
  DISCONNECT: 'disconnect',
  CHAT: 'chat',
  ERROR: 'error',
  INIT: 'init',
  USER_LOGIN_ERROR: 'user-login-error',
  USER_LOGOUT: 'user-logout',
  USER_LOGIN: 'user-login',
  SWITCH_ROOM: 'switch-room',
  SWITCH_ROOM_SUCCESS: 'switch-room-success',
}