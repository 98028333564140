import React, { useState, useEffect } from 'react';
import { getBadgeUserGroupColor, handleOpenChat } from 'app/utils/common';
import { paginationOptions, UserGroup } from 'app/utils/constants';
import { MdMoreVert} from "react-icons/md";
import ContactEditor from "./ContactEditor";
import { Dropdown } from "react-bootstrap";
import swal from "sweetalert2";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useAuthState } from 'app/auth';
import { useHistory } from "react-router-dom";
import { ProfileImageWidget } from 'app/user';

export const ContactsWidget = (props) => {

  const history = useHistory();
  const [showEditorDialog, setShowEditorDialog] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [dialogValues, setDialogValues] = useState();
  const authState = useAuthState();

  const { SearchBar, ClearSearchButton } = Search;

  const columns = [
    {
      dataField: 'index',
      text: '#',
      headerAlign: 'center',
      align: 'center',
      headerStyle: (colum, colIndex) => ({ width: '40px' }),
    },
    {
      dataField: 'Avatar',
      text: 'Avatar',
      headerAlign: 'center',
      align: 'center',
      headerStyle: (colum, colIndex) => ({ width: '60px' }),
      formatter: ((cell, row) => { 
        return <ProfileImageWidget profileImage={row.profileImage} userGroup={row.userGroup} className="rounded-circle m-0 avatar-sm-table" />
      })
    },
    {
      dataField: 'firstName',
      text: 'First Name',
      headerAlign: 'center',
      sort: true,
    },
    {
      dataField: 'lastName',
      text: 'Surname',
      headerAlign: 'center',
      sort: true,
    },
    {
      dataField: 'userGroup',
      text: 'Role',
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => { 
        return  <div className={`badge badge-${getBadgeUserGroupColor(row.userGroup)} p-2 text-capitalize`}>
          {row.userGroup}
        </div>
      })
    },
    {
      dataField: 'businessName',
      text: 'Company',
      headerAlign: 'center',
      sort: true,
    },
    {
      dataField: 'mobile',
      text: 'Mobile',
      headerAlign: 'center',
      align: 'center',
    },
    {
      dataField: 'email',
      text: 'Email',
      headerAlign: 'center',
      align: 'center',
    },
    {
      dataField: 'website',
      text: 'Website',
      headerAlign: 'center',
      align: 'center',
    },
    {
      dataField: 'Action',
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '60px' }),
      formatter: ((cell, row) => { 
        return  <Dropdown alignRight>
          <Dropdown.Toggle as="span" className="cursor-pointer toggle-hidden">
            <MdMoreVert size={18}></MdMoreVert>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            
          <Dropdown.Item onClick={() => handleOpenChat(authState, history, row.id)}>
            <i className="nav-icon i-Speach-Bubbles text-default font-weight-bold mr-2"></i>
            Chat
          </Dropdown.Item>

            <Dropdown.Item onClick={() => handleViewCard(row)}>
              <i className="nav-icon i-Engineering text-default font-weight-bold mr-2"></i>
              View Card
            </Dropdown.Item>

            <Dropdown.Item onClick={() => handleEditContact(row)}>
              <i className="nav-icon i-Pen-2 text-success font-weight-bold mr-2"></i>
              Edit Contact
            </Dropdown.Item>

            <Dropdown.Item
              onClick={() => {
                swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No"
                  })
                  .then(result => {
                    if (result.value) {
                      props.deleteContact(row);
                    } 
                  });
              }}
            >
              <i className="nav-icon i-Close-Window text-danger font-weight-bold mr-2"></i>
              Delete Contact
            </Dropdown.Item>
          
          </Dropdown.Menu>
        </Dropdown>
      })
    },
  ];

  useEffect(() => {

    if (props.contacts && Array.isArray(props.contacts)) {
      setContacts(props.contacts);
    }
  }, [props.contacts]);

  const toggleEditorDialog = arg => {

    if (arg && arg.toString()) {
      setShowEditorDialog(arg);
    }
    else {
        setShowEditorDialog(!showEditorDialog);
    }
    setDialogValues();
  };

  const handleViewCard = contact => {

    if (authState.user.userGroup === UserGroup.CLIENT && authState.navigation && authState.navigation.CONTRACTOR_CARD) {
      const path = authState.navigation.CONTRACTOR_CARD.replace(':contractorId', contact.id);
      history.push({ pathname: path })
    }
  };

  const handleEditContact = contact => {

    setDialogValues(contact);
    setShowEditorDialog(true);
  };

  const handleFormSubmit = async values => {

    if (!dialogValues) {
      props.addContact(values);
    } else {
      props.updateContact({ ...dialogValues, ...values });
    }
    toggleEditorDialog(false);
  };

  return ( <>
    {contacts && <>
      <ToolkitProvider
        keyField='id'
        data={contacts}
        columns={columns}
        search
        exportCSV
      >
        {props => (<>
          <div className='d-flex justify-content-end align-items-center'>
            
            <span className='mb-2 mr-1'>Search:</span>
            <SearchBar {...props.searchProps} className='mb-0 ' />
            <ClearSearchButton { ...props.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
            <button className='btn btn-outline-success mb-2 mr-1' onClick={_ => props.csvProps.onExport()}>Export CSV</button>
            <button className='btn btn-primary mb-2' onClick={toggleEditorDialog}>New Contact</button>
          </div>
          <BootstrapTable
            {...props.baseProps}
            bootstrap4
            wrapperClasses="table-responsive"
            pagination={paginationFactory({ ...paginationOptions, totalSize: contacts.length })}
            noDataIndication={'No records found'}
            condensed
          />
        </>)}
      </ToolkitProvider>
      
      <ContactEditor
        show={showEditorDialog}
        toggleEditorDialog={toggleEditorDialog}
        initialValues={dialogValues}
        handleFormSubmit={handleFormSubmit}
      ></ContactEditor>
    </>}
  </>);
}

