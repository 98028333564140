import React, { useEffect } from "react";
import IwtbLayout from "app/IwtbLayout/IwtbLayout";
import { useAuthState } from 'app/auth';
import { useHistory } from "react-router-dom";
import { SubscriptionStatus, UserStatus, UserGroup, ProfileCardTab } from "app/utils/constants";
import { NotificationContainer, NotificationManager } from "react-notifications";

export const AuthGuard = (props) => {

  const authState = useAuthState();
  const history = useHistory();

  useEffect(() => {

    const path = history.location.pathname
    const authPath = path !== '/' && path.indexOf('/chats') === -1 && path.indexOf('/profile') === -1 && path.indexOf('/contact-us') === -1

    if (authState.user && authPath) {
      // redirect to profile page for unverified or suspended users
      if (authState.user.status === UserStatus.UNVERIFIED || authState.user.status === UserStatus.SUSPENDED || authState.user.status === UserStatus.PENDING) {
        NotificationManager.warning(`Your account is ${authState.user.status}. Your access is limited to a few resources at this stage.`, 'Unauthorized User', 10000)        
        props.history.push({
          pathname: (authState.navigation.USER_PROFILE)?.replace(':tab?', ProfileCardTab.SUBSCRIPTION),
        })          
      }
      else if (authState.user.userGroup === UserGroup.CONTRACTOR || authState.user.userGroup === UserGroup.SPECIALIST) {
        // redirect to membership page for contractors without active subscription
        if (authState.subscription?.status !== SubscriptionStatus.ACTIVE) {
          NotificationManager.warning('You need current subscription to access the portal resources.', 'Unauthorized User', 10000)        
          props.history.push({
            pathname: (authState.navigation.USER_PROFILE)?.replace(':tab?', ProfileCardTab.SUBSCRIPTION),
          })          
        }
      }
    }
  }, [history.location])

  return (<>
    <IwtbLayout route={props.route}></IwtbLayout>
    <NotificationContainer />
  </>);
}
