import React from 'react';
import { formatDateTime12 } from 'app/utils/time';
import { Badge } from "react-bootstrap";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { paginationOptions, StatusClass, audFormatter } from 'app/utils/constants';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

export const SowWidget = ({ sow }) => {
  
  const { SearchBar, ClearSearchButton } = Search;

  const columns = [
    {
      dataField: 'index',
      text: '#',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '50px' }),
    },
    {
      dataField: 'task',
      text: 'Task',
      headerAlign: 'center',
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
    {
      dataField: 'amount',
      text: 'Amount',
      headerAlign: 'center',
      align: 'right',
      headerStyle: () => ({ width: '120px' }),
      formatter: ((cell, row) => row.amount ? audFormatter.format(row.amount) : audFormatter.format(0))
    },
  ];  

  return (
    <div>
      {sow && <>

        <div className="card">

          <div className="card-header bg-transparent">
            <div className='d-flex flex-row justify-content-between'>
              {sow.status && <span>Status: <Badge className={StatusClass[sow.status.toUpperCase()]}>{sow.status}</Badge></span>}
              {sow.created && <>Submitted: {formatDateTime12(sow.created)}</>}
              {sow.updated && <>Confirmed: {formatDateTime12(sow.updated)}</>}
            </div>
          </div>

          <div className="card-body">

            <div className='row mt-1'>
              <div className='col-md-12'>
                <label htmlFor='details'><h5>Details</h5></label>
                <textarea value={sow.details} className='form-control' readOnly />
              </div>
            </div>
            
            <div className='row mt-4'>
              <div className='col-md-12'>

                <ToolkitProvider
                  keyField='index'
                  data={sow.tasks}
                  columns={columns}
                  search
                  exportCSV
                >
                  {props => (
                    <>
                      <div className='d-flex flex-row justify-content-between align-items-center'>
                        <div>
                          <label htmlFor='details'><h5>Tasks</h5></label>
                        </div>
                        <div>
                          <span className='mb-2 mr-1'>Search:</span>
                          <SearchBar {...props.searchProps} className='mb-0 ' />
                          <ClearSearchButton { ...props.searchProps } className='btn btn-light mb-1 mr-1 ml-1' />
                          <button className='btn btn-outline-success mb-1 mr-1' onClick={_ => props.csvProps.onExport()}>Export CSV</button>
                        </div>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4
                        wrapperClasses="table-responsive"
                        pagination={paginationFactory({ ...paginationOptions, totalSize: sow.tasks.length })}
                        noDataIndication='No records found'
                        condensed
                      />
                    </>
                  )}
                </ToolkitProvider>
                
              </div>
            </div>

          </div>              
        </div>
      </>}
    </div>
  );
}

