import React, { useState, useEffect } from "react";
import { Formik, Form } from 'formik';
import { Button, Modal } from "react-bootstrap";
import { IwtbMultiSelect } from 'app/widgets';
import { TradeStatus } from "app/utils/constants";
import { useConfiguration } from "app/configuration/ConfigurationContext";

export const AddTradeWidget = ({ show, currentTrades, onSubmit, onClose }) => {

    const [trades, setTrades] = useState();
    const config = useConfiguration()

    useEffect(() => {

        if (currentTrades) {
            const activeTrades = currentTrades.filter(t => t.status !== TradeStatus.DELETED)
            setTrades(activeTrades)
        }
    }, [currentTrades]);
    

    return (
        <Formik enableReinitialize={true}>
        {({ values }) => {
            return (
            <Form className='needs-validation' noValidate>
                <Modal show={show} onHide={onClose} centered={true} size="lg">
                    <Modal.Header closeButton>
                    <Modal.Title>Add Trade / Specialist</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <IwtbMultiSelect label='Trade / Specialist' name='trades' displayValue="text" groupBy='category' selectedValues={trades} disablePreSelectedValues={true} onChange={selects => setTrades(selects)} options={[...config.tradeCategories, ...config.specialistCategories]} />
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => onSubmit(trades)}>
                        Save Changes
                    </Button>
                    </Modal.Footer>
                </Modal>
            </Form>
            );
        }}
        </Formik>
    )
}