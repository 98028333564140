import React, { useEffect } from 'react';
import { Breadcrumb } from "@gull";
import { Formik, Form, ErrorMessage } from 'formik';
import { classList } from '@utils';
import * as yup from 'yup';
import { NotificationManager } from "react-notifications";
import passwordService from './passwordService';
import ReactGA from 'react-ga';
import { useParams } from "react-router-dom";

export const NewPassword = () => {
  
  const { token } = useParams();

  const initialValues = {
    username: '',
    email: '',
    newPassword: '',
    rePassword: '',
  }

  const formSchema = yup.object().shape({
    username: yup.string().required('Username is required'),
    email: yup.string().required('Email is required').email('Invalid email'),
    newPassword: yup.string().required('New password is required'),
    rePassword: yup.string().required('Confirm password is required').when('newPassword', {
      is: val => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf(
        [yup.ref('newPassword')],
        'Both passwords need to be the same'
      )
    }),  
  });

  useEffect(() => {
    
    ReactGA.pageview(window.location.pathname);
  }, []);

  const newPassword = (values, resetForm, setSubmitting) => {
    
    if (values.username.length === 0) {
      return
    }
    try {
      ReactGA.event({ category: 'Password', action: 'New', value: values.username });
    } catch {}

    setSubmitting(true);
    passwordService.newPassword(token, values.newPassword, values.username, values.email).then(result => {
      setSubmitting(false);
      if (result?.error) {
        NotificationManager.error(result.message, 'New Password', 5000);
      }
      else {
        resetForm()
        NotificationManager.success('Your password has been reset.', 'New Password', 7000);
      }
    })
    .catch(ex => {
      setSubmitting(false);
      NotificationManager.error('Something went wrong', 'New Password', 5000);
    });
  };

  return (<>
      <Breadcrumb routeSegments={[{ name: "New Password" }]}></Breadcrumb>

      <Formik initialValues={initialValues} validationSchema={formSchema}>
      {({ values, errors, touched, isValid, isSubmitting, handleChange, resetForm, handleBlur, setSubmitting }) => {
        return (
          <Form>
            <div className="row">
              <div className="col-md-6">
                  <div className="card mb-5">
                    <div className="card-body">
                      <div className="d-flex flex-column">
                        <div className='form-row'>
                          <div
                            className={classList({
                              'col-md-12 mb-3': true,
                              'valid-field': !errors.username && touched.username,
                              'invalid-field': errors.username && touched.username,
                            })}
                          >
                            <label htmlFor='username'>Username</label>
                            <input
                              type='text'
                              className='form-control'
                              id='username'
                              name='username'
                              value={values.username}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                            />
                            <ErrorMessage name="username">{msg => <div className='invalid-feedback'>{msg}</div>}</ErrorMessage>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div
                            className={classList({
                              'col-md-12 mb-3': true,
                              'valid-field': !errors.email && touched.email,
                              'invalid-field': errors.email && touched.email,
                            })}
                          >
                            <label htmlFor='email'>Email</label>
                            <input
                              type='email'
                              className='form-control'
                              id='email'
                              name='email'
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                            />
                            <ErrorMessage name="email">{msg => <div className='invalid-feedback'>{msg}</div>}</ErrorMessage>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div
                            className={classList({
                              'col-md-12 mb-3': true,
                              'valid-field': !errors.newPassword && touched.newPassword,
                              'invalid-field': errors.newPassword && touched.newPassword,
                            })}
                          >
                            <label htmlFor='newPassword'>New password</label>
                            <input
                              type='password'
                              className='form-control'
                              id='newPassword'
                              name='newPassword'
                              value={values.newPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                            />
                            <ErrorMessage name="newPassword">{msg => <div className='invalid-feedback'>{msg}</div>}</ErrorMessage>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div
                            className={classList({
                              'col-md-12 mb-3': true,
                              'valid-field': !errors.rePassword && touched.rePassword,
                              'invalid-field': errors.rePassword && touched.rePassword,
                            })}
                          >
                            <label htmlFor='rePassword'>Confirm password</label>
                            <input
                              type='password'
                              className='form-control'
                              id='rePassword'
                              name='rePassword'
                              value={values.rePassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                            />
                            <ErrorMessage name="rePassword">{msg => <div className='invalid-feedback'>{msg}</div>}</ErrorMessage>
                          </div>
                        </div>
                        <button className="btn btn-warning pd-x-20" onClick={() => newPassword(values, resetForm, setSubmitting)} disabled={!isValid || isSubmitting}>Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </Form>
        );
      }}
      </Formik>
  </>);
}
