import React, { useEffect, useState, useCallback } from 'react';
import { Breadcrumb } from '@gull';
import { Modal, Tabs, Tab, Button, Alert } from 'react-bootstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import profileService from './profileService';
import { addSubscription, constructCompanyName, uploadFile, getProfileBackgroundUrl, getDefaultProfileImageUrl, customTabHeader } from 'app/utils/common';
import { IwtbImageCrop } from 'app/widgets';
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda';
import { ContractorSubscriptionKey, UserGroup, SubscriptionStatus, ClientSubscriptionKey } from 'app/utils/constants';
import { MyProfileTab } from './constants';
import { useAuthDispatch, useAuthState, refreshUser } from 'app/auth';
import { CompanyEditWidget, UserEditWidget, UserEditFilesWidget, HistorySubscriptionWidget, UserEditPhotosWidget } from '.';
import { ContractorSubscriptionWidget, ClientSubscriptionWidget } from 'app/subscription'
import { PaymentWidget } from 'app/payment/PaymentWidget'
import guestService from 'app/guest/guestService';
import { useParams, useHistory } from "react-router-dom";
import { useSpinner } from 'app/IwtbLayout/SharedComponents/spinner/SpinnerContext'
import userService from 'app/user/userService';

export const MyProfile = () => {

  const history = useHistory();
  const authState = useAuthState();
  const authDispatch = useAuthDispatch();
  const [profile, setProfile] = useState();
  const [notFound, setNotFound] = useState(false);
  const [showImageCrop, setShowImageCrop] = useState(false);
  const [showBackgroundCrop, setShowBackgroundCrop] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const [profileBackground, setProfileBackground] = useState();
  const [profileBackgroundUrl, setProfileBackgroundUrl] = useState();
  const [defaultActiveKey, setDefaultActiveKey] = useState(MyProfileTab.ABOUT);
  const [showPayment, setShowPayment] = useState(false)
  const [subscriptions, setSubscriptions] = useState([])
  const [historySubscriptions, setHistorySubscriptions] = useState([])
  const [newSubscription, setNewSubscription] = useState()
  const [paid, setPaid] = useState(false)
  const spinner = useSpinner()

  const { tab } = useParams();

  const getUserProfile = useCallback(async user => {

    spinner.show()

    let profile;

    try {
      if (user.userGroup === UserGroup.CLIENT) {
        profile = await profileService.getClientProfile(user.id, user.id);
      }
      else if (user.userGroup === UserGroup.ADMIN) {
        profile = await profileService.getAdminProfile(user.id, user.id);
      }
      else {
        profile = await profileService.getContractorProfile(user.id, user.id);
      }
      if (profile?.error) {
        return NotificationManager.error(profile.message, 'Server Connection', 3000);      
      }

      if (profile?.id) {
        setProfile(profile);

        getMySubscriptions()
        
        if (profile.profileImage && profile.profileImage.length > 0) {
          setProfileImage(profile.profileImage);
        }
        else {
          const defaultProfileImage = getDefaultProfileImageUrl(profile.userGroup);
          setProfileImage(defaultProfileImage);
        }
        const url = await getProfileBackgroundUrl(profile);
        setProfileBackgroundUrl(url);
      }
      else {
        setTimeout(() => setNotFound(true), 2000);
      }

      let subscriptions = []
      if (user.userGroup === UserGroup.CONTRACTOR || user.userGroup === UserGroup.SPECIALIST) {
        subscriptions = await guestService.getContractorSubscriptions()
      }
      else if (user.userGroup === UserGroup.CLIENT) {
        subscriptions = await guestService.getClientSubscriptions()
      }
      if (subscriptions?.error) {
        return NotificationManager.error('Something went wrong.', 'Server Connection', 3000);      
      }
      if (subscriptions?.length > 0) {
        setSubscriptions(subscriptions)
      }  
    }
    catch {
      NotificationManager.error('Something went wrong.', 'Server Connection', 3000);
    }
    finally {
      spinner.hide()
    }
  });
  
  useEffect(() => {

    getUserProfile(authState.user);
  }, [authState.user]);

  useEffect(() => {

    if (authState?.user?.userGroup === UserGroup.ADMIN) {
      setDefaultActiveKey(MyProfileTab.COMPANY);
    }
    else {
      const profileTab = !tab || tab === ':tab' ? MyProfileTab.SUBSCRIPTION : tab;
      setDefaultActiveKey(profileTab);
    }
  }, [tab]);

  useEffect(() => {

    if (authState.subscription?.status === SubscriptionStatus.ACTIVE) {
      setPaid(true)
    }
    else {
      setPaid(false)
    }
  }, [authState.subscription]);

  const updateProfileImage = async () => {

    if (profileImage && profileImage.length > 0) {
      spinner.show()
      const userProfile = await profileService.updateProfileImage(authState.user.id, profileImage);
      spinner.hide()
      if (userProfile.error) {
        return NotificationManager.error('Profile image failed to upload.', 'File Upload', 3000);
      }
      setShowImageCrop(false);
      // this is to refresh local copy of user profile after image was updated
      refreshUser(authDispatch, authState.user.token);
    }
  }

  const updateProfileBackground = async () => {

    if (profileBackground && profileBackground.type) {
      spinner.show()
      profileBackground.name = 'background.jpeg'
      const blobName = await uploadFile(profileBackground);
      spinner.hide()
      if (blobName.error) {
        return NotificationManager.error('Profile background failed to upload.', 'File Upload', 3000);
      }
      const result = await profileService.updateProfileBackground(authState.user.id, blobName);
      spinner.hide()
      if (result.error) {
        return NotificationManager.error('Profile background failed to upload.', 'File Upload', 3000);
      }
      setShowBackgroundCrop(false);
      getUserProfile(authState.user);
    }
  }

  const handleUpgrade = async (subscription) => {

    if (subscription.key === ContractorSubscriptionKey.TRIAL || subscription.key === ClientSubscriptionKey.TRIAL) {
      
      // no payment required, add trial subscription
      await addSubscription(authDispatch, authState.user.id, subscription.key)
      getMySubscriptions()
    }
    else {
      // payment required, show payment widget
      setNewSubscription(subscription)  
      setShowPayment(true)
    }
  }

  const getMySubscriptions = () => {

    userService.getMySubscriptions(authState.user.id)
    .then(subscriptions => {
      if (subscriptions.error) {
        NotificationManager.error(subscriptions.message, 'Server Connection', 3000);      
      }
      else {
        setHistorySubscriptions(subscriptions); 
      }
    })
    .catch(e => { 
      NotificationManager.error('Something went wrong.', 'Server Connection', 3000) 
    });
  }

  const handlePaymentComplete = async (payment) => {

    // payment gateway has processed payment
    setShowPayment(false)
    // validate payment on the server, then add subscription
    await addSubscription(authDispatch, authState.user.id, newSubscription.key, payment)
    getMySubscriptions()
  }

  const cancelImageCrop = () => {

    setShowImageCrop(false)
    setProfileImage(profile.profileImage);
  }

  return ( <>
    <Breadcrumb
      routeSegments={[
        { name: `User Profile (${authState.user.status})` }
      ]}
    ></Breadcrumb>

    <Modal show={showImageCrop} onHide={() => setShowImageCrop(false)} centered={true} size="lg" scrollable={true} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Edit Profile Photo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <IwtbImageCrop circularCrop={true} dataUrlChanged={dataUrl => setProfileImage(dataUrl)} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={cancelImageCrop}>Cancel</Button>
        <Button variant='success' disabled={spinner.isLoading} onClick={updateProfileImage}>Crop & Save</Button>
      </Modal.Footer>
    </Modal>        

    <Modal show={showBackgroundCrop} onHide={() => setShowBackgroundCrop(false)} centered={true} size="lg" scrollable={true} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Edit Profile Background</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <IwtbImageCrop aspect={27 / 9} blobChanged={img => setProfileBackground(img)} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setShowBackgroundCrop(false)}>Close</Button>
        <LaddaButton 
              className='btn btn-success btn-lg border-0 mr-2 mb-2 position-relative'
              loading={spinner.isLoading}
              disabled={spinner.isLoading}
              progress={0.5}
              onClick={updateProfileBackground}
              data-style={EXPAND_RIGHT}
              type='button'
            >Crop & Save</LaddaButton>
      </Modal.Footer>
    </Modal>        

    {profile && <div>

        <div className='card user-profile o-hidden mb-4'>
          <div className='header-cover cursor-pointer' onClick={() => setShowBackgroundCrop(true)} style={{backgroundImage: `url(${profileBackgroundUrl})`}}></div>
          <div className='user-info'>
            <img onClick={() => setShowImageCrop(true)} className='profile-picture avatar-lg cursor-pointer mb-2' src={profileImage} alt='Profile Photo' title='Edit Photo' />
            <p className='m-0 text-24'>{constructCompanyName(profile)}</p>
            <p className='text-muted m-0'>{profile.role}</p>
          </div>
          <div className='card-body pt-4'>

            <Tabs defaultActiveKey={defaultActiveKey} className='justify-content-center profile-nav mb-4'>

              {profile.userGroup !== UserGroup.ADMIN && <Tab eventKey={MyProfileTab.ABOUT} title={customTabHeader(MyProfileTab.ABOUT, profile.userGroup === UserGroup.CLIENT ? 'i-Business-Man' : 'i-Engineering')}>
                <UserEditWidget user={profile} />
              </Tab>}

              <Tab eventKey={MyProfileTab.COMPANY} title={customTabHeader(MyProfileTab.COMPANY, 'i-Building')}>
                <CompanyEditWidget user={profile} />
              </Tab>

              {/* <Tab eventKey={MyProfileTab.PROJECTS} title={customTabHeader(MyProfileTab.PROJECTS, 'i-Gears')}>
                <ProjectsWidget projects={projects} />
              </Tab> */}

              <Tab eventKey={MyProfileTab.DOCUMENTS} title={customTabHeader(MyProfileTab.DOCUMENTS, 'i-Files')}>
                <UserEditFilesWidget user={profile} />
              </Tab>                        
                            
              <Tab eventKey={MyProfileTab.PHOTOS} title={customTabHeader(MyProfileTab.PHOTOS, 'i-Picasa')}>
                <UserEditPhotosWidget user={profile} />
              </Tab>                        

              {/* <Tab eventKey={MyProfileTab.REVIEWS} title={customTabHeader(MyProfileTab.REVIEWS, 'i-Like')}>
                <ReviewsWidget reviews={profile.reviews} />
              </Tab> */}

              {profile.userGroup !== UserGroup.ADMIN && <Tab eventKey={MyProfileTab.SUBSCRIPTION} title={customTabHeader(MyProfileTab.SUBSCRIPTION, 'i-Dollar-Sign')}>
                {(profile.userGroup === UserGroup.CONTRACTOR || profile.userGroup === UserGroup.SPECIALIST) &&
                    <ContractorSubscriptionWidget current={authState.subscription} subscriptions={subscriptions} onUpgrade={handleUpgrade} />
                }
                {profile.userGroup === UserGroup.CLIENT && 
                  <ClientSubscriptionWidget show={true} subscriptions={subscriptions} current={authState.subscription} onSelect={handleUpgrade} />
                }
                <PaymentWidget 
                  show={showPayment} 
                  userId={authState.user.id}
                  subscription={newSubscription}
                  onPaymentCompleted={handlePaymentComplete} 
                  onHide={() => setShowPayment(false)}
                />
                <div className="border-top mt-2 mb-4"></div>
                <HistorySubscriptionWidget subscriptions={historySubscriptions} />
              </Tab>}
            </Tabs>
          </div>
        </div>
      </div>}
        
      { !profile && notFound &&
        <Alert className='text-center alert-card' variant='danger'>
          Something went wrong. Profile was not found.
          <Button variant='warning' className='btn ml-3' onClick={_ => {history.goBack();}}>Return</Button>
        </Alert>
      }

      <NotificationContainer />
  </>)
}
