import React, { useState } from 'react';
import { SimpleCard } from '@gull';
import { NotificationContainer } from 'react-notifications';
import { Breadcrumb } from '@gull';
import { useLocation } from "react-router-dom";
import { ClientNavigation } from '../clientRoutes';
import { AccessRegisterWidget } from '../widgets/AccessRegisterWidget';

export const AccessRegister = (props) => {

  const [project, setProject] = useState();
  const route = useLocation();

  React.useEffect(() => {

    const state = route.state;
    if (state && state.project) {
      setProject(state.project);
    }
  }, [route]);

  return (
    <div>
      <Breadcrumb
        routeSegments={[
          { name: "Projects", path: ClientNavigation.PROJECTS },
          { name: 'Access Register' }
        ]}
      ></Breadcrumb>

      <div className='row'>
          <div className='col-md-12'>

          <SimpleCard>

            {project && <h2>{project.projectName}</h2>}

            <AccessRegisterWidget project={project} readOnly={props.readOnly} />

          </SimpleCard>
        </div>
      </div>

      <NotificationContainer />
    </div>
  );
}
