import React from 'react';
import { Breadcrumb } from '@gull';
import { SubmitBidWidget } from 'app/widgets'
import bidService from './bidService';

export const SubmitBid = () => {
  
  return (
    <div>
      <Breadcrumb
        routeSegments={[
          { name: 'Add Trade Bid' }
        ]}
      ></Breadcrumb>
      <SubmitBidWidget bidService={bidService} />
  </div>
  );
}

