import HttpService from 'app/services/httpService';

class PaymentService {

    http = new HttpService();
       
    verifyPayment = (userId, paymentId) => {
        return this.http.post(`payments/${paymentId}`, { userId: userId })
    }
 
    getPaymentToken = (userId, subscriptionKey, paymentProvider) => {
        return this.http.post(`payments/token`, { subscriptionKey: subscriptionKey, userId: userId, paymentProvider: paymentProvider })
    }

    checkout = (userId, subscriptionKey, payload) => {
        return this.http.post(`payments/checkout/${userId}`, { payload, subscriptionKey: subscriptionKey, userId: userId })
    }
}

export default new PaymentService();