import React from 'react';
import { Modal, FormGroup, FormControl, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { UserGroup } from 'app/utils/constants';

const ContactEditor = ({
  show,
  initialValues,
  toggleEditorDialog,
  handleFormSubmit
}) => {
  return (
    <Modal show={show} onHide={toggleEditorDialog} centered>
      <div className='modal-header'>
        <h5 className='modal-title' id='exampleModalLabel'>
          {initialValues ? 'Update' : 'New'} Contact
        </h5>
        <button
          type='button'
          className='close'
          aria-label='Close'
          onClick={() => toggleEditorDialog(false)}
        >
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <div className='modal-body'>
        <Formik
          initialValues={
            initialValues ? initialValues : { firstName: '', lastName: '', businessName: '', email: '', mobile: '', note: '' }
          }
          validationSchema={contactSchema}
          enableReinitialize={true}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>

              <FormGroup>
                <div key={'inline-radio'} className='mb-3'>
                <Form.Check
                    inline
                    label='Contractor'
                    name='userGroup'
                    type='radio'
                    id='inline-radio-1'
                    value={UserGroup.CONTRACTOR}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.userGroup === UserGroup.CONTRACTOR}
                  />
                  <Form.Check
                    inline
                    label='Specialist'
                    name='userGroup'
                    type='radio'
                    id='inline-radio-2'
                    value={UserGroup.SPECIALIST}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.userGroup === UserGroup.SPECIALIST}
                  />
                </div>              
              </FormGroup>

              <FormGroup>
                <FormControl
                  placeholder='First name...'
                  name='firstName'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.firstName && touched.firstName}
                  value={values.firstName}
                ></FormControl>
              </FormGroup>

              <FormGroup>
                <FormControl
                  placeholder='Last name...'
                  name='lastName'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.lastName && touched.lastName}
                  value={values.lastName}
                ></FormControl>
              </FormGroup>

              <FormGroup>
                <FormControl
                  placeholder='Business name...'
                  name='businessName'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.businessName && touched.businessName}
                  value={values.businessName}
                ></FormControl>
              </FormGroup>

              <FormGroup>
                <FormControl
                  placeholder='Enter email....'
                  name='email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.email && touched.email}
                  value={values.email}
                ></FormControl>
              </FormGroup>

              <FormGroup>
                <FormControl
                  placeholder='Enter website....'
                  name='website'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.website && touched.website}
                  value={values.website}
                ></FormControl>
              </FormGroup>   

              <FormGroup>
                <FormControl
                  placeholder='Mobile....'
                  name='mobile'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.mobile && touched.mobile}
                  value={values.mobile}
                ></FormControl>
              </FormGroup>

              <FormGroup>
                <FormControl
                  placeholder='Notes....'
                  name='note'
                  as='textarea'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.note && touched.note}
                  value={values.note}
                ></FormControl>
              </FormGroup>

              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-secondary'
                  onClick={() => toggleEditorDialog(false)}
                >
                  Close
                </button>
                <button type='submit' className='btn btn-primary'>
                  Save changes
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

const contactSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email().required('note is required'),
  mobile: yup.string().required('mobile is required')
});

export default ContactEditor;
