export const initialState = {
    notifications: [],
    loading: false,
    error: null,
};
 
export const NotificationReducer = (state, action) => {

    switch (action.type) {

        case 'FETCHING_NOTIFICATIONS':
            return {
                ...initialState,
                loading: true,
            };
            
        case 'FETCHING_NOTIFICATIONS_SUCCESS':
            return {
                ...state,
                notifications: action.payload,
                loading: false,
                error: null
            };    
        
        case 'FETCHING_NEW_NOTIFICATIONS_SUCCESS':
            return {
                ...state,
                newNotifications: action.payload,
                loading: false,
                error: null
            };    
                        
        case 'FETCHING_NOTIFICATIONS_ERROR':
            return {
                ...initialState,
                error: action.error
            };
    
        default:
            return state
    }
};