import React, { useEffect, useState } from 'react'
import storageService from 'app/services/storageService'
import { NotificationManager } from 'react-notifications'
import userService from 'app/user/userService'
import { specialistCategories, UserGroup, SowStatus } from './constants'
import { ImageFileTypes, ServiceStatus, BidStatus, ContractorSubscriptionKey, ClientSubscriptionKey } from 'app/utils/constants'
import { formatDateTimeIso } from 'app/utils/time'
import { ProjectTab } from 'app/admin/constants'
import { refreshSubscription } from 'app/auth';
import swal from "sweetalert2";
import { useConfiguration } from 'app/configuration/ConfigurationContext'

export {
    getTrades,
    deleteTrade,
    deleteService,
    addSubscription,
    handleDateChange,
    navigateTo,
    toggleAdditionalService,
    hasService,
    mapServices,
    handleOpenChat,
    customTabHeader,
    getSowBadge,
    groupBy,
    calcVariation,
    useScript,
    uniqArrayBy,
}

const getTrades = async (service, userId, projectId) => {

    if (!service?.getTrades) {
        return NotificationManager.error('Semething went wrong', 'Error', 3000);
    }
    const result = await service.getTrades(userId, projectId);
    if (!result || result?.error) {
        NotificationManager.error('Semething went wrong', 'Error', 3000);
        return [];    
      }
      if (result?.trades) {
          if (Array.isArray(result.bidRegister)) {
              const trades = result.trades.map(trade => {
                  // check if trade was awarded
                  const award = result.bidRegister.find(bid => bid.trade.id === trade.id && (bid.status === BidStatus.AWARDED || bid.status === BidStatus.ACCEPTED));
                  trade.awarded = award ? award.status : '';
                  return trade;
              })
              result.trades = trades
              return result;
          }
          else {
              return result;
          }
      }
}

const deleteTrade = async (service, userId, projectId, tradeId) => {

    return new Promise((resolve, reject) => {
        swal.queue([{
        title: "Enter removal reason",
        input: "textarea",
        inputAttributes: {
            autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        inputAutoTrim: true,
        inputValidator: result => !result && 'You need to enter something!',
        preConfirm: async message => {
            if (message.length > 0) {
                try {
                    const result = await service.deleteTrade(userId, projectId, tradeId, message);
                    if (!result || result.error) {
                        return reject(result.message);
                    }
                    return resolve();
                }
                catch(e) {
                    return reject();
                }
            }
        },
        allowOutsideClick: () => !swal.isLoading()
        }]);
    });
}

const deleteService = async (service, userId, projectId, serviceId) => {

    return new Promise((resolve, reject) => {
        swal.queue([{
        title: "Enter removal reason",
        input: "textarea",
        inputAttributes: {
            autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        inputAutoTrim: true,
        inputValidator: result => !result && 'You need to enter something!',
        preConfirm: async message => {
            if (message.length > 0) {
                try {
                    const result = await service.deleteService(userId, projectId, serviceId, message);
                    if (!result || result.error) {
                        return reject(result.message);
                    }
                    return resolve();
                }
                catch {
                    return reject();
                }
            }
        },
        allowOutsideClick: () => !swal.isLoading()
        }]);
    });
}

const useScript = src => {

    const [status, setStatus] = useState(src ? 'loading' : 'idle')

    useEffect(() => {

        if (!src) {
            setStatus('idle')
            return
        }

        const script = document.createElement('script')

        script.src = src
        script.async = true
        script.addEventListener('load', () => { 
            setStatus('ready')
        })
        script.addEventListener('error', () => { 
            setStatus('error')
        })

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [src])

    return status
}

const addSubscription = async (authDispatch, userId, subscriptionKey, payment) => {

    if (payment?.error) {
        return NotificationManager.error(payment.message ?? 'Your payment was unsuccessful. Please try again or contact our customer support.', 'Payment', 6000)  
    }
    if (!(subscriptionKey === ContractorSubscriptionKey.TRIAL || subscriptionKey === ClientSubscriptionKey.TRIAL)) {
        // do not show payment message for trial subscription
        NotificationManager.success('Thank you for your payment. Your subscription will be activated shortly.', 'Payment', 3000)
    }

    try {      
        const result = await userService.addSubscription(userId, subscriptionKey, payment)
        if (result?.error) {
            return NotificationManager.error(result.message ?? 'Unable to activate your subscription. Please contact our customer support.', 'Subscription', 7000)
        }
        NotificationManager.success('Your subscription has been activated', 'Subscription', 6000)
        refreshSubscription(authDispatch, userId)     
        return result   
    }
    catch {
        NotificationManager.error('Something went wrong. Please contact our customer support.', 'Subscription', 7000)
    }
}

const handleDateChange = (handleChange, filedName, date) => {

    return handleChange({
        target: {
            name: filedName,
            value: formatDateTimeIso(date)
        }
    })
}

const navigateTo = ({ history, navigation, pathname, project, tab, bid, user: user }) => {

    switch(pathname) {
       
        case navigation.SUBMIT_BID:
            pathname = pathname.replace(':projectId', project.id)
            break
         
        case navigation.APPROVE_USER:
            pathname = pathname.replace(':userId', user.id)
            break
       
        case navigation.PROJECT_CARD:
            pathname = pathname.replace(':projectId', project.id).replace(':tab?', ':tab').replace(':tab', tab ?? ProjectTab.DETAILS)
            break

        case navigation.EDIT_PROJECT:
        case navigation.APPROVE_PROJECT:
            pathname = pathname.replace(':projectId', project.id)
            break

        case navigation.CLIENT_CARD:
            pathname = pathname.replace(':clientId', project ? project.clientId : user?.id).replace(':tab?', tab ?? '')
            break

        case navigation.CONTRACTOR_CARD:
            pathname = pathname.replace(':contractorId', user?.id).replace(':tab?', ':tab').replace(':tab', tab ?? '')
            break
    
        case navigation.BID_CARD:
            pathname = pathname.replace(':projectId', bid.projectId).replace(':bidId', bid.bidId).replace(':tab?', tab)
            break
    }
    history.push({ pathname: pathname, state: { project: project }})  
}

const toggleAdditionalService = (service, values, setFieldValue) => {

    let services = [...values.additionalServices]
    var index = services.findIndex(v => v.service === service)

    if (index === -1) {
      services.push({ 
          status: ServiceStatus.PENDING,
          service: service,
          worker: ServiceStatus.UNALLOCATED,
          budget: 0,
          actual: 0,
        })
    } else {
      services.splice(index, 1)
    }
    setFieldValue('additionalServices', services)
}

const hasService = (services, service) => {

    const foundService = services.find(v => v.status !== ServiceStatus.DELETED && v.service === service)
    return foundService ? true : false
}

const mapServices = services => {

    const items = services.map((v, i) => {

        const budget = parseInt(v.budget)
        const actual = parseInt(v.actual)

        return {
            ...v,
            index: i + 1,
            status: v.status ?? ServiceStatus.PENDING,
            service: v.service ?? v, // back compatability, initially it was an array of strings
            worker: v.worker ?? ServiceStatus.UNALLOCATED,
            budget: isNaN(budget) ? 0 : budget,
            actual: isNaN(actual) ? 0 : actual
        }
    })
    return items
}

const handleOpenChat = (authState, history, opponentId) => {

    const pathname = authState.navigation.CHAT.replace(':opponentId?', opponentId)
    history.push({ pathname: pathname })
}

const customTabHeader = (title, icon, badge) => (
    <div className='d-flex align-items-center'>
      <span className='mr-2'>
        <i className={icon}></i>
      </span>
      <span>{title}</span>
      {badge && <span className={badge}></span>}
    </div>
)

const getSowBadge = sowStatus => {

    switch (sowStatus) {
      case SowStatus.CONFIRMED:
        return 'badge-dot-success ml-1'
      
        case SowStatus.PENDING:
            return 'badge-dot-twitter ml-1'

        case SowStatus.AMENDED:
            return 'badge-dot-warning ml-1'
    }
}

const uniqArrayBy = (objArray, key) => {

    const uniqueArray = [...new Map(objArray.map((item) => [item[key], item])).values()]
    return uniqueArray
}

const groupBy = (data, key) => { 

    // `data` is an array of objects, `key` is the key (or property accessor) to group by
    // reduce runs this anonymous function on each element of `data` (the `item` parameter,
    // returning the `storage` parameter at the end
    const groups = data.reduce((storage, item) => {

      // get the first instance of the key by which we're grouping
      let group = item[key] === undefined ? 'Other' : item[key]
      
      // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
      storage[group] = storage[group] || []
      
      // add this item to its group within `storage`
      storage[group].push(item)
      
      // return the updated storage to the reduce function, which will then loop through the next 
      return storage 
    }, {}) // {} is the initial value of the storage

    const grouped = Object.keys(groups).map((key, index) => {

        const files = groups[key].map((file, index) => ({ ...file, index: index + 1 }))
        return { index: index, group: key, files: files, count: groups[key].length }
    })
    return grouped
}  

const calcVariation = sows => {
    
    let variation = 0
    try {
        sows.map(sow => {
            sow.tasks.map(task => {
                if (task.status !== SowStatus.REJECTED) {
                    if (!isNaN(task.amount)) {
                        variation = variation + task.amount
                    }
                }
            })
        })
    }
    catch {}

    return variation
}

export const calcProjectTotal = (awardRegister, services) => {

    let total = 0

    if (Array.isArray(awardRegister)) {
      awardRegister.forEach(v => {
        if (v.status === BidStatus.AWARDED || v.status === BidStatus.ACCEPTED) {
          const variation = calcVariation(v.sow)
          total = total + v.amount + variation  
        }
      })
    }
    if (Array.isArray(services)) {
      services.forEach(v => {
        if (v.status !== ServiceStatus.DELETED && v.status !== BidStatus.DECLINED) {
            total = total + v.budget  
        }
      })
    }
    return total
}

export const addContactWithId = async (userId, contactId) => {

    try {
      const result = await userService.addContactWithId(userId, contactId)

      if (result && result.error) {
        NotificationManager.error('Update failed.', 'Contact', 3000)
      }
      else {
        NotificationManager.success('Update succeeded.', 'Contact', 3000)
      }
    }
    catch {
      NotificationManager.error('Update failed.', 'Contact', 3000)
    }
}

export const addContact = async (userId, contact) => {

    try {
      const result = await userService.addContact(userId, contact)

      if (result && result.error) {
        NotificationManager.error('Update failed.', 'Contact', 3000)
      }
      else {
        NotificationManager.success('Update succeeded.', 'Contact', 3000)
      }
    }
    catch {
      NotificationManager.error('Update failed.', 'Contact', 3000)
    }
}

export const getBadgeUserGroupColor = userGroup => {

    switch (userGroup) {
      case UserGroup.SPECIALIST:
        return 'primary'

      case UserGroup.CONTRACTOR:
        return 'success'

      case UserGroup.CLIENT:
        return 'warning'

      default:
        return 'primary'
    }
}

export const uploadFile = async (file) => {

    if (file && file.name) { 

        const formData = new FormData()
        formData.append('documents', file, file.name)
        try {
            const blobName = await storageService.uploadFiles(formData)
            return blobName
        }
        catch {
            return { error: true, message: 'File upload failed' }
        }
    }
    else {
        return { error: true, message: 'File upload failed' }
    }
}

export const deleteUserFile = async (userId, file) => {

    return new Promise((resolve, reject) => {
        if (userId && file?.blobName) {
            swal.fire({
                title: 'Are you sure?',
                icon: 'warning',
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete file',
                cancelButtonText: 'No'
            })
            .then(async result => {
                if (result.value) {
                    try {
                        let result = await deleteBlobFile(file.blobName)
                        if (result?.error) {
                            NotificationManager.error('Something went wrong.', 'Delete File', 3000)
                            reject()
                        }
                        result = await userService.deleteFile(userId, file.blobName)
                        if (result?.error) {
                            NotificationManager.error('Something went wrong.', 'Delete File', 3000)
                            reject()
                        }
                        NotificationManager.success('File was deleted.', 'Delete File', 2000)
                        resolve()
                    }
                    catch {
                        NotificationManager.error('Something went wrong.', 'Delete File', 3000)
                        reject()
                    }
                }
                else {
                    resolve()
                }
            }) 
        }
        else {
            NotificationManager.error('Something went wrong.', 'Delete File', 3000)
            reject()
        }
    })
}

export const deleteProjectFile = async (userId, projectId, file, service) => {

    return new Promise((resolve, reject) => {
        if (userId && projectId && file?.blobName && service?.deleteProjectFile) {
            swal.fire({
                title: 'Are you sure?',
                icon: 'warning',
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete file',
                cancelButtonText: 'No'
            })
            .then(async result => {
                if (result.value) {
                    try {
                        let result = await deleteBlobFile(file.blobName)
                        if (result?.error) {
                            NotificationManager.error('Something went wrong.', 'Delete File', 3000)
                            reject()
                        }
                        result = await service.deleteProjectFile(userId, projectId, file.blobName)
                        if (result?.error) {
                            NotificationManager.error('Something went wrong.', 'Delete File', 3000)
                            reject()
                        }
                        NotificationManager.success('File was deleted.', 'Delete File', 2000)
                        resolve()
                    }
                    catch {
                        NotificationManager.error('Something went wrong.', 'Delete File', 3000)
                        reject()
                    }
                }
                else {
                    resolve()
                }
            }) 
        }
        else {
            NotificationManager.error('Something went wrong.', 'Delete File', 3000)
            reject()
        }
    })
}

export const deleteBlobFile = async (blobName) => {

    if (blobName?.length > 0) { 

        try {
            const result = await storageService.deleteFile(blobName)
            return result
        }
        catch(e) {
            console.log('Error >> deleteFile >> ', e.message)
            return { error: true, message: 'File upload failed' }
        }
    }
    else {
        return { error: true, message: 'File upload failed' }
    }
}

export const getProfileBackgroundUrl = async (profile) => {

    if (profile.profileBackground && profile.profileBackground.length > 0) {
        const url = await downloadBlobFile(profile.profileBackground)
        if (url && url.error) {
          NotificationManager.error(url.message, 'Server Connection', 3000)    
          return getDefaultProfileBackgroundUrl(profile.userGroup)
        }
        else {
          return (url && url.length > 0) ? url : getDefaultProfileBackgroundUrl(profile.userGroup)
        }
    }
    else {
        return getDefaultProfileBackgroundUrl(profile.userGroup)
    }
}

export const getDefaultProfileBackgroundUrl = userGroup => {

    switch(userGroup) {
        case UserGroup.SPECIALIST:
        case UserGroup.CONTRACTOR:
                return '/assets/images/contractor-background.png'
        case UserGroup.CLIENT:
            return '/assets/images/client-background.png'
        case UserGroup.ADMIN:
            return '/assets/images/admin-background.png'
    }
}

export const getDefaultProfileImageUrl = userGroup => {
    switch(userGroup) {
        case UserGroup.SPECIALIST:
        case UserGroup.CONTRACTOR:
            return '/assets/images/contractor.png'
        case UserGroup.CLIENT:
            return '/assets/images/client.png'
        case UserGroup.ADMIN:
            return '/assets/images/admin.png'
        default:
            return '/assets/images/unknown-user.png'
    }
}
    
export const isImageFile = fileName => {
    
    let retVal = false
    const arr = fileName.split('.')

    if (arr.length > 0) {
      const ext = arr[arr.length - 1]
      if (ImageFileTypes.indexOf(ext) !== -1) {
        return true
      }
    }
    return retVal
}

export const downloadBlobArchive = async (files) => {

    if (Array.isArray(files) && files.length > 0) { 

        try {
            const result = await storageService.downloadArchive(files)
            if (result.error) {
                return { error: true, message: 'Download failed' }
            }
            const type = result.headers['content-type']
            const blob = new Blob([result.data], { type: type, encoding: 'UTF-8' })
            const url = window.URL.createObjectURL(blob)
            return url
          }
        catch {
            return { error: true, message: 'Download failed' }
        }
    }
    else {
        return { error: true, message: 'Download failed' }
    }
}

export const downloadBlobFile = async (blobName) => {

    if (blobName && blobName.length > 0) { 

        try {
            const result = await storageService.downloadFile(blobName)
            if (result.error) {
                return { error: true, message: 'Download failed' }
            }
            const type = result.headers['content-type']
            const blob = new Blob([result.data], { type: type, encoding: 'UTF-8' })
            const url = window.URL.createObjectURL(blob)
            return url
          }
        catch {
            return { error: true, message: 'Download failed' }
        }
    }
    else {
        return { error: true, message: 'Download failed' }
    }
}

export const constructCompanyName = contractor => {

    if (contractor === 'IWTB') {
        return contractor
    }
    else if (contractor && contractor.businessName) {
        return contractor.businessName 
    }
    else if (contractor && contractor.firstName) {
        return `${contractor.firstName} ${contractor.lastName}`
    }
    else {
        return ''
    }
}

export const getSowStatus = sow => {

    if (Array.isArray(sow) && sow.length > 0) {
        if (sow.filter(v => v.status === SowStatus.PENDING).length > 0) {
            return SowStatus.PENDING
        }
        if (sow.filter(v => v.status === SowStatus.AMENDED).length > 0) {
            return SowStatus.AMENDED
        }
        if (sow.filter(v => v.status === SowStatus.CONFIRMED).length === sow.length) {
            return SowStatus.CONFIRMED
        }
    }
    return ''
}

export const indexArray = arr => {

    if (arr && Array.isArray(arr)) {
        const indexedArray = arr.map((v, i) => {
            v.index = i + 1
            return v
        })
        return indexedArray
    }
    return arr
}

export const sortArray = (arr, property, desc = false) => {
    
    if (desc) {
        return arr.sort((a, b) => (a[property] < b[property] && 1) || -1)
    }
    else {
        return arr.sort((a, b) => (a[property] > b[property] && 1) || -1)
    }
}

