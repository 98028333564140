import React, { useEffect } from "react";
import { Breadcrumb } from "@gull";
import { useParams } from "react-router-dom";
import guestService from 'app/guest/guestService';
import ReactGA from 'react-ga';

export const VerifyUserForm = () => {

  const { key } = useParams();
  const [active, setActive] = React.useState(false);
  const [activating, setActivating] = React.useState(true);

  useEffect( _ => {

    ReactGA.pageview(window.location.pathname);

    guestService.verifyUser(key)
    .then(result => {
      setActivating(false)
      if (result.error) {
        setActive(false);
      }
      else {
        setActive(true);
      }
      try {
        ReactGA.event({ category: 'Registration', action: 'Verified', value: `Key: ${key} Result: result` });
      } catch {}  
    })
    .catch(e => {
      console.log(e);
    });
  }, []);

  return (<>
      <Breadcrumb
        routeSegments={[
          { name: "User Verification" }
        ]}
      ></Breadcrumb>

      <div className="d-flex justify-content-center">
        {activating && 
          <div className="text-center">
            <h4 className='text-success'>Verifying...</h4>
            <p>This can take a moment.</p>
          </div>
        }
        {!activating && <>
          {active && 
          <div className="text-center">
            <h4 className='text-success'>User Verified!</h4>
            <p>Your account was successfully verified. You may sign in now.</p>
          </div>
          } 
          {!active && 
            <div className="text-center">
              <h4 className='text-danger'>Verification Failed!</h4>
              <p>Your account was not verified. Please contact our support team.</p>
            </div>
          } 
        </>}
      </div>
  </>);
};
