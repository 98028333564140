import React, { useRef, useEffect } from "react";
import { CanvasRenderer } from "echarts/renderers";
import { init, getInstanceByDom, use } from "echarts/core";
import { ScatterChart, LineChart, BarChart, PieChart } from "echarts/charts";
import {
  LegendComponent,
  GridComponent,
  TooltipComponent,
  ToolboxComponent,
  TitleComponent,
  DataZoomComponent,
} from "echarts/components";

// Register the required components
use([
  PieChart,
  LegendComponent,
  ScatterChart,
  LineChart,
  BarChart,
  GridComponent,
  TooltipComponent,
  TitleComponent,
  ToolboxComponent, // A group of utility tools, which includes export, data view, dynamic type switching, data area zooming, and reset.
  DataZoomComponent, // Used in Line Graph Charts
  CanvasRenderer, // If you only need to use the canvas rendering mode, the bundle will not include the SVGRenderer module, which is not needed.
]);

export const ReactEcharts = ({ option, style, settings, loading, theme, onChartReady }) => {

  const chartRef = useRef(null);

  useEffect(() => {

    // Initialize chart
    let chart
    if (chartRef.current) {
      chart = init(chartRef.current, theme)
      if (onChartReady) {
        onChartReady(chart)        
      }
    }

    // Add chart resize listener
    const resizeChart = () => {

      if (chart) {
        chart.resize();
      }
    }
    window.addEventListener("resize", resizeChart);

    // Return cleanup function
    return () => {
      if (chart) {
        chart.dispose()
      }
      window.removeEventListener("resize", resizeChart);
    };
  }, [theme]);

  useEffect(() => {

    // Update chart
    if (chartRef.current) {
      const chart = getInstanceByDom(chartRef.current);
      chart.setOption(option, settings);
    }
  }, [option, settings, theme]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

  useEffect(() => {

    // Update chart
    if (chartRef.current) {
      const chart = getInstanceByDom(chartRef.current);
      loading === true ? chart.showLoading() : chart.hideLoading();
    }
  }, [loading, theme]);

  return <div ref={chartRef} style={{ width: "100%", height: "100px", ...style }} />;
}