import React, { useEffect, useState, useCallback } from 'react';
import { Breadcrumb } from '@gull';
import { NotificationContainer, NotificationManager } from "react-notifications";
import { classList } from '@utils';
import { Formik, Form } from 'formik';
import { AllowedFileTypes, UserGroup, Industry } from 'app/utils/constants';
import { 
  IwtbRadio, 
  IwtbCheckbox, 
  IwtbInput, 
  IwtbTextArea, 
  IwtbSelect, 
  IwtbMultiSelect, 
  IwtbFileUpload,
  IwtbPlaceAutocomplete } from 'app/widgets';
import { membershipSchema } from './membershipSchema';
import adminService from '../adminService';
import LaddaButton, { EXPAND_RIGHT } from "react-ladda";
import { useParams, useHistory } from "react-router-dom";
import { Alert, Button } from "react-bootstrap";
import swal from "sweetalert2";
import { navigateTo } from 'app/utils/common';
import { useAuthState } from 'app/auth';
import { AdminNavigation } from 'app/admin/adminRoutes';
import { useConfiguration } from 'app/configuration/ConfigurationContext';

export const UserApproval = () => {
  
  let isSameAddress = false;

  const [user, setUser] = useState();
  const authState = useAuthState();
  const history = useHistory();
  const { userId } = useParams();
  const [notFound, setNotFound] = useState(false);
  const config = useConfiguration()

  const getUser = useCallback(userId => {

    adminService.getUser(authState.user.id, userId)
    .then(user => {

      if (user && user.error) {
        return NotificationManager.error(user.message, 'Server Connection', 3000);      
      }
      if (user && user.id) {
        setUser(user);
      }
      else {
        setTimeout(() => setNotFound(true), 2000);
      }
    })
    .catch(e => { NotificationManager.error('Something went wrong.', 'Server Connection', 3000) });
  });

  useEffect(() => {

    if (userId && authState.isAuthenticated) {
      getUser(userId);
    }
  }, [userId, authState.user]);

  const handleApprove = (values, setSubmitting) => {

    console.log(values);
    setSubmitting(true);

    adminService.approveRegistration(authState.user.id, values)
    .then(result => {
      NotificationManager.success("Registration was approved successfully.", "Registration", 2000);
      setSubmitting(false);      
      setTimeout(_ => history.goBack(), 2000);
    })
    .catch(ex => {  
      setSubmitting(false);      
      NotificationManager.error("Registration approval was unsucessfull", "Registration", 6000);
    });
  };

  const handleRequestInfo = (user, setSubmitting) => {

    swal.queue([{
      title: "Enter request message",
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Send Request",
      showLoaderOnConfirm: true,
      inputAutoTrim: true,
      inputValidator: result => !result && 'You need to enter something!',
      preConfirm: message => {
        if (message.length > 0) {
          return adminService.requestRegistrationInfo(authState.user.id, user.id, { message: message })
          .then(response => {
            if (response.error) {
              swal.showValidationMessage(`Request failed: ${response.message}`);
            }
            swal.insertQueueStep('Request sent.')
          })
          .catch(error => {
            swal.insertQueueStep({
              icon: "error",
              title: "Semething went wrong."
            });
          });  
        }
      },
      allowOutsideClick: () => !swal.isLoading()
    }])
    .then(result => {
      if (result.value) {
        history.goBack()
      }
    });
  }

  const handleReject = (user, setSubmitting) => {

    swal.queue([{
      title: "Enter reason for reject",
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Reject Registration",
      showLoaderOnConfirm: true,
      inputAutoTrim: true,
      inputValidator: result => !result && 'You need to enter something!',
      preConfirm: message => {
        if (message.length > 0) {
          return adminService.rejectRegistration(authState.user.id, user.id, message)
          .then(response => {
            if (response.error) {
              swal.showValidationMessage(`Request failed: ${response.message}`);
            }
            swal.insertQueueStep('Application was rejected');
          })
          .catch(error => {
            swal.insertQueueStep({
              icon: "error",
              title: "Semething went wrong."
            });
          });  
        }
      },
      allowOutsideClick: () => !swal.isLoading()
    }])
    .then(result => {
      if (result.value) {
        history.goBack()
      }
    });
  }

  const viewProfile = () => {

    if (user.userGroup === UserGroup.CLIENT) {
      return navigateTo({ history: history, navigation: AdminNavigation, pathname: AdminNavigation.CLIENT_CARD, user: user });
    }
    else if (user.userGroup === UserGroup.CONTRACTOR || user.userGroup === UserGroup.SPECIALIST) {
      return navigateTo({ history: history, navigation: AdminNavigation, pathname: AdminNavigation.CONTRACTOR_CARD, user: user });
    }
  }
  
  const userGroupChanged = (userGroup, setFieldValue) => {

    setFieldValue('trades', []);
  };

  const sameAddressClicked = (e, setFieldValue, values) => {
    
    if (e.target.checked) {
      isSameAddress = true;
      setFieldValue('businessLocation', values.location)
    }
    else {
      isSameAddress = false;
      setFieldValue('businessLocation', null);
    }
  };

  return (<>
    <Breadcrumb
      routeSegments={[
        { name: 'Admin', path: '/admin' },
        { name: 'Registration Approval' }
        ]}
    ></Breadcrumb>

    {user &&
      <Formik
        initialValues={user}
        enableReinitialize={true}
        validationSchema={membershipSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          isValid,
          setFieldValue,
          setSubmitting
        }) => {
          return (

            <Form
              className='needs-validation'
              onSubmit={handleSubmit}
              noValidate
            >

              <div className='row mt-3'>
                <div className='col-md-6'>
                  <h4>User Group</h4>
                  <div className='mb-3'></div>
                </div>
              </div>

              <div className='form-row'>
                <div className='form-group col-md-6'>
                <div className='ul-form__radio-inline'>
                  <IwtbRadio onChange={e => {handleChange(e); userGroupChanged(e.target.value, setFieldValue)}} name='userGroup' value={UserGroup.CONTRACTOR} />
                  <IwtbRadio onChange={e => {handleChange(e); userGroupChanged(e.target.value, setFieldValue)}} name='userGroup' value={UserGroup.SPECIALIST} />
                  <IwtbRadio onChange={e => {handleChange(e); userGroupChanged(e.target.value, setFieldValue)}} name='userGroup' value={UserGroup.CLIENT} />
                </div>                
              </div>
              </div>

              <div className='form-row'>
                <div className='col-md-12'>
                  { values.userGroup === UserGroup.CONTRACTOR && 
                    <div>
                      <IwtbMultiSelect label='Trade Category (up to 5)' name='trades' selectionLimit={5} displayValue="text" selectedValues={values.trades} onChange={ selects => setFieldValue('trades', selects) } options={config.tradeCategories} />
                    </div>
                  }
                  { values.userGroup === UserGroup.SPECIALIST && 
                    <div>
                      <IwtbMultiSelect label='Speciality (up to 5)' name='trades' selectionLimit={5} displayValue="text" selectedValues={values.trades} onChange={ selects => setFieldValue('trades', selects) } options={config.specialistCategories} />
                    </div>
                  }
                  { values.userGroup === UserGroup.CLIENT && 
                    <div>
                      <IwtbMultiSelect label='Client Relationship' name='trades' selectionLimit={5} displayValue="text" selectedValues={values.trades} onChange={ selects => setFieldValue('trades', selects) } options={config.clientCategories} />
                    </div>
                  }
                </div>
              </div>

              {/* Personal Details  */}

              <div>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <h4>Personal Details</h4>
                    <div className='mb-3'></div>
                  </div>
                </div>

                <div className='form-row'>
                  <IwtbInput label='Title' name='title' type='text' placeholder='Title' required />
                  <IwtbInput label='First Name' name='firstName' type='text' placeholder='First name' required />
                  <IwtbInput label='Surname' name='lastName' type='text' placeholder='Surname' required />
                </div>

                {/* MOBILE, DOB, EMAIL  */}

                <div className='form-row'>
                  <IwtbInput label='Date of Birth' name='DOB' type='text' placeholder='DD/MM/YYYY' required />
                  <IwtbInput label='Mobile' name='mobile' type='text' placeholder='0400 111 888' required />
                  <IwtbInput label='Email' name='email' type='text' placeholder='Email' required />
                </div>

                {/* Address  */}

                <div className='form-row'>
                  <div className='col-md-8 mb-3'>
                    <IwtbPlaceAutocomplete name='location' label='Address' location={values.location} type='text' placeholder='Start typing address' locationSelected={ location => setFieldValue('location', location) } />
                  </div>
                </div>

                <div className='form-row'>
                  <IwtbTextArea label='About Me' name='about' type='text' placeholder='Max 200 characters' maxLength={200} required />
                </div>

              </div>

              {/* Business Details  */}
                                
              <div>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <h4>Business Address</h4>
                    <div className='mb-3'></div>
                  </div>
                </div>

                <IwtbCheckbox name='isBusinessAddressSame' onClick={ e => sameAddressClicked(e, setFieldValue, values) }>
                  <span>Business address is the same as personal</span>
                </IwtbCheckbox>

                { !isSameAddress && 
                  <div className='form-row'>
                    <div className='col-md-8 mb-3'>
                      <IwtbPlaceAutocomplete name='businessLocation' label='Address' location={values.businessLocation} type='text' placeholder='Start typing address' locationSelected={ location => setFieldValue('businessLocation', location) } />
                    </div>   
                  </div>   
                }
              </div>

              <div>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <h4>Business Details</h4>
                    <div className='mb-3'></div>
                  </div>
                </div>

                <div className='form-row'>
                  <IwtbSelect label='Industry' name='industry' required>
                      <option value=''>Select industry</option>
                      {Industry.map((v, i) => <option value={v} key={i}>{v.toUpperCase()}</option>)}
                  </IwtbSelect>
                  <IwtbInput label='Business Name' name='businessName' type='text' placeholder='Business name' required />
                  <IwtbInput label='ABN' name='ABN' type='text' placeholder='ABN' required />
                </div>

                <div className='form-row'>
                  <IwtbInput label='Phone' name='businessPhone' type='text' placeholder='Phone' required />
                  <IwtbInput label='Fax' name='businessFax' type='text' placeholder='Fax' />
                  <IwtbInput label='Email' name='businessEmail' type='text' placeholder='Email' required />

                  <IwtbInput label='Website' name='website' type='text' placeholder='http://www.iwt.build' required />
                  <IwtbInput label='Years Operating' name='businessYearsOperating' type='number' placeholder='Years operating' />
                  <IwtbInput label='References / Reviews' name='businessReferences' type='text' placeholder='References and/or reviews' required />
                </div>
              </div>


              {/* Supporting Documents (Contractor)  */}

              { values && (values.userGroup === UserGroup.CONTRACTOR || values.userGroup === UserGroup.SPECIALIST) && 
              <div>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <h4>Supporting Documents</h4>
                    <p>
                      Contractors require to upload following documents:
                    </p>
                    <ul>
                      <li>LICENSE(S)</li>
                      <li>INSURANCES (PUBLIC LIABILITY 20MILL + WORKERS COMP)</li>
                      <li>CAPABILITY STATEMENT (UPLOAD OR TYPE MANUALLY)</li>
                      <li>DRIVERS LICENSE OR ID CARD</li>
                      <li>ANY OTHER DOCUMENTS (HOME INDEMNITY INSURANCE, ETC)</li>
                    </ul>
                    <div className='mb-3'></div>
                  </div>
                </div>

                <div className='col-md-12 mb-5'>
                  <IwtbFileUpload allowedTypes={AllowedFileTypes} uploadedFiles={values.files} setUploadedFiles={files => setFieldValue('files', files)} />
                </div>
              </div> }

              {/* Answer Yes/No */}

              <div className='row mt-3'>
                <div className='col-md-6'>
                  <h4>Answer Yes or No</h4>
                  <div className='mb-3'></div>
                </div>
              </div>

              <div className='form-row'>
                <div className='form-group col-md-12'>
                <div className={classList({ 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                    <label className='ul-form__label'>
                      Have you been bankrupt, insolvent or have been part of a company that has been bankrupt or insolvent?
                    </label>
                    <div className='ul-form__radio-inline'>
                      <IwtbRadio name='beenBankrupt' value='yes' />
                      <IwtbRadio name='beenBankrupt' value='no' />
                    </div>
                    <div className='invalid-feedback'>{ errors.beenBankrupt }</div>
                  </div>
                </div>
              </div>

              { values && values.userGroup === UserGroup.CLIENT && 
              <div className='form-row'>
                <div className='form-group col-md-12'>
                  <div className={classList({ 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                    <label className='ul-form__label'>
                      Some projects may require you to provide a financial guarantee statement by your financial institution to confirm the financial capability of a project.
                      Is there a concern should it be required?
                    </label>
                    <div className='ul-form__radio-inline'>
                      <IwtbRadio name='financialGuarantee' value='yes' />
                      <IwtbRadio name='financialGuarantee' value='no' />
                    </div>
                    <div className='invalid-feedback'>{ errors.financialGuarantee }</div>
                  </div>
                </div>
              </div> }

              { values && values.userGroup === UserGroup.CONTRACTOR && 
              <div>

                <div className='form-row'>
                  <div className='form-group col-md-12'>
                  <div className={classList({ 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                    <label className='ul-form__label'>
                      Do you have a company pre-start & safety pack (SWMS, MSDS, Environmental Controls, etc.) in place?
                    </label>
                    <div className='ul-form__radio-inline'>
                      <IwtbRadio name='safetyPack' value='yes' />
                      <IwtbRadio name='safetyPack' value='no' />
                    </div>
                    <div className='invalid-feedback'>{ errors.safetyPack }</div>
                  </div>
                  </div>
                </div>

              <div className='form-row'>
                <div className='form-group col-md-12'>
                <div className={classList({ 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                  <label className='ul-form__label'>
                    Some projects may require background checks, such as criminal history checks & child safety checks amoung others. If required, you will be required to supply all documentation at your expense. Is there a concern should it be requested?
                  </label>
                  <div className='ul-form__radio-inline'>
                    <IwtbRadio name='backgroundCheck' value='yes' />
                    <IwtbRadio name='backgroundCheck' value='no' />
                  </div>
                  <div className='invalid-feedback'>{ errors.backgroundCheck }</div>
                  </div>
                </div>
              </div>

                <div className='form-row'>
                  <div className='form-group col-md-12'>
                  <div className={classList({ 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                  <label className='ul-form__label'>
                      Is there anything at all that would preclude your company to complete any works should they be awarded to you? If Yes, why?
                    </label>
                    <div className='ul-form__radio-inline'>
                      <IwtbRadio name='precludeWork' value='yes' />
                      <IwtbRadio name='precludeWork' value='no' />
                    </div>
                    <div className='invalid-feedback'>{ errors.precludeWork }</div>
                  </div>
                  </div>
                
                  { values && values.precludeWork === 'yes' &&
                  <div className='form-row col-md-12'>
                    <IwtbTextArea label='If yes, write why' name='precludeWorkDetails' type='text' placeholder='' style={{height: 100}} />
                  </div> }
                </div>
              </div> }

              {/* Username  */}
                                
              <div>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <h4>Username</h4>
                    <div className='mb-3'></div>
                  </div>
                </div>

                <div className='form-row'>
                  <IwtbInput disabled label='Username' name='username' type='text' placeholder='Please choose a username' required />
                </div>
              </div>

              <div className='mb-4'></div>

              <LaddaButton 
                className='btn btn-success btn-lg border-0 mr-2 mb-2 position-relative'
                loading={isSubmitting}
                disabled={!isValid}
                progress={0.5}
                onClick={_ => handleApprove(values, setSubmitting)}
                data-style={EXPAND_RIGHT}
                type='button'
              >Approve</LaddaButton>

              <Button className="btn btn-info btn-lg border-0 mr-2 mb-2" onClick={_ => handleRequestInfo(values, setSubmitting)}>Request Info</Button>

              <LaddaButton 
                className='btn btn-danger btn-lg border-0 mr-2 mb-2 position-relative'
                loading={isSubmitting}
                disabled={!isValid}
                progress={0.5}
                onClick={_ => handleReject(values, setSubmitting)}
                data-style={EXPAND_RIGHT}
                type='button'
              >Reject</LaddaButton>
              
              <Button className="btn btn-warning btn-lg border-0 mr-2 mb-2 footer-delete-right" onClick={() => {history.goBack();}}>Cancel</Button>
              <Button className="btn btn-info btn-lg border-0 mr-2 mb-2 footer-delete-right" onClick={viewProfile}>View user profile</Button>

            </Form>
          );
        }}
      </Formik>
    }
    { !user && notFound && <>
        <Alert className="text-center alert-card" variant="danger">
          Something went wrong. Profile was not found.
          <Button variant="warning" className="btn ml-3" onClick={_ => {history.goBack();}}>Return</Button>
        </Alert>
    </>}
    
    <NotificationContainer />
  </>);
}

