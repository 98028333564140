import React, { useReducer, useContext, Suspense, useEffect } from "react";
import { Router } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import history from "@history";
import { Loading } from "@gull";
import { AuthReducer, initialState } from './authReducer';
import { loadUser } from './authActions';
import { useSocket } from '../socket.io'

const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();

export function useAuthState() {

    const context = useContext(AuthStateContext);
    if (context === undefined) {
      throw new Error("useAuthState must be used within a AuthProvider");
    }
    return context;
};
   
export function useAuthDispatch() {

    const context = useContext(AuthDispatchContext);
    if (context === undefined) {
      throw new Error("useAuthDispatch must be used within a AuthProvider");
    }
    return context;
};

export const AuthProvider = ({ children }) => {
    
  const [authState, dispatch] = useReducer(AuthReducer, initialState);
  const socket = useSocket()

    useEffect(() => {
      
      loadUser(dispatch, socket);
    }, []);

    return (
      <AuthStateContext.Provider value={authState}>
        <AuthDispatchContext.Provider value={dispatch}>
          <Suspense fallback={<Loading></Loading>}>
            <Router history={history}>{ renderRoutes(authState.routes) }</Router>
          </Suspense>
          {children}
        </AuthDispatchContext.Provider>
      </AuthStateContext.Provider>
    );
};