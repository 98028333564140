import React, { useEffect, useState } from 'react';
import { Breadcrumb } from '@gull';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Formik, Form } from 'formik';
import { AllowedFileTypes, StatusClass } from 'app/utils/constants';
import { 
  IwtbCheckbox,
  IwtbInput, 
  IwtbTextArea, 
  IwtbMultiSelect,
  IwtbFileUpload } from 'app/widgets';
import { submitRfiSchema } from './submitRfiSchema';
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda';
import rfiService from './rfiService';
import { useAuthState } from 'app/auth';
import { useParams, useHistory } from "react-router-dom";
import { Badge } from 'react-bootstrap';
import { classList } from '@utils';
import contractorService from '../contractorService';

export const SubmitRfi = (props) => {
  
  const authState = useAuthState();
  let { projectId } = useParams();
  const [project, setProject] = useState();
  const [showFileUploader, setShowFileUploader] = useState(false);
  const history = useHistory();

  const initialValues = { 
    trade: [],
    subject: '',
    message: '',
    files: [],
  };

  const getContractorProject = React.useCallback((contractorId, projectId) => {

    contractorService.getContractorProject(contractorId, projectId)
    .then(project => {

      if (project && project.error) {
        return NotificationManager.error(project.message, 'Server Connection', 3000);      
      }

      if (project && project.id) {
        setProject(project);
      }
    })
    .catch(e => { NotificationManager.error('Something went wrong.', 'Server Connection', 3000) });
  });

  useEffect(() => {

    if (projectId && authState.isAuthenticated) {
      getContractorProject(authState.user.id, projectId);
    }
  }, [projectId, authState.user]);

  const handleSubmit = (values, { setSubmitting }) => {
    
    setSubmitting(true);

    const rfi = {
      contractorId: authState.user.id,
      userGroup: authState.user.userGroup,
      trade: values.trade.length > 0 ? values.trade[0] : '',
      subject: values.subject,
      message: values.message,
      files: values.files,  
    };

    rfiService.submitRfi(authState.user.id, project.id, rfi)
    .then(result => {
      setSubmitting(false);
      NotificationManager.success('Request was submitted successfully.', 'RFI', 2000);
      setTimeout(_ => props.history.goBack(), 2000);
    })
    .catch(ex => {
      setSubmitting(false);
      NotificationManager.error('Request was not submitted sucessfully', 'RFI', 3000);
    });
  };

  const handleUploadedFiles = (files, setFieldValue) => {

    setFieldValue('files', files)
  }

  const handleClose = () => {

    history.goBack();
  }

  return (
    <div>
      <Breadcrumb
        routeSegments={[
          { name: 'My Projects', path: authState.navigation.CONTRACTOR_PROJECTS },
          { name: 'Information Request' }
        ]}
      ></Breadcrumb>

      {project && <>
        
        <Formik
          initialValues = {initialValues}
          validationSchema={submitRfiSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            handleSubmit,
            isValid,
            isSubmitting,
            setFieldValue
          }) => {
            return (

              <Form
                className='needs-validation'
                onSubmit={handleSubmit}
                noValidate
              >              

              <div className='row'>
                <div className='col-md-12'>

                  <div className='card'>

                    <div className='card-header bg-transparent'>
                        <div className='d-flex flex-row justify-content-between'>
                          {project.projectName && <h5>Project: <strong>{project.projectName}</strong></h5>}
                          {project.status && <h5>Status: <Badge className={StatusClass[project.status.toUpperCase()]}>{project.status}</Badge></h5>}
                        </div>
                    </div>

                    <div className='card-body'>

                      {/* <div className='form-row mb-4'>
                        <IwtbTagInput label='Required Trade / Specialist' tags={project.trades ?? []} readOnly={true} />
                      </div> */}

                      <div className='form-row'>
                        <div className='form-group col-md-6'>

                          <div className='form-row'>
                            <div className={classList({
                                'col-md-12 mb-3': true,
                                'valid-field': touched.trade && !errors.trade,
                                'invalid-field': touched.trade && errors.trade
                              })}
                            >
                            <IwtbMultiSelect label='Trade (one per bid)' name='trade' selectionLimit={1} displayValue="text" onChange={selects => setFieldValue('trade', selects)} options={project.trades} />

                            {/* {authState.user && authState.user.userGroup === UserGroup.CONTRACTOR && <>
                              <IwtbMultiSelect label='Trade (one per request)' name='trade' selectionLimit={1} displayValue='text' onChange={ selects => setFieldValue('trade', selects) } options={getTradeCategories()} />
                            </>}
                            {authState.user && authState.user.userGroup === UserGroup.SPECIALIST && <>
                              <IwtbMultiSelect label='Speciality (one per request)' name='trade' selectionLimit={1} displayValue='text' onChange={ selects => setFieldValue('trade', selects) } options={getSpecialistCategories()} />
                            </>} */}
                            <div className='invalid-feedback'>Trade is required.</div>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div>
                        <div className='form-row'>
                          <IwtbInput label='Subject' name='subject' type='text' required />
                        </div>
                      </div>

                      <div>
                        <div className='form-row'>
                          <IwtbTextArea label='Requesting Information Regarding' name='message' type='text' placeholder='' style={{height: 100}} />
                        </div> 
                      </div>

                      {/* Supporting Documents (Contractor)  */}

                      <div>
                        <div className='form-row'>
                          <IwtbCheckbox name='showFileUploader' onClick={ e => setShowFileUploader(e.target.checked) }>
                            <span>Upload Files</span>
                          </IwtbCheckbox>
                        </div>
                      </div>

                      { showFileUploader && 
                      <div>
                        <div className='row mt-3'>
                          <div className='col-md-6'>
                            <h5>Upload Files</h5>
                            <div className='mb-3'></div>
                          </div>
                        </div>

                        <div className='col-md-12 mb-5'>
                          <IwtbFileUpload allowedTypes={AllowedFileTypes} setUploadedFiles={files => handleUploadedFiles(files, setFieldValue)} />
                        </div>
                      </div> }

                      {!isValid && <div className='form-row mb-3'><span className='text-18 text-danger'>Please check mandatory fields and try again.</span></div>}

                      </div>
    
                      <div className='card-footer'>
                        <div className='mc-footer'>
                          <div className='row'>
                            <div className='col-md-12'>
                            <LaddaButton 
                              className='btn btn-success border-0 mr-2 mb-2 position-relative'
                              loading={isSubmitting}
                              disabled={!isValid}
                              progress={0.5}
                              data-style={EXPAND_RIGHT}
                            >Submit</LaddaButton>
                            <button type='button' className='btn btn-outline-warning mb-2 footer-delete-right' onClick={handleClose}>Cancel & Return</button>
                          </div>
                        </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </Form>
            );
          }}
        </Formik>

      </>}
      <NotificationContainer />
  </div>
  );
}

