import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { NotificationContainer } from 'react-notifications';
import { paginationOptions, StatusClass, audFormatter } from 'app/utils/constants';
import { Badge } from 'react-bootstrap';

export const ProjectsWidget = ({ projects }) => {

  const { SearchBar, ClearSearchButton } = Search;

  const columns = [
    {
      dataField: 'ref',
      text: 'Ref',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
    },
    {
      dataField: 'projectName',
      text: 'Project',
      headerAlign: 'center',
    },
    {
      dataField: 'classification',
      text: 'Classification',
      headerAlign: 'center',
    },
    {
      dataField: 'sector',
      text: 'Sector',
      headerAlign: 'center',
    },
    {
      dataField: 'budget',
      text: 'Budget',
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => { 
        return <span>{isNaN(row.budget) ? row.budget : audFormatter.format(row.budget)}</span>
      })
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '80px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status?.toUpperCase()]}>{row.status}</Badge>
      })
    }];

  return (
    <div>
      <div className='row'>
          <div className='col-md-12'>
              <ToolkitProvider
                keyField='ref'
                data={projects}
                columns={columns}
                search
                exportCSV
              >
                {props => (
                  <>
                    <div className='d-flex justify-content-end align-items-center'>
                    
                      <span className='mb-2 mr-1'>Search:</span>
                      <SearchBar {...props.searchProps} className='mb-0 ' />
                      <ClearSearchButton { ...props.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
                      <button className='btn btn-outline-success mb-2 mr-1' onClick={_ => props.csvProps.onExport()}>Export CSV</button>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4
                      wrapperClasses="table-responsive"
                      pagination={paginationFactory({ ...paginationOptions, totalSize: projects.length })}
                      // overlay={ overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(255, 0, 0, 0.5)'}) } }) }
                      noDataIndication={'No projects found'}
                      condensed
                    />
                  </>
                )}
              </ToolkitProvider>
        </div>
      </div>

      <NotificationContainer />
    </div>
  );
}
