import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Badge, Dropdown  } from "react-bootstrap";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { NotificationContainer } from 'react-notifications';
import rfiService from './rfiService';
import { ContractorService } from 'app/contractor';
import { RfiAction, RfiStatus } from '../constants';
import { paginationOptions, StatusClass, AccessStatus } from 'app/utils/constants';
import { formatDateTime12 } from 'app/utils/time';
import { constructCompanyName } from 'app/utils/common';
import { AdminNavigation } from '../adminRoutes';
import { useHistory } from "react-router-dom";
import { useAuthState } from 'app/auth';
import { MdMoreVert} from "react-icons/md";

export const RfiRegisterWidget = (props) => {

  const authState = useAuthState();
  const history = useHistory();
  const [rfiRegister, setRfiRegister] = useState([]);
  const [loading, setLoading] = useState(true);
  const { SearchBar, ClearSearchButton } = Search;
  const contractorService = new ContractorService();

  let tableColumns = [
    {
      dataField: 'trade.text',
      text: 'Trade',
      headerAlign: 'center',
      sort: true,
    },
    {
      dataField: 'contractorId',
      text: 'Company',
      headerAlign: 'center',
      sort: true,
      formatter: ((cell, row) => { 
        return <span className="text-primary cursor-pointer" onClick={() => navigateTo(AdminNavigation.CONTRACTOR_CARD, row.contractor)}>{constructCompanyName(row.contractor)}</span>;
      })
    },
    {
      dataField: '1',
      text: 'Requested',
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => formatDateTime12(row.created))
    },
    {
      dataField: '2',
      text: 'Responded',
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => row.response ? formatDateTime12(row.response.created) : '')
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '90px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    }
  ];

  if (!props.readOnly) {
    tableColumns = [...tableColumns,
      {
        dataField: 'action',
        text: 'Action',
        headerAlign: 'center',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '60px' };
        },
        formatter: ((cell, row) => { 
          return <Dropdown onSelect={(action) => actionRfi(action, row)}>
            <Dropdown.Toggle as="span" className="cursor-pointer toggle-hidden">
              <MdMoreVert size={18}></MdMoreVert>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {row.status !== RfiStatus.RESPONDED &&
                <Dropdown.Item eventKey={RfiAction.REPLY}><i className="i-Left"> </i>Reply</Dropdown.Item>
              }
              {row.status !== AccessStatus.PENDING &&
                <Dropdown.Item eventKey={RfiAction.VIEW}><i className="i-ID-Card"> </i>View</Dropdown.Item>
              }
            </Dropdown.Menu>
          </Dropdown>
        })
      },
    ];
  }

  useEffect(() => {

    if (props.projectId) {
      loadRfiRegister(props.projectId);
    }
  }, [props.projectId]);

  useEffect(() => {

    if (props.rfiRegister) {
      setRfiRegister(props.rfiRegister);
    }
  }, [props.rfiRegister]);

  const loadRfiRegister = async projectId => {

    const register = await rfiService.getRfiRegister(authState.user.id, projectId)
    setRfiRegister(register);
  }

  const navigateTo = (pathname, contractor) => {
    
    switch(pathname) {
      case AdminNavigation.CONTRACTOR_CARD:
        pathname = pathname.replace(':contractorId', contractor.id);
    }
    history.push({ pathname: pathname });  
  }

  const actionRfi = (action, rfi) => {
    
    history.push({
      pathname: AdminNavigation.VIEW_RFI,
      state: { rfi: rfi }
    });  
  }

  return (
    <>
        <ToolkitProvider
            keyField='id'
            data={rfiRegister}
            columns={tableColumns}
            search
            exportCSV
        >
            {props => (
            <>
                <div className='d-flex justify-content-end align-items-center'>
                
                <span className='mb-2 mr-1'>Search:</span>
                <SearchBar {...props.searchProps} className='mb-0 ' />
                <ClearSearchButton { ...props.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
                <button className='btn btn-outline-success mb-2 mr-1' onClick={_ => props.csvProps.onExport()}>Export CSV</button>
                </div>
                <BootstrapTable
                {...props.baseProps}
                bootstrap4
                wrapperClasses="table-responsive"
                pagination={paginationFactory({ ...paginationOptions, totalSize: rfiRegister.length })}
                noDataIndication={'No records found'}
                condensed
                loading={ loading }
                />
            </>
            )}
        </ToolkitProvider>

        <NotificationContainer />
    </>
  );
}
