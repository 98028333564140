import React from "react";
import ReactEcharts from "echarts-for-react";

export const PieChart = ({ onClick, height, data = [] }) => {

  const onEvents = {
    click: onClick,
  }

  const option = {
    tooltip: {
      show: true,
      backgroundColor: "rgba(0, 0, 0, .8)"
    },
    series: [
      {
        type: "pie",
        radius: "70%",
        center: ["50%", "40%"],
        data: data,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)"
          }
        }
      }
    ]
  };

  return <ReactEcharts style={{ height: height }} option={option} onEvents={onEvents} />;
}
