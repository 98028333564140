import React, { useState, createContext, useContext } from 'react';
import Loading from "@gull/components/GullLoadable/Loading";
 
const SpinnerContext = createContext({
  isLoading: false,
  message: '',
  show: () => {},
  hide: () => {},
})
 
export const useSpinner = () => {

  const context = useContext(SpinnerContext);

  if (context === undefined) {
    throw new Error("useSpinner must be used within a LoadingSpinnerProvider");
  }
  return context;
};

export const SpinnerProvider = ({ children }) => {
 
  const show = () => {

    update((prev) => ({
      ...prev,
      isLoading: true,
    }))
  }

  const hide = () => {

    update(prev => ({ ...prev, isLoading: false }))
  }

  const [state, update] = useState({ isLoading: false, show, hide  })

  return (
    <SpinnerContext.Provider value={state}>
      {state.isLoading ? <Loading /> : null}
      {children}
    </SpinnerContext.Provider>
  )
}
