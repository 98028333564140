import React from 'react';
import contractorService from './contractorService';
import swal from "sweetalert2";
import { BidStatus, SowStatus } from 'app/utils/constants';
import { calcVariation } from 'app/utils/common';

export const declineAward = async (contractorId, projectId, bidId) => {

    return new Promise((resolve, reject) => {
        swal.queue([{
        title: "Enter decline reason",
        input: "textarea",
        inputAttributes: {
            autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "Decline",
        showLoaderOnConfirm: true,
        inputAutoTrim: true,
        inputValidator: result => !result && 'You need to enter something!',
        preConfirm: async message => {
            if (message.length > 0) {
                try {
                    const result = await contractorService.acceptAward(contractorId, projectId, bidId, false, message);
                    if (!result || result.error) {
                        return reject(result.message);
                    }
                    return resolve();
                }
                catch {
                    return reject();
                }
            }
        },
        allowOutsideClick: () => !swal.isLoading()
        }]);
    });
}

export const acceptAward = async (contractorId, projectId, bidId) => {

    return new Promise((resolve, reject) => {
        swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, accept award',
            cancelButtonText: 'No'
        })
        .then(result => {
            if (result.value) {    
                return contractorService.acceptAward(contractorId, projectId, bidId, true)
                .then(response => {
                    if (response.error) {
                        return reject(response.message);
                    }
                    return resolve();
                })
                .catch(ex => {
                    return reject();
                });
            }
        });  
    })
}

export const withdrawBid = async (contractorId, projectId, bidId) => {

    return new Promise((resolve, reject) => {
        swal.queue([{
        title: "Enter withdrawal reason",
        input: "textarea",
        inputAttributes: {
            autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        inputAutoTrim: true,
        inputValidator: result => !result && 'You need to enter something!',
        preConfirm: async reason => {
            if (reason.length > 0) {
                try {
                    const result = await contractorService.withdrawBid(contractorId, projectId, bidId, reason)
                    if (!result || result.error) {
                        return reject(result.message);
                    }
                    return resolve();
                }
                catch {
                    return reject();
                }
            }
        },
        allowOutsideClick: () => !swal.isLoading()
        }]);
    });
}

export const getProjectAwards = bidRegister => {

    if (Array.isArray(bidRegister)) {
        const awards = bidRegister.filter(v => v.status === BidStatus.AWARDED || v.status === BidStatus.ACCEPTED);
        let total = 0;

        awards.forEach(v => {
            const variation = calcVariation(v.sow);
            total = total + v.amount + variation;  
        });
        return { total: total, awards: awards };    
    }
    return [];
}

export const getRequestCount = bidRegister => {

    let requestCount = 0;
    if (Array.isArray(bidRegister)) {
        bidRegister.map(bid => {
            if (Array.isArray(bid.sow)) {
                bid.sow.map(sow => {
                    if (sow.status === SowStatus.PENDING) {
                        requestCount++;
                    }
                })    
            }
        })
    }
    return requestCount;
}

export const getMatchedTrades = (contractor, project) => {

    if (contractor && project) {

        const contractorTrades = contractor.trades || [];
        const projectTrades = project.trades || [];

        const trades = contractorTrades.filter(c => projectTrades.some(p => c.id === p.id));
        
        return trades;
    }
    return [];
}

export const formatMatchedTrades = (contractor, project) => {

    if (contractor && project) {

        const contractorTrades = contractor.trades || [];
        const projectTrades = project.trades || [];

        const formatted = projectTrades.map(pt => {
            const contains = contractorTrades.some(ct => ct.id === pt.id)
            if (contains) {
                return <span className="badge badge-twitter w-badge m-1">{pt.text}</span>
            }
            else {
                return <span className="m-1">{pt.text}</span>
            }
        })
        return formatted;
    }
    return '';
}
