import HttpService from './httpService';

class StorageService {

    http = new HttpService();

    deleteFile = (fileName) => {
        if (fileName && fileName.length > 0) {
            return this.http.delete(`uploads/document/${fileName}`);
        }
    };

    downloadArchive = (files) => {
        return this.http.getBlobArchive(`uploads/documents`, { files: files });
    };

    downloadFile = (name) => {
        return this.http.getBlob(`uploads/document/${name}`);
    };

    uploadFiles = (formData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return this.http.post('uploads/document', formData, config);
    };
}

export default new StorageService();