import React, { useState, useEffect } from 'react'
import { SowStatus, BidStatus, UserGroup } from 'app/utils/constants'
import { sortArray } from 'app/utils/common'
import { useAuthState } from 'app/auth'
import { SowItemWidget } from '.'

export const SowWidget = ({ bid, handleSowConfirm, handleRejectVariation }) => {
  
  const [sow, setSow] = useState()
  const authState = useAuthState()

  useEffect(() => {

    if (bid?.sow?.length > 0) {

      // Sort SOW's recent on top
      let sow = sortArray(bid.sow, 'created', true)

      // Flag editable sow items
      sow = sow.map(item => {
        item.allowReject = (bid.status === BidStatus.AWARDED || bid.status === BidStatus.ACCEPTED ) 
          && item.status === SowStatus.AMENDED
          && (authState.user.userGroup === UserGroup.CLIENT || authState.user.userGroup === UserGroup.ADMIN)
        item.allowAmend = bid.status !== BidStatus.DECLINED && bid.contractorId === authState.user.id && item.status !== SowStatus.CONFIRMED
        return item
      })
      setSow(sow)
    }
  }, [bid.sow])

  return (
    <div>
      {sow?.length > 0 && <>

        {sow.map((item, index) => (
          <SowItemWidget key={index} sow={item} handleRejectVariation={handleRejectVariation} handleSowConfirm={handleSowConfirm} />
        ))}
      </>}
    </div>
  )
}

