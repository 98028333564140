import React from 'react';
import { classList } from '@utils';
import { useField } from 'formik';

export const IwtbSelect = ({ label, ...props }) => {
    
    const [field, meta] = useField(props);
    
    return (
        <div className={classList({
        'col-md-4 mb-3': true,
        'valid-field': meta.touched && !meta.error,
        'invalid-field': meta.touched && meta.error
        })}
        >
        {label && <label htmlFor={props.id || props.name}>{label}</label>}
        <select className="form-control" {...field} {...props} />
        <div className='invalid-feedback'>{ meta.error }</div>
        </div>
    );
};

