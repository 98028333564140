import React from "react";
import { Modal, FormGroup, FormControl } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

const contactSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email().required("Email is required"),
  message: yup.string().required("Message is required"),
});

export const ContactUsWidget = ({
  show,
  initialValues,
  toggleEditorDialog,
  handleFormSubmit,
}) => {
  return (
    <Modal show={show} onHide={toggleEditorDialog} centered>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Contact Us</h5>
        <span
          className="close fw-bold fs-3 cursor-pointer"
          aria-label="Close"
          onClick={() => toggleEditorDialog(false)}
        >
          <span aria-hidden="true">&times;</span>
        </span>
      </div>
      <div className="modal-body">
        <Formik
          initialValues={
            initialValues
              ? initialValues
              : { name: "", email: "", phone: "", message: "" }
          }
          validationSchema={contactSchema}
          enableReinitialize={true}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <FormControl
                  placeholder="Name..."
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.name && touched.name}
                  className="px-1 mb-2"
                  value={values.name}
                ></FormControl>
              </FormGroup>
              <FormGroup>
                <FormControl
                  placeholder="Enter email...."
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.email && touched.email}
                  className="px-1 mb-2"
                  value={values.email}
                ></FormControl>
              </FormGroup>
              <FormGroup>
                <FormControl
                  placeholder="Phone...."
                  name="phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.phone && touched.phone}
                  className="px-1 mb-2"
                  value={values.phone}
                ></FormControl>
              </FormGroup>
              <FormGroup>
                <FormControl
                  placeholder="Message...."
                  name="message"
                  as="textarea"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.message && touched.message}
                  className="px-1 mb-2"
                  value={values.message}
                ></FormControl>
              </FormGroup>
              <div className="modal-footer border-0">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => toggleEditorDialog(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Send message
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
