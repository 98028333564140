import React from 'react';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Badge, Dropdown } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { paginationOptions, audFormatter, StatusClass, BidAction, BidStatus } from 'app/utils/constants';
import { AdminNavigation } from '../adminRoutes';
import { useHistory } from "react-router-dom";
import { MdMoreVert} from "react-icons/md";
import { calcVariation, getSowStatus, constructCompanyName } from 'app/utils/common';
import { useAuthState } from 'app/auth';
import { formatDate } from 'app/utils/time';

export const BidRegisterCategoryWidget = ({ bidRegister, readOnly, showExport, handleBidAction, handleSubmitBid }) => {

  const { SearchBar, ClearSearchButton } = Search;
  const history = useHistory();
  const authState = useAuthState();

  const columns = [
    {
      dataField: 'trade',
      text: 'Trade',
      headerAlign: 'center',
      sort: true,
    },
    {
      dataField: 'count',
      text: 'Bids',
      sort: true,
      align: 'center',
      headerAlign: 'center',
      headerStyle: () => ({ width: '90px' }),
      formatter: ((cell, row) => (row.count ?? 0))
    },
    {
      dataField: 'min',
      text: 'Lowest Bid',
      align: 'right',
      headerAlign: 'center',
      sort: true,
      headerStyle: () => ({ width: '130px' }),
      formatter: ((cell, row) => audFormatter.format(row.min))
    },
  ];

  let bidInfoColumns = [
    {
      dataField: 'index',
      text: '#',
      align: 'center',
      headerAlign: 'center',
      headerStyle: () => ({ width: '40px' })
    },
    {
      dataField: 'company',
      text: 'Company',
      headerAlign: 'center',
      sort: true,
      formatter: ((cell, row) => { 
        return <span className="text-primary cursor-pointer" onClick={() => navigateTo(AdminNavigation.CONTRACTOR_CARD, row.contractor)}>{constructCompanyName(row.contractor)}</span>;
      })
    },
    {
      dataField: 'sow',
      text: 'SOW',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '100px' }),
      formatter: ((cell, row) => { 
        const sowStatus = getSowStatus(row.sow);
        return readOnly ? 
          <span className="text-primary"><Badge className={StatusClass[sowStatus.toUpperCase()]}>{sowStatus}</Badge></span> 
          : 
          <span className="text-primary cursor-pointer" onClick={() => handleBidAction(row, BidAction.VIEW_SOW)}><Badge className={StatusClass[sowStatus.toUpperCase()]}>{sowStatus}</Badge></span>;
      })
    },
    {
      dataField: 'bid',
      text: 'Bid',
      align: 'right',
      headerAlign: 'center',
      sort: true,
      headerStyle: () => ({ width: '110px' }),
      formatter: ((cell, row) => <span className='text-light'>{audFormatter.format(row.amount)}</span>)
    },
    {
      dataField: 'variation',
      text: 'Variation',
      headerAlign: 'center',
      align: 'right',
      headerStyle: () => ({ width: '100px' }),
      formatter: ((cell, row) => <span className='text-warning'>{audFormatter.format(calcVariation(row.sow))}</span>)
    },
    {
      dataField: 'value',
      text: 'Value',
      headerAlign: 'center',
      align: 'right',
      headerStyle: () => ({ width: '100px' }),
      formatter: ((cell, row) => <span className='text-primary'>{audFormatter.format(row.amount + calcVariation(row.sow))}</span>)
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '90px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
    {
      dataField: 'ref',
      text: 'Ref',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
    },
    {
      dataField: 'created',
      text: 'Added',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
      formatter: ((cell, row) => formatDate(row.created))
    },
  ];

  if (!readOnly) {
    bidInfoColumns = [
      ...bidInfoColumns,
      {
        dataField: 'action',
        text: '',
        headerAlign: 'center',
        align: 'center',
        headerStyle: () => ({ width: '40px' }),
        formatter: ((cell, row) => { 
  
          const sowStatus = getSowStatus(row.sow);
  
          return <Dropdown onSelect={(action) => handleBidAction(row, action)}>
            <Dropdown.Toggle as="span" className="cursor-pointer toggle-hidden">
              <MdMoreVert size={18}></MdMoreVert>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {row.comments?.length > 0 && <>
                <Dropdown.Item eventKey={BidAction.VIEW_COMMENTS}><i className='i-Speach-Bubble-3 text-info'> </i>View comments</Dropdown.Item>
              </>}
              <Dropdown.Item eventKey={BidAction.BID_CARD}><i className="i-ID-Card text-success"> </i>View Bid</Dropdown.Item>
              {row.status !== BidStatus.DELETED && row.status !== BidStatus.DECLINED && row.status !== BidStatus.AWARDED && <>
                <Dropdown.Item eventKey={BidAction.REQUEST_SOW}><i className="i-Check text-primary"> </i>Add SOW</Dropdown.Item>
                <div className="dropdown-divider"></div>
              </>}
              {row.status === BidStatus.DELETED &&
                <Dropdown.Item eventKey={BidAction.VIEW_DELETE_REASON}><i className='i-Receipt-3 text-info'> </i>View reason</Dropdown.Item>
              }
            </Dropdown.Menu>
          </Dropdown>
        })
      }
    ]
  }

  const expendRow = {

    parentClassName: 'table-primary',
    showExpandColumn: true,
    renderer: row => (
      <BootstrapTable 
        keyField='index' 
        data={ row.bids } 
        columns={ bidInfoColumns } 
        defaultSorted={[{ dataField: 'amount', order: 'asc'}]}
      />
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
      if (expanded) {
        return <i className='nav-icon i-Arrow-Down font-weight-900 text-primary cursor-pointer'></i>    
      }
      else {
        return <i className='nav-icon i-Arrow-Right font-weight-900 text-primary cursor-pointer'></i>    
      }
    },
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (''),
  }

  const navigateTo = (pathname, contractor) => {
    
    switch(pathname) {
      case AdminNavigation.CONTRACTOR_CARD:
        pathname = pathname.replace(':contractorId', contractor.id);
    }
    history.push({ pathname: pathname });  
  }

  return (
    <>
      <ToolkitProvider
        keyField='index'
        data={bidRegister ?? []}
        columns={columns}
        search
        exportCSV
      >
        {toolkitProps => (
          <>
            <div className='d-flex justify-content-end align-items-center'>
              
              <span className='mb-2 mr-1'>Search:</span>
              <SearchBar {...toolkitProps.searchProps} className='mb-0 ' />
              <ClearSearchButton { ...toolkitProps.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
              <button className='btn btn-success mb-2 mr-1' onClick={handleSubmitBid}>Add Bid</button>
              {showExport && <button className='btn btn-outline-success mb-2 mr-1' onClick={_ => toolkitProps.csvProps.onExport()}>Export CSV</button>}
            </div>
            <BootstrapTable
              {...toolkitProps.baseProps}
              bootstrap4
              wrapperClasses="table-responsive"
              pagination={paginationFactory({ ...paginationOptions, totalSize: bidRegister ? bidRegister.length : 0 })}
              noDataIndication={'No records found'}
              condensed
              expandRow={ expendRow }
              defaultSorted={[{ dataField: 'trades', order: 'asc'}]}
            />
          </>
        )}
      </ToolkitProvider>
    </>
  );
}
