import { AdminNavigation } from './adminRoutes';

export const adminMenu = [
  {
    name: "Dashboard",
    description: "Dashboard",
    type: "link",
    icon: "i-Dashboard",
    path: AdminNavigation.HOME
  },
  {
    name: "Services",
    description: "Our services",
    type: "link",
    icon: "i-Cube-Molecule-2",
    path: AdminNavigation.SERVICES
  },
  {
    name: "Projects",
    description: "Live projects",
    type: "dropDown",
    icon: "nav-icon i-Gear",
    sub: [
      {
        icon: "i-Engineering",
        name: "Construction",
        path: AdminNavigation.PROJECTS_CONSTRUCTION,
        type: "link"
      },
      {
        icon: "i-Dollar-Sign",
        name: "Tenders",
        path: AdminNavigation.PROJECTS_TENDERING,
        type: "link"
      },
      {
        icon: "i-Close-Window",
        name: "Canceled & Declined",
        path: AdminNavigation.DECLINED_PROJECTS,
        type: "link"
      },
    ]  
  },
  {
    name: "Approvals",
    description: "Approvals",
    type: "dropDown",
    icon: "nav-icon i-Approved-Window",
    sub: [
      {
        icon: "i-Add-User",
        name: "Members",
        path: AdminNavigation.MEMBERSHIPS_PENDING,
        type: "link"
      },
      {
        icon: "i-Gears",
        name: "Projects",
        path: AdminNavigation.PROJECTS_PENDING,
        type: "link"
      },
    ]
  },
 {
    name: "Members",
    description: "Members",
    type: "dropDown",
    icon: "nav-icon i-Business-Mens",
    sub: [
      {
        icon: "i-MaleFemale",
        name: "All",
        path: AdminNavigation.MEMBERSHIPS_ALL,
        type: "link"
      },
      {
        icon: "i-Assistant",
        name: "Clients",
        path: AdminNavigation.MEMBERSHIPS_CLIENT,
        type: "link"
      },
      {
        icon: "i-Engineering",
        name: "Contractors",
        path: AdminNavigation.MEMBERSHIPS_CONTRACTOR,
        type: "link"
      },
      {
        icon: "i-Cool-Guy",
        name: "Specialists",
        path: AdminNavigation.MEMBERSHIPS_SPECIALIST,
        type: "link"
      },
    ]
  },

  {
    name: "Messenger",
    description: "Messenger",
    type: "link",
    icon: "i-Speach-Bubbles",
    path: AdminNavigation.CHAT
  },
];
