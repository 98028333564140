import React, { useEffect, useState } from "react";
import { Breadcrumb } from '@gull';
import { ProjectWidget } from 'app/widgets';
import { NotificationContainer, NotificationManager } from "react-notifications";
import adminService from '../adminService';
import { useAuthState } from 'app/auth';
import { useParams, useHistory } from "react-router-dom";
import { formatDateTime24 } from 'app/utils/time'
import { AdminNavigation } from '../adminRoutes';
import swal from "sweetalert2";
import { Button, Alert } from "react-bootstrap";

export const ApproveProject = () => {
  
  const authState = useAuthState();
  const history = useHistory()
  const { projectId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [project, setProject] = useState();
  const [notFound, setNotFound] = useState(false);

  const getProject = React.useCallback((adminId, projectId) => {

    adminService.getProject(adminId, projectId)
    .then(project => {

      if (project && project.error) {
        return NotificationManager.error(project.message, 'Server Connection', 3000);      
      }
      if (project.inspectionRequiredOn?.length > 0) {
        project.inspectionRequiredOn = formatDateTime24(project.inspectionRequiredOn)
      }
      if (project.tenderRequiredBy?.length > 0) {
        project.tenderRequiredBy = formatDateTime24(project.tenderRequiredBy)
      }
      if (project && project.id) {
        setProject(project);
      }
      else {
        setTimeout(() => setNotFound(true), 2000);
      }
    })
    .catch(e => { NotificationManager.error('Something went wrong.', 'Server Connection', 3000) });
  });

  useEffect(() => {

    if (projectId && authState.isAuthenticated) {
      getProject(authState.user.id, projectId);
    }
  }, [projectId, authState.user]);

  const handleRequestInfo = (project, setSubmitting) => {

    swal.queue([{
      title: "Enter request message",
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Send Request",
      showLoaderOnConfirm: true,
      inputAutoTrim: true,
      inputValidator: result => !result && 'You need to enter something!',
      preConfirm: message => {
        if (message.length > 0) {
          return adminService.requestProjectInfo(authState.user.id, project.id, { message: message })
          .then(response => {
            if (response.error) {
              swal.showValidationMessage(`Request failed: ${response.message}`);
            }
            swal.insertQueueStep('Request sent.')
          })
          .catch(error => {
            // swal.showValidationMessage(`Request failed: ${error}`);
            swal.insertQueueStep({
              icon: "error",
              title: "Semething went wrong."
            });
          });  
        }
      },
      allowOutsideClick: () => !swal.isLoading()
    }])
    .then(result => {
      if (result.value) {
        history.goBack()
      }
    });
  }

  const handleApprove = project => {

    setIsSubmitting(true);
    
    adminService.approveProject(authState.user.id, project)
    .then(() => {
      NotificationManager.success("Project has been approved.", "Approve Project", 2000);
      setTimeout(_ => history.goBack(), 1000);
    })
    .catch(ex => {
      setIsSubmitting(false);
      NotificationManager.error("Project has not been approved.", "Approve Project", 6000);
    });
  };

  const handleReject = (project, setSubmitting) => {

    swal.queue([{
      title: "Enter reason for decline",
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Decline Project",
      showLoaderOnConfirm: true,
      inputAutoTrim: true,
      inputValidator: result => !result && 'You need to enter something!',
      preConfirm: message => {
        if (message.length > 0) {
          return adminService.rejectProject(authState.user.id, project.id, message)
          .then(response => {
            if (response.error) {
              swal.showValidationMessage(`Decline failed: ${response.message}`);
            }
            swal.insertQueueStep('Project was declined');
          })
          .catch(error => {
            swal.insertQueueStep({
              icon: "error",
              title: "Semething went wrong."
            });
          });  
        }
      },
      allowOutsideClick: () => !swal.isLoading()
    }])
    .then(result => {
      if (result.value) {
        history.push({ pathname: AdminNavigation.PROJECTS_PENDING })
      }
    });
  }

  return (<>
    <Breadcrumb
      routeSegments={[
        { name: "Projects", path: '' },
        { name: 'Approve Project' }
      ]}
    ></Breadcrumb>

    {project && 
      <ProjectWidget project={project} 
        handleApprove={handleApprove} 
        handleReject={handleReject}
        handleRequestInfo={handleRequestInfo} 
        handleCancel={() => history.goBack()} 
        isSubmitting={isSubmitting} />
    }
    { !project && notFound && <>
        <Alert className="text-center alert-card" variant="danger">
          Something went wrong. Project was not found.
          <Button variant="warning" className="btn ml-3" onClick={_ => {history.goBack();}}>Return</Button>
        </Alert>
    </>}
    <NotificationContainer />
  </>
  );
}
