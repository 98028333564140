import React, { useState, useEffect } from 'react';
import { Breadcrumb } from '@gull';
import { NotificationContainer, NotificationManager } from "react-notifications";
import { classList } from '@utils';
import { Formik, Form, ErrorMessage } from 'formik';
import ReCAPTCHA from "react-google-recaptcha";
import { AllowedFileTypes, UserGroup, Industry, ProfileFileCategories } from 'app/utils/constants';
import * as iwtb from 'app/widgets';
import { registrationSchema } from './registrationSchema';
import guestService from 'app/guest/guestService';
import LaddaButton, { EXPAND_RIGHT } from "react-ladda";
import { GuestNavigation } from '../guestRoutes';
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga';
import { useConfiguration } from 'app/configuration/ConfigurationContext';

export const GuestRegistration = () => {
  
  const [isSameAddress, setIsSameAddress] = useState(false);
  const history = useHistory();
  const config = useConfiguration()
  
  const [state, setState] = useState({
    reCaptchaToken: '',
    userGroup: UserGroup.CONTRACTOR,
    trades: [],
    title: '',
    firstName: '',
    lastName: '',
    DOB: '',
    mobile: '',
    email: '',
    role: '',
    about: '',
    location: '',
    businessLocation: '',
    businessName: '',
    ABN: '',
    businessPhone: '',
    businessFax: '',
    businessEmail: '',
    website: '',
    businessYearsOperating: '',
    businessReferences: '',
    industry: '',
    beenBankrupt: '',
    safetyPack: '',
    backgroundCheck: '',
    financialGuarantee: '',
    precludeWork: '',
    files: [],
    username: '',
    password: '',
    repassword: '',
    acceptedTerms: false,
  });

  useEffect(() => {
    
    ReactGA.pageview(window.location.pathname);
  }, []);
  
  const handleSubmit = (values, { setSubmitting }) => {

    try {
      ReactGA.event({ category: 'Registration', action: 'Registered', value: values.email });
    } catch {}

    setSubmitting(true);

    guestService.registerUser(values)
    .then(result => {
      if (result?.error) {
        return NotificationManager.error(result.message, "Registration", 7000);
      }
      history.push(GuestNavigation.REGISTRATION_CONFIRMED);
    })
    .catch(ex => {
      NotificationManager.error("Registration form was not submitted sucessfully", "Registration", 3000);
    })
    .finally(() => {
      setSubmitting(false);
    })
  };

  const sameAddressClicked = (e, setFieldValue, values) => {
    if (e.target.checked) {
      setIsSameAddress(true);
      setFieldValue('businessLocation', values.location)
    }
    else {
      setIsSameAddress(false);
      setFieldValue('businessLocation', '')
    }
  };

  return (
    <div>
      <Breadcrumb
        routeSegments={[
          // { name: "Home", path: "/" },
          { name: 'Registration Form' }
        ]}
      ></Breadcrumb>

      <Formik
        initialValues = {state}
        validationSchema={registrationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          isValid,
          setFieldValue
        }) => {
          return (

            <Form
              className='needs-validation'
              onSubmit={handleSubmit}
              noValidate
            >

              <div className='row mt-3'>
                <div className='col-md-6'>
                  <h4>User Group</h4>
                  <div className='mb-3'></div>
                </div>
              </div>

              <div className='form-row'>
                <div className='form-group col-md-6'>
                  <div className='ul-form__radio-inline'>
                    <iwtb.IwtbRadio onChange={e => {handleChange(e); setFieldValue('trades', [])}} name='userGroup' value={UserGroup.CONTRACTOR} />
                    <iwtb.IwtbRadio onChange={e => {handleChange(e); setFieldValue('trades', [])}} name='userGroup' value={UserGroup.SPECIALIST} />
                    <iwtb.IwtbRadio onChange={e => {handleChange(e); setFieldValue('trades', [])}} name='userGroup' value={UserGroup.CLIENT} />
                  </div>                
                </div>
              </div>

              <div className='form-row'>
                <div className='col-md-12'>
                  { values.userGroup === UserGroup.CONTRACTOR && 
                    <div>
                      <iwtb.IwtbMultiSelect label='Trade Category (up to 5)' name='trades' selectionLimit={5} displayValue="text" selectedValues={values.trades} onChange={ selects => setFieldValue('trades', selects) } options={config.tradeCategories} />
                    </div>
                  }
                  { values.userGroup === UserGroup.SPECIALIST && 
                    <div>
                      <iwtb.IwtbMultiSelect label='Speciality (up to 5)' name='trades' selectionLimit={5} displayValue="text" selectedValues={values.trades} onChange={ selects => setFieldValue('trades', selects) } options={config.specialistCategories} />
                    </div>
                  }
                  { values.userGroup === UserGroup.CLIENT && 
                    <div>
                      <iwtb.IwtbMultiSelect label='Client Relationship' name='trades' selectionLimit={5} displayValue="text" selectedValues={values.trades} onChange={ selects => setFieldValue('trades', selects) } options={config.clientCategories} />
                    </div>
                  }
                </div>
              </div>

              {/* Personal Details  */}

              <div>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <h4>Personal Details</h4>
                    <div className='mb-3'></div>
                  </div>
                </div>

                <div className='form-row'>
                  <iwtb.IwtbInput label='Title' name='title' type='text' placeholder='Title' required />
                  <iwtb.IwtbInput label='First Name' name='firstName' type='text' placeholder='First name' required />
                  <iwtb.IwtbInput label='Surname' name='lastName' type='text' placeholder='Surname' required />
                </div>

                {/* MOBILE, DOB, EMAIL  */}

                <div className='form-row'>
                  <iwtb.IwtbInput label='Date of Birth' name='DOB' type='text' placeholder='DD/MM/YYYY' required />
                  <iwtb.IwtbInput label='Mobile' name='mobile' type='text' placeholder='0412345678' required />
                  <iwtb.IwtbInput label='Email' name='email' type='text' placeholder='Email' required />
                </div>

                {/* Address  */}

                <div className='form-row'>
                  <iwtb.IwtbInput label='Role / Job Title' name='role' type='text' placeholder='Engineer' required />
                  <div  className='col-md-8'>
                    <iwtb.IwtbPlaceAutocomplete name='location' label='Address' type='text' placeholder='Start typing address' locationSelected={ location => setFieldValue('location', location) } />
                  </div>
                </div>

                <div className='mt-md-0 mt-sm-3 mt-3'></div>

                <div className='form-row'>
                  <iwtb.IwtbTextArea label='About Me' name='about' type='text' placeholder='Max 200 characters' maxLength={200} required />
                </div>

              </div>

              <div>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <h4>Business Details</h4>
                    <div className='mb-3'></div>
                  </div>
                </div>

                <div className='form-row'>
                  <iwtb.IwtbSelect label='Industry' name='industry' required>
                      <option value=''>Select industry</option>
                      {Industry.map((v, i) => <option value={v} key={i}>{v.toUpperCase()}</option>)}
                  </iwtb.IwtbSelect>
                  <iwtb.IwtbInput label='Business Name' name='businessName' type='text' placeholder='Business name' required />
                  <iwtb.IwtbInput label='ABN' name='ABN' type='text' placeholder='ABN' required />
                </div>

                <div className='form-row'>
                  <iwtb.IwtbInput label='Phone' name='businessPhone' type='text' placeholder='Phone' required />
                  <iwtb.IwtbInput label='Fax' name='businessFax' type='text' placeholder='Fax' />
                  <iwtb.IwtbInput label='Email' name='businessEmail' type='text' placeholder='Email' required />

                  <iwtb.IwtbInput label='Website' name='website' type='text' placeholder='https://www.iwtb.com.au' required />
                  <iwtb.IwtbInput label='Years Operating' name='businessYearsOperating' type='number' placeholder='Years operating' />
                  <iwtb.IwtbInput label='References / Reviews' name='businessReferences' type='text' placeholder='References and/or reviews' required />
                </div>
              </div>

              {/* Business Details  */}
                                
              <div>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <h4>Business Address</h4>
                    <div className='mb-3'></div>
                  </div>
                </div>

                <iwtb.IwtbCheckbox name='isBusinessAddressSame' onClick={ e => sameAddressClicked(e, setFieldValue, values) }>
                  <span>Business address is the same as personal</span>
                </iwtb.IwtbCheckbox>

                { !isSameAddress && 
                  <div className='form-row'>
                    <div className='col-md-8'>
                      <iwtb.IwtbPlaceAutocomplete name='businessLocation' label='Address' type='text' placeholder='Start typing address' locationSelected={ location => setFieldValue('businessLocation', location) } />
                    </div>   
                  </div>   
                }
              </div>

              {/* Supporting Documents (Contractor)  */}

              { values && (values.userGroup === UserGroup.CONTRACTOR || values.userGroup === UserGroup.SPECIALIST) && 
              <div>
                <div className='row mt-5'>
                  <div className='col-md-6'>
                    <h4>Supporting Documents</h4>
                    <p>
                      Contractors require to upload following documents:
                    </p>
                    <ul>
                      <li>LICENSE(S)</li>
                      <li>INSURANCES (PUBLIC LIABILITY 20MILL + WORKERS COMP)</li>
                      <li>CAPABILITY STATEMENT (UPLOAD OR TYPE MANUALLY)</li>
                      <li>DRIVERS LICENSE OR ID CARD</li>
                      <li>ANY OTHER DOCUMENTS (HOME INDEMNITY INSURANCE, ETC)</li>
                    </ul>
                    <div className='mb-3'></div>
                  </div>
                </div>

                <div className='col-md-12 mb-5'>
                  <iwtb.IwtbFileUpload allowedTypes={AllowedFileTypes} categories={ProfileFileCategories} setUploadedFiles={files => setFieldValue('files', files)} />
                </div>
              </div> }

              {/* Answer Yes/No */}

              <div className='row mt-3'>
                <div className='col-md-6'>
                  <h4>Answer Yes or No</h4>
                  <div className='mb-3'></div>
                </div>
              </div>

              <div className='form-row'>
                <div className='form-group col-md-12'>
                <div className={classList({ 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                    <label className='ul-form__label'>
                      Have you been bankrupt, insolvent or have been part of a company that has been bankrupt or insolvent?
                    </label>
                    <div className='ul-form__radio-inline'>
                      <iwtb.IwtbRadio name='beenBankrupt' value='yes' />
                      <iwtb.IwtbRadio name='beenBankrupt' value='no' />
                    </div>
                    <div className='invalid-feedback'>{ errors.beenBankrupt }</div>
                  </div>
                </div>
              </div>

              { values.userGroup === UserGroup.CLIENT && 
              <div className='form-row'>
                <div className='form-group col-md-12'>
                  <div className={classList({ 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                    <label className='ul-form__label'>
                      Some projects may require you to provide a financial guarantee statement by your financial institution to confirm the financial capability of a project.
                      Is there a concern should it be required?
                    </label>
                    <div className='ul-form__radio-inline'>
                      <iwtb.IwtbRadio name='financialGuarantee' value='yes' />
                      <iwtb.IwtbRadio name='financialGuarantee' value='no' />
                    </div>
                    <div className='invalid-feedback'>{ errors.financialGuarantee }</div>
                  </div>
                </div>
              </div> }

              { values.userGroup === UserGroup.CONTRACTOR && 
              <div>

                <div className='form-row'>
                  <div className='form-group col-md-12'>
                  <div className={classList({ 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                    <label className='ul-form__label'>
                      Do you have a company pre-start & safety pack (SWMS, MSDS, Environmental Controls, etc.) in place?
                    </label>
                    <div className='ul-form__radio-inline'>
                      <iwtb.IwtbRadio name='safetyPack' value='yes' />
                      <iwtb.IwtbRadio name='safetyPack' value='no' />
                    </div>
                    <div className='invalid-feedback'>{ errors.safetyPack }</div>
                  </div>
                  </div>
                </div>

              <div className='form-row'>
                <div className='form-group col-md-12'>
                <div className={classList({ 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                  <label className='ul-form__label'>
                    Some projects may require background checks, such as criminal history checks & child safety checks amoung others. If required, you will be required to supply all documentation at your expense. Is there a concern should it be requested?
                  </label>
                  <div className='ul-form__radio-inline'>
                    <iwtb.IwtbRadio name='backgroundCheck' value='yes' />
                    <iwtb.IwtbRadio name='backgroundCheck' value='no' />
                  </div>
                  <div className='invalid-feedback'>{ errors.backgroundCheck }</div>
                  </div>
                </div>
              </div>

                <div className='form-row'>
                  <div className='form-group col-md-12'>
                  <div className={classList({ 'valid-field': touched && !errors, 'invalid-field': touched && errors })}>
                  <label className='ul-form__label'>
                      Is there anything at all that would preclude your company to complete any works should they be awarded to you? If Yes, why?
                    </label>
                    <div className='ul-form__radio-inline'>
                      <iwtb.IwtbRadio name='precludeWork' value='yes' />
                      <iwtb.IwtbRadio name='precludeWork' value='no' />
                    </div>
                    <div className='invalid-feedback'>{ errors.precludeWork }</div>
                  </div>
                  </div>
                
                  { values.precludeWork === 'yes' &&
                  <div className='form-row col-md-12'>
                    <iwtb.IwtbTextArea label='If yes, write why' name='precludeWorkDetails' type='text' placeholder='' style={{height: 100}} />
                  </div> }
                </div>
              </div> }

              {/* Username  */}
                                
              <div>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <h4>Username and Password</h4>
                    <div className='mb-3'></div>
                  </div>
                </div>

                <div className='form-row'>
                  <iwtb.IwtbInput label='Username' name='username' type='text' placeholder='Please choose a username' required />
                  <iwtb.IwtbInput label='Password' name='password' type='password' placeholder='Please choose your password' required />
                  <iwtb.IwtbInput label='Confirm Password' name='repassword' type='password' placeholder='Confirm your password' required />
                </div>
              </div>

              {/* Terms and Conditions */}

              <div className='mb-4'></div>

              <div className='form-row'>
                <label style={{color: '#f44336'}}>
                Confirm that all answers provided are 100% correct & true. Should any fraudulent documents or information be provided, your account will immediately be terminated with no refund.
                </label>
              </div>

              <div className='mb-4'></div>

              <div className='form-row'>
                <iwtb.IwtbCheckbox name='acceptedTerms' required>
                  <span>Agree to <a href='/legal/privacy' target='_blank'>privacy policy</a>, <a href='/legal/terms' target='_blank'>terms and conditions</a></span>
                </iwtb.IwtbCheckbox>
              </div>

              {!isValid && <div className='form-row mb-3'><span className='text-18 text-danger'>Please check mandatory fields and try again.</span></div>}

              <LaddaButton 
                className='btn btn-success btn-lg border-0 mr-2 mb-2 position-relative'
                loading={isSubmitting}
                progress={0.5}
                data-style={EXPAND_RIGHT}
              >Submit Form</LaddaButton>

              <ReCAPTCHA sitekey={config.RECAPTCHA_KEY} onChange={ token => setFieldValue('reCaptchaToken', token) } />
              <span className='validation-error'><ErrorMessage name='reCaptchaToken' /></span>
            </Form>
          );
        }}
      </Formik>
      <NotificationContainer />
    </div>
  );
}

