  import React from 'react';
import { userSchema } from './userSchema';
import { Formik, Form } from 'formik';
import profileService from './profileService';
import { NotificationManager } from "react-notifications";
import { useAuthState } from 'app/auth';
import { ImageFileTypes, PhotoCategories } from 'app/utils/constants';
import { IwtbFileUpload } from 'app/widgets';

export const UserEditPhotosWidget = ({ user }) => {

  const authState = useAuthState();
  
  const handleUploadedFiles = (photos, values, setFieldValue, setSubmitting) => {

    setSubmitting(true);

    setFieldValue('photos', photos);
    profileService.updateProfile(authState.user.id, { ...values, photos: photos })
    .then(result => {
      NotificationManager.success('Your profile was updated', 'Profile', 2000);
      setSubmitting(false);      
    })
    .catch(ex => {  
      setSubmitting(false);      
      NotificationManager.error('Something went wrong', 'Profile', 3000);
    });
  };

  return ( <>
    {user && 
        <Formik initialValues={user} enableReinitialize={true} validationSchema={userSchema}>
        {({
          values,
          handleSubmit,
          setFieldValue,
          setSubmitting
        }) => {
          return (
            <Form className='needs-validation' onSubmit={handleSubmit} noValidate>
              <IwtbFileUpload id='idUploadPhoto' allowedTypes={ImageFileTypes} categories={PhotoCategories} uploadedFiles={user.photos ?? []} setUploadedFiles={photos => handleUploadedFiles(photos, values, setFieldValue, setSubmitting)} />
            </Form>
          );
        }}
      </Formik>
    }
    </>
  );
}

