import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Badge, Dropdown } from "react-bootstrap";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { NotificationContainer } from 'react-notifications';
import rfiService from './rfiService';
import { RfiAction, RfiStatus } from '../constants';
import { paginationOptions, StatusClass, AccessStatus } from 'app/utils/constants';
import { formatDateTime12 } from 'app/utils/time';
import { constructCompanyName } from 'app/utils/common';
import { ClientNavigation } from '../clientRoutes';
import { useHistory } from "react-router-dom";
import { MdMoreVert} from "react-icons/md";
import { useSpinner } from 'app/IwtbLayout/SharedComponents/spinner/SpinnerContext';
import { RefreshType, refreshOnNotification } from 'app/notification'
import { useSocket } from 'app/socket.io';
import { useAuthState } from 'app/auth';
import { ReloadButton } from 'app/widgets';

export const RfiRegisterWidget = ({ projectId, readOnly }) => {

  const history = useHistory();
  const [rfiRegister, setRfiRegister] = useState([]);
  const { SearchBar, ClearSearchButton } = Search;
  const spinner = useSpinner()
  const socket = useSocket()
  const authState = useAuthState();

  let tableColumns = [
    {
      dataField: 'trade.text',
      text: 'Trade',
      headerAlign: 'center',
      sort: true,
    },
    {
      dataField: 'contractorId',
      text: 'Company',
      headerAlign: 'center',
      sort: true,
      formatter: ((cell, row) => { 
        return <span className="text-primary cursor-pointer" onClick={() => navigateTo(ClientNavigation.CONTRACTOR_CARD, row.contractor)}>{constructCompanyName(row.contractor)}</span>;
      })
    },
    {
      dataField: 'requested',
      text: 'Requested',
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => formatDateTime12(row.created))
    },
    {
      dataField: 'responded',
      text: 'Responded',
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => row.response ? formatDateTime12(row.response.created) : '')
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
    {
      dataField: 'ref',
      text: 'Ref',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
    },
  ];

  if (!readOnly) {
    tableColumns = [...tableColumns,
      {
        dataField: 'action',
        text: 'Action',
        headerAlign: 'center',
        align: 'center',
        headerStyle: () => ({ width: '60px' }),
        formatter: ((cell, row) => { 
          return <Dropdown onSelect={(action) => actionRfi(action, row)}>
            <Dropdown.Toggle as="span" className="cursor-pointer toggle-hidden">
              <MdMoreVert size={18}></MdMoreVert>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {row.status !== RfiStatus.RESPONDED &&
                <Dropdown.Item eventKey={RfiAction.REPLY}><i className="i-Left"> </i>Reply</Dropdown.Item>
              }
              {row.status !== AccessStatus.PENDING &&
                <Dropdown.Item eventKey={RfiAction.VIEW}><i className="i-ID-Card"> </i>View</Dropdown.Item>
              }
            </Dropdown.Menu>
          </Dropdown>
        })
      },
    ];
  }

  useEffect(() => {

    const onUserNotification$ = socket.onUserNotification().subscribe(notification => {

      refreshOnNotification(notification.type, RefreshType.CLIENT_PROJECT_RFI, loadRfiRegister)
    })
    return() => {
        onUserNotification$.unsubscribe()
    }
  }, []);

  useEffect(() => {

    loadRfiRegister();
  }, [projectId]);

  const loadRfiRegister = async () => {

    if (projectId) {
      spinner.show()
      try {
        const result = await rfiService.getRfiRegister(authState.user.id, projectId)
        if (result.rfiRegister) {
          setRfiRegister(result.rfiRegister);
        }
      }
      catch(ex) {}
      finally {
        spinner.hide()
      }
    }
  }

  const navigateTo = (pathname, contractor) => {
    
    switch(pathname) {
      case ClientNavigation.CONTRACTOR_CARD:
        pathname = pathname.replace(':contractorId', contractor.id);
    }
    history.push({ pathname: pathname });  
  }

  const actionRfi = (action, rfi) => {
    
    history.push({
      pathname: ClientNavigation.VIEW_RFI,
      state: { rfi: rfi }
    });  
  }

  return (
    <>
        {rfiRegister && <ToolkitProvider
            keyField='id'
            data={rfiRegister}
            columns={tableColumns}
            search
            exportCSV
        >
            {props => (
            <>
                <div className='d-flex justify-content-end align-items-center'>
                  <span className='mb-2 mr-1'>Search:</span>
                  <SearchBar {...props.searchProps} className='mb-0 ' />
                  <ClearSearchButton { ...props.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
                  <ReloadButton onClick={loadRfiRegister} />
                </div>
                <BootstrapTable
                {...props.baseProps}
                bootstrap4
                wrapperClasses="table-responsive"
                pagination={paginationFactory({ ...paginationOptions, totalSize: rfiRegister.length })}
                noDataIndication={'No records found'}
                condensed
                />
            </>
            )}
        </ToolkitProvider>}

        <NotificationContainer />
    </>
  );
}
