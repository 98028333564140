
export const UserAction = {
    SUSPEND_USER: 'suspend-user',
    DELETE_USER: 'delete-user',
    APPROVE_USER: 'approve-user',
    VIEW_PROFILE: 'view-profile',
    USER_SUBSCRIPTIONS: 'user-subscriptions',
    USER_PROJECTS: 'user-projects',
    CONTACT_USER: 'contact-user',
}

export const ProjectAction = {
    SETUP_ALLOCATION: 'setup-allocation',
    CONTACT_CLIENT: 'contact-client',
    CONTACT_CONTRACTOR: 'contact-contractor',
    EDIT_PROJECT: 'edit-project',
    VIEW_PROJECT: 'view-project',
    VIEW_CLIENT: 'view-client',
    VIEW_CONTRACTOR: 'view-contractor',
    DELETE_SERVICE: 'delete-service',
    SUBMIT_BID: 'submit-bid',
    DELETE_PROJECT: 'delete-project',
    VIEW_REASON: 'view-reason',
}

export const AwardAction = {
    SEND_MESSAGE: 'send-message',
    ORIGINAL_SOW: 'original-sow',
    ADDITIONAL_SOW: 'additional-sow',
    DECLINE: 'decline',
    ADD_CONTACT: 'add-contact',
    DELETE_BID: 'delete-bid',
}

export const ProjectTab = {
    DETAILS: 'Details',
    SERVICES: 'Services',
    ACCESS: 'Access Register',
    RFI: 'RFI Register',
    BIDS: 'Bid Register',
    AWARDS: 'Awarded Trades',
    TRADES: 'Trades',
    FILES: 'Documents',
}

export const TradeType = {
    TRADE: 'trade',
    SPECIALIST: 'specialist'
}

export const RfiStatus = {
    PENDING: 'pending',
    RESPONDED: 'responded',
}
  
export const RfiAction = {
    REPLY: 'reply',
    VIEW: 'view',
}
