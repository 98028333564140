
export const ProjectAction = {
    ADD_ADDENDUM: 'add-addendum',
    AWARD_TRADE: 'award-trade',
    VIEW_TRADES: 'view-trades',
    VIEW_PROJECT: 'view-project',
    SEND_NOTIFICATION: 'send-notification',
    CANCEL_PROJECT: 'cancel-project',
    DELETE_PROJECT: 'delete-project',
    REVISE: 'revise',
    VIEW_REASON: 'view-reason',
    UPLOAD_QUOTE: 'upload-quote',
}

export const ProjectTab = {
    DETAILS: 'Details',
    ACCESS: 'Access Register',
    RFI: 'RFI Register',
    BIDS: 'Bid Register',
    AWARDS: 'Awarded Trades',
    TRADES: 'Trades',
    FILES: 'Documents',
}

export const RfiStatus = {
    PENDING: 'pending',
    RESPONDED: 'responded',
}
  
export const RfiAction = {
    REPLY: 'reply',
    VIEW: 'view',
}

export const AwardAction = {
    SEND_MESSAGE: 'send-message',
    ORIGINAL_SOW: 'original-sow',
    ADDITIONAL_SOW: 'additional-sow',
    DECLINE: 'decline',
    ADD_CONTACT: 'add-contact',
    DELETE_BID: 'delete-bid',
}

export const WorkAction = {
    DELETE: 'delete',
}
