import React, { useState, useEffect } from 'react';
import { downloadBlobFile, isImageFile } from 'app/utils/common';

export const IwtbBlobImage = ({ blobName, handleViewImage, ...props }) => {

    const [error, setError] = useState(false);
    const [url, setUrl] = useState();

    useEffect(() => {

      if (blobName && blobName.length > 0) {
        try {
          if (isImageFile(blobName)) {  
            downloadBlobFile(blobName).then(url => {
              if (url && url.error) {
                setError(true);
              }
              if (url && url.length > 0) {
                setUrl(url);
              }
              else {
                setError(true);
              }  
            })
            .catch(e => { setError(true) });
          }
          else {
            setError(true);
          }  
        }
        catch {
          setError(true);
        }  
      }

    }, [blobName]);

    if (url) {
        return (<img {...props} src={url} onClick={() => handleViewImage(url)} />);
    } 
    else if (error) {
      // TODO: show error image
      return ('')
    }
    return (<img {...props} />);
}
