import React from "react";
import { Redirect } from 'react-router-dom';
import { AuthRole, AuthGuard } from 'app/auth';
import { AdminDashboard } from './dashboard/AdminDashboard';
import { PendingProjects, TenderingProjects, ConstructionProjects, ApproveProject, DeclinedProjects } from './project';
import { Services } from './service';
import { Allocations } from './allocation';
import { Memberships, UserApproval } from './membership';
import legalRoutes from 'app/pages/legal/legalRoutes';
import { PageNotFound } from 'app/error'
import { MyProfile, ClientCard, ContractorCard } from "app/profile";
import { ProjectCard } from "./project/ProjectCard";
import { SubmitBid, BidCard } from './bid'
import { Chat } from 'app/chat';
import { SubmitSow } from "./sow";

export const AdminNavigation = {


    HOME:                   '/admins/:id/home',
    ALLOCATIONS:            '/admins/:id/allocatoins/:projectId',
    CHAT:                   '/admins/:id/chats/:opponentId?',
    SERVICES:               '/admins/:id/services',
    CLIENT_CARD:            '/admins/:id/clients/:clientId/:tab?',
    CONTRACTOR_CARD:        '/admins/:id/contractors/:contractorId/:tab?',
    
    APPROVE_USER:           '/admins/:id/users/approve/:userId',

    APPROVE_PROJECT:        '/admins/:id/projects/:projectId',
    SUBMIT_BID:             '/admins/:id/projects/:projectId/bids',
    BID_CARD:               '/admins/:id/projects/:projectId/bids/:bidId/tabs/:tab?',
    SUBMIT_SOW:             '/admins/:id/projects/:projectId/bids/:bidId/sow',

    PROJECT_CARD:           '/admins/:id/projects/card/:projectId/:tab?',
    PROJECTS_PENDING:       '/admins/:id/projects/pending',
    PROJECTS_CONSTRUCTION:  '/admins/:id/projects/construction',
    PROJECTS_TENDERING:     '/admins/:id/projects/tendering',
    DECLINED_PROJECTS:      '/admins/:id/declined',

    MEMBERSHIPS:            '/admins/:id/users/:userGroup?',
    MEMBERSHIPS_PENDING:    '/admins/:id/users/pending',
    MEMBERSHIPS_ALL:        '/admins/:id/users/all',
    MEMBERSHIPS_CLIENT:     '/admins/:id/users/client',
    MEMBERSHIPS_CONTRACTOR: '/admins/:id/users/contractor',
    MEMBERSHIPS_SPECIALIST: '/admins/:id/users/specialist',

    REVENUE:                '/admins/:id/revenue',
    COMMUNICATION:          '/admins/:id/communications',
    USER_PROFILE:           '/admins/:id/profile',
}
  
const routes = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to={ AdminNavigation.HOME } />
    },
    {
        path: AdminNavigation.HOME,
        component: AdminDashboard,
        auth: AuthRole.ADMIN,
    },
    {
        path: AdminNavigation.SUBMIT_SOW,
        exact: true,
        component: SubmitSow,
        auth: AuthRole.CLIENT,
    },
    {
        path: AdminNavigation.BID_CARD,
        component: BidCard,
        auth: AuthRole.ADMIN,
        exact: true,
    },
    {
        path: AdminNavigation.ALLOCATIONS,
        component: Allocations,
        auth: AuthRole.ADMIN,
    },
    {
        path: AdminNavigation.CHAT,
        component: Chat,
        auth: AuthRole.ADMIN,
    },
    {
        path: AdminNavigation.SUBMIT_BID,
        component: SubmitBid,
        auth: AuthRole.ADMIN,
        exact: true,
    },
    {
        path: AdminNavigation.SERVICES,
        component: Services,
        auth: AuthRole.ADMIN,
    },
    {
        path: AdminNavigation.APPROVE_USER,
        component: UserApproval,
        auth: AuthRole.ADMIN,
    },
    {
        path: AdminNavigation.MEMBERSHIPS,
        component: Memberships,
        auth: AuthRole.ADMIN,
    },
    {
        path: AdminNavigation.PROJECTS_PENDING,
        component: PendingProjects,
        auth: AuthRole.ADMIN,
    },
    {
        path: AdminNavigation.PROJECTS_CONSTRUCTION,
        component: ConstructionProjects,
        auth: AuthRole.ADMIN,
    },
    {
        path: AdminNavigation.PROJECTS_TENDERING,
        component: TenderingProjects,
        auth: AuthRole.ADMIN,
    },
    {
        path: AdminNavigation.PROJECT_CARD,
        component: ProjectCard,
        auth: AuthRole.ADMIN,
        exact: true,
    },
    {
        path: AdminNavigation.CONTRACTOR_CARD,
        component: ContractorCard,
        auth: AuthRole.ADMIN,
        exact: true,
    },
    {
        path: AdminNavigation.CLIENT_CARD,
        component: ClientCard,
        auth: AuthRole.ADMIN,
        exact: true,
    },
    {
        path: AdminNavigation.USER_PROFILE,
        component: MyProfile,
        auth: AuthRole.ADMIN,
    },
    {
        path: AdminNavigation.DECLINED_PROJECTS,
        exact: true,
        component: DeclinedProjects,
        auth: AuthRole.ADMIN,
    },
    {
        path: AdminNavigation.APPROVE_PROJECT,
        component: ApproveProject,
        auth: AuthRole.ADMIN,
    },
    {
        path:'/*',
        component: PageNotFound
    },
];

export const adminRoutes = [{
    path: '/',
    component: AuthGuard,
    routes: [
    ...legalRoutes,
    ...routes,
    ]
}];
