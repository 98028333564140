import React from 'react'
import { Button } from "react-bootstrap";


export const ReloadButton = ({ onClick }) => {

    return (
        <Button title="Reload" onClick={onClick} variant="warning" className="btn-icon mb-2 mr-1 text-capitalize">
            <span className="ul-btn__icon"><i className="i-Repeat-3"></i></span>
        </Button>
    )
}
