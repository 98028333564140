import HttpService from 'app/services/httpService';
import { indexArray } from 'app/utils/common';

export class ContractorService {

    constructor() {
        this.http = new HttpService();
    }

    getContractorProject = async (contractorId, projectId) => {
        
        let result = await this.http.get(`contractors/${contractorId}/projects/${projectId}`);
        if (result && result.rfiRegister) {
            result.rfiRegister = indexArray(result.rfiRegister);
        }
        return result;
    };

    getContractorProjects = async (contractorId) => {

        let results = await this.http.get(`contractors/${contractorId}/projects`);
        results = indexArray(results);
        return results
    };

    getContractorProfile = (contractorId) => {

        return this.http.get(`contractors/${contractorId}`);
    };
    
    getContractorAwards = async (contractorId) => {

        let results = await this.http.get(`contractors/${contractorId}/awards`);
        results = indexArray(results);
        return results
    };
    
    getBidRegister = async (contractorId, projectId) => {

        let results = await this.http.get(`contractors/${contractorId}/projects/${projectId}/bids`);
        results = indexArray(results);
        return results
    };

    acceptAward = async (contractorId, projectId, bidId, accepted, reason) => {
        return this.http.post(`contractors/${contractorId}/projects/${projectId}/awards/${bidId}`, { accepted: accepted, reason: reason });
    };

    withdrawBid = async (contractorId, projectId, bidId, reason) => {

        const result = await this.http.put(`contractors/${contractorId}/projects/${projectId}/bids/${bidId}`, { reason: reason });
        return result
    };

    getAccessRegister = async (contractorId) => {

        let results = await this.http.get(`contractors/${contractorId}/projects/access`);
        results = indexArray(results);
        return results
    };

    getTenderingProjects = async (contractorId, match) => {

        return this.http.get(`contractors/${contractorId}/projects/tenders?match=${match}`);
    };

    submitAccess = (contractorId, projectId, message) => {

        return this.http.post(`contractors/${contractorId}/projects/${projectId}/access`, { message: message });
    };

    deleteAccess = (contractorId, projectId) => {

        return this.http.delete(`contractors/${contractorId}/projects/${projectId}/access`);
    };
}

export default new ContractorService();