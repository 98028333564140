import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { paginationOptions, StatusClass, audFormatter, IWTB_AdditionalServiceTitles, AllocationAction, ServiceStatus } from 'app/utils/constants';
import { MdMoreVert} from 'react-icons/md';
import { Dropdown, Badge } from 'react-bootstrap';

export const ServiceRegisterWidget = ({ services, handleServiceAction }) => {

  const { SearchBar, ClearSearchButton } = Search;

  const columns = [
    {
      dataField: 'service',
      text: 'Service',
      headerAlign: 'center',
      formatter: ((cell, row) => IWTB_AdditionalServiceTitles[cell])
    },
    {
      dataField: 'worker',
      text: 'Company',
      headerAlign: 'center',
      align: 'center',
      formatter: ((cell, row) => 'IWTB')
    },
    {
      dataField: 'budget',
      text: 'Budget',
      headerAlign: 'center',
      align: 'right',
      formatter: ((cell, row) => <span className='text-primary'>{audFormatter.format(row.budget)}</span>)
    },
    {
      dataField: 'actual',
      text: 'Actual',
      headerAlign: 'center',
      align: 'right',
      formatter: ((cell, row) => <span className='text-info'>{audFormatter.format(row.actual)}</span>)
    },
    {
      dataField: 'difference',
      text: 'Difference',
      headerAlign: 'center',
      align: 'right',
      formatter: ((cell, row) => {
        return <span className={row.budget - row.actual >= 0 ? 'text-success' : 'text-danger'}>
          {audFormatter.format(row.budget - row.actual)}
        </span>
      })
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      editable: false,
      headerStyle: () => ({ width: '100px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
    {
      dataField: 'revision',
      text: 'Rev',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '50px' })
    },
    {
      dataField: 'ref',
      text: 'Ref',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '60px' }),
      formatter: ((cell, row) => { 

        return <Dropdown onSelect={action => handleServiceAction(action, row)}>
          <Dropdown.Toggle as='span' className='cursor-pointer toggle-hidden'>
            <MdMoreVert size={18}></MdMoreVert>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {row.comments?.length > 0 && <>
              <Dropdown.Item eventKey={AllocationAction.VIEW_COMMENTS}><i className='i-Speach-Bubble-3 text-info'> </i>View comments</Dropdown.Item>
            </>}
            <Dropdown.Item eventKey={AllocationAction.CONTACT_SUPPORT}><i className='i-Speach-Bubble-Asking text-success'> </i>Support</Dropdown.Item>
            {row.status !== ServiceStatus.DELETED && <>
              <div className="dropdown-divider"></div>
              <Dropdown.Item eventKey={AllocationAction.DELETE_SERVICE}><i className="i-Close-Window text-danger"> </i>Delete service</Dropdown.Item>
            </>}
          </Dropdown.Menu>
        </Dropdown>
      })
    }];

  return (<>
    <ToolkitProvider
      keyField='ref'
      data={services}
      columns={columns}
      search
      exportCSV
    >
      {props => (
        <>
          <div className='d-flex justify-content-end align-items-center'>
            
            <span className='mb-2 mr-1'>Search:</span>
            <SearchBar {...props.searchProps} className='mb-0 ' />
            <ClearSearchButton { ...props.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
            <button className='btn btn-outline-success mb-2 mr-1' onClick={_ => props.csvProps.onExport()}>Export CSV</button>
          </div>
          <BootstrapTable
            {...props.baseProps}
            bootstrap4
            wrapperClasses="table-responsive"
            pagination={paginationFactory({ ...paginationOptions, totalSize: services.length })}
            noDataIndication={'No services'}
            condensed
          />
        </>
      )}
    </ToolkitProvider>
  </>);
}
