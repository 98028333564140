import React, { useState, useEffect } from 'react';
import { addContactWithId } from 'app/utils/common';
import { MdStar, MdStarBorder } from "react-icons/md";
import { Link } from "react-router-dom";
import Rating from "react-rating";
import { useAuthState } from 'app/auth';
import { ProfileImageWidget } from 'app/user';

export const ProfileCardWidget = ({ user, handleOpenChat, handleViewProfile }) => {

  const [rating, setRating] = useState(0);
  const authState = useAuthState();

  useEffect(() => {

    if (user && user.reviews) {
      calcRating(user.reviews);
    }
  }, [user]);

  const calcRating = reviews => {

    if (reviews && Array.isArray(reviews)) {
      let rating = 0;
      try {
        reviews.map(review => {rating = rating + review.rating});
        rating = rating / reviews.length;
        setRating(rating);
      }
      catch {
        setRating(0);
      }
    }
  }

  return ( <>
    {user && <div className="card card-profile-1 mb-4">
      <div className="card-body text-center">
        <div className="avatar box-shadow-2 mb-3 cursor-pointer" onClick={handleViewProfile}>
          <ProfileImageWidget profileImage={user.profileImage} userGroup={user.userGroup} />
        </div>
        <h5 className="m-0">{user.firstName} {user.lastName}</h5>
        <p className="mt-0">{user.role}</p>
        <p>
          <Rating readonly
              initialRating={rating}
              emptySymbol={<MdStarBorder size={22}></MdStarBorder>}
              fullSymbol={<MdStar className="text-warning" size={22}></MdStar>}
          />
        </p>
        <p>{user.about}</p>

        <div className="ul-widget-app__profile-footer mt-4">

          <div className="ul-widget-app__profile-footer-font">
            <button className='btn btn-outline-primary' onClick={handleOpenChat}>
              <i className="i-Speach-Bubble-6 text-15 me-1 mr-1"></i>
              <span className="text-15">
                {user.online && <span className="text-success">Online</span>}
                {!user.online && <span className="text-light">Offline</span>}
              </span>
            </button>
          </div>

          <div className="ul-widget-app__profile-footer-font">
            <button className='btn btn-outline-success' onClick={() => addContactWithId(authState.user.id, user.id)}>
              <i className="i-Add-User text-15 me-1 mr-1"></i>
              <span className="text-15">Add Contact</span>
            </button>
          </div>

        </div>

        {/* <div className="card-socials-simple mt-4">
          <span className="cursor-pointer px-1">
            <i className="i-Linkedin-2"></i>
          </span>
          <span className="cursor-pointer px-1">
            <i className="i-Facebook-2"></i>
          </span>
          <span className="cursor-pointer px-1">
            <i className="i-Twitter"></i>
          </span>
        </div> */}
      </div>
    </div>}
  </>);
}

