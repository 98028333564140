import React from 'react';
import { classList } from '@utils';
import { useField, Field } from 'formik';


export const IwtbRadio = ({ children, ...props }) => {

    const [field, meta] = useField({ ...props, type: 'radio' });
    return (
        <label className=" ul-radio__position radio radio-primary form-check-inline">
            <Field type="radio" {...field} {...props} />
            <span className="ul-form__radio-font" style={{textTransform: 'capitalize'}}>
                {field.value}
            </span>
            <span className="checkmark"></span> 
        </label>
    );
};
