import HttpService from 'app/services/httpService';

class profileService {

    http = new HttpService();

    getClientProfile = (userId, clientId) => {
        return this.http.get(`users/${userId}/clients/${clientId}`);
    };

    getContractorProfile = (userId, contractorId) => {
        return this.http.get(`users/${userId}/contractors/${contractorId}`);
    };

    getClientSubscription = (userId, clientId) => {
        return this.http.get(`users/${userId}/clients/${clientId}/subscriptions/current`);
    }

    getContractorSubscription = (userId, contractorId) => {
        return this.http.get(`users/${userId}/contractors/${contractorId}/subscriptions/current`);
    }

    getAdminProfile = (userId, adminId) => {
        return this.http.get(`users/${userId}/admins/${adminId}`);
    };

    getClientProjects = (userId, clientId) => {
        return this.http.get(`users/${userId}/clients/${clientId}/projects`);
    };

    getContractorProjects = (userId, contractorId) => {
        return this.http.get(`users/${userId}/contractors/${contractorId}/projects`);
    };

    updateProfile = (userId, user) => {
        return this.http.put(`users/${userId}/profile`, { user: user });
    };

    updateProfileImage = (userId, image) => {
        return this.http.put(`users/${userId}/profile/image`, { image: image });
    };

    updateProfileBackground = (userId, blobName) => {
        return this.http.put(`users/${userId}/profile/background`, { image: { blobName: blobName }});
    };

    addProfilePhotos = (userId, photos) => {
        return this.http.post(`users/${userId}/profile/photos`, { photos: photos });
    };

    getUserContacts = (userId) => {
        return this.http.get(`users/${userId}/contacts`);
    };

    addContactWithId = (userId, contactId) => {
        return this.http.post(`users/${userId}/contacts/${contactId}`);
    };

    addContact = (userId, contact) => {
        return this.http.post(`users/${userId}/contacts`, { contact: contact });
    };

    updateContact = (userId, contactId, contact) => {
        return this.http.put(`users/${userId}/contacts/${contactId}`, { contact: contact });
    };

    deleteContact = (userId, contactId) => {
        return this.http.delete(`users/${userId}/contacts/${contactId}`);
    };
}

export default new profileService();