import React, { useState, useEffect, useCallback } from 'react';
import { SimpleCard } from '@gull';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Breadcrumb } from '@gull';
import adminService from '../adminService';
import { AdminNavigation } from '../adminRoutes';
import { paginationOptions, StatusClass, audFormatter, IWTB_AdditionalServiceTitles, AllocationAction, ServiceStatus } from 'app/utils/constants';
import { useParams, useHistory } from "react-router-dom";
import { useAuthState } from 'app/auth';
import { MdMoreVert} from 'react-icons/md';
import { Dropdown, Badge } from 'react-bootstrap';
import { ProjectTab } from '../constants';
import { handleOpenChat, mapServices, constructCompanyName, navigateTo } from 'app/utils/common';

export const Allocations = (props) => {

  const { SearchBar, ClearSearchButton } = Search;
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState();
  const [services, setServices] = useState([]);
  const [workers, setWorkers] = useState([]);
  const { projectId } = useParams();
  const authState = useAuthState();

  const columns = [
    {
      dataField: 'service',
      text: 'Service',
      headerAlign: 'center',
      editable: false,
      formatter: ((cell, row) => IWTB_AdditionalServiceTitles[cell])
    },
    {
      dataField: 'client',
      text: 'Client',
      headerAlign: 'center',
      sort: true,
      editable: false,
      formatter: ((cell, row) => { 
        return <span className="text-primary cursor-pointer" onClick={() => actionService(AllocationAction.CONTACT_CLIENT, row)}>{constructCompanyName(project?.contact)}</span>;
      })
    },
    {
      dataField: 'worker',
      text: 'Worker',
      headerAlign: 'center',
      style: { cursor: 'pointer' },
      editor: {
        type: Type.SELECT,
        getOptions: () => workers
      }
    },
    {
      dataField: 'budget',
      text: 'Budget',
      headerAlign: 'center',
      align: 'right',
      style: { cursor: 'pointer' },
      formatter: ((cell, row) => <span className='text-primary'>{audFormatter.format(row.budget)}</span>)
    },
    {
      dataField: 'actual',
      text: 'Actual',
      headerAlign: 'center',
      align: 'right',
      style: { cursor: 'pointer' },
      formatter: ((cell, row) => <span className='text-info'>{audFormatter.format(row.actual)}</span>)
    },
    {
      dataField: 'difference',
      text: 'Difference',
      headerAlign: 'center',
      align: 'right',
      editable: false,
      formatter: ((cell, row) => {
        return <span className={row.budget - row.actual >= 0 ? 'text-success' : 'text-danger'}>
          {audFormatter.format(row.budget - row.actual)}
        </span>
      })
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      editable: false,
      headerStyle: () => ({ width: '90px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
    {
      dataField: 'revision',
      text: 'Rev',
      headerAlign: 'center',
      editable: false,
      align: 'center',
      headerStyle: (colum, colIndex) => ({ width: '50px' })
    },
    {
      dataField: 'ref',
      text: 'Ref',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
    },
    {
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: '60px' };
      },
      formatter: ((cell, row) => { 

        return <Dropdown onSelect={action => actionService(action, row)}>
          <Dropdown.Toggle as='span' className='cursor-pointer toggle-hidden'>
            <MdMoreVert size={18}></MdMoreVert>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey={AllocationAction.VIEW_PROJECT}><i className='i-ID-Card text-primary'> </i>Project Card</Dropdown.Item>
            <Dropdown.Item eventKey={AllocationAction.CONTACT_CLIENT}><i className='i-Business-Man text-success'> </i>Contact Client</Dropdown.Item>
            {/* <Dropdown.Item eventKey={AllocationAction.TIMESHEET}><i className='i-Over-Time text-info'> </i>Timesheet</Dropdown.Item>
            <Dropdown.Item eventKey={AllocationAction.INVOICE}><i className='i-Financial text-danger'> </i>Invoice</Dropdown.Item> */}
            
        </Dropdown.Menu>
        </Dropdown>
      })
    }];

  const getProject = useCallback((adminId, projectId) => {

    adminService.getProject(adminId, projectId)
    .then(result => {
      if (result.error) {
        NotificationManager.error(result.message, 'Server Connection', 3000);      
      }
      else {
        setLoading(false); 
        setProject(result); 
        if (result.additionalServices) {
          const services = mapServices(result.additionalServices);
          setServices(services);       
        }
      }
    })
    .catch(e => { NotificationManager.error('Something went wrong.', 'Server Connection', 3000) });

    loadWorkers()
  })

  useEffect(() => {
    
    if (projectId && authState.isAuthenticated) {
      getProject(authState.user.id, projectId)
    }
  }, [projectId]);

  const submitAllocations = () => {
    
    setLoading(true); 

    const items = mapServices(services);
    adminService.updateServices(authState.user.id, project.id, items)
    .then(result => {
      NotificationManager.success(
        "Allocation was successful.",
        "Allocation",
        2000
      );
      setLoading(false); 
    })
    .catch(ex => {  
      setLoading(false); 
      NotificationManager.error(
        "Allocation was unsuccessful.",
        "Allocation",
        3000
      );
    });
  }

  const loadWorkers = () => {

    adminService.getWorkers(authState.user.id)
    .then(result => {
      if (result.error) {
        NotificationManager.error(result.message, 'Server Connection', 3000);      
      }
      else {
        const workers = result.map(v => {
          const name = constructCompanyName(v)
          return { label: name, value: name }
        });
        setWorkers([{ value: ServiceStatus.UNALLOCATED, label: 'Unallocated' }, ...workers]);
      }
    })
    .catch(e => { NotificationManager.error('Something went wrong.', 'Server Connection', 3000) });  
  }

  const actionService = (action, service) => {

    switch(action) {

      case AllocationAction.VIEW_PROJECT:
        return navigateTo({ history: history, navigation: AdminNavigation, pathname: AdminNavigation.PROJECT_CARD, project: project, tab: ProjectTab.DETAILS });
  
      case AllocationAction.CONTACT_CLIENT:
        return handleOpenChat(authState, history, project.clientId);
    }
  }

  const handleUpdate = () => {

    const items = services.map(v => {
      v.difference = v.budget - v.actual
      return v
    })
    setServices(items);       
  }

  return (
    <div>
      <Breadcrumb
        routeSegments={[
          { name: 'Admin', path: AdminNavigation.HOME },
          { name: `Allocation: ${project?.projectName}` },
        ]}
      ></Breadcrumb>

      <div className='row'>
          <div className='col-md-12'>

          <SimpleCard>

            <ToolkitProvider
              keyField='index'
              data={services}
              columns={columns}
              search
              exportCSV
            >
              {props => (
                <>
                  <div className='d-flex justify-content-end align-items-center'>
                   
                    <span className='mb-2 mr-1'>Search:</span>
                    <SearchBar {...props.searchProps} className='mb-0 ' />
                    <ClearSearchButton { ...props.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
                    <button className='btn btn-outline-success mb-2 mr-1' onClick={_ => props.csvProps.onExport()}>Export CSV</button>
                    <button className='btn btn-primary mb-2 mr-1' onClick={submitAllocations}>Submit Allocations</button>
                    <button className='btn btn-warning mb-2 mr-1' onClick={() => getProject(authState.user.id, projectId)}>Reset</button>
                  </div>
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4
                    wrapperClasses="table-responsive"
                    cellEdit={cellEditFactory({ 
                      mode: 'click', 
                      blurToSave: true, 
                      autoSelectText: true,
                      afterSaveCell: () => handleUpdate()
                    })}
                    pagination={paginationFactory({ ...paginationOptions, totalSize: services.length })}
                    noDataIndication={'No services'}
                    condensed
                    loading={loading}
                  />
                </>
              )}
            </ToolkitProvider>

          </SimpleCard>
        </div>
      </div>

      <NotificationContainer />
    </div>
  );
}
