import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import EmptyMessage from './EmptyMessage';
import { humanReadableDate } from 'app/utils/time';
import { ProfileImageWidget } from 'app/user';
import { Formik, Form } from 'formik';
import { AllowedFileTypes, UserGroup } from 'app/utils/constants';
import { IwtbFileUpload, IwtbFilePreview } from 'app/widgets';
import { NotificationManager } from 'react-notifications';
import { useHistory } from "react-router-dom";
import { useAuthState } from 'app/auth';
import { navigateTo } from 'app/utils/common'

const ChatWidget = ({
  currentUser = {},
  toggleSidenav,
  opponentUser,
  messages = [],
  handleMessageSend,
  isMobile
}) => {

  const authState = useAuthState();
  const history = useHistory();
  const bottomRef = useRef();
  const [message, setMessage] = useState('');
  const [previewFile, setPreviewFile] = useState();
  const [showUpload, setShowUpload] = useState({
    show: false,
    title: '',
    message: '',
    files: [],
  });

  const initialValues = { files: [] }

  const handleHideUpload = () => {

    setShowUpload({
      show: false,
      title: '',
      files: [],
    });
  }

  useEffect(() => {

    if (messages && bottomRef.current) {
      bottomRef.current.scrollTop = 99999999;
    }
  }, [messages]);
  
  const handleAttachmentSend = (values, setFieldValue) => {

    if (!(values.files && values.files.length >0)) {
      return NotificationManager.error('No files were uploaded', 'Send Attachment', 3000);      
    }

    handleMessageSend('', values.files)
    handleHideUpload()
    setFieldValue('files', [])
  };

  const handlePreviewFile = file => {

    setPreviewFile(); // reset preview
    setTimeout(() => setPreviewFile(file), 0);
  }

  const openOpponentCard = (opponent) => {
    
    if (opponent?.userGroup === UserGroup.CLIENT) {
      return navigateTo({ history: history, navigation: authState.navigation, pathname: authState.navigation.CLIENT_CARD, user: opponent });
    }
    if (opponent?.userGroup === UserGroup.CONTRACTOR || opponent?.userGroup === UserGroup.SPECIALIST) {
      return navigateTo({ history: history, navigation: authState.navigation, pathname: authState.navigation.CONTRACTOR_CARD, user: opponent });
    }
  }

  return (
    <Formik initialValues={initialValues} enableReinitialize={true}>
    {({
      values,
      handleSubmit,
      setFieldValue,
    }) => { return (
        <Form className='needs-validation' onSubmit={handleSubmit} noValidate>

          <div className='chat-content-wrap sidebar-content' style={{ marginLeft: isMobile ? 0 : '260px' }}>
            <div className='d-flex pl-3 pr-3 pt-2 pb-2 o-hidden box-shadow-1 chat-topbar'>
              <span className='link-icon d-md-none' onClick={toggleSidenav}>
                <i className='icon-regular i-Right ml-0 mr-3'></i>
              </span>
              {opponentUser && 
                <div className='d-flex align-items-center cursor-pointer' onClick={() => openOpponentCard(opponentUser)}>
                  <ProfileImageWidget profileImage={opponentUser.profileImage} userGroup={opponentUser.userGroup} className='avatar-sm rounded-circle mr-2' />
                  <p className='m-0 text-title text-16 flex-grow-1'>{opponentUser.firstName}</p>
                </div>
              }
            </div>

            {opponentUser ? (
              <>
                <div className='overflow-auto chat-content' ref={bottomRef}>
                  {messages.map((item, index) =>
                    item.from === currentUser.id ? (
                      // right side - current user
                      <div key={index} className='d-flex mb-3'>
                        <div className='message flex-grow-1'>
                          <div className='d-flex'>
                            <p className='text-title text-15 flex-grow-1'></p>
                            <span className='text-small text-muted'>
                              {humanReadableDate(item.time)}
                            </span>
                          </div>
                          <div>
                            {item.files && item.files.map((file, i) => (
                              <div className="ul-widget4__item" key={i}>
                                <div className="ul-widget4__pic-icon">
                                  <i className="i-File text-primary"></i>
                                </div>
                                <span className='cursor-pointer text-primary' onClick={() => handlePreviewFile(file)}>{file.name}</span> 
                              </div>
                            ))}
                            {item.message && <p className='m-0 white-space-pre-line'>{item.message}</p>}
                          </div>
                        </div>
                        <ProfileImageWidget profileImage={currentUser.profileImage} userGroup={currentUser.userGroup} className='avatar-sm rounded-circle ml-3' />
                      </div>
                    ) : (
                      // left side - opponent
                      <div key={index} className='d-flex mb-3 user'>
                        <span className='cursor-pointer' onClick={() => openOpponentCard(opponentUser)}>
                          <ProfileImageWidget profileImage={opponentUser.profileImage} userGroup={opponentUser.userGroup} className='avatar-sm rounded-circle ml-3 mr-3' />
                        </span>
                        <div className='message flex-grow-1'>
                          <div className='d-flex'>
                            <p className='text-title text-15 flex-grow-1'>
                            </p>
                            <span className='text-small text-muted'>
                              {humanReadableDate(item.time)}
                            </span>
                          </div>
                          <div>
                            {item.files && item.files.map((file, i) => (
                              <div className="ul-widget4__item">
                                <div className="ul-widget4__pic-icon">
                                  <i className="i-File text-primary"></i>
                                </div>
                                <span className='cursor-pointer text-primary' onClick={() => handlePreviewFile(file)}>{file.name}</span> 
                              </div>
                            ))}
                            {item.message && <p className='m-0 white-space-pre-line'>{item.message}</p>}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div className='pl-3 pr-3 pt-3 pb-3 box-shadow-1 chat-input-area'>
                  <div className='inputForm'>
                    <div className='form-group'>
                      <textarea
                        className='form-control form-control'
                        placeholder='Type your message'
                        name='message'
                        id='message'
                        cols='30'
                        rows='3'
                        onChange={e => setMessage(e.target.value)}
                        value={message}
                      ></textarea>
                    </div>
                    <div className='d-flex'>
                      <div className='flex-grow-1'></div>
                      <Button className='btn btn-icon mr-2' variant='primary'
                        onClick={() => {
                          if (message.trim() !== '') 
                            handleMessageSend(message);
                            setMessage('');
                          }}
                      >
                        <i className='i-Paper-Plane'></i>
                      </Button>

                      <label htmlFor='attachment' className='mb-0'>
                        <Button type='button' className='mr-2' as='span' variant='outline-primary' onClick={() => setShowUpload({show: true, title: 'Send Attachment'})}>
                          <i className='i-Add-File'></i>
                        </Button>
                      </label>
                    </div>
                  </div>
                </div>
          
                <IwtbFilePreview file={previewFile} />

                <Modal show={showUpload.show} onHide={handleHideUpload} centered={true} size='xl' >
                  <Modal.Header closeButton>
                    <Modal.Title>{showUpload.title}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <IwtbFileUpload allowedTypes={AllowedFileTypes} setUploadedFiles={files => setFieldValue('files', files)} />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant='secondary' onClick={handleHideUpload}>
                      Close
                    </Button>
                    <Button variant='warning' onClick={() => handleAttachmentSend(values, setFieldValue)}>
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>

              </>

            ) : (

              <EmptyMessage></EmptyMessage>
            )}
          </div>
        </Form>
    )}}
    </Formik>  
  );
};

export default ChatWidget;
