import React, { useReducer, useContext } from "react";
import { ErrorReducer, initialState } from './errorReducer';

const ErrorStateContext = React.createContext();
const ErrorDispatchContext = React.createContext();

export function useErrorState() {

    const context = useContext(ErrorStateContext);
    if (context === undefined) {
      throw new Error("useErrorState must be used within a ErrorProvider");
    }
    return context;
};
   
export function useErrorDispatch() {

    const context = useContext(ErrorDispatchContext);
    if (context === undefined) {
      throw new Error("useErrorDispatch must be used within a ErrorProvider");
    }
    return context;
};

export const ErrorProvider = ({ children }) => {
    
  const [errorState, dispatch] = useReducer(ErrorReducer, initialState);

  return (
    <ErrorStateContext.Provider value={errorState}>
      <ErrorDispatchContext.Provider value={dispatch}>
        {children}
      </ErrorDispatchContext.Provider>
    </ErrorStateContext.Provider>
  );
};