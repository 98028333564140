import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Breadcrumb } from '@gull';
import { ClientNavigation } from '../clientRoutes';
import sowService from './sowService';
import { constructCompanyName, indexArray, sortArray } from 'app/utils/common';
import cellEditFactory from 'react-bootstrap-table2-editor';
import swal from "sweetalert2";
import { paginationOptions, BidStatus, UserGroup } from 'app/utils/constants';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useAuthState } from 'app/auth';
import { SowWidget } from '../../contractor/widgets/SowWidget';
import { awardBid } from '../project/functions';

export const RequestSow = () => {
    
  const authState = useAuthState();
  const history = useHistory();
  let { projectId, bidId } = useParams();
  const [sow, setSow] = useState([]);
  const [project, setProject] = useState();
  const [contractor, setContractor] = useState();
  const [bid, setBid] = useState();
  const [tasks, setTasks] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [title, setTitle] = useState('SOW');
  const [details, setDetails] = useState('');
  const [loading, setLoading] = useState(false);
  const { SearchBar, ClearSearchButton } = Search;
  const [showAwardButton, setShowAwardButton] = useState(false);

  const submitSowColumns = [
    {
      dataField: 'index',
      text: '#',
      headerAlign: 'center',
      align: 'center',
      headerStyle: (colum, colIndex) => ({ width: '50px' }),
      editable: false,
    },
    {
      dataField: 'task',
      text: 'Task',
      headerAlign: 'center',
    },
  ];  

  const getSow = React.useCallback((projectId, bidId) => {

    sowService.getSow(authState.user.id, projectId, bidId)
    .then(result => {
      if (result && result.project && result.bid && result.contractor) {
        setProject(result.project);
        setBid(result.bid);
        setContractor(result.contractor);

        if (result.sow && result.sow.length > 0) {
          setTitle('Additional SOW')
          const sow = sortArray(result.sow, 'created', true);
          setSow(sow);
        }
        else {
          setTitle('Submit SOW')
        }  
      }
      else {
        NotificationManager.error(
          'Something went wrong.',
          'SOW',
          3000
        );
      }
    })
    .catch(e => {
      NotificationManager.error(
        'Something went wrong.',
        'SOW',
        3000
      );
    });
  })

  useEffect(() => {

    if (projectId && bidId) {
      getSow(projectId, bidId);
    }
  }, [projectId, bidId]);

  useEffect(() => {

    if (bid && contractor) {
      // for client/admin self bid only
      if ((bid.contractorId === authState.user.id || contractor.userGroup === UserGroup.ADMIN) && bid.status !== BidStatus.AWARDED) {
        setShowAwardButton(true)
      }
    }
  }, [bid, contractor]);

  const handleClose = () => {

    history.goBack();
  }

  const handleDetailsChange = event => {

    setDetails(event.target.value);
  }

  const handleAddTask = () => {

    const indexedArray = indexArray([...tasks, { task: 'Click here to enter task' }]);
    setTasks(indexedArray)
  }

  const handleAwardBid = async () => {
    
    try {
      const result = await sowService.submitSow(authState.user.id, projectId, bidId, { details: details, tasks: tasks })
      if (result.error) {
        return NotificationManager.error('Something went wrong.', 'Submit SOW', 6000);
      }
      await awardBid(authState.user.id, projectId, bidId)
      NotificationManager.success('Trade was awarded successfully.', 'Trade Award', 2000);  
      handleClose()
    }
    catch {
      NotificationManager.error('Sorry, something went wrong.', 'Trade Award', 3000);
    }
  }

  const handleSubmit = () => {

    swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, submit SOW',
        cancelButtonText: 'No'
    })
    .then(result => {
      if (result.value) {    
        return sowService.submitSow(authState.user.id, projectId, bidId, { details: details, tasks: tasks })
        .then(result => {
          setLoading(false);
          NotificationManager.success('SOW was submitted successfully.', 'Submit SOW', 2000);
          // refresh();
          handleClose()
        })
        .catch(ex => {
          setLoading(false);
          NotificationManager.error('Something went wrong.', 'Submit SOW', 6000);
        });
      }
    });  
  }

  const handleDelete = () => {

    swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete task(s)',
      cancelButtonText: 'No'
    })
    .then(result => {
        if (result.value) {    
          const filteredTasks = tasks.filter(task => {
            const found = selectedTasks.find(selected => task.index === selected);
            return found && found > 0 ? false : true;
          });
          const indexedArray = indexArray(filteredTasks);
          setTasks(indexedArray);
          setSelectedTasks([]);
        }
    });  
  }

  const selectRow = { 
    mode: 'checkbox', 
    hideSelectAll: true,
    clickToEdit: true,
    selectColumnStyle: { 'text-align': 'center' },
    selected: selectedTasks,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedTasks([...selectedTasks, row.index]);
      }
      else {
        // remove unselected items
        const selectedItems = selectedTasks.filter(selected => selected !== row.index);
        setSelectedTasks(selectedItems);
      }
    }
  }

  const cellEdit = {
    mode: 'click', 
    autoSelectText: true, 
    blurToSave: true,
  };
  
  return (
    <>
      <Breadcrumb
        routeSegments={[
          { name: "My Projects", path: ClientNavigation.PROJECTS },
          { name: `${title}`}
        ]}
      ></Breadcrumb>

      <div className='row'>
        <div className='col-md-10'>

          <div className="card">

              <div className="card-header bg-transparent">
                  <div className='d-flex flex-row justify-content-between'>
                    {project && <h5>Project: <strong>{project.name}</strong></h5>}
                    {bid && <h5>Trade: <strong>{bid.trade.text}</strong></h5>}
                    {contractor && <h5>Contractor: <strong>{constructCompanyName(contractor)}</strong></h5>}
                  </div>
              </div>

              <div className="card-body">

                <div className='row mt-1'>
                  <div className='col-md-12'>
                    <label htmlFor='details'><h5>Details</h5></label>
                    <textarea name='details' value={details} placeholder='Enter short description here' className='form-control' onChange={handleDetailsChange} />
                  </div>
                </div>
                
                <div className='row mt-4'>
                  <div className='col-md-12'>

                    {tasks.length > 0 && <>
                      <label htmlFor='details'><h5>Tasks</h5></label>
                      <ToolkitProvider
                        keyField='index'
                        data={tasks}
                        columns={submitSowColumns}
                        search
                        exportCSV
                      >
                        {props => (
                          <>
                            <p><b>Note: </b> Click on the task cell to edit content.</p>
                            <div className='d-flex justify-content-end align-items-center'>
                              <span className='mb-2 mr-1'>Search:</span>
                              <SearchBar {...props.searchProps} className='mb-0 ' />
                              <ClearSearchButton { ...props.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
                              <button className='btn btn-outline-success mb-2 mr-1' onClick={_ => props.csvProps.onExport()}>Export CSV</button>
                            </div>
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4
                              wrapperClasses="table-responsive"
                              pagination={paginationFactory({ ...paginationOptions, totalSize: tasks.length })}
                              noDataIndication='No records found'
                              condensed
                              selectRow={ selectRow }
                              cellEdit={ cellEditFactory(cellEdit) }
                            />
                          </>
                        )}
                      </ToolkitProvider>
                    </>}
                    
                    {tasks.length === 0 && <>
                      <Alert className="text-center alert-card" variant="warning">Start adding SOW's tasks by clicking button below.</Alert>
                    </>}

                  </div>
                </div>

              </div>
  
              <div className="card-footer ">

                <div className="mc-footer">
                  <div className="row">
                    <div className="col-md-12">
                    {showAwardButton && <button type="button" className="btn btn-primary m-1" onClick={handleAwardBid} disabled={tasks.length === 0}>Award Trade</button>}
                    {!showAwardButton && <button type="button" className="btn btn-primary m-1" onClick={handleSubmit} disabled={tasks.length === 0}>Submit</button>}
                    <button type="button" className="btn btn-success m-1" onClick={handleAddTask}>Add Task</button>
                    <button type="button" className="btn btn-warning m-1 footer-delete-right" onClick={handleClose}>Cancel & Return</button>
                    <button type="button" className="btn btn-danger m-1 footer-delete-right" onClick={handleDelete} disabled={selectedTasks.length === 0}>Delete</button>
                  </div>
                </div>

              </div>
            </div>
          
          </div>
        </div>
      </div>
      
      {sow && sow.length > 0 && <>

        {sow.map((item, index) => (

          <div className='row mt-4' key={index}>
            <div className='col-md-10'>
              <SowWidget sow={item} key={`sow_${index}`} />
            </div>
          </div>
        ))}
      </>}

      <NotificationContainer />
    </>
  );
}
