import React, { useState, createContext, useContext, useEffect } from 'react';
import configurationService from './configurationService';
import config from 'app/utils/config';

const ConfigurationContext = createContext({
  tradeCategories: [],
  specialistCategories: [],
  clientCategories: [],
  projectBudgets: [],
  projectSectors: [],
  ...config,
})

export const useConfiguration = () => {

  const context = useContext(ConfigurationContext);

  if (context === undefined) {
    throw new Error("useConfiguration must be used within a ConfigurationProvider");
  }
  return context;
};

export const ConfigurationProvider = ({ children }) => {
 
  useEffect(() => {
    
    configurationService.getClientCategories()
    .then(results => {
      update(prev => ({ ...prev, clientCategories: results }))
    })
    
    configurationService.getProjectBudgets()
    .then(results => {
      update(prev => ({ ...prev, projectBudgets: results }))
    })
    
    configurationService.getProjectSectors()
    .then(results => {
      update(prev => ({ ...prev, projectSectors: results }))
    })
    
    configurationService.getSpecialistCategories()
    .then(results => {
      update(prev => ({ ...prev, specialistCategories: results }))
    })
    
    configurationService.getTradeCategories()
    .then(results => {
      update(prev => ({ ...prev, tradeCategories: results }))
    })

  }, [])

  const [state, update] = useState({
    tradeCategories: [],
    specialistCategories: [],
    clientCategories: [],
    projectBudgets: [],
    projectSectors: [],
    ...config,
  })

  return (
    <ConfigurationContext.Provider value={state}>
      {children}
    </ConfigurationContext.Provider>
  )
}
