import HttpService from 'app/services/httpService'

class bidService {

    http = new HttpService()

    awardBid = (adminId, projectId, bidId) => {
        return this.http.post(`admins/${adminId}/projects/${projectId}/bids/${bidId}`);
    }

    submitBid = (adminId, projectId, bids) => {
        return this.http.post(`admins/${adminId}/projects/${projectId}/bids`, { bids: bids })
    }

    getBid = (clientId, projectId, bidId) => {
        return this.http.get(`admins/${clientId}/projects/${projectId}/bids/${bidId}`)
    }

    confirmSow = (adminId, projectId, bidId, sow) => {
        return this.http.post(`admins/${adminId}/projects/${projectId}/bids/${bidId}/sows/${sow.id}`, { sow: sow });
    };

    getTrades = (adminId, projectId) => {
        return this.http.get(`admins/${adminId}/projects/${projectId}/trades`);
    };
}

export default new bidService()