import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, useParams } from "react-router-dom";
import { Breadcrumb } from '@gull';
import { ContractorNavigation } from '../contractorRoutes';
import { RfiWidget } from '.';
import rfiService from './rfiService';
import { useAuthState } from 'app/auth';
import { NotificationContainer, NotificationManager } from 'react-notifications';

export const ViewRfi = (props) => {
  
  const authState = useAuthState();
  const history = useHistory();
  const route = useLocation();
  const [rfi, setRfi] = useState();
  const { projectId, requestId } = useParams();
  const [loading, setLoading] = useState(false);
  
  const getRfi = React.useCallback((contractorId, projectId, requestId) => {
      
    rfiService.getRfi(contractorId, projectId, requestId)
    .then(result => {
      if (result.error) {
        NotificationManager.error(
          result.message,
          'Server Connection',
          3000
        );      
      }
      else {
        setLoading(false);
        setRfi(result); 
      }
    })
    .catch(e => {
      NotificationManager.error(
        'Something went wrong.',
        'Server Connection',
        3000
      );      
    });
  })

  useEffect(() => {

    if (authState.user && projectId && requestId) {
      getRfi(authState.user.id, projectId, requestId);
    }

  }, [projectId, requestId, authState.user]);

  return (
    <>
      <Breadcrumb
        routeSegments={[
          { name: "My Projects", path: ContractorNavigation.CONTRACTOR_PROJECTS },
          { name: 'RFI' }
        ]}
      ></Breadcrumb>

      {rfi && <RfiWidget rfi={rfi} />} 

    </>
  );
}
