 
import service from './notificationService';

export async function getUserNewNotifications(dispatch, userId) {
    
    try {
        dispatch({ type: 'FETCHING_NOTIFICATIONS' });
        let response = await service.getUserNewNotifications(userId);
    
        if (response.error) {
            dispatch({ type: 'FETCHING_NOTIFICATIONS_ERROR', error: response.message });
        }
        else {
            dispatch({ type: 'FETCHING_NEW_NOTIFICATIONS_SUCCESS', payload: response });
        }
    } 
    catch (error) {
        dispatch({ type: 'FETCHING_NOTIFICATIONS_ERROR', error: error });
    }
}
