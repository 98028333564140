import React from 'react';
import { audFormatter } from 'app/utils/constants';

export const BidDetailsWidget = ({ bid }) => {

  return (
    <>
      {bid && <>
        <div className="row">
          <div className="col-md-12">

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="firstName">Project</label>
                <div className="form-control">{bid.projectName}</div>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="firstName">Trade</label>
                <div className="form-control">{bid.trade.text}</div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-12">
                <label htmlFor="firstName">Subject</label>
                <div className="form-control">{bid.subject}</div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-12">
                <label htmlFor="firstName">Proposal</label>
                <textarea rows={6} readOnly className="form-control" value={bid.proposal}></textarea>          
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="firstName">Value</label>
                <div className="form-control">{audFormatter.format(bid.amount ?? 0)}</div>
              </div>
              {bid.declineReason && <div className="form-group col-md-6">
                <label htmlFor="firstName">Decline Reason</label>
                <div className="form-control">{bid.declineReason}</div>
              </div>}
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="ref">Ref</label>
                <div className="form-control">{bid.ref}</div>
              </div>
            </div>

          </div>
        </div>
      </>}
    </>
  );
}

