import React, { useEffect, useState } from "react";
import { Breadcrumb } from '@gull';
import { Tabs, Tab, Badge, Button, Alert } from "react-bootstrap";
import { ContractorNavigation } from '../contractorRoutes';
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useParams, useHistory } from "react-router-dom";
import { ProjectTab } from '../constants';
import { ProjectDetailsWidget, TradesWidget } from '../widgets';
import contractorService from '../contractorService';
import { ProjectStatus, StatusClass, audFormatter, BidTab, BidAction } from 'app/utils/constants';
import { RfiRegisterWidget } from '../rfi';
import { BidRegisterWidget } from '../bid';
import { useAuthState } from 'app/auth';
import { getRequestCount, getProjectAwards, withdrawBid, acceptAward, declineAward } from '../functions';
import { IwtbFileRegistry, IwtbModal } from 'app/widgets';
import profileService from 'app/profile/profileService';
import { ProfileCardWidget } from 'app/profile';
import { customTabHeader, handleOpenChat } from 'app/utils/common';
import { RefreshType, refreshOnNotification } from 'app/notification'
import { useSocket } from 'app/socket.io';

export const ProjectCard = () => {

  let { projectId, tab } = useParams();
  const [client, setClient] = useState();
  const [project, setProject] = useState();
  const [defaultActiveKey, setDefaultActiveKey] = useState(ProjectTab.DETAILS.toLowerCase());
  const [projectTotal, setProjectTotal] = useState(0);
  const [awardRegister, setAwardRegister] = useState([]);
  const [bidRegister, setBidRegister] = useState([]);
  const [trades, setTrades] = useState([]);
  const [requestCount, setRequestCount] = useState(0);
  const authState = useAuthState();
  const [notFound, setNotFound] = useState(false);
  const [pending, setPending] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const history = useHistory();
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState('');
  const socket = useSocket()

  const getContractorProject = React.useCallback(() => {

    if (!(projectId && authState.isAuthenticated)) {
      return
    }

    contractorService.getContractorProject(authState.user.id, projectId)
    .then(project => {

      if (project && project.error) {
        return NotificationManager.error(project.message, 'Server Connection', 3000);      
      }

      if (project && project.id) {
        setProject(project);
        setPending(project.status === ProjectStatus.PENDING);
        setReadOnly(project.status === ProjectStatus.DECLINED || project.status === ProjectStatus.CANCELED);

        if (project.clientId && !client) {
          // load client profile
          profileService.getClientProfile(authState.user.id, project.clientId)
          .then(client => {
            if (client && client.id) {
              setClient(client);
            }
            else {
              NotificationManager.error('Something went wrong.', 'Server Connection', 3000);
            }
          })
          .catch(e => { NotificationManager.error('Something went wrong.', 'Server Connection', 3000) });
        }

        if (project.trades) {
          setTrades(project.trades);
        }
        getBidRegister(authState.user.id, projectId);
      }
      else {
        setTimeout(() => setNotFound(true), 2000);
      }
    })
    .catch(e => { NotificationManager.error('Something went wrong.', 'Server Connection', 3000) });
  });

  useEffect(() => {

    const onUserNotification$ = socket.onUserNotification().subscribe(notification => {

      refreshOnNotification(notification.type, RefreshType.CONTRACTOR_PROJECT_CARD, getContractorProject)
    })
    return() => {
        onUserNotification$.unsubscribe()
    }
  }, []);

  useEffect(() => {

    const projectTab = tab === 'undefined' || tab === undefined ? ProjectTab.DETAILS.toLowerCase() : tab.toLowerCase();
    setDefaultActiveKey(projectTab);
  }, [tab]);

  useEffect(() => {

    getContractorProject();
  }, [projectId, authState.user]);

  const getBidRegister = (contractorId, projectId) => {

    contractorService.getBidRegister(contractorId, projectId)
    .then(bidRegister => {
      if (bidRegister.error) {
        NotificationManager.error(bidRegister.message, 'Server Connection', 3000);      
      }
      else {
        setBidRegister(bidRegister);
        const {total, awards } = getProjectAwards(bidRegister);
        setProjectTotal(total);
        setAwardRegister(awards);
        setRequestCount(getRequestCount(bidRegister));
      }
    })
    .catch(e => {
      NotificationManager.error('Something went wrong.', 'Server Connection', 3000);      
    });
  }

  const navigateTo = (pathname) => {
    
    pathname = pathname.replace(':projectId', project.id);
    history.push({ pathname: pathname , state: { project: project }});  
  }

  const handleBidAction = (bid, action) => {

    switch(action) {

      case BidAction.VIEW_COMMENTS:
        setComments(bid.comments)
        setShowComments(true)
        return 

      case BidAction.VIEW_SOW:
        navigateTo(ContractorNavigation.BID_CARD.replace(':bidId', bid.id).replace(':tab?', BidTab.SOW));
        break;

      case BidAction.VIEW_BID:
        navigateTo(ContractorNavigation.BID_CARD.replace(':bidId', bid.id).replace(':tab?', BidTab.DETAILS));
        break;

      case BidAction.WITHDRAW:
        handleWithdrawBid(projectId, bid.id);
        break;

      case BidAction.ACCEPT_AWARD:
        handleAcceptAward(projectId, bid.id);
        break;
    
      case BidAction.DECLINE_AWARD:
        handleDeclineAward(projectId, bid.id);
        break;
      }
  }

  const handleDeclineAward = async (projectId, bidId) => {
    
    try {
      await declineAward(authState.user.id, projectId, bidId);
      getBidRegister(authState.user.id, projectId);
    }
    catch {
      NotificationManager.error('Sorry, something went wrong.', 'Decline Award', 3000);
    }
  }

  const handleAcceptAward = async (projectId, bidId) => {
    
    try {
      await acceptAward(authState.user.id, projectId, bidId);
      getBidRegister(authState.user.id, projectId);
    }
    catch(e) {
      NotificationManager.error('Sorry, something went wrong.', 'Accept Award', 3000);
    }
  }

  const handleWithdrawBid = async (projectId, bidId) => {

    try {
      await withdrawBid(authState.user.id, projectId, bidId);
      getBidRegister(authState.user.id, projectId);
    }
    catch {
      NotificationManager.error('Sorry, something went wrong.', 'Withdraw Bid', 3000);
    }
  }

  return (
    <div>
      { project && <Breadcrumb
        routeSegments={[
          { name: "My Projects", path: ContractorNavigation.CONTRACTOR_PROJECTS },
          { name: project.projectName }
        ]}
      ></Breadcrumb>}

      { project && <>
      
        <section className="ul-contact-detail">
          <div className="row">
            <div className="col-lg-3 col-xl-3">              

              <div className="card o-hidden">
              <div className="card-body">
                  <div className="ul-widget1">
                    <div className="ul-widget__item">
                      <div className="ul-widget__info">
                        <h3 className="ul-widget1__title">My Budget</h3>
                        <span className="ul-widget__desc text-mute">Awarded bids</span>
                      </div>
                      <span className="ul-widget__number text-primary">{audFormatter.format(projectTotal)}</span>
                    </div>
                    <div className="ul-widget__item">
                      <div className="ul-widget__info">
                        <h3 className="ul-widget1__title">RFI</h3>
                        <span className="ul-widget__desc text-mute">Request for information</span>
                      </div>
                      <span className="ul-widget__number text-warning">{project.rfiRegister ? project.rfiRegister.length : 0}</span>
                    </div>
                    <div className="ul-widget__item">
                      <div className="ul-widget__info">
                        <h3 className="ul-widget1__title">Bids</h3>
                        <span className="ul-widget__desc text-mute">Registered bids</span>
                      </div>
                      <span className="ul-widget__number text-success">{bidRegister ? bidRegister.length : 0}</span>
                    </div>
                    <div className="ul-widget__item">
                      <div className="ul-widget__info">
                        <h3 className="ul-widget1__title">Awards</h3>
                        <span className="ul-widget__desc text-mute">Awarded trades</span>
                      </div>
                      <span className="ul-widget__number text-primary">{awardRegister ? awardRegister.length : 0}</span>
                    </div>
                    <div className="ul-widget__item">
                      <div className="ul-widget__info">
                        <h3 className="ul-widget1__title">Notices</h3>
                        <span className="ul-widget__desc text-mute"> </span>
                      </div>
                      <span className="ul-widget__number text-danger">{requestCount}</span>
                    </div>
                  </div>
                </div>
              </div>
              
              {client && <div className='mt-3'>
                <ProfileCardWidget user={client} handleOpenChat={() => handleOpenChat(authState, history, client.id)} handleViewProfile={() => navigateTo(ContractorNavigation.CLIENT_CARD.replace(':clientId', client.id))} />
              </div>}

            </div>
            <div className="col-lg-9 col-xl-9">
              <div className="card mb-4">
                <div className="card-header bg-transparent">
                    <div className='d-flex flex-row justify-content-between'>
                      <span>Status: <Badge className={StatusClass[project.status.toUpperCase()]}>{project.status}</Badge></span>
                      {project.revision && <span>Revision: {project.revision}</span>}
                    </div>
                </div>
                <div className="card-body">

                  <Tabs defaultActiveKey={defaultActiveKey}>

                    <Tab eventKey={ProjectTab.DETAILS.toLowerCase()} title={ProjectTab.DETAILS}>
                      <ProjectDetailsWidget project={project} />
                    </Tab>
                    
                    <Tab eventKey={ProjectTab.BIDS.toLowerCase()} title={customTabHeader(ProjectTab.BIDS, "")}>
                      <BidRegisterWidget bidRegister={bidRegister} disableNewBid={pending || readOnly} readOnly={readOnly} handleBidAction={handleBidAction} handleSubmitBid={() => navigateTo(ContractorNavigation.SUBMIT_BID)} />
                    </Tab>
                    
                    <Tab eventKey={ProjectTab.RFI.toLowerCase()} title={ProjectTab.RFI}>
                      <RfiRegisterWidget projectId={project.id} disableNewRfi={pending || readOnly} readOnly={readOnly} onSubmitRfi={() => navigateTo(ContractorNavigation.SUBMIT_RFI)} />                  
                    </Tab>
                    
                    <Tab eventKey={ProjectTab.TRADES.toLowerCase()} title={ProjectTab.TRADES}>
                      <TradesWidget trades={trades}  title='Trade' />
                    </Tab>
                    
                    <Tab eventKey={ProjectTab.DOCUMENTS.toLowerCase()} title={ProjectTab.DOCUMENTS}>
                      <IwtbFileRegistry files={project.files} showSearch={true} />
                    </Tab>

                  </Tabs>
                </div>

                <div className="card-footer">
                  <div className="mc-footer">
                    <div className="row">
                      <div className="col-md-12">
                        {!(project.status === ProjectStatus.DECLINED || project.status === ProjectStatus.CANCELED) && <>
                          <Button variant="primary" className="btn-icon m-1 text-capitalize" onClick={() => handleOpenChat(authState, history, client?.id)}>
                            <span className="ul-btn__icon">
                              <i className="i-Speach-Bubbles"></i>
                            </span>
                            <span className="ul-btn__text"> Contact Client</span>
                          </Button>
                        </>}
                        <button type="button" className="btn btn-warning m-1 footer-delete-right" onClick={_ => {history.goBack();}}>Close</button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
    
      </>}
      
      { !project && notFound && <>
        <Alert className="text-center alert-card" variant="danger">
          Something went wrong. Project was not found.
          <Button variant="warning" className="btn ml-3" onClick={_ => {history.goBack();}}>Return</Button>
        </Alert>
      </>}

      <NotificationContainer />
      <IwtbModal show={showComments} title='Comments' message={comments} handleClose={() => setShowComments(false)} />
  </div>
  );  
}
