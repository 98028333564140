import React from "react";
import { Redirect } from 'react-router-dom';
import { AccessRegister, TenderingProjects, ContractorProjects, ProjectCard } from './project';
import { Awards } from './award';
import { PageNotFound } from 'app/error'
import { AuthRole, AuthGuard } from 'app/auth';
import legalRoutes from 'app/pages/legal/legalRoutes';
import { SubmitBid, BidCard } from "./bid";
import { SubmitRfi, ViewRfi } from "./rfi";
import { MyProfile } from "app/profile";
import { ClientCard } from "app/profile";
import { Chat } from 'app/chat';
import { ContactUs } from 'app/pages'

export let ContractorNavigation = {
    CONTRACTOR_PROJECTS:   '/contractors/:id/projects',
    AWARDED_BIDS:           '/contractors/:id/awards',
    TENDERING_PROJECTS:     '/contractors/:id/projects/tenders',
    ACCESS_REGISTER:        '/contractors/:id/projects/access',
    PROJECT_CARD:           '/contractors/:id/projects/:projectId/tabs/:tab?',
    SUBMIT_RFI:             '/contractors/:id/projects/:projectId/requests',
    SUBMIT_BID:             '/contractors/:id/projects/:projectId/bids',
    VIEW_RFI:               '/contractors/:id/projects/:projectId/requests/:requestId',
    BID_CARD:               '/contractors/:id/projects/:projectId/bids/:bidId/tabs/:tab?',
    CLIENT_CARD:            '/contractors/:id/clients/:clientId',
    COMMUNICATION:          '/contractors/:id/chats',
    CHAT:                   '/contractors/:id/chats/:opponentId?',
    USER_PROFILE:           '/contractors/:id/profile/:tab?',
    CONTACT_US:             '/contact-us',
}

const routes = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to={ ContractorNavigation.USER_PROFILE } />
    },
    {
        path: ContractorNavigation.CONTACT_US,
        component: ContactUs,
    },
    {
        path: ContractorNavigation.CONTRACTOR_PROJECTS,
        component: ContractorProjects,
        auth: AuthRole.CONTRACTOR,
        exact: true,
    },
    {
        path: ContractorNavigation.AWARDED_BIDS,
        component: Awards,
        auth: AuthRole.CONTRACTOR,
        exact: true,
    },
    {
        path: ContractorNavigation.TENDERING_PROJECTS,
        component: TenderingProjects,
        auth: AuthRole.CONTRACTOR,
        exact: true,
    },
    {
        path: ContractorNavigation.ACCESS_REGISTER,
        component: AccessRegister,
        auth: AuthRole.CONTRACTOR,
        exact: true,
    },
   {
        path: ContractorNavigation.PROJECT_CARD,
        component: ProjectCard,
        auth: AuthRole.CONTRACTOR,
        exact: true,
    },
    {
        path: ContractorNavigation.SUBMIT_BID,
        component: SubmitBid,
        auth: AuthRole.CONTRACTOR,
        exact: true,
    },
    {
        path: ContractorNavigation.SUBMIT_RFI,
        component: SubmitRfi,
        auth: AuthRole.CONTRACTOR,
        exact: true,
    },
    {
        path: ContractorNavigation.VIEW_RFI,
        component: ViewRfi,
        auth: AuthRole.CONTRACTOR,
        exact: true,
    },
    {
        path: ContractorNavigation.BID_CARD,
        component: BidCard,
        auth: AuthRole.CONTRACTOR,
        exact: true,
    },

    {
        path: ContractorNavigation.CLIENT_CARD,
        component: ClientCard,
        auth: AuthRole.CONTRACTOR,
        exact: true,
    },
    {
        path: ContractorNavigation.COMMUNICATION,
        component: Chat,
        auth: AuthRole.CONTRACTOR,
        exact: true,
    },
    {
        path: ContractorNavigation.CHAT,
        component: Chat,
        auth: AuthRole.CONTRACTOR,
        exact: true,
    },
    {
        path: ContractorNavigation.USER_PROFILE,
        component: MyProfile,
        auth: AuthRole.CONTRACTOR,
        exact: true,
    },
    {
        path:'/*',
        component: PageNotFound
    },
];
  
export const contractorRoutes = [{
    path: '/',
    component: AuthGuard,
    routes: [
    ...legalRoutes,
    ...routes,
    ]
}];
