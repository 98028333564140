import HttpService from 'app/services/httpService';

class MapsService {

    http = new HttpService();

    getAppKey = () => {
        return this.http.get('maps/key');
    }

    getPlaceAutocomplete = (place) => {
        const url = `maps/find?place=${place}`;
        return this.http.get(url);
    }

    getPlaceDetails = (placeId) => {
        const url = `maps/places/${placeId}`;
        return this.http.get(url);
    }
}

export default new MapsService()