import React, { useEffect, useState, useCallback } from "react";
import { Breadcrumb } from '@gull';
import { ClientNavigation } from '../clientRoutes';
import { ProjectWidget } from 'app/widgets';
import { NotificationContainer, NotificationManager } from "react-notifications";
import projectService from './projectService';
import { useAuthState } from 'app/auth';
import { useParams, useHistory } from "react-router-dom";
import { formatDateTime24 } from 'app/utils/time'
import { ProjectStatus } from "app/utils/constants";

export const EditProject = () => {
  
  const authState = useAuthState();
  const history = useHistory()
  const { projectId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [project, setProject] = useState();

  const getClientProject = useCallback((clientId, projectId) => {

    projectService.getClientProject(clientId, projectId)
    .then(project => {
      if (project.error) {
        NotificationManager.error(project.message, 'Server Connection', 3000);      
      }
      else {
        if (project.inspectionRequiredOn?.length > 0) {
          project.inspectionRequiredOn = formatDateTime24(project.inspectionRequiredOn)
        }
        if (project.tenderRequiredBy?.length > 0) {
          project.tenderRequiredBy = formatDateTime24(project.tenderRequiredBy)
        }
        setProject(project);
      }
    })
    .catch(e => {
      NotificationManager.error('Something went wrong.', 'Server Connection', 3000);      
    })
  })

  useEffect(() => {

    if (projectId && authState.isAuthenticated) {
      getClientProject(authState.user.id, projectId)
    }
  }, [projectId, authState.user]);
  
  const handleDraft = project => {

    setIsSubmitting(true);
    
    projectService.updateDraft(authState.user.id, project)
    .then(draft => {
      NotificationManager.success("Draft has been saved.", "Project Draft", 2000);
    })
    .catch(ex => {
      NotificationManager.error("Something went wrong.", "Project Draft", 6000);
    })
    .finally(() => {
      setIsSubmitting(false);
    })
  }
  
  const handleSubmit = project => {

    setIsSubmitting(true);
    
    projectService.updateProject(authState.user.id, project)
    .then(() => {
      NotificationManager.success("Project has been updated.", "Update Project", 2000);
      setTimeout(_ => history.push(ClientNavigation.PROJECTS), 2000);
    })
    .catch(ex => {
      setIsSubmitting(false);
      NotificationManager.error("Something went wrong.", "Update Project", 6000);
    });
  };

  return (<>
    <Breadcrumb
      routeSegments={[
        { name: "Projects", path: '' },
        { name: `${project?.status === ProjectStatus.DRAFT ? 'Project Draft' : 'Revise Project'}` }
      ]}
    ></Breadcrumb>

    {project && <>
      {project.status === ProjectStatus.DRAFT && <ProjectWidget project={project} handleDraft={handleDraft} handleSubmit={handleSubmit} handleCancel={() => history.goBack()} isSubmitting={isSubmitting} />}
      {project.status !== ProjectStatus.DRAFT && <ProjectWidget project={project} handleSubmit={handleSubmit} handleCancel={() => history.goBack()} isSubmitting={isSubmitting} />}
    </>}

    <NotificationContainer />
  </>
  );
}
