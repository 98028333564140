export const SystemDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
export const DateFormat = 'DD/MM/YYYY HH:mm';

export const ImageFileTypes = '.png, .jpg, .jpeg';
export const AllowedFileTypes = '.png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx';
  
export const audFormatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 2
})

export const ContractorSubscriptionKey = {
    TRIAL: '0',
    THREE_MONTH: '3',
    SIX_MONTH: '6',
    TWELVE_MONTH: '12',
}
  
  export const ClientSubscriptionKey = {
    TRIAL: '0',
    THREE_MONTH: '3',
    SIX_MONTH: '6',
    TWELVE_MONTH: '12',
}
  
export const SubscriptionStatus = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    EXPIRED: 'expired',
}

export const AccessAction = {
    APPROVE: 'approve',
    DEDCLINE: 'decline',
    VIEW_COMMENTS: 'view-comments',
}

export const TradeAction = {
    VIEW_DELETE_REASON: 'view-delete-reason',
}

export const BidAction = {
    AWARD: 'award',
    DECLINE: 'decline',
    CONFIRM_SOW: 'confirm-sow',
    REQUEST_SOW: 'request-sow',
    VIEW_SOW: 'view-sow',
    VIEW_BID: 'view-bid',
    VIEW_COMMENTS: 'view-comments',
    VIEW_DELETE_REASON: 'view-delete-reason',
    BID_CARD: 'bid-card',
    ADD_CONTACT: 'add-contact',
    WITHDRAW: 'withdraw',
    ACCEPT_AWARD: 'accept-award',
    DECLINE_AWARD: 'decline-award',
    CONTACT_CLIENT: 'contact-client',
}

export const AllocationAction = {
    CONTACT_SUPPORT: 'contact-support',
    CONTACT_CLIENT: 'contact-client',
    VIEW_PROJECT: 'view-project',
    VIEW_COMMENTS: 'view-comments',
    INVOICE: 'invoice',
    TIMESHEET: 'timesheet',
    DELETE_SERVICE: 'delete-service',
}

export const TradeStatus = {
    ACTIVE: 'active',
    ADDING: 'adding',
    REMOVING: 'removing',
    DELETED: 'deleted'
}
      
export const ServiceStatus = {
    ACTIVE: 'active',
    ADDING: 'adding',
    REMOVING: 'removing',
    DELETED: 'deleted'
}
  
export const ProfileCardTab = {
    ABOUT: 'About',
    COMPANY: 'Company',
    PROJECTS: 'Projects',
    SUBSCRIPTION: 'Subscription',
    PHOTOS: 'Photos',
    DOCUMENTS: 'Documents',
    REVIEWS: 'Reviews',
}

export const SowStatus = {
    PENDING: 'pending',
    CONFIRMED: 'confirmed',
    AMENDED: 'amended',
    EXCLUDED: 'excluded',
    REJECTED: 'rejected',
}

export const BidStatus = {
    PENDING: 'pending',               // contractor submitted bid
    AWARDED: 'awarded',               // client awarded bid
    DECLINED: 'declined',             // client declined bid
    WITHDRAWN: 'withdrawn',           // contractor withdraw bid
    ACCEPTED: 'accepted',             // contractor accepted award
    UNACCEPTED: 'unaccepted',         // contractor unaccepted award
    DELETED: 'deleted',               // client deleted trade
}
    
export const RfiStatus = {
    PENDING: 'pending',
    RESPONDED: 'responded',
}

export const AccessStatus = {
    PENDING: 'pending',
    APPROVED: 'approved',
    DECLINED: 'declined',
    NOT_INTERESTED: 'not-interested',
}

export const UserStatus = {
    UNVERIFIED: 'unverified',
    ACTIVE: 'active',
    PENDING: 'pending',
    SUSPENDED: 'suspended',
}

export const paginationOptions = {
    sizePerPage: 15,
    pageStartIndex: 1,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
};

export const StatusClass = {
    UNVERIFIED: 'badge badge-light w-badge m-1 text-capitalize',
    ACTIVE: 'badge badge-success w-badge m-1 text-capitalize',
    DRAFT: 'badge badge-info w-badge m-1 text-capitalize',
    INACTIVE: 'badge badge-warning w-badge m-1 text-capitalize',
    AMENDED: 'badge badge-warning w-badge m-1 text-capitalize',
    ACCEPTED: 'badge badge-primary w-badge m-1 text-capitalize',
    UNACCEPTED: 'badge badge-danger w-badge m-1 text-capitalize',
    AWARDED: 'badge badge-success w-badge m-1 text-capitalize',
    APPROVED: 'badge badge-success w-badge m-1 text-capitalize',
    CONFIRMED: 'badge badge-success w-badge m-1 text-capitalize',
    RESPONDED: 'badge badge-success w-badge m-1 text-capitalize',
    PENDING: 'badge badge-warning w-badge m-1 text-capitalize',
    REVISE: 'badge badge-google w-badge m-1 text-capitalize',
    EXCLUDED: 'badge badge-google w-badge m-1 text-capitalize',
    REVISED: 'badge badge-warning w-badge m-1 text-capitalize',
    DECLINED: 'badge badge-google w-badge m-1 text-capitalize',
    TENDERING: 'badge badge-success w-badge m-1 text-capitalize',
    LOCKED: 'badge badge-light w-badge m-1 text-capitalize',
    ALLOCATING: 'badge badge-dribble w-badge m-1 text-capitalize',
    CONSTRUCTION: 'badge badge-primary w-badge m-1 text-capitalize',
    COMPLETED: 'badge badge-success w-badge m-1 text-capitalize',
    CANCELED: 'badge badge-light w-badge m-1 text-capitalize',
    DELETED: 'badge badge-light w-badge m-1 text-capitalize',
    WITHDRAWN: 'badge badge-light w-badge m-1 text-capitalize',
    EXPIRED: 'badge badge-danger w-badge m-1 text-capitalize',
    REJECTED: 'badge badge-light w-badge m-1 text-capitalize',
    SUSPENDED: 'badge badge-danger w-badge m-1 text-capitalize',
    REMOVING: 'badge badge-danger w-badge m-1 text-capitalize',
    ADDING: 'badge badge-warning w-badge m-1 text-capitalize',
    'TRADE-DELETED': 'badge badge-light w-badge m-1 text-capitalize',
}

export const FileCategoryClass = {
    'Annexure': 'badge badge-warning w-badge m-1 text-capitalize',
    'Structual Drawings': 'badge badge-primary w-badge m-1 text-capitalize',
    'Structual Drawing': 'badge badge-primary w-badge m-1 text-capitalize',
    'Invitation to Tender': 'badge badge-warning w-badge m-1 text-capitalize',
    'Architectual Drawings': 'badge badge-dark w-badge m-1 text-capitalize',
    'Architectual Drawing': 'badge badge-dark w-badge m-1 text-capitalize',
    'Service Drawings': 'badge badge-success w-badge m-1 text-capitalize',
    'Service Drawing': 'badge badge-success w-badge m-1 text-capitalize',
    'Specifications': 'badge badge-info w-badge m-1 text-capitalize',
    'Specification': 'badge badge-info w-badge m-1 text-capitalize',
    'Reports': 'badge badge-dribble w-badge m-1 text-capitalize',
    'Report': 'badge badge-dribble w-badge m-1 text-capitalize',
    'Permits': 'badge badge-twitter w-badge m-1 text-capitalize',
    'Permit': 'badge badge-twitter w-badge m-1 text-capitalize',
    'Quote': 'badge badge-twitter w-badge m-1 text-capitalize',
    'Chat': 'badge badge-twitter w-badge m-1 text-capitalize',
    'Other': 'badge badge-light w-badge m-1 text-capitalize',
}

export const FileBidCategories = [
    'Cover Letter',
    'Quote',
    'Other',
]

export const FileCategory = {
    ANNEXURE: 'Annexure',
    ARCHITECTUAL_DRAWING: 'Architectual Drawing',
    CHAT: 'Chat',
    COVER_LETTER: 'Cover Letter',
    PRICING: 'Pricing Schedule',
    QUOTE: 'Quote',
    SERVICE_DRAWING: 'Service Drawing',
    SPECIFICATION: 'Specification',
    STRUCTUAL_DRAWING: 'Structual Drawing',
    REPORT: 'Report',
    PERMIT: 'Permit',
    PREVIOUS_WORK: 'Previous Works',
    TENDER_INVITATION: 'Invitation to Tender',
    LICENSE: 'Licenses',
    INSURANCE: 'Insurances',
    CAPABILITY_STATEMENT: 'Capability Statement',
    ID: 'Drivers License or ID',
    SOCIAL: 'Social',
    OTHER: 'Other'
}
  
export const FileCategories = [
    FileCategory.ANNEXURE,
    FileCategory.ARCHITECTUAL_DRAWING,
    FileCategory.COVER_LETTER,
    FileCategory.TENDER_INVITATION,
    FileCategory.PRICING,
    FileCategory.QUOTE,
    FileCategory.SERVICE_DRAWING,
    FileCategory.SPECIFICATION,
    FileCategory.STRUCTUAL_DRAWING,
    FileCategory.REPORT,
    FileCategory.PERMIT,
    FileCategory.PREVIOUS_WORK,
    FileCategory.OTHER,
]

export const ProfileFileCategories = [
    FileCategory.CAPABILITY_STATEMENT,
    FileCategory.ID,
    FileCategory.INSURANCE,
    FileCategory.LICENSE,
    FileCategory.OTHER
]

export const PhotoCategories = [
    FileCategory.PREVIOUS_WORK,
    FileCategory.SOCIAL,
    FileCategory.OTHER,
]

export const ProjectStatus = {
    DRAFT: 'draft',
    PENDING: 'pending',
    REVISE: 'revise',
    REVISED: 'revised',
    DECLINED: 'declined',
    TENDERING: 'tendering',
    LOCKED: 'locked',
    ALLOCATING: 'allocating',
    CONSTRUCTION: 'construction',
    COMPLETED: 'completed',
    CANCELED: 'canceled',
}

// export const ProjectSector = [
//     'Aged Care',
//     'Automotive',
//     'Commercial (single level or multi-level fitouts)',
//     'Education',
//     'Government',
//     'Health & Medical',
//     'Hospitality',
//     'Industrial',
//     'Mixed Development',
//     'Outdoor / Recreation',
//     'Shopping Centres & Retail',
//     'Other'
// ]

// export const ProjectBudget = [
//     '< $50K', 
//     '$50K - $100K', 
//     '$100K - $500K', 
//     '$500K - $1Mill', 
//     '$1Mill - $3Mill', 
//     '$3Mill - $6Mill',
//     '$6Mill - $10Mill',
//     '$10Mill - $20Mill',
//     '$20Mill - $50Mill',
//     '> $50Mill'
// ];

export const UserGroup = {
    CONTRACTOR: 'contractor',
    SPECIALIST: 'specialist',
    CLIENT: 'client',
    ADMIN: 'admin',
    WORKER: 'worker',
}

export const IWTB_AdditionalServiceTitles = {
    iwtb_project_manager: 'IWTB Project Manager',
    iwtb_site_supervisor: 'IWTB Site Supervisor',
    iwtb_contract_administrator: 'IWTB Contract Administrator',
    iwtb_site_labourer: 'IWTB Site Labourer',
}

export const IWTB_AdditionalServices = {
    IWTB_PROJECT_MANAGER: 'iwtb_project_manager',
    IWTB_SITE_SUPERVISOR: 'iwtb_site_supervisor',
    IWTB_CONTRACT_ADMINISTRATOR: 'iwtb_contract_administrator',
    IWTB_SITE_LABOURER: 'iwtb_site_labourer',
}

export const BidTab = {
    DETAILS: 'Details',
    SOW: 'SOW',
    Documents: 'Documents',
}

export const DataType = {
    PROJECT: 'project',
    ADMIN: 'admin'
}

export const UserGroupIcons = {
    ADMIN: 'i-Cool-Guy',
    CONTRACTOR: 'i-Engineering',
    SPECIALIST: 'i-Idea-2',
    CLIENT: 'i-MaleFemale',
}

export const Industry = [
    'residential',
    'commercial',
    'residential & commercial'
]

// export const clientCategories = [
//     { id: '67f4e371-8045-4ab6-afd1-3150e49e9b36', text: 'Client', category: 'Client' },
//     { id: 'a8433c72-3cfc-4a54-9584-2c7ac52b14be', text: 'Architect', category: 'Client' },
//     { id: '11015bf7-70cc-4c60-aef1-20351233662c', text: 'Client Representative', category: 'Client' },
//     { id: '194037f0-b899-44cb-906f-90e562401d5b', text: 'Project Manager', category: 'Client' },
// ]

// export const specialistCategories = [
//     { id: '658be986-40e9-40e7-92d0-30fbe0156998', text: 'Architect', category: 'Specialist' },
//     { id: '658be986-40e9-40e7-92d0-30fbe0156072', text: 'Air Quality Testing', category: 'Specialist' },
//     { id: '6fd7958c-c06d-48ce-a8b8-31d4f7383e23', text: 'Asbestos Testing', category: 'Specialist' },
//     { id: 'f15e8312-f9ea-478b-8c3a-ca89815024cc', text: 'Authorities & Council', category: 'Specialist' },
//     { id: '82311e22-7acd-4439-864e-7e185ffc6f38', text: 'Certifier', category: 'Specialist' },
//     { id: '929769d7-ac42-4a8f-b2a6-916c71295050', text: 'Consultancy', category: 'Specialist' },
//     { id: 'd9286ab0-5f77-4e51-baff-e2e5a9846433', text: 'Energy Efficiency', category: 'Specialist' },
//     { id: 'c73e1661-8165-4fb4-a40a-1466bd2d9a89', text: 'Engineering (Civil)', category: 'Specialist' },
//     { id: '5d51e07f-dc32-4124-8c5f-0d2c746bd433', text: 'Engineering (Structural)', category: 'Specialist' },
//     { id: 'e493b227-a9b1-4d8f-b96a-87e52600067d', text: 'Engineering (Services)', category: 'Specialist' },
//     { id: '19aaab8e-e8bd-41f2-b684-eb9705fa9055', text: 'Engineering (Acoustic)', category: 'Specialist' },
//     { id: 'a0039599-ea63-410f-9dc9-2f671591fa27', text: 'Feature Lighting', category: 'Specialist' },
//     { id: 'v1239599-ea63-410f-9dc9-2f671591de89', text: 'Fees and Permits', category: 'Specialist' },
//     { id: '63c6adec-36ec-4b41-8fd8-6b94184f8a0c', text: 'Fire Inspection', category: 'Specialist' },
//     { id: 'fda7c024-c370-40c1-90c0-c9dadfaaa975', text: 'GeoTech Service', category: 'Specialist' },
//     { id: '272d6133-91f5-4231-b8e1-a915bc1a9063', text: 'Hygienist', category: 'Specialist' },
//     { id: 'af7b1ab3-de9c-4608-ad92-4dabbd774aeb', text: 'Inspecting General (Inspector)', category: 'Specialist' },
//     { id: '24b0694d-08bb-43c3-9b75-7637e8743e9c', text: 'Service Mains (Power | Water | Sewage | Drainage)', category: 'Specialist' },
//     { id: '9d8c2d92-0f7b-4dba-a095-e2702c2e13ca', text: 'Site Establishment', category: 'Specialist' },
//     { id: '1c0c2d92-0f7b-4dba-a095-e2702c2e14b3', text: 'Specilaised Services', category: 'Specialist' },
//     { id: 'e9880b7d-6e10-4e81-8714-3bb86e52aa31', text: 'Surveryor', category: 'Specialist' },
//     { id: '9795ecb0-bc74-4d82-9648-de5789b5f06d', text: 'Traffic Management', category: 'Specialist' },
//     { id: '0ef45cab-d161-4594-b761-390983bd0bcb', text: 'Quantity Surveyor', category: 'Specialist' },
// ]

// export const tradeCategories = [
//     {
//         id: "add47f00-2139-480d-a768-5c858e0182f9",
//         text: "Air-conditioning",
//         category: "Trade"
//     },
//     {
//         id: "2f1480d6-0db1-4d5d-ab8b-2a86183e7355",
//         text: "Skylights",
//         category: "Trade"
//     },
//     {
//         id: "fdc8b1ac-5d19-4cfd-b97b-995c0fb94507",
//         text: "Polished Concrete",
//         category: "Trade"
//     },
//     {
//         id: "0115e326-c5b6-4a77-9ac0-523d6ac64d71",
//         text: "Garage Doors",
//         category: "Trade"
//     },
//     {
//         id: "8dea6a9d-db4c-4961-8fca-166a3446001e",
//         text: "Access Equipment",
//         category: "Trade"
//     },
//     {
//         id: "ed4fb44c-9b1b-49ad-8235-eef875487530",
//         text: "Access Flooring (Computer Flooring)",
//         category: "Trade"
//     },
//     {
//         id: "87e23adb-6262-4df8-9fbe-8b5c256032ee",
//         text: "Anchoring / Safety Systems",
//         category: "Trade"
//     },
//     {
//         id: "694feb13-ce3e-48ed-8382-e5e496329645",
//         text: "Appliances / White Goods",
//         category: "Trade"
//     },
//     {
//         id: "687a7ac1-08cd-46b5-b591-6b5533118d74",
//         text: "Arborist",
//         category: "Trade"
//     },
//     {
//         id: "ba450154-fa1b-458a-918f-d21570e328fd",
//         text: "Asbestos Removal",
//         category: "Trade"
//     },
//     {
//         id: "13a967cf-473e-410e-a309-37a78297682b",
//         text: "Asphalt Works",
//         category: "Trade"
//     },
//     {
//         id: "94182bce-aeef-494b-b07d-c029ab3d38f9",
//         text: "Audio Visual",
//         category: "Trade"
//     },
//     {
//         id: "9dd20003-f74d-41da-8686-2f24aeddcdf1",
//         text: "Awnings & Shelters",
//         category: "Trade"
//     },
//     {
//         id: "55132242-c454-4765-831b-c75b6aa9eca1",
//         text: "Balustrades / Handrails",
//         category: "Trade"
//     },
//     {
//         id: "ef789128-5cc9-4a46-b968-cd1bfa02705a",
//         text: "Blinds / Curtains",
//         category: "Trade"
//     },
//     {
//         id: "5f1d7c0c-50ec-486c-be38-6b206165b4ed",
//         text: "Bricklaying / Blocklaying",
//         category: "Trade"
//     },
//     {
//         id: "5b0f4b1f-77eb-4120-bab1-2f6fca096831",
//         text: "Call systems / Intercoms",
//         category: "Trade"
//     },
//     {
//         id: "316b2e7e-4c9b-4add-8dfd-3da2966d0a35",
//         text: "Carpentry",
//         category: "Trade"
//     },
//     {
//         id: "d383ef1c-37ea-4cc1-9792-e85415183a01",
//         text: "Carpet Flooring / Floor Tiles",
//         category: "Trade"
//     },
//     {
//         id: "977e7801-d649-4203-b886-8aa5fed500ef",
//         text: "Caulking & Sealing",
//         category: "Trade"
//     },
//     {
//         id: "f63a258d-d278-42d3-9f44-5d97868fa7c9",
//         text: "Civil Works / Road Works",
//         category: "Trade"
//     },
//     {
//         id: "b3577351-59d7-4a80-aeff-a658d18744d3",
//         text: "Cleaning",
//         category: "Trade"
//     },
//     {
//         id: "40a2426d-f8b4-4d2b-bb3e-313e5d264b32",
//         text: "Concreting",
//         category: "Trade"
//     },
//     {
//         id: "00091006-9b38-41af-9988-2216f7fb9282",
//         text: "Concrete Cutting",
//         category: "Trade"
//     },
//     {
//         id: "8fd5c25b-7cf2-4fc6-9acf-0b9a0219e591",
//         text: "Concrete Grinding",
//         category: "Trade"
//     },
//     {
//         id: "a44c2d35-2b2a-4fee-a7d8-f79b826c6cf2",
//         text: "Concrete Pumping",
//         category: "Trade"
//     },
//     {
//         id: "9c2eab52-1892-492a-86dd-b2e8551555ac",
//         text: "Cool Rooms",
//         category: "Trade"
//     },
//     {
//         id: "7467bb30-8282-4a8b-9db4-e7eab5b79629",
//         text: "Core Drilling",
//         category: "Trade"
//     },
//     {
//         id: "0b02ca50-3518-4529-87a3-8f83f50fe958",
//         text: "Craning",
//         category: "Trade"
//     },
//     {
//         id: "25970fc6-f02e-4339-b25c-77b99d3916a7",
//         text: "Data & Communications",
//         category: "Trade"
//     },
//     {
//         id: "fbb38b1c-57cb-4cbc-b9bd-4bdf6015c87b",
//         text: "Demolition",
//         category: "Trade"
//     },
//     {
//         id: "35f989d1-b93b-44ec-8fe7-c2a0bb2a21de",
//         text: "Doors & Jambs (Internal)",
//         category: "Trade"
//     },
//     {
//         id: "d8d57754-2896-44c7-9db6-ce2614edcad2",
//         text: "Door & Jambs (External)",
//         category: "Trade"
//     },
//     {
//         id: "a5f900c2-9bb5-4f65-8607-f58a40c0a09e",
//         text: "Doors & Jambs (Specialised)",
//         category: "Trade"
//     },
//     {
//         id: "94bdeca2-ed8c-43f0-b912-8732132d826d",
//         text: "Door Hardware",
//         category: "Trade"
//     },
//     {
//         id: "5a859bd9-39c5-44c2-a59e-267ab5ee9646",
//         text: "Dwelling Moving & Lifting",
//         category: "Trade"
//     },
//     {
//         id: "804b173f-128b-452e-ba65-836bbccdf7f5",
//         text: "Entry Mats",
//         category: "Trade"
//     },
//     {
//         id: "ee5518fb-fee3-4d5a-a8f7-d4cc4b1b884e",
//         text: "Electrical Services",
//         category: "Trade"
//     },
//     {
//         id: "a841cc8b-03d9-4407-8d38-ed9bf5b87176",
//         text: "Elevator, Lift & Hoist Equipment",
//         category: "Trade"
//     },
//     {
//         id: "a045aed5-f208-4f00-b987-2a3b8996c8c6",
//         text: "Epoxy Flooring",
//         category: "Trade"
//     },
//     {
//         id: "97fafa4d-3174-4232-a562-28c647e20662",
//         text: "Excavating / Earthworks",
//         category: "Trade"
//     },
//     {
//         id: "32192d26-970c-4978-bc7a-190e6022ba66",
//         text: "External Cladding / Aluminium Cladding",
//         category: "Trade"
//     },
//     {
//         id: "4f7bfc73-eb31-4986-9590-db6894adf20d",
//         text: "Facades",
//         category: "Trade"
//     },
//     {
//         id: "cdd3e02e-dcfc-4750-b5d2-77e153f6zd5l",
//         text: "Fees and Permits",
//         category: "Trade"
//     },
//     {
//         id: "cdd3e02e-dcfc-4750-b5d2-77e153f6acba",
//         text: "Fencing / Gates",
//         category: "Trade"
//     },
//     {
//         id: "4492abd7-4ac8-4f64-b201-e7ee68a6d6f4",
//         text: "Film / Decals",
//         category: "Trade"
//     },
//     {
//         id: "c2f1df25-3d03-4505-acfb-f2cb6c10adbf",
//         text: "Fire Services & Protection",
//         category: "Trade"
//     },
//     {
//         id: "d029facb-0a80-4ee0-b318-dcba4d0f23a2",
//         text: "Fixtures & Fittings (FFE)",
//         category: "Trade"
//     },
//     {
//         id: "42a9e27e-3218-49df-b612-3061cdbfe9b7",
//         text: "Floor Finishing",
//         category: "Trade"
//     },
//     {
//         id: "f68f2e52-d1f9-42ff-a011-09d19d6897a1",
//         text: "Floor Grinding",
//         category: "Trade"
//     },
//     {
//         id: "9186de6e-7b47-4cba-8e4b-625a82c59b7d",
//         text: "Flooring (Specialised)",
//         category: "Trade"
//     },
//     {
//         id: "a3122290-35ae-422b-82ec-a5c88ad97f9b",
//         text: "Formworking",
//         category: "Trade"
//     },
//     {
//         id: "2db1d218-b9a5-4814-8126-bf28f5ca739b",
//         text: "Furniture & Fixtures (Internal)",
//         category: "Trade"
//     },
//     {
//         id: "a9a29a1e-3772-4073-88cf-3935bba6e5c5",
//         text: "Furniture & Fixtures (External)",
//         category: "Trade"
//     },
//     {
//         id: "c98c7bea-1ed9-4250-97b4-2c0caf436c83",
//         text: "Furniture Removal",
//         category: "Trade"
//     },
//     {
//         id: "05b2ec3f-99be-47a9-bb36-cb8d39e0f818",
//         text: "Gates (Automatic / Specialised)",
//         category: "Trade"
//     },
//     {
//         id: "91f0c63e-3443-412f-be51-e166c1883816",
//         text: "Hire Equipment / Gantry / Cage",
//         category: "Trade"
//     },
//     {
//         id: "fd013fad-93fa-46ca-b486-50a2109d134c",
//         text: "Hoardings",
//         category: "Trade"
//     },
//     {
//         id: "a8aca17b-0727-4f86-9d69-f4c8dd447b08",
//         text: "Hydraulic Services",
//         category: "Trade"
//     },
//     {
//         id: "c171b19b-e5a7-4262-8f45-10b89fd6be9b",
//         text: "Internal Cladding / Wall Panels",
//         category: "Trade"
//     },
//     {
//         id: "e493d36a-a95a-4d71-97d5-9d6efc245f6a",
//         text: "Insulation",
//         category: "Trade"
//     },
//     {
//         id: "f680e1a7-8289-4485-bd4b-07e32aea07d1",
//         text: "Insulation (Sprayfoam)",
//         category: "Trade"
//     },
//     {
//         id: "60805e14-d647-4949-a114-0d679d6c3d75",
//         text: "Joinery & Shopfitting",
//         category: "Trade"
//     },
//     {
//         id: "940974ac-05fa-4e35-8a1b-858bebb06f6e",
//         text: "Labouring",
//         category: "Trade"
//     },
//     {
//         id: "0d23197f-d42e-41db-bf04-b3a8fb37d9c3",
//         text: "Landscaping",
//         category: "Trade"
//     },
//     {
//         id: "5849a0fb-2f60-4d5d-8526-dc7d5de3986c",
//         text: "Line Marking",
//         category: "Trade"
//     },
//     {
//         id: "99402000-0a04-4576-a44b-f72830512bb8",
//         text: "Louvers / Grilles",
//         category: "Trade"
//     },
//     {
//         id: "3533f1c6-a857-4887-aba2-b0240acda7a8",
//         text: "Louvers (Motorised)",
//         category: "Trade"
//     },
//     {
//         id: "e36ee23f-796c-4881-98b5-0004345b7ad4",
//         text: "Air-Conditioning & Mechanical",
//         category: "Trade"
//     },
//     {
//         id: "66368515-896f-4d9f-82a6-7f7d6a5bf8d3",
//         text: "Metal Working",
//         category: "Trade"
//     },
//     {
//         id: "c05f635c-d1f8-4b6f-8f2f-c90d53750602",
//         text: "Operable Walls",
//         category: "Trade"
//     },
//     {
//         id: "c4232635-d7b9-403f-bd20-c828a7be00af",
//         text: "Painting",
//         category: "Trade"
//     },
//     {
//         id: "a6f10d44-ba3d-402d-9b62-eddfce654c02",
//         text: "Park & Playsets",
//         category: "Trade"
//     },
//     {
//         id: "91d2b596-931d-4b4f-bbc8-039b7a85afd6",
//         text: "Partitioning",
//         category: "Trade"
//     },
//     {
//         id: "b6b4f413-a570-4f8f-99d6-baef39b4af4d",
//         text: "Paving",
//         category: "Trade"
//     },
//     {
//         id: "fb56de36-cbe0-4a4c-9ccf-4fb778285bf1",
//         text: "Pest Controlling",
//         category: "Trade"
//     },
//     {
//         id: "3aa5604d-2310-4169-a4b5-f2c4113fe855",
//         text: "Piling",
//         category: "Trade"
//     },
//     {
//         id: "585b75ce-75c8-42fa-962f-5a840c35e9d6",
//         text: "Plastering / Ceilings",
//         category: "Trade"
//     },
//     {
//         id: "bb3b13dd-c01d-4d6d-9245-c078a25e429b",
//         text: "Plastering (Specialised / Venetian)",
//         category: "Trade"
//     },
//     {
//         id: "bda75b67-f145-4ef4-9ae8-c5d8cd0528d2",
//         text: "Plumbing / Drainage",
//         category: "Trade"
//     },
//     {
//         id: "02913ef1-d541-4c1b-b2f9-4ba7e3786f39",
//         text: "Plumbing (Roofing)",
//         category: "Trade"
//     },
//     {
//         id: "93349923-98a6-46b9-8ff9-8ae189ef228a",
//         text: "Post Tensioning",
//         category: "Trade"
//     },
//     {
//         id: "364e5e36-6e43-493a-8141-da226d87dc52",
//         text: "Precast Concrete Panels",
//         category: "Trade"
//     },
//     {
//         id: "1d7810bb-1679-4ad5-8ad3-1ecee19e8254",
//         text: "Pressure Cleaning / Acid Washing",
//         category: "Trade"
//     },
//     {
//         id: "c08f324b-99d8-494d-8581-800a6018a252",
//         text: "Rendering",
//         category: "Trade"
//     },
//     {
//         id: "affd0813-54d0-4604-a6bf-16df4e74acc0",
//         text: "Resilient Flooring",
//         category: "Trade"
//     },
//     {
//         id: "d72f37c2-5828-40b7-b178-91d7b3fcf655",
//         text: "Retaining Walls",
//         category: "Trade"
//     },
//     {
//         id: "8f286409-9306-46c4-892e-9da4abdadd44",
//         text: "Roller Shutters",
//         category: "Trade"
//     },
//     {
//         id: "0cb16a0b-ea23-4564-a1b4-45bdf38bfd4e",
//         text: "Roof Access & Walkways",
//         category: "Trade"
//     },
//     {
//         id: "1e2eeb72-3e72-4ac6-b3a0-0a4771cbba76",
//         text: "Roofing (Sheets/ Colourbond)",
//         category: "Trade"
//     },
//     {
//         id: "fceb4d0c-7141-4673-a15c-498f2703b2c1",
//         text: "Roofing (Tiled)",
//         category: "Trade"
//     },
//     {
//         id: "b1a7ac84-54d0-444e-a764-40608a7d2567",
//         text: "Rubber Softfall",
//         category: "Trade"
//     },
//     {
//         id: "09d6aa91-f7cb-4389-81b0-a76565b566c5",
//         text: "Scaffolding",
//         category: "Trade"
//     },
//     {
//         id: "21e6e858-e181-4393-8e77-3bf4c2ce05cf",
//         text: "Security (Access Systems)",
//         category: "Trade"
//     },
//     {
//         id: "4fb162b1-ac77-47eb-9c9f-fe2cee80fa6f",
//         text: "Security Screens & Shutters",
//         category: "Trade"
//     },
//     {
//         id: "fcd6eb68-2720-4ea7-b40d-75ba791be978",
//         text: "Security Systems",
//         category: "Trade"
//     },
//     {
//         id: "645c1d34-5d9a-4feb-ad7d-2152ff9f16fb",
//         text: "Shade & Sails",
//         category: "Trade"
//     },
//     {
//         id: "bacb8635-024c-4c2f-a4e9-0bdfa24ab890",
//         text: "Shotcrete",
//         category: "Trade"
//     },
//     {
//         id: "781543e3-23fb-46a5-a265-04fe1aa0eb77",
//         text: "Site Establishment",
//         category: "Trade"
//     },
//     {
//         id: "587543e3-23fb-46a5-a265-04fe1aa0ee15",
//         text: "Signages & Displays",
//         category: "Trade"
//     },
//     {
//         id: "7777d904-91e0-4baf-a045-850c5ffa46de",
//         text: "Slab Scanning/ Service Detection",
//         category: "Trade"
//     },
//     {
//         id: "1e4dacce-4af5-4342-b4d6-1c495e834258",
//         text: "Solar Power Systems",
//         category: "Trade"
//     },
//     {
//         id: "8d0291c3-272b-449b-9971-9c108d745c68",
//         text: "Staircase",
//         category: "Trade"
//     },
//     {
//         id: "51b3597e-ed64-4d2e-baa9-ab0ee66c8eba",
//         text: "Steel fixing",
//         category: "Trade"
//     },
//     {
//         id: "06e27438-8afb-4082-95d2-7faa82995f8f",
//         text: "Stone Masonry",
//         category: "Trade"
//     },
//     {
//         id: "d0ec450a-4990-4041-b229-e7e98dfc5492",
//         text: "Street Works",
//         category: "Trade"
//     },
//     {
//         id: "bab4a292-5f9b-402d-8957-8a30ff4c400e",
//         text: "Structural Services",
//         category: "Trade"
//     },
//     {
//         id: "05c738dd-9c35-4750-83ee-1103b541a44b",
//         text: "Swimming Pools",
//         category: "Trade"
//     },
//     {
//         id: "666f35f8-aef5-4175-ac29-0e51d741ce8c",
//         text: "Synthetic Grass",
//         category: "Trade"
//     },
//     {
//         id: "5beed063-1215-4c52-9e37-5273cd0519bd",
//         text: "Tactile, Treads & Nosing",
//         category: "Trade"
//     },
//     {
//         id: "d3b6bf74-8b73-44a3-9197-fe72ddebf96b",
//         text: "Telecommunications",
//         category: "Trade"
//     },
//     {
//         id: "96b4f194-a20e-4932-82dc-62cf13e133c1",
//         text: "Tiling",
//         category: "Trade"
//     },
//     {
//         id: "6f3ad49c-352a-4e61-bd5a-a2d3d57493a0",
//         text: "Timber Flooring",
//         category: "Trade"
//     },
//     {
//         id: "a44b6b55-eaae-4cc8-a7cb-201dda94f318",
//         text: "Toilet Partitions",
//         category: "Trade"
//     },
//     {
//         id: "86619015-f1bd-4304-bb1c-d2d90f20ea20",
//         text: "Travelators / Escalators",
//         category: "Trade"
//     },
//     {
//         id: "f6d3c065-d566-43e5-81cf-e47563fd6478",
//         text: "Tree & Shrubs",
//         category: "Trade"
//     },
//     {
//         id: "aaba87f6-ea10-4e5b-8fe9-ca3fd708fe61",
//         text: "Turnstiles / Security Gates",
//         category: "Trade"
//     },
//     {
//         id: "54767a55-9211-4733-9396-485c0ae20462",
//         text: "Vermin Barriers & Vermin Protection",
//         category: "Trade"
//     },
//     {
//         id: "3d0b90a0-7cf0-4a7b-afb0-035e0dca72d8",
//         text: "Vinyl Flooring",
//         category: "Trade"
//     },
//     {
//         id: "8e6b6777-4c11-4a0e-b2c9-55dc8b382d20",
//         text: "Wall Paper Installation",
//         category: "Trade"
//     },
//     {
//         id: "ee81e930-01e7-4dd6-a3ea-c3a4e5852f0e",
//         text: "Water Tanks",
//         category: "Trade"
//     },
//     {
//         id: "2d7521f1-8992-4ad1-8e59-d5636abd8bbd",
//         text: "Waste Removal",
//         category: "Trade"
//     },
//     {
//         id: "59a0782f-bcf8-4f6a-ad08-dce25f6e2212",
//         text: "Waterproofing / Sealing",
//         category: "Trade"
//     },
//     {
//         id: "bb1822c8-e1dc-4857-b2d7-e3bf15219f7a",
//         text: "Windows & Glazing",
//         category: "Trade"
//     },
//     {
//         id: "7a0e218a-ddc5-4a67-bbec-e1bf18edeee3",
//         text: "Windows & Glazing (Specialised)",
//         category: "Trade"
//     },
//     {
//         id: "d42ba1e7-c36e-4573-9272-da978ed7dbb7",
//         text: "Workstations",
//         category: "Trade"
//     }
// ]
