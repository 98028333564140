import React from "react";

export const ErrorFallback = ({ error, componentStack, resetErrorBoundary }) => {

    return (
        <div className="not-found-wrap text-center">
            <h1>Something went wrong</h1>
            <p className="mb-5  text-muted text-18">
            We’re sorry, something must have gone wrong. Please try back again
            later.
            </p>
            <p>
                <button type="button" className="btn btn-warning m-1" onClick={resetErrorBoundary}>Return to home</button>
            </p>
        </div>
    );
}
