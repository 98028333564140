import * as yup from 'yup';
import guestService from 'app/guest/guestService';
import { UserGroup } from 'app/utils/constants';
import * as moment from 'moment';
import { parse, isDate } from "date-fns";

const maxLength = 50;
const maxLengthError = `Maximum allowed characters is ${maxLength}`;

export const registrationSchema = yup.object().shape({

  // required fields

  trades: yup.array().min(1, 'Enter one or more items'),
  mobile: yup.string().required('Mobile is required').test('len', 'Must be 10 digits', val => val ? val.length === 10 : false),
  reCaptchaToken: yup.string().required('reCaptcha is required'),
  firstName: yup.string().required('First name is required').test('len', maxLengthError, val => val ? val.length <= maxLength : false),
  lastName: yup.string().required('Surname is required').test('len', maxLengthError, val => val ? val.length <= maxLength : false),
  DOB: yup.date()
    .required('Date of birth is required')
    .transform(parseDateString)
    .typeError('Invalid date format. Use DD/MM/YYYY format')
    .max(moment().add(-18, 'years').toDate(), 'You must be at least 18'),
  role: yup.string().required('Role is required').test('len', maxLengthError, val => val ? val.length <= maxLength : false),
  email: yup.string().email('Invalid email').required('Email is required').test('len', maxLengthError, val => val ? val.length <= maxLength : false),
  industry: yup.string().required('Industry is required'),
  beenBankrupt: yup.string().required('Please answer Yes or No'),
  precludeWork: yup.string().when('userGroup', {
    is: UserGroup.CONTRACTOR,
    then: yup.string().required('Please answer Yes or No')
  }),
  backgroundCheck: yup.string().when('userGroup', {
    is: UserGroup.CONTRACTOR,
    then: yup.string().required('Please answer Yes or No')
  }),
  safetyPack: yup.string().when('userGroup', {
    is: UserGroup.CONTRACTOR,
    then: yup.string().required('Please answer Yes or No')
  }),
  financialGuarantee: yup.string().when('userGroup', {
    is: UserGroup.CLIENT,
    then: yup.string().required('Please answer Yes or No')
  }),
  username: yup.string()
    .required('Username is required')
    .min(4, 'A minimum of 4 characters is required')
    .max(40, 'Maximum allowed characters is 40')
    .test('is-exist', 'Username already exists', async (username) => {
      if (username && username.length >= 4) {
        const result = await guestService.validateUsername(username);
        return result.error ? false : true;
      }
    }),
  password: yup.string().required('Password is required'),
  repassword: yup.string().required('Confirm password is required').oneOf([yup.ref('password'), null], 'Passwords must match'),
  acceptedTerms: yup.boolean().oneOf([true], 'You must agree before submitting'),

  // not required fields

  title: yup.string().test('len', 'Maximum allowed characters is 10', val => val ? val.length <= 10 : true),
  about: yup.string().test('len', maxLengthError, val => val ? val.length <= maxLength : true),
  businessName: yup.string().test('len', maxLengthError, val => val ? val.length <= maxLength : true),
  ABN: yup.string().test('len', 'Must be 11 digits', val => val ? val.length === 11 : true),
  businessPhone: yup.string().test('len', 'Must be 10 digits', val => val ? val.length === 10 : true),
  businessFax: yup.string().test('len', 'Must be 10 digits', val => val ? val.length === 10 : true),
  businessEmail: yup.string().email('Invalid email').test('len', maxLengthError, val => val ? val.length <= maxLength : true),
  website: yup.string().url('https://www.iwtb.com.au').test('len', maxLengthError, val => val ? val.length <= maxLength : true),
  businessYearsOperating: yup.number().positive('Must be greater than or equal to 0').integer('Must be an integer'),
  businessReferences: yup.string().test('len', maxLengthError, val => val ? val.length <= maxLength : true),
  
});

function parseDateString(value, originalValue) {

  const parsedDate = isDate(originalValue)
  ? originalValue
  : parse(originalValue, "dd/MM/yyyy", new Date());

  return parsedDate;
}