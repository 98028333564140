import React, { useState, useEffect } from 'react';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { IwtbFilePreview } from 'app/widgets';
import { FileCategoryClass } from 'app/utils/constants';
import { saveAs } from 'file-saver';
import { downloadBlobArchive, groupBy } from 'app/utils/common';
import { NotificationManager } from 'react-notifications';
import { Button, Badge } from 'react-bootstrap';

export const IwtbFileRegistry = ({ files, showSearch, handleDeleteFile }) => {

  const [filesByCategory, setFilesByCategory] = useState([]);
  const [previewFile, setPreviewFile] = useState();

  const { SearchBar, ClearSearchButton } = Search;

  const columns = [
    {
      dataField: 'index',
      text: 'Category',
      headerAlign: 'center',
      sort: true,
      headerStyle: () => ({ width: '160' }),
      formatter: ((cell, row) => { 
        return <Badge className={FileCategoryClass[row.group]}>{row.group}</Badge>
      })
    },
    {
      dataField: 'count',
      text: 'Count',
      align: 'center',
      headerAlign: 'center',
      headerStyle: () => ({ width: '90px' }),
      formatter: ((cell, row) => (row.count ?? 0))
    },
  ];

  const fileColumns = [
    {
      dataField: 'index',
      text: '#',
      align: 'center',
      headerAlign: 'center',
      headerStyle: () => ({ width: '40px' })
    },
    {
      dataField: 'name',
      text: 'File Name',
      headerAlign: 'center',
      sort: true,
      formatter: ((cell, row) => { 
        return <span className='cursor-pointer text-primary' onClick={() => handlePreviewFile(row)}>{row.name}</span>          
      })
    },
    {
      dataField: 'size',
      text: 'Size',
      headerAlign: 'center',
      align: 'right',
      sort: true,
      headerStyle: () => ({ width: '100px' }),
      formatter: ((cell, row) => { return (row.size / 1024 / 1024).toFixed(2) + ' MB' })
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '80px' }),
      formatter: ((cell, row) => { 
        return <>
          <span onClick={() => handlePreviewFile(row)} className="cursor-pointer text-success me-2">
            <i className="nav-icon i-Landscape font-weight-bold"></i>
          </span>
          {handleDeleteFile &&
            <span onClick={() => handleDeleteFile(row)} className="cursor-pointer text-danger me-2 ml-2">
              <i className="nav-icon i-Close-Window font-weight-bold"></i>
            </span>
          }
        </>
      })
    },
  ];

  useEffect(_ => {

    if (files) {
      const filesByCategory = groupBy(files, 'category');
      setFilesByCategory(filesByCategory);
    }
  }, [files]);  

  const downloadAll = () => {

    if (filesByCategory?.length > 0) {
      filesByCategory.map(async category => {
          try {
            // load file from BLOB
            const url = await downloadBlobArchive(category.files);
            if (url.error) {
              return NotificationManager.error(`Unable to download '${category.group}' archive.`, 'Download All', 6000);      
            }
            saveAs(url, `${category.group}.zip`);
          }
          catch {
            NotificationManager.error(`Category ${category.name} failed to download.`, 'File Preview', 3000);
          }    
      })
    }
  }

  const handlePreviewFile = file => {

    setPreviewFile(); // reset preview
    setTimeout(() => setPreviewFile(file), 0);
  }

  const expendRow = {

    parentClassName: 'table-primary',
    showExpandColumn: true,
    renderer: row => (
      <BootstrapTable 
        keyField='index' 
        data={ row.files } 
        columns={ fileColumns } 
        defaultSorted={[{ dataField: 'created', order: 'asc'}]}
      />
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
      if (expanded) {
        return <i className='nav-icon i-Arrow-Down font-weight-900 text-primary cursor-pointer'></i>    
      }
      else {
        return <i className='nav-icon i-Arrow-Right font-weight-900 text-primary cursor-pointer'></i>    
      }
    },
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (''),
  }

  return (
    <>
      <ToolkitProvider
        keyField='index'
        data={filesByCategory}
        columns={columns}
        search
        exportCSV
      >
        {toolkitProps => (
          <>
            {showSearch && <div className='d-flex justify-content-end align-items-center'>
              <span className='mb-2 mr-1'>Search:</span>
              <SearchBar {...toolkitProps.searchProps} className='mb-0 ' />
              <ClearSearchButton { ...toolkitProps.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
              <button className='btn btn-outline-success mb-2 mr-1' onClick={_ => toolkitProps.csvProps.onExport()}>Export CSV</button>
              <button className='btn btn-outline-primary mb-2 mr-1' onClick={downloadAll}>Download All</button>
            </div>}
            <BootstrapTable
              {...toolkitProps.baseProps}
              bootstrap4
              wrapperClasses="table-responsive"
              noDataIndication={'No records found'}
              expandRow={ expendRow }
              defaultSorted={[{ dataField: 'category', order: 'asc'}]}
              condensed
            />
          </>
        )}
      </ToolkitProvider>

      <IwtbFilePreview file={previewFile} />
    </>
  );
}
