import React, { useEffect, useState } from 'react';
import { useAuthState } from 'app/auth';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import { Dropdown } from 'react-bootstrap';
import { humanReadableDate } from 'app/utils/time';
import { useNotificationState } from './notificationContext';
import { useNotificationDispatch, getUserNewNotifications } from 'app/notification';
import { useSocket } from 'app/socket.io';
import { useHistory } from "react-router-dom";
import { setNotificationByType } from './functions';
import service from './notificationService';

export const NotificationList = () => {

    const socket = useSocket()
    const notificationState = useNotificationState();
    const notificationDispatch = useNotificationDispatch();
    const authState = useAuthState();
    const [notificationList, setNotificationList] = useState([]);
    const history = useHistory();

    useEffect(() => {

        const notifications = notificationState.newNotifications;
        if (notifications) {
            const list = notifications.map(n => setNotificationByType(n, authState));
            setNotificationList(list);
        }
    }, [notificationState]);
     
    useEffect(() => {

        if (authState.isAuthenticated) {
            getUserNewNotifications(notificationDispatch, authState.user.id);
        }

        const onUserNotification$ = socket.onUserNotification().subscribe(() => {

            if (authState.isAuthenticated) {
                getUserNewNotifications(notificationDispatch, authState.user.id)
            }
        })
        return() => {
            onUserNotification$.unsubscribe()
        }
    }, [authState]);

    const handleOnClick = async notification => {

        if (notification.path) {
            history.push({ pathname: notification.path })
        }
        await service.markAsRead(authState.user.id, notification.id)
        getUserNewNotifications(notificationDispatch, authState.user.id);
    }
    
    return(<>
        <Dropdown>
            <Dropdown.Toggle as="span" className="toggle-hidden cursor-pointer">
            <div
                className="badge-top-container"
                role="button"
                id="dropdownNotification"
                data-toggle="dropdown"
            >
                <span className="badge badge-primary">{notificationList.length}</span>
                <i className="i-Bell text-muted header-icon"></i>
            </div>
            </Dropdown.Toggle>
            
            <DropdownMenu className="notification-dropdown rtl-ps-none">
            {notificationList.map((note, index) => (
                <div key={index} className="dropdown-item d-flex" onClick={() => handleOnClick(note)}>
                    <div className="notification-icon">
                        <i className={`${note.icon} text-${note.color} mr-1`}></i>
                    </div>
                    <div className="notification-details flex-grow-1">
                        <p className="m-0 d-flex align-items-center">
                        <span>{note.title}</span>
                        <span
                            className={`badge badge-pill badge-${note.color} ml-1 mr-1`}
                        >
                            {note.status}
                        </span>
                        <span className="flex-grow-1"></span>
                        <span className="text-small text-muted ml-auto">
                            {humanReadableDate(note.time)} ago
                        </span>
                        </p>
                        <p className="text-small text-muted m-0">
                        {note.description}
                        </p>
                    </div>
                </div>
            ))}
            </DropdownMenu>
        </Dropdown>
    </>);
}