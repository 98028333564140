import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { constructCompanyName } from 'app/utils/common';
import { formatDateTime12 } from 'app/utils/time';
import { Badge } from "react-bootstrap";
import { StatusClass } from 'app/utils/constants';
import { IwtbFileRegistry } from 'app/widgets';

export const RfiWidget = (props) => {
  
  const history = useHistory();
  const [rfi, setRfi] = useState();

  useEffect(_ => {

    if (props.rfi) {
      setRfi(props.rfi);
    }
  }, [props.rfi]);

  const handleClose = () => {
    history.goBack();
  }

  return (
    <>
      { rfi && 
        <div className="row">
          <div className="col-md-8">
            <div className="card">

              <div className="card-header bg-transparent">
                  <div className='d-flex flex-row justify-content-between'>
                    <h5>Project: <strong>{rfi.projectName}</strong></h5>
                    <h5>RFI Status: <Badge className={StatusClass[rfi.status.toUpperCase()]}>{rfi.status}</Badge></h5>
                  </div>
              </div>
              
              <div className="card-body">
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="firstName">Trade</label>
                    <div className="form-control">{rfi.trade.text}</div>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="firstName">Company</label>
                    <div className="form-control">{constructCompanyName(rfi.contractor)}</div>
                  </div>
                </div>
                <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="firstName">Submitted</label>
                    <div className="form-control">{formatDateTime12(rfi.created)}</div>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="firstName">Replied</label>
                    <div className="form-control">{rfi.response ? formatDateTime12(rfi.response.created) : ''}</div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label htmlFor="firstName">Subject</label>
                    <div className="form-control">{rfi.subject}</div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label htmlFor="firstName">Message</label>
                    <textarea rows={6} readOnly className="form-control">{rfi.message}</textarea>          
                  </div>
                </div>

                { rfi.files && rfi.files.length > 0 &&

                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="firstName">Attachments</label>
                      <IwtbFileRegistry files={rfi.files} />
                    </div>
                  </div>
                }

              </div>
  
              <div className="card-footer">
                <div className="mc-footer">
                  <div className="row">
                    <div className="col-md-12">
                      <button type="button" className="btn btn-outline-warning m-1 footer-delete-right" onClick={handleClose}>Close</button>
                    </div>
                </div>
              </div>
            </div>
           
           </div>

            <div className="card mt-4">

              { rfi.response && <>

                <div className="card-header bg-transparent"><h4>Response</h4></div>
                <div className="card-body">

                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="firstName">Message</label>
                      <textarea rows={6} readOnly className="form-control">{rfi.response.message}</textarea>          
                    </div>
                  </div>

                  { rfi.response.files && rfi.response.files.length > 0 &&

                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="firstName">Attachments</label>
                        <IwtbFileRegistry files={rfi.response.files} />
                      </div>
                    </div>
                  }           
                </div>

                <div className="card-footer">
                  <div className="mc-footer">
                    <div className="row">
                      <div className="col-md-12">
                        <button type="button" className="btn btn-outline-warning m-1 footer-delete-right" onClick={handleClose}>Close</button>
                      </div>
                    </div>
                  </div>
                </div>

              </> }
            
            </div>

        </div>
      </div> }
    </>
  );
}
