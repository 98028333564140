import React, { useEffect, useState, useRef, Children, isValidElement, cloneElement } from 'react'
import { createCustomEqual } from 'fast-equals'
import { isLatLngLiteral } from '@googlemaps/typescript-guards'

export const Map = ({ onClick, children, style, ...options }) => {

  const ref = useRef(null)
  const [map, setMap] = useState()

  let infoWindow

  const handleLocationError = (browserHasGeolocation, infoWindow, pos) => {
    
    infoWindow.setPosition(pos);
    infoWindow.setContent(browserHasGeolocation ?
      'Error: The Geolocation service failed.' :
      'Error: Your browser doesn\'t support geolocation.');
  }

  const setCurrentPosition = () => {

    if (navigator.geolocation) {

      infoWindow = new window.google.maps.InfoWindow()

      navigator.geolocation.getCurrentPosition(position => {
          const pos = { lat: position.coords.latitude, lng: position.coords.longitude };
  
          // infoWindow.setPosition(pos);
          // infoWindow.setContent('Location found.');
          // infoWindow.open(map)
          map.setCenter(pos);
      }, 
      () => {
          handleLocationError(true, infoWindow, map.getCenter());
      });
    } 
    else {
        // Browser doesn't support Geolocation
        handleLocationError(false, infoWindow, map.getCenter());
    }    
  }

  useEffect(() => {
        
    if (ref.current) {
      if (!map) {
        setMap(new window.google.maps.Map(ref.current, {})) 
      }
      else {
        setCurrentPosition()
      }
    }
  }, [ref, map])

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {

    if (map) {
      map.setOptions(options)
    }
  }, [map, options])

  useEffect(() => {

    if (map) {
      ['click', 'idle'].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      )

      if (onClick) {
        map.addListener('click', onClick)
      }
    }
  }, [map, onClick])

  return (
    <>
      <div ref={ref} style={style} />
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          // set the map prop on the child component
          return cloneElement(child, { map })
        }
      })}
    </>
  )
}

const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a, b) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof window.google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof window.google.maps.LatLng
    ) {
      return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b))
    }

    // TODO extend to other types

    // use fast-equals for other objects
    return deepEqual(a, b)
  }
)

function useDeepCompareMemoize(value) {
  const ref = useRef()

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

function useDeepCompareEffectForMaps(
  callback,
  dependencies
) {
  useEffect(callback, dependencies.map(useDeepCompareMemoize))
}
