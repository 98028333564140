import React, { useEffect, useState, useCallback } from "react";
import { Breadcrumb } from '@gull';
import { Tabs, Tab, Alert, Button, Badge } from "react-bootstrap";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useParams, useHistory } from "react-router-dom";
import { ProfileCardTab, SubscriptionStatus, StatusClass, UserGroup } from 'app/utils/constants';
import profileService from './profileService';
import { CompanyWidget, ProjectsWidget, UserWidget } from '.';
import { useAuthState } from 'app/auth';
import { IwtbFileRegistry } from 'app/widgets';
import { ProfileCardWidget } from 'app/profile';
import { customTabHeader, handleOpenChat, deleteUserFile } from 'app/utils/common';
import { formatDate } from 'app/utils/time';

export const ContractorCard = (props) => {

  let { contractorId, tab } = useParams();
  const [contractor, setContractor] = useState();
  const [projects, setProjects] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [subscription, setSubscription] = useState()
  const [showRemoveFile, setShowRemoveFile] = useState(false)
  const authState = useAuthState();
  const history = useHistory();
  const [defaultActiveKey, setDefaultActiveKey] = useState(ProfileCardTab.ABOUT);

  const getContractorProfile = useCallback(() => {

    profileService.getContractorProfile(authState.user.id, contractorId)
    .then(contractor => {

      if (contractor && contractor.error) {
        return NotificationManager.error(contractor.message, 'Server Connection', 3000);      
      }

      if (contractor && contractor.id) {
        setContractor(contractor);
        profileService.getContractorSubscription(authState.user.id, contractorId)
        .then(subscription => {
          if (!subscription || subscription?.error) {
          }
          else {
            setSubscription(subscription)
          }
        })
        .catch(e => { NotificationManager.error('Something went wrong.', 'Server Connection', 3000) });
      }
      else {
        setTimeout(() => setNotFound(true), 2000);
      }
    })
    .catch(e => { NotificationManager.error('Something went wrong.', 'Server Connection', 3000) });
  });

  const getContractorProjects = useCallback(() => {

    profileService.getContractorProjects(authState.user.id, contractorId)
    .then(projects => {

      if (projects && projects.error) {
        return NotificationManager.error(projects.message, 'Server Connection', 3000);      
      }

      if (projects && Array.isArray(projects)) {
        setProjects(projects);
      }
    })
    .catch(e => { NotificationManager.error('Something went wrong.', 'Server Connection', 3000) });
  });

  useEffect(() => {

    const projectTab = tab === 'undefined' || tab === undefined ? ProfileCardTab.ABOUT : tab;
    setDefaultActiveKey(projectTab);
  }, [tab]);

  useEffect(() => {

    if (contractorId && authState.isAuthenticated) {
      getContractorProfile();
      getContractorProjects();
      setShowRemoveFile(authState.user.userGroup === UserGroup.ADMIN)
    }
  }, [contractorId, authState.user]);

  const handleClose = () => {
    
    history.goBack();
  }

  const handleDeleteUserFile = async file => {

    await deleteUserFile(contractorId, file)
    getContractorProfile()
  }

  return (
    <div>
      <Breadcrumb routeSegments={[
        { name: `Contractor Profile (${contractor?.status})` }
      ]}></Breadcrumb>

      { contractor && <>

        <section className="ul-contact-detail">
          <div className="row">
            <div className="col-lg-3 col-xl-3">
              <ProfileCardWidget user={contractor} handleOpenChat={() => handleOpenChat(authState, history, contractor.id)}/>
            </div>
            
            <div className="col-lg-9 col-xl-9">
              <div className="card  mb-4">
                
                <div className="card-body">
                  
                <Tabs defaultActiveKey={defaultActiveKey}>

                  <Tab eventKey={ProfileCardTab.ABOUT} title={customTabHeader(ProfileCardTab.ABOUT, "i-Engineering")}>
                    <UserWidget user={contractor} />
                  </Tab>

                  <Tab eventKey={ProfileCardTab.COMPANY} title={customTabHeader(ProfileCardTab.COMPANY, "i-Building")}>
                    <CompanyWidget user={contractor} />
                  </Tab>

                  <Tab eventKey={ProfileCardTab.PROJECTS} title={customTabHeader(ProfileCardTab.PROJECTS, "i-Gears")}>
                    <ProjectsWidget projects={projects} />
                  </Tab>

                  {authState.user.userGroup === UserGroup.ADMIN && <Tab eventKey={ProfileCardTab.SUBSCRIPTION} title={customTabHeader(ProfileCardTab.SUBSCRIPTION, 'i-Dollar-Sign')}>
                    <div className="row">
                        <div className="col-md-12">
                            {subscription && <Alert variant={subscription.status === SubscriptionStatus.ACTIVE ? 'light' : 'warning'}>
                                <div className='d-flex flex-row justify-content-between'>
                                    <span><strong>Status: </strong><Badge className={StatusClass[subscription.status.toUpperCase()]}>{subscription.status}</Badge></span>
                                    <span><strong>{subscription.status === SubscriptionStatus.ACTIVE ? 'Expires On: ' : 'Expired On: '}</strong> {formatDate(subscription.end)}</span>
                                </div>
                            </Alert>}
                        </div>
                    </div>
                  </Tab>}
                  
                  <Tab eventKey={ProfileCardTab.DOCUMENTS} title={customTabHeader(ProfileCardTab.DOCUMENTS, "i-Files")}>
                    <IwtbFileRegistry files={contractor.files ?? []} showSearch={true} handleDeleteFile={showRemoveFile ? handleDeleteUserFile : null} />
                  </Tab>                        
                            
                  <Tab eventKey={ProfileCardTab.PHOTOS} title={customTabHeader(ProfileCardTab.PHOTOS, 'i-Picasa')}>
                    <IwtbFileRegistry files={contractor.photos ?? []} showSearch={true} handleDeleteFile={showRemoveFile ? handleDeleteUserFile : null} />
                  </Tab>                        
                </Tabs>

                </div>
                
                <div className="card-footer">
                  <div className="mc-footer">
                    <div className="row">
                      <div className="col-md-12">
                        <button type="button" className="btn btn-warning m-1 footer-delete-right" onClick={handleClose}>Cancel & Return</button>
                      </div>
                    </div>

                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </section>

      </>}
      
      { !contractor && notFound && <>
        <Alert className="text-center alert-card" variant="danger">
          Something went wrong. Profile was not found.
          <Button variant="warning" className="btn ml-3" onClick={_ => {props.history.goBack();}}>Return</Button>
        </Alert>
      </>}

      <NotificationContainer />
  </div>
  );  
}
