import React, { useEffect, useState, useMemo } from "react";
import { Breadcrumb } from '@gull';
import { Tabs, Tab, Button, Badge, Alert } from "react-bootstrap";
import { AdminNavigation } from '../adminRoutes';
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useParams, useHistory } from "react-router-dom";
import { ProjectTab, ProjectAction } from '../constants';
import { ProjectDetailsWidget, AccessRegisterWidget } from '../widgets';
import adminService from '../adminService';
import bidService from '../bid/bidService';
import { IwtbFileRegistry, IwtbModal, TradesWidget, AddTradeWidget } from 'app/widgets';
import { audFormatter, StatusClass, ProjectStatus, BidAction, BidTab, AllocationAction, TradeAction } from 'app/utils/constants';
import { RfiRegisterWidget } from '../rfi';
import { BidRegisterCategoryWidget, AwardRegisterWidget } from '../bid';
import { getProjectBids } from './functions';
import { useAuthState } from 'app/auth';
import { calcProjectTotal, handleOpenChat, deleteTrade, deleteService, mapServices, getTrades, navigateTo, deleteProjectFile } from 'app/utils/common';
import { InviteUserWidget } from 'app/user';
import { ServiceRegisterWidget } from '../service'
import { ProfileCardWidget } from 'app/profile';
import profileService from 'app/profile/profileService';
import { useSocket } from 'app/socket.io';
import { RefreshType, refreshOnNotification } from 'app/notification'
import { useSpinner } from 'app/IwtbLayout/SharedComponents/spinner/SpinnerContext'

export const ProjectCard = () => {

  const { projectId, tab } = useParams();
  const [project, setProject] = useState();
  const [defaultActiveKey, setDefaultActiveKey] = useState(ProjectTab.DETAILS);
  const [showAddTrade, setShowAddTrade] = useState(false);
  const [awardRegister, setAwardRegister] = useState([]);
  const [bidRegister, setBidRegister] = useState([]);
  const [services, setServices] = useState([]);
  const [trades, setTrades] = useState([]);
  const history = useHistory();
  const [notFound, setNotFound] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [showInviteFriend, setShowInviteFriend] = useState(false);
  const [client, setClient] = useState();
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState('');
  const socket = useSocket()
  const authState = useAuthState();
  const spinner = useSpinner()

  const projectTotal = useMemo(() => calcProjectTotal(awardRegister, services), [awardRegister, services]);

  const getProject = React.useCallback(() => {

    if (!(projectId && authState.isAuthenticated)) {
      return
    }

    spinner.show()

    adminService.getProject(authState.user.id, projectId)
    .then(async project => {
      spinner.hide()

      if (project && project.error) {
        return NotificationManager.error(project.message, 'Server Connection', 3000);      
      }

      if (project && project.id) {
        setProject(project);
        
        setReadOnly(project.status === ProjectStatus.DECLINED || project.status === ProjectStatus.CANCELED);

        if (project.clientId && !client) {
          // load client profile
          profileService.getClientProfile(authState.user.id, project.clientId)
          .then(client => {
            if (client && client.id) {
              setClient(client);
            }
            else {
              NotificationManager.error('Something went wrong.', 'Server Connection', 3000);
            }
          })
          .catch(e => { NotificationManager.error('Something went wrong.', 'Server Connection', 3000) });
        }

        if (project.additionalServices) {
          const services = mapServices(project.additionalServices);
          setServices(services);    
        }

        if (project.awardRegister) {
          setAwardRegister(project.awardRegister);
        }
        const result = await getTrades(bidService, authState.user.id, project.id);
        setTrades(result.trades);
    
        loadProjectBids(authState.user.id, projectId);
      }
      else {
        setTimeout(() => setNotFound(true), 2000);
      }
    })
    .catch(e => { 
      spinner.hide()
      NotificationManager.error('Something went wrong.', 'Server Connection', 3000) 
    });
  });

  useEffect(() => {

    const onUserNotification$ = socket.onUserNotification().subscribe(notification => {

      refreshOnNotification(notification.type, RefreshType.ADMIN_PROJECT_CARD, getProject)
    })
    return() => {
        onUserNotification$.unsubscribe()
    }
  }, []);

  useEffect(() => {

    const projectTab = tab === 'undefined' || tab === undefined  ? ProjectTab.DETAILS : tab;
    setDefaultActiveKey(projectTab);
  }, [tab]);

  useEffect(() => {

    getProject(projectId);
  }, [projectId, authState.user]);
  
  const loadProjectBids = (adminId, projectId) => {

    getProjectBids(adminId, projectId).then(result => setBidRegister(result));
  }

  const handleBidAction = (bid, action) => {

    switch(action) {

      case BidAction.VIEW_COMMENTS:
        setComments(bid.comments)
        setShowComments(true)
        break 

      case BidAction.VIEW_DELETE_REASON:
        setComments(bid.deleteReason)
        setShowComments(true)
        break;
  
      case BidAction.REQUEST_SOW:
        history.push({
          pathname: AdminNavigation.SUBMIT_SOW.replace(':projectId', project.id).replace(':bidId', bid.id),
        });            
        break; 
  
      case BidAction.VIEW_SOW:
        history.push({
          pathname: AdminNavigation.BID_CARD.replace(':projectId', project.id).replace(':bidId', bid.id).replace(':tab?', BidTab.SOW),
        });            
        break;

      case BidAction.BID_CARD:
        const path = AdminNavigation.BID_CARD.replace(':projectId', project.id).replace(':bidId', bid.id).replace(':tab?', BidTab.DETAILS)

        history.push({ pathname: path });            
        break;
      }
  }

  const handleTradeAction = (trade, action) => {

    switch(action) {

      case TradeAction.VIEW_DELETE_REASON:
        setComments(trade.deleteReason)
        setShowComments(true)
        break;
      }
  }

  const handleUpdateTrades = async newTrades => {

    try {
      let result = await adminService.updateTrades(authState.user.id, project.id, newTrades);
      if (result.error) {
        NotificationManager.error('Semething went wrong', 'Trades', 3000);      
      }
      result = await getTrades(bidService, authState.user.id, project.id);
      setTrades(result.trades);
      NotificationManager.success('Trades were updated', 'Trades', 3000);      
    }
    catch {
      NotificationManager.error('Semething went wrong', 'Trades', 3000);      
    }
    finally {
      setShowAddTrade(false);
    }
  }

  const handleApproveTrade = async (tradeId) => {

    try {

      await adminService.approveTrade(authState.user.id, project.id, tradeId);

      const result = await getTrades(bidService, authState.user.id, project.id);
      setTrades(result.trades);

      NotificationManager.success('Trade change has beeen approved.', 'Trades', 2000);  
    }
    catch {
      NotificationManager.error('Sorry, something went wrong.', 'Trades', 3000);
    }
  }

  const handleDeleteTrade = async (tradeId) => {

    try {
      await deleteTrade(adminService, authState.user.id, project.id, tradeId);

      const result = await getTrades(bidService, authState.user.id, project.id);
      setTrades(result.trades);

      NotificationManager.success('Trade change was submitted successfully.', 'Trades', 2000);  
    }
    catch {
      NotificationManager.error('Sorry, something went wrong.', 'Trades', 3000);
    }
  }

  const handleRestoreTrade = async (tradeId) => {

    try {

      await adminService.restoreTrade(authState.user.id, project.id, tradeId);

      const result = await getTrades(bidService, authState.user.id, project.id);
      setTrades(result.trades);

      NotificationManager.success('Trade has beeen restored.', 'Trades', 2000);  
    }
    catch {
      NotificationManager.error('Sorry, something went wrong.', 'Trades', 3000);
    }
  }

  const handleProjectAction = (action, project) => {

    switch(action) {

      case ProjectAction.SUBMIT_BID:
        return navigateTo({ history: history, navigation: AdminNavigation, pathname: AdminNavigation.SUBMIT_BID, project: project });

      case ProjectAction.VIEW_CLIENT:
        return navigateTo({ history: history, navigation: AdminNavigation, pathname: AdminNavigation.CLIENT_CARD, project: project });

      case ProjectAction.EDIT_PROJECT:
        return navigateTo({ history: history, navigation: AdminNavigation, pathname: AdminNavigation.APPROVE_PROJECT, project: project });
    }
  }

  const getProjectServices = () => {

    adminService.getProjectServices(authState.user.id, projectId).then(project => {
      if (project?.error) {
        return NotificationManager.error(project.error, 'Additional Services', 5000);      
      }
      if (project?.additionalServices) {
        setServices(project?.additionalServices)
      }
    });
  }

  const handleAllocation = () => {
    
    spinner.show()
    
    const items = mapServices(services);
    adminService.updateServices(authState.user.id, project.id, items)
    .then(result => {
      if (result?.error) {
        return NotificationManager.error(result.message, "Allocation", 6000);
      }
      NotificationManager.success("Allocation was successful.", "Allocation", 2000);
      spinner.hide()
    })
    .catch(ex => {  
      spinner.hide() 
      NotificationManager.error("Allocation was unsuccessful.", "Allocation", 6000);
    });
  }

  const handleDeleteService = async (serviceId) => {

    try {

      await deleteService(adminService, authState.user.id, projectId, serviceId);

      getProjectServices()

      NotificationManager.success('Service was deleted successfully.', 'Additional Services', 2000);  
    }
    catch {
      NotificationManager.error('Sorry, something went wrong.', 'Additional Services', 3000);
    }
  }

  const handleServiceAction = (action, service) => {

    switch(action) {

      case AllocationAction.TIMESHEET:
        return NotificationManager.warning('Not implemented.', 'Timesheet', 3000);

      case AllocationAction.INVOICE:
        return NotificationManager.warning('Not implemented.', 'Invoice', 3000);
  
      case AllocationAction.DELETE_SERVICE:
        return handleDeleteService(service.id)
  
      case AllocationAction.CONTACT_CLIENT:
        return handleOpenChat(authState, history, project.clientId);
    }
  }

  const handleDeleteFile = async file => {

    await deleteProjectFile(authState.user.id, projectId, file, adminService)
    getProject()
  }

  return (
    <div>
      { project && <Breadcrumb
        routeSegments={[
          { name: "My Projects", path: AdminNavigation.PROJECTS },
          { name: project.projectName }
        ]}
      ></Breadcrumb>}

      { project && <>

        <section className="ul-contact-detail">
          <div className="row">
            <div className="col-lg-3 col-xl-3">
              <div className="card o-hidden">
              <div className="card-body">
                  <div className="ul-widget1">
                    <div className="ul-widget__item">
                      <div className="ul-widget__info">
                        <h3 className="ul-widget1__title">Current Value</h3>
                        <span className="ul-widget__desc text-mute">Awarded bids</span>
                      </div>
                      <span className="ul-widget__number text-primary">{audFormatter.format(projectTotal)}</span>
                    </div>
                    <div className="ul-widget__item">
                      <div className="ul-widget__info">
                        <h3 className="ul-widget1__title">Services</h3>
                        <span className="ul-widget__desc text-mute">Our services</span>
                      </div>
                      <span className="ul-widget__number text-danger">{project.additionalServices ? project.additionalServices.length : 0}</span>
                    </div>
                    <div className="ul-widget__item">
                      <div className="ul-widget__info">
                        <h3 className="ul-widget1__title">RFI</h3>
                        <span className="ul-widget__desc text-mute">Request for information</span>
                      </div>
                      <span className="ul-widget__number text-warning">{project.rfiRegister ? project.rfiRegister.length : 0}</span>
                    </div>
                    <div className="ul-widget__item">
                      <div className="ul-widget__info">
                        <h3 className="ul-widget1__title">Bids</h3>
                        <span className="ul-widget__desc text-mute">Registered bids</span>
                      </div>
                      <span className="ul-widget__number text-success">{project.bidRegister ? project.bidRegister.length : 0}</span>
                    </div>
                    <div className="ul-widget__item">
                      <div className="ul-widget__info">
                        <h3 className="ul-widget1__title">Awards</h3>
                        <span className="ul-widget__desc text-mute">Awarded trades</span>
                      </div>
                      <span className="ul-widget__number text-info">{awardRegister ? awardRegister.length : 0}</span>
                    </div>

                  </div>
                </div>
              </div>

              {client && <div className='mt-3'>
                <ProfileCardWidget user={client} handleOpenChat={() => handleOpenChat(authState, history, client.id)} handleViewProfile={() => handleProjectAction(ProjectAction.VIEW_CLIENT, project)} />
              </div>}

            </div>

            <div className="col-lg-9 col-xl-9">
              <div className="card mb-4">
                <div className="card-header bg-transparent">
                    <div className='d-flex flex-row justify-content-between'>
                      <span>Status: <Badge className={StatusClass[project.status.toUpperCase()]}>{project.status}</Badge></span>
                      {project.revision && <span>Revision: {project.revision}</span>}
                      {project.ref && <span>Ref: {project.ref}</span>}
                    </div>
                </div>

                <div className="card-body">

                  <Tabs defaultActiveKey={defaultActiveKey}>

                    <Tab eventKey={ProjectTab.DETAILS} title={ProjectTab.DETAILS}>
                      <ProjectDetailsWidget project={project} />
                    </Tab>
                    
                    <Tab eventKey={ProjectTab.ACCESS} title={ProjectTab.ACCESS}>
                        <AccessRegisterWidget project={project} />                  
                    </Tab>
                    
                    <Tab eventKey={ProjectTab.RFI} title={ProjectTab.RFI}>
                      <RfiRegisterWidget rfiRegister={project.rfiRegister} readOnly={readOnly} />                  
                    </Tab>
                    
                    <Tab eventKey={ProjectTab.BIDS} title={ProjectTab.BIDS}>                        
                      <BidRegisterCategoryWidget bidRegister={bidRegister} handleBidAction={handleBidAction} handleSubmitBid={() => handleProjectAction(ProjectAction.SUBMIT_BID, project)} />
                    </Tab>
                    
                    <Tab eventKey={ProjectTab.AWARDS} title={ProjectTab.AWARDS}>
                      <AwardRegisterWidget awardRegister={awardRegister} readOnly={true} />
                      <ServiceRegisterWidget services={services} 
                        handleServiceChange={services => setServices(services)} 
                        handleAction={handleServiceAction} 
                        handleAllocation={handleAllocation} 
                        handleReset={getProjectServices}
                      />
                    </Tab>
                                        
                    <Tab eventKey={ProjectTab.TRADES} title={ProjectTab.TRADES}>{ 
                      readOnly ?
                        <TradesWidget trades={trades} readOnly={true} />
                        :
                        <TradesWidget trades={trades} title='Trade' 
                          onAddTrade={() => setShowAddTrade(true)} 
                          deleteTrade={tradeId => handleDeleteTrade(tradeId)} 
                          approveTrade={tradeId => handleApproveTrade(tradeId)} 
                          restoreTrade={tradeId => handleRestoreTrade(tradeId)} 
                          handleTradeAction={handleTradeAction}
                        />
                      }
                    </Tab>
                    
                    <Tab eventKey={ProjectTab.FILES} title={ProjectTab.FILES}>
                      <IwtbFileRegistry files={project.files} showSearch={true} handleDeleteFile={handleDeleteFile} />
                    </Tab>

                  </Tabs>
                </div>
              
                <div className="card-footer">
                  <div className="mc-footer">
                    <div className="row">
                      <div className="col-md-12">
                        {!(project.status === ProjectStatus.DECLINED || project.status === ProjectStatus.CANCELED) && <>
                          <Button variant="primary" className="btn-icon m-1 text-capitalize" onClick={() => handleOpenChat(authState, history, client.id)}>
                            <span className="ul-btn__icon">
                              <i className="i-Speach-Bubbles"></i>
                            </span>
                            <span className="ul-btn__text"> Contact Client</span>
                          </Button>
                        </>}
                        <button type="button" className="btn btn-secondary m-1 mr-2 footer-delete-right" onClick={_ => {history.goBack()}}>Close</button>
                        <button type="button" className="btn btn-warning m-1 footer-delete-right" onClick={() => handleProjectAction(ProjectAction.EDIT_PROJECT, project)}>Edit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
 
        <InviteUserWidget title='Invite a Friend Form' show={showInviteFriend} handleCloseInvite={() => setShowInviteFriend(false)} />    
        <AddTradeWidget show={showAddTrade} currentTrades={trades} onSubmit={trades => handleUpdateTrades(trades)} onClose={() => setShowAddTrade(false)}></AddTradeWidget>
      </>}

      { !project && notFound && <>
        <Alert className="text-center alert-card" variant="danger">
          Something went wrong. Project was not found.
          <Button variant="warning" className="btn ml-3" onClick={_ => {history.goBack();}}>Return</Button>
        </Alert>
      </>}

      <NotificationContainer />
      <IwtbModal show={showComments} title='Comments' message={comments} handleClose={() => setShowComments(false)} />
    </div>
  );
}
